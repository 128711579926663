import { createContext, useState } from "react";
import { getUserInfoFromLocalStorage } from "../services/auth";

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [plan, setPlan] = useState(getUserInfoFromLocalStorage)

  return (
    <UserContext.Provider value={{ plan, setPlan }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext;