import React, { useContext, useEffect, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input, } from '@material-ui/core';

import TagsContext from '../../context/TagsContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function TagsList({ savedTags, setDeletedTags, selectedTags, setSelectedTags }) {
	const { tags } = useContext(TagsContext);

	const [plainTags, setPlainTags] = useState([]);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		// Como los datos obtenidos son un array de {id: 1, nombre: "tag1"} lo convierto a un array de ["tag1", "tag2"] que así lo necesito en <TagsList/>
		const aux = [];
		selectedTags.forEach(tag => {
			aux.push(tag.nombre);
		});
		setPlainTags(aux);
	}, [selectedTags]);

	// Función encargada de convertir un array de strings ['tag1','tag2'] en un array de objetos con la propiedad {id, nombre}
	const makeObjectArray = strArr => {
		// strArr podrá ser por ejemplo ['Fuerza motriz', 'Iluminacion']

		const strSet = new Set(strArr);

		const result = [];

		// A partir de los tags leídos en la DB
		tags.forEach(tag => {
			// Si tengo este tag en el set
			if (strSet.has(tag.nombre)) {
				// Agrego un objeto al resultado
				result.push({ nombre: tag.nombre, id: tag.id });
			}
		});

		return result;
	};

	// Función encargada de comparar los elementos seleccionados con los que leí de la base de datos para determinar aquellos que eliminé.
	const detectDeleted = strArr => {
		// strArr podrá ser por ejemplo ['Fuerza motriz', 'Iluminacion']

		// Crear un set a partir de los elementos seleccionados en el select
		const strSet = new Set(strArr);

		// Si un tag que leí de la DB no forma parte de esta selección significa que lo eliminé
		const deletedTags = savedTags.filter(savedTag => !strSet.has(savedTag.nombre));

		return deletedTags;
	};

	const hanldeOpenClose = () => {
		setOpen(!open)
	}
	const handleChange = e => {
		const obj = makeObjectArray(e.target.value);
		setSelectedTags(obj);
		const obj2 = detectDeleted(e.target.value);
		setDeletedTags(obj2);
		setOpen(false);
	}

	return (
		<div>
			{tags.length === 0 ?
				<div style={{ fontWeight: '100', }}>
					Usted no posee etiquetas.
				</div>
				:
				<FormControl sx={{ m: 1, width: "95%" }}>
					<Select
						labelId='tags-select-label'
						id='tags-select'
						multiple
						onOpen={hanldeOpenClose}
						onClose={hanldeOpenClose}
						value={plainTags}
						onChange={handleChange}
						input={<Input />}
						MenuProps={MenuProps}
					>
						{tags.map(tag => (
							<MenuItem
								key={tag.id}
								data-index={tag.id}
								value={tag.nombre}
							>
								{tag.nombre}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			}

		</div>
	);
}
