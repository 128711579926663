import instance from './testing.instance';

export const getReports = () => instance.get(`/reportes/`);
export const getReportsPage = page => instance.get(`/reportes?page=${page}`);
// export const getReportById = id => instance.get(`/reportes/${id}`);
export const generateReport = id => instance.get(`/reportes/${id}/generar_reporte/`);
export const getLastReport = id => instance.get(`/reportes/${id}/ultimo_reporte/`, { responseType: 'blob' });
export const testSendEmails = id => instance.get(`/reportes/${id}/test_send_emails/`);

export const createReport = body => instance.post(`/reportes/`, body);
export const createAutomaticReport = (id, body) => instance.post(`/reportes/${id}/automaticos/`, body);

export const editReport = (id, body) => instance.put(`/reportes/${id}/`, body);
export const updateAutomaticReport = (id, id2, body) => instance.put(`/reportes/${id}/automaticos/${id2}/`, body);

export const deleteReport = id => instance.delete(`/reportes/${id}/`);
