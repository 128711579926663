import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core';
// Material UI
import {
	Box, Dialog, Chip, IconButton,
	TextField, MenuItem, Select, CircularProgress,
	List, Button, FormControl, Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
//Helpers
import { blueBar } from '../../helpers/common'
import { format } from 'date-fns';
//Componentes
import DatePickerBill from '../DatePicker/DatePickerBill';
import SubBillFilterNode from './SubBillFilterNode';
//Servicios
import {
	getNodesTree
} from '../../services/hierarchy';
import {
	createSublocalizationPeriod,
	getDisabledDaysFilterUnderBilling, getListPeriodLocalization,
	getSublocalizationPeriod
} from '../../services/billing';
import { getStatusNodes } from '../../services/meters';

let scrollsSettings = {
	overflow: "scroll",
	height: "90vh",
	overflowX: "hidden",
}
let webkitScrollbar = {
	background: "white",
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: "#beb1b18f",
	color: "red",
	width: "10px"
}
let webkitscrollbarTrack = {
	background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2, 4),
		'& .MuiGrid-root': {
			padding: theme.spacing(1),
		},
		"@media (max-width: 720px)": {
			padding: theme.spacing(0),
		},
	},
	addBtn: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: theme.spacing(2),
		width: theme.spacing(2),
		marginLeft: theme.spacing(2),
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(1),
	},
	circularDevicesBox: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: "40%",
	},
	cancelBtn: {
		height: theme.spacing(2),
		width: theme.spacing(2),
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.main,
	},
	labelPeriod: {
		fontSize: 16,
		color: "red"
	},
	btnSpacing: {
		margin: theme.spacing(0, 1),
	},
	containerSelectMainFactura: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginTop: "20px",
		gap: theme.spacing(3)
	},
	hint: {
		backgroundColor: 'rgba(0, 0, 0, 0.38)',
		color: theme.palette.common.white,
		height: theme.spacing(3),
		width: theme.spacing(3),
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	tick: {
		backgroundColor: theme.palette.success.light,
	},
	containerSelect: {
		// padding: theme.spacing(2),
		//son los estilos de las barras scroll en una variable.
		...scrollsSettings,
		'&::-webkit-scrollbar': {
			//son los estilos de las barras scroll en una variable.
			...webkitScrollbar
		},
		'&::-webkit-scrollbar-track': {
			//son los estilos de las barras scroll en una variable.
			...webkitscrollbarTrack
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.primary.main,
		},
		"@media (max-width: 700px)": {
			padding: theme.spacing(0, 0, 0, 1),
		}
	},
	filterChip: {
		fontSize: 15,
		fontWeight: "500",
	},
	labelDate: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.main,
	},
	wrapperDatepicker: {
		position: "relative",
		width: "100%",
		left: "50%",
		transform: "translate(-50%)",
		textAlign: "center",
		marginBottom: "20px"
	},
	steppersFilter: {
		"@media (max-width: 920px)": {
			display: "none",
		},
		'&.MuiStep-horizontal': {
			paddingLeft: "0",
			paddingRight: "0"
		}
	},
	containerPeriod: {
		display: "flex",
		alignItems: "center",
		marginRight: theme.spacing(3),
		"@media (max-width: 720px)": {
			flexWrap: "wrap"
		}
	},
	containerPeriodSelect: {
		width: "600px",
		marginLeft: theme.spacing(3),
		"@media (max-width: 720px)": {
			marginTop: theme.spacing(2),
		}
	},
	devicesLabel: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
		marginLeft: theme.spacing(2),
	},
	wordCut: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
}));

function Filter({
	setFilterPanel, filterPanel,
	setLoadingCointainerMain,
	setShowAlertInfo, setShowContentMain, setFilterState,
	filterState, setSelectPeriod, selectPeriod,
	location, setLocation, setClose, setMsg, setLocationAux, locationAux

}) {
	const classes = useStyles();
	const blueLine = blueBar()
	//show select periodo
	const [showPeriod, setShowPeriod] = useState(false);
	//loading para selector de periodos
	const [loadingPeriod, setLoadingPeriod] = useState(false);
	//loading para la caja de dispositivos.
	const [loadingDevices, setLoadingDevices] = useState(false);
	const [nodes, setNodes] = useState([]);
	//Steeps options
	const [activeStep, setActiveStep] = useState(0);
	//Variable que indica que tengo que cargar una factura nueva
	const [newBill, setNewBill] = useState(false);
	//estado de la lista de periodos existentes en la localizacion
	const [listPeriod, setListPeriod] = useState([])
	//estado del formulario 
	const [stateDataForm, setStateDataForm] = useState({})
	//estado para abrir el modal de nodos de el filtro
	const [openModalTree, setOpenModalTree] = useState(false)
	//Estado q maneja el disabled del input de las fechas
	const [displayDisabled, setDisplayDisabled] = useState(true)
	//Estados q manejan el inicia y final de el datePicker
	const [endDate, setEndDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date());
	//estado para desabilitar dias en el datePicker
	const [datePickerDisabled, setDatePickerDisabled] = useState({
		no_disponibles: [],
	});
	let variation = !newBill ? "Consultar" : "Nuevo"
	const steps = [
		'Elija la localización de la cual desea consultar la factura',
		'Elija o cree periodo',
		'Seleccione las fechas a cargar o consultar',
		'Haga click en ' + variation
	];
	const [loadingButton, setLoadingButton] = useState(false)
	const [statusNodes, setStatusNodes] = useState([]);

	//Verifica status de el medidor.
	useEffect(() => {
		const getStatusNodesAux = async () => {
			let res = await getStatusNodes()
			let arry = res.data
			setStatusNodes(arry)
		}
		getStatusNodesAux()
	}, [])

	//OBTENGO TREE
	useEffect(() => {
		const fetchNodesTreeData = async () => {
			try {
				const res = await getNodesTree();
				setNodes(res.data);
				setLoadingDevices(true)
			}
			catch (error) {
				// console.log(error.response.data.msg)
			}
		};
		fetchNodesTreeData();
	}, []);

	// Función encargada de colocar mayúsculas en la primera letra de cada palabra
	const capitalizeFirstLetter = str => {
		const arr = str.toLowerCase().split(' ');
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		return arr.join(' ');
	};

	//funcion para deshabilitar el boton 'consultar' 'nuevo'
	const disabledButton = () => {
		if (location?.id && locationAux?.id && selectPeriod?.id) {
			return false
		}
		if (location?.id && locationAux?.id && stateDataForm?.name?.length > 3) {
			return false
		}
		return true
	}

	//maneja el estado de el selector de la lista de periodos existentes
	const handleChangePeriod = async (e) => {
		setLoadingButton(true)
		setActiveStep(3)
		let periodoData = await getSublocalizationPeriod(e.target.value)
		setSelectPeriod(periodoData?.data)
		let from = periodoData?.data.date_from
		let to = periodoData?.data.date_to
		setStartDate(new Date(from + ':03:00 GMT'))
		setEndDate(new Date(to + ':03:00 GMT'))
		setLoadingButton(false)
	}

	//maneja estado de formulario
	const handleBillData = (e) => {
		setStateDataForm({
			...stateDataForm,
			[e.target.name]: e.target.value
		})
	}

	//Funcion para cerrar el modal de los tree
	const handleCloseDevicesDialog = () => {
		setOpenModalTree(false);
	};

	const nodeList = () => {
		return nodes.map(node => <SubBillFilterNode node={node} handleSelectedNode={handleSelectedNode} key={node.id} statusNodes={statusNodes} />);
	};

	const handleCancelFilterDialog = () => {
		setFilterPanel(!filterPanel);
		setLocationAux({})
	};

	const handleSelectedNode = async (elem) => {
		try {
			setShowAlertInfo(true)
			setShowContentMain(false)
			setNewBill(false)
			setLoadingCointainerMain(false)
			setLoadingPeriod(false)
			setShowPeriod(true)
			setLocation(elem)
			setLocationAux(elem)
			// estado del filtro
			setFilterState({
				...filterState,
				location: elem
			})
			setOpenModalTree(false)
			let disabledDays = await getDisabledDaysFilterUnderBilling(elem.id)
			setDatePickerDisabled(disabledDays.data)
			//listas de subFacturas si existen
			let list_period = await getListPeriodLocalization(elem.id)
			setListPeriod(list_period.data)

			setLoadingPeriod(true)
			setActiveStep(1)
		}
		catch (error) {
			// console.log(error.response.data.msg)
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			//si estoy creando periodo nuevo!
			if (newBill) {
				// console.log("creo un periodo")
				let start = format(startDate, 'yyyy-MM-dd')
				let end = format(endDate, 'yyyy-MM-dd')
				let body = {
					name: stateDataForm?.name,
					date_from: start,
					date_to: end,
					location: location?.id
				}
				let res = await createSublocalizationPeriod(body)
				setSelectPeriod(res?.data)
			}
			//si ya tengo un periodo lo selecciono!
			setShowContentMain(true)
			setShowAlertInfo(false)
			setTimeout(() => {
				setLoadingCointainerMain(false)
			}, [1000])

		}
		catch (error) {
			setClose(true)
			let msg = "Ocurrio un error inesperado."
			if (error?.response?.data?.msg) {
				msg = error?.response?.data?.msg
			}
			setMsg({
				msg: msg,
				type: "error"
			})
			setLoadingCointainerMain(false)
		}
		setFilterPanel(false)
	}

	return (
		<>
			<Stepper activeStep={activeStep} className={classes.steppersFilter} alternativeLabel>
				{steps.map(label => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<Dialog
				open={openModalTree}
				onClose={handleCloseDevicesDialog}
				aria-labelledby='form-dialog-title'
				// maxWidth='lg'
				fullWidth
				disableScrollLock
			>
				<Grid className={classes.containerSelect}>
					{
						!loadingDevices ?
							<Box className={classes.circularDevicesBox}>
								<CircularProgress />
							</Box>
							:
							<>
								{
									nodes.length === 0 &&
									<h1 style={{ textAlign: "center", fontSize: "19px", color: "#2586bc", paddingTop: "20px" }}>
										Usted no posee ninguna localización configurada en su cuenta.<br></br>
										Por favor, configure al menos una localización para poder continuar.
										<NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
											[link]
										</NavLink>
									</h1>
								}
								<List >
									<div  >{nodeList()}</div>
								</List>
							</>
					}
				</Grid>
			</Dialog>

			<form className={classes.root} onSubmit={e => handleSubmit(e)}>

				{blueLine}

				{/* STEP 1 */}
				<Box mb={2} mt={2} className={classes.containerSelectMainFactura}>
					<Box display="flex" alignItems={"center"} >
						<Tooltip title='Elija la localización de la cual desea consultar la factura' arrow placement='right'>
							<div className={clsx(classes.hint, { [classes.tick]: activeStep !== 0 })}>
								{activeStep > 0 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
							</div>
						</Tooltip>
						<Box ml={2} className={classes.devicesLabel} >
							Selecciona una Localización
						</Box>
						<IconButton
							aria-label="agregar dispositivos"
							className={classes.addBtn}
							onClick={() => {
								setOpenModalTree(true)
							}}  >
							<AddIcon fontSize="small" />
						</IconButton>
					</Box>
					{
						locationAux?.nombre &&
						<Box >
							<Chip
								label={locationAux?.nombre ? locationAux?.nombre : "Elija una localización"}
								clickable
								color='primary'
								className={classes.filterChip}
								onClick={() => {
									setOpenModalTree(true);
								}}
							/>
						</Box>
					}
				</Box>

				{blueLine}

				{/* STEP 2 */}
				<Box mt={2} style={{ fontWeight: "400" }} display="flex" alignItems={"center"} >
					<Tooltip title='Elija o cree periodo' arrow placement='right'>
						<div className={clsx(classes.hint, { [classes.tick]: activeStep !== 0 })}>
							{activeStep > 1 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
						</div>
					</Tooltip>
					<Box ml={2} className={classes.devicesLabel}>
						Selecciona un periodo
					</Box>
				</Box>

				<Box mb={2} display="flex" alignItems="center" flexWrap={"wrap"} className={clsx(classes.label)}>
					<Box className={classes.selectNuevaFactura}>
						{
							location.id && !newBill && locationAux.id &&
							<Box ml={3} mt={3} >
								Nuevo Periodo
								<IconButton
									aria-label='nueva Factura'
									className={classes.addBtn}
									onClick={() => {
										setLoadingCointainerMain(false)
										setActiveStep(2)
										setNewBill(true)
										setDisplayDisabled(false)
										setEndDate(new Date())
										setStartDate(new Date())
										setSelectPeriod({})
										setShowPeriod(false)
									}}
								>
									<AddIcon fontSize='small' />
								</IconButton>
							</Box>
						}
						{
							location.id && newBill &&
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<TextField
									autoFocus
									placeholder='Nombre'
									name='name'
									// value={billData.name ? billData.name : ''}
									onChange={e => {
										handleBillData(e);
									}}
									color='primary'
								/>
								<IconButton
									aria-label='cancelar'
									className={classes.cancelBtn}
									onClick={() => {
										setActiveStep(1);
										setNewBill(false)
										setDisplayDisabled(true)
										setSelectPeriod({})
										setStateDataForm({})
										setShowPeriod(true)
										// setLoadingPeriod(true)
									}}
								>
									<CancelIcon fontSize='small' />
								</IconButton>
							</Box>
						}
					</Box>
					{/* SI TIENE LISTA DE PERIODOS APARECE EL SELECTOR DE LISTAS. */}
					{
						showPeriod &&
						<Box mt={3} className={classes.containerPeriodSelect}   >
							{
								!loadingPeriod ?
									(
										<div className={classes.circular}>
											<CircularProgress size={20} />
										</div>
									)
									:
									<Box  >
										{
											listPeriod.length > 0 && !newBill ?
												<Box className={classes.containerPeriod}>
													<Box mr={3} className={classes.label}>Periodos</Box>
													<FormControl fullWidth >
														<Select
															// disabled={activeStep === 0 ? true : false}
															labelId='period'
															name='period'
															defaultValue=''
															onChange={e => {
																setActiveStep(2);
																handleChangePeriod(e)
															}}>
															{
																listPeriod?.map(bill => (
																	<MenuItem key={bill.id} value={bill.id}>
																		{capitalizeFirstLetter(bill.name)}

																	</MenuItem>
																))
															}
														</Select>
													</FormControl>
												</Box>
												:
												(
													<Box className={classes.labelPeriod}>
														(No posee periodos!)
													</Box>
												)
										}
									</Box>
							}
						</Box>
					}

				</Box>

				{blueLine}

				{/* STEP 3 */}
				<Box display="flex" alignItems={"center"}>
					<Tooltip title='Seleccione las fechas del Factura a consultar' arrow placement='right'>
						<div className={clsx(classes.hint, { [classes.tick]: activeStep > 2 })}>
							{activeStep > 2 ? <DoneIcon style={{ padding: '2px' }} /> : 3}
						</div>
					</Tooltip>
					<Box ml={2} className={classes.devicesLabel}>
						Seleccione las fechas a consultar
					</Box>
				</Box>

				<Box width={"100%"} textAlign={"center"} className={classes.wrapperDatepicker} >
					<label className={classes.labelDate} >
						Desde / Hasta
					</label>
					<DatePickerBill
						setActiveStep={setActiveStep}
						datePickerDisabled={datePickerDisabled}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						displayDisabled={displayDisabled}
					/>
				</Box>


				<Box display='flex' justifyContent='center'>

					{loadingButton ?
						<Box mr={2} mt={1}>
							<CircularProgress size={17} />
						</Box>
						:
						<>
							<Button
								// disabled={!buttonCheck}
								variant='contained'
								color='primary'
								type='submit'
								disabled={disabledButton()}
								className={classes.btnSpacing}
								onClick={() => {
									setFilterPanel(false);
									setLoadingCointainerMain(true)
								}
								}
							>
								{newBill ? 'Generar' : 'Consultar'}
							</Button>
						</>
					}


					<Button variant='contained' onClick={handleCancelFilterDialog} className={classes.btnSpacing}>
						Cancelar
					</Button>
				</Box>
			</form >
		</>
	);
}
export default Filter;
