import React, { useContext } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { toFormattedNumber } from '../../../helpers/common';
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { DefaultDevice } from './Summary';

const useStyles = makeStyles(theme => ({
	tituloHeaderBox: {
		margin: "0px 0px 30px 30px",
		fontSize: "18px"
	},
	table: {
		height: "240px",
		"@media (max-width: 400px)": {
			height: "200px"
		}
	},
	headerCell: {
		fontWeight: 'bold',
		fontSize: 14,
		padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
		"&.MuiTableCell-head": {
			color: theme.palette.common.white,
		}
	},
	cell: {
		padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
		fontWeight: 'bold',
	},
	lastRead: {
		marginTop: "20px",
		textAlign: 'center'
	}
}));

export default function PowerTableChart({ data }) {
	const classes = useStyles();
	const defaultDevice = useContext(DefaultDevice);
	const { isMobile } = useDeviceDetect();

	const createData = (titulo, faseR, faseS, faseT, total) => {
		return { titulo, faseR, faseS, faseT, total };
	}

	const rows = [
		createData('Potencia activa', toFormattedNumber(data?.f?.[0]?.p / 1000, "kW"), toFormattedNumber(data?.f?.[1]?.p / 1000, "kW"), toFormattedNumber(data?.f?.[2]?.p / 1000, "kW"), toFormattedNumber((data?.f?.[0]?.p + data?.f?.[1]?.p + data?.f?.[2]?.p) / 1000, "kW")),
		createData('Potencia reactiva', toFormattedNumber(data?.f?.[0]?.q / 1000, "kVAR"), toFormattedNumber(data?.f?.[1]?.q / 1000, "kVAR"), toFormattedNumber(data?.f?.[2]?.q / 1000, "kVAR"), toFormattedNumber((data?.f?.[0]?.q + data?.f?.[1]?.q + data?.f?.[2]?.q) / 1000, "kVAR")),
		createData('Tensión', toFormattedNumber(data?.f?.[0]?.v, "V"), toFormattedNumber(data?.f?.[1]?.v, "V"), toFormattedNumber(data?.f?.[2]?.v, "V"), '-'),
		createData('Corriente', toFormattedNumber(data?.f?.[0]?.i, "A"), toFormattedNumber(data?.f?.[1]?.i, "A"), toFormattedNumber(data?.f?.[2]?.i, "A"), '-'),
	];

	return (
		<Table className={classes.table} aria-label='simple table'>
			<TableHead style={{ backgroundColor: `${defaultDevice.producto === 'SMART' ? '#00C3A5' : '#594F96'}` }}>
				<TableRow>
					<TableCell className={classes.headerCell}></TableCell>
					<TableCell className={classes.headerCell} align='center'>Fase R</TableCell>
					<TableCell className={classes.headerCell} align='center'>Fase S</TableCell>
					{
						defaultDevice.producto === 'SMART' &&
						(
							<>
								<TableCell className={classes.headerCell} align='center'>Fase T</TableCell>
							</>
						)
					}
					{!isMobile && <TableCell className={classes.headerCell} align='center'>Total</TableCell>}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map(row => (
					<TableRow key={row.titulo} >
						<TableCell component='th' scope='row'>
							{row.titulo}
						</TableCell>
						<TableCell className={classes.cell} align='center' style={{ color: `${defaultDevice.producto === 'SMART' ? '#00C3A5' : '#594F96'}` }}>{row.faseR}</TableCell>
						<TableCell className={classes.cell} align='center' style={{ color: `${defaultDevice.producto === 'SMART' ? '#00C3A5' : '#594F96'}` }}>{row.faseS}</TableCell>
						{
							defaultDevice.producto === 'SMART' &&
							(
								<>

									<TableCell className={classes.cell} align='center' style={{ color: `${defaultDevice.producto === 'SMART' ? '#00C3A5' : '#594F96'}` }}>{row.faseT}</TableCell>
								</>
							)}
						{!isMobile && <TableCell className={classes.cell} align='center' style={{ color: `${defaultDevice.producto === 'SMART' ? '#00C3A5' : '#594F96'}` }}>{row.total}</TableCell>}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
