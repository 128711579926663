import { useEffect, useState } from 'react';

// MUI
import { Box, CircularProgress } from '@material-ui/core';
import { Alert } from '@mui/material';

// Components
import DashBar from './DashBar';

// Services
import { getHistoryV2 } from '../../../services/meters';

// Date-fns
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

function EnergyMonth({ id }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHistory = async url => {
      try {
        setLoading(true);
        setData([])
        setShowErrorAlert(false);

        const res = await getHistoryV2(url)

        setLoading(false);
        setData(res?.data);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        } else {
          setErrorMessage(`Ocurrió un error inesperado`);
        }
        setShowErrorAlert(true);
      }
    };
    //  /meters/electric/energy/monthly?
    //  filter = 1271 & year=2023 & showPhase=false
    const currentYear = new Date().getFullYear();


    fetchHistory(`filter=${id}&year=${currentYear}&fields=kwh&showPhase=false&period=monthly`);
  }, [id]);
  return (
    <DashBar
      name="bar"
      title="Consumo de energía"
      subtitle={`este año ${format(new Date(), 'yyyy', { locale: es })}`}
      data={data}
    >
      {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}
      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
    </DashBar>
  );
}

export default EnergyMonth;
