import React, { useEffect, useState } from 'react';
// Material UI
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    makeStyles,
} from '@material-ui/core'
//Servicios
import { getNotificationPreferencesNodes } from '../../services/notifications';
//Componentes
import NodeNotificationSettings from './NodeNotificationSettings';

let scrollsSettings = {
    overflow: 'scroll',
    height: '50vh',
    marginBottom: '10px',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    circular: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
    },
    devicesBtn: {
        margin: theme.spacing(3, 1),
    },
    dialogSubtitle: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
        '@media (max-width: 700px)': {
            display: 'none',
        },
    },
    dialogTitle: {
        paddingBottom: 0,
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(3),
    },
    containerNodes: {
        paddingRight: '2px',
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '@media (max-width: 1100px)': {
            width: '100%',
        },
    },
}));

function ModalNodeNotificationSettings({ open, setOpen, settingNotificationNodeState, handleCloseModalNodeNotificationSettings }) {
    const classes = useStyles();
    const [nodes, setNodes] = useState([]);
    const [loadingNodes, setLoadingNodes] = useState(true);

    useEffect(() => {
        const getNotificationPreferencesNodesAux = async () => {
            setLoadingNodes(true)
            let res = await getNotificationPreferencesNodes()
            setNodes(res?.data?.preferencias_nodo)
            setLoadingNodes(false)
        }
        getNotificationPreferencesNodesAux()
    }, [])

    //Modifica title para ciertos tipo de alertas.
    const remplaceTitle = (name) => {
        if (name === 'CortesConectividad') {
            return 'Cortes de Conectividad'
        }
        else if (name === 'CortesEnergia') {
            return 'Cortes de Energia'
        }
        return name
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseModalNodeNotificationSettings}
                aria-labelledby="form-dialog-title"
                maxWidth={'lg'}
                fullWidth
                disableScrollLock
            >

                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    {`Configuración ${remplaceTitle(settingNotificationNodeState?.type)}`}
                </DialogTitle>

                <Box className={classes.dialogSubtitle}>
                    Puede utilizar el switch para activar o desactivar sus notificaciones.
                </Box>

                <Box padding="8px">

                    <Box component="label" display="block" className={classes.label}>
                        Dispositivos
                    </Box>

                    <Box className={classes.containerNodes}>
                        {loadingNodes ? (
                            <div className={classes.circular}>
                                <CircularProgress />
                            </div>
                        ) :
                            <>
                                {nodes.map(node => (
                                    <NodeNotificationSettings
                                        settingNotificationNodeState={settingNotificationNodeState}
                                        node={node}
                                        key={node.id}
                                    />
                                ))}
                            </>
                        }
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => (setOpen(false))}
                            className={classes.devicesBtn}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Box>
            </Dialog >
        </>
    );
}

export default ModalNodeNotificationSettings;
