import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { React, useEffect, useState } from 'react';
import { Fragment } from 'react';
const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(2, 5, 2, 10),
		color: theme.palette.primary.main,
		width: 'calc(100% - 80px - 40px)',
	},
	leftContainer: {
		paddingRight: theme.spacing(3),
	},
	rightContainer: {
		borderLeft: `1px solid ${theme.palette.primary.main}`,
		paddingLeft: theme.spacing(3),
		height: 'calc(100vh - 48px - 64px)',
	},
	form: {
		marginLeft: theme.spacing(2),
		'& .MuiInputBase-root': {
			color: '#666666',
		},
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	btn: {
		margin: theme.spacing(3, 1),
	},
	tableContainer: {},
	headerRow: {
		background: theme.palette.primary.main,
		color: 'white',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	subHeaderRow: {
		background: theme.palette.primary.light,
		color: 'white',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	tableTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	spacer: {
		borderRight: '1px solid white',
	},
	tableSubTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	tableLeftTitle: {
		padding: theme.spacing(0.5),
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		background: theme.palette.grey[300],
		color: theme.palette.primary.light,
		paddingLeft: theme.spacing(0.5),
	},
	firstRow: {
		padding: theme.spacing(0.5),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderBottom: `1px solid ${theme.palette.primary.main}`,
		borderRight: `1px solid ${theme.palette.primary.main}`,
		// borderTop: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
	},
	row: {
		padding: theme.spacing(0.5),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderBottom: `1px solid ${theme.palette.primary.main}`,
		borderRight: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
	},
	lastRow: {
		padding: theme.spacing(0.5),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRight: `1px solid ${theme.palette.primary.main}`,
		borderBottom: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
	},
	transparent: {
		color: theme.palette.grey[400],
	},
}));

/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
Number.prototype.round = function (places) {
	return +(Math.round(this + 'e+' + places) + 'e-' + places);
};

export default function EnergyTable({ tableData, billData }) {
	const classes = useStyles();
	const [ranges, setRanges] = useState(['total']);
	const [load, setLoad] = useState(false);
	const [tagOpen, setTagOpen] = useState(false);

	useEffect(() => {
		//Obtengo array con las franjas horarias
		setLoad(false);
		tableData?.real?.slots?.cost.forEach(slot => {
			setRanges([...ranges, slot.name]);
			return
		});
		setLoad(true);
		if (tableData.tags?.length > 0) {
			setTagOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Función encargada de colocar mayúsculas en la primera letra de cada palabra
	const capitalizeFirstLetter = str => {
		const arr = str.toLowerCase().split(' ');

		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}

		return arr.join(' ');
	};

	//Expando o contraigo tags de energy
	const handleArrows = () => {
		setTagOpen(!tagOpen);
	};

	return (
		<>
			{/* Si tengo un valor de data lo muestro */}
			{tableData && load ? (
				<>
					<Grid container className={classes.tableContainer}>
						<Grid container className={classes.subHeaderRow}>
							<Grid item xs={2} className={clsx(classes.tableTitle, classes.spacer)}>
								Energía Activa
							</Grid>
							{tableData['real'].slots.cost.map((slot, i) => (
								<Grid key={i} item xs className={clsx(classes.tableTitle, classes.spacer)}>
									{capitalizeFirstLetter(slot.name)}
								</Grid>
							))}
							<Grid item xs className={classes.tableTitle}>
								Total
							</Grid>
						</Grid>
					</Grid>
					<Grid container className={classes.tableContainer}>
						<Grid item xs={2} className={classes.tableLeftTitle}>
							Factura real
						</Grid>


						{tableData.paper.slots.cost.map((cost, idx) => (
							<Fragment key={idx}>
								<Grid item xs className={classes.firstRow}>
									{billData?.factura_papel?.energy_use?.slots[idx].value ? parseInt(billData?.factura_papel?.energy_use?.slots[idx].value).toLocaleString() : 0} kWh
								</Grid>
								<Grid item xs className={classes.firstRow}>
									$ {parseInt(billData?.factura_papel?.energy_cost?.slots[idx].value).toLocaleString()}
								</Grid>
							</Fragment >
						))}
						<Grid item xs className={classes.firstRow}>
							{billData?.factura_papel?.energy_use?.total ? parseInt(billData?.factura_papel?.energy_use?.total).toLocaleString() : 0} kWh
						</Grid>
						<Grid item xs className={classes.firstRow}>
							$ {parseInt(billData?.factura_papel?.energy_cost?.total).toLocaleString()}
						</Grid>
					</Grid>

					<Grid container className={classes.tableContainer}>
						<Grid item xs={2} className={classes.tableLeftTitle}>
							{tableData.tags.length > 0 && (
								<IconButton aria-label='expandir o contraer fila' size='small' onClick={() => handleArrows()}>
									{tagOpen ? <KeyboardArrowUpIcon color='primary' /> : <KeyboardArrowDownIcon color='primary' />}
								</IconButton>
							)}
							Factura reconstruida
						</Grid>


						{tableData.real.slots.cost.map((cost, idx) => (
							<Fragment key={idx}>
								<Grid item xs className={classes.lastRow}>
									{parseInt(tableData.real.slots.energy[idx].value).toLocaleString()} kWh
								</Grid>
								<Grid item xs className={classes.lastRow}>
									$ {parseInt(cost.value).toLocaleString()}
								</Grid>
							</Fragment >
						))}
						<Grid item xs className={classes.lastRow}>
							{parseInt(tableData.real.total.energy).toLocaleString()} kWh
						</Grid>
						<Grid item xs className={classes.lastRow}>
							$ {parseInt(tableData.real.total.cost).toLocaleString()}
						</Grid>
					</Grid>

					<Collapse in={tagOpen}>
						{tableData.tags.map((tag, idx) => (
							<Fragment key={idx}>
								<Grid container className={classes.tableContainer}>
									<Grid item xs={2} className={classes.tableTitle}>
										{tag.name}
									</Grid>
									{tag.slots.cost.map((tagSlot, idx2) => (
										<Fragment key={idx2}>
											<Grid item xs className={idx === 0 ? classes.firstRow : classes.lastRow}>
												{parseInt(tag.slots.energy[idx2].value).toLocaleString()} kWh
											</Grid>
											<Grid item xs className={idx === 0 ? classes.firstRow : classes.lastRow}>
												$ {parseInt(tagSlot.value).toLocaleString()}
											</Grid>
										</Fragment >
									))}
									<Grid item xs className={idx === 0 ? classes.firstRow : classes.lastRow}>
										{parseInt(tag.total.energy).toLocaleString()} kWh
									</Grid>
									<Grid item xs className={idx === 0 ? classes.firstRow : classes.lastRow}>
										$ {parseInt(tag.total.cost).toLocaleString()}
									</Grid>
								</Grid>
							</Fragment >
						))}
					</Collapse>
				</>
			) : null}
		</>
	);
}
