import { Chip } from '@material-ui/core'
import { green, red, yellow } from '@material-ui/core/colors'
import React from 'react'

function StatusChip({ status }) {

  const getStatusText = (status) => {
    switch (status) {
      case 'INVITACIONENVIADA':
        return 'Invitación Enviada'
      case 'ACEPTADO':
        return 'Aceptado'
      case 'REVOCADO':
        return 'Revocado'
      default:
        return status;
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'INVITACIONENVIADA':
        return yellow[400]
      case 'ACEPTADO':
        return green[400]
      case 'REVOCADO':
        return red[400]
      default:
        return status;
    }
  }

  return (
    <Chip label={getStatusText(status)} style={{ backgroundColor: getStatusColor(status) }} />
  )
}

export default StatusChip