import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  rightPanel: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
    gap: theme.spacing(3)
  },
  powerOffIcon: {
    fontSize: 80
  },
  color: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  }

}));

const SummaryOutages = ({ eventsData, dashboard, powerCutCant, powerCutTime }) => {
  const classes = useStyles();
  const { page: events } = eventsData;

  const colorSwap = (dashboard) => {
    if (dashboard) {
      return classes.color
    }
  }

  const sumOfOutages = () => {
    if (!dashboard) {
      return events.reduce((total, event) => {
        return total + Number(event.event.duration.replace(' minutos', ''));
      }, 0);
    }
    else {
      if (powerCutTime === "None") {
        return 0
      }
      return powerCutTime
    }
  };

  return (

    <Grid item xs={12} sm={dashboard ? false : 3} className={clsx(classes.rightPanel, colorSwap(dashboard))}>
      <PowerOffIcon className={clsx(classes.powerOffIcon, colorSwap(dashboard))} />
      <div className={classes.itemValue}>{dashboard ? (powerCutCant) : (events.length)} {dashboard ? (powerCutCant === 1 ? 'corte' : 'cortes') : (events.length === 1 ? 'corte' : 'cortes')}</div>
      <div className={classes.itemValue}>{`${sumOfOutages()} ${dashboard?'':`minutos`}`}</div>
      {/* <div className={classes.itemTitle}>Mantenimiento</div>
								<div className={classes.itemValue}>#Cortes</div>
								<div className={classes.itemValue}>Minutos</div> */}
    </Grid>
  );
};
export default SummaryOutages;
