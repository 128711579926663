import { useEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { Box, makeStyles } from '@material-ui/core';
import { format, getWeek, parseISO, subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { COLORS, getProportionalColor } from './helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    // margin: theme.spacing(2),
    '@media (max-width: 700px)': {
      margin: theme.spacing(0),
    },
  },
}));

const MonthChart = ({ month, monthData, filterOptions, minimum, maximum }) => {
  const classes = useStyles();

  useEffect(() => {
    // Obtener qué dia fue el primero de mes sin que nos afecte la zona horaria local, con new Date(kwh[0].timestamp) en GMT-3 nos quedaría el dia anterior a las 21hs.
    const dateObj = parseISO(monthData[0].timestamp);
    const firstDayOfMonth = dateObj.getDay();
    const monthName = format(dateObj, 'LLLL yyyy', { locale: es });

    let aux = [];
    for (let i = 1; i <= firstDayOfMonth; i++) {
      aux.unshift({
        valor: null,
        timestamp: format(subDays(dateObj, i), 'yyyy-MM-dd'),
        type: 'FILL',
      });
    }

    aux = aux.concat(monthData);

    const processedItems = aux.map(item => {
      const date = parseISO(item.timestamp);
      const weekNumber = getWeek(date);
      const dayName = format(date, 'EEE', { locale: es });
      const value = item.valor * 1000;
      const dateStr = format(date, 'dd/MM/yyyy');
      const type = item.type;

      return {
        weekNumber,
        dayName,
        value,
        dateStr,
        type,
      };
    });

    const getChartUnit = () => {
      if (filterOptions.variable.includes('energia')) return 'Wh';
      if (filterOptions.variable.includes('adquirida')) return 'W';
    };

    // console.log(
    //   `${monthName}: ${firstDayOfMonth} ${monthData[0].timestamp}`,
    //   emptyDays
    // );

    const chartTitle = monthName;
    am4core.useTheme(am4themes_animated);
    const chart = am4core.create(
      `heatmap-month-chart-${month}`,
      am4charts.XYChart
    );

    chart.logo.disabled = true;
    chart.fontSize = 11;

    // Establecer notación ingeniería con k como mínima unidad.
    chart.numberFormatter.numberFormat = `#.## !a${getChartUnit()}`;
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.maskBullets = false;

    // Crear contenedor para colocar el título
    const topContainer = chart.chartContainer.createChild(am4core.Container);
    topContainer.layout = 'absolute';
    topContainer.toBack();
    // topContainer.paddingBottom = 1;
    topContainer.width = am4core.percent(100);

    const Title = topContainer.createChild(am4core.Label);
    Title.text = chartTitle;
    Title.fontWeight = 600;
    Title.align = 'center';

    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    xAxis.renderer.opposite = true;

    //Oculto el número de semana

    yAxis.renderer.labels.template.disabled = true;
    // Si en cambio pusiera esto lo ocultaría pero el espacio asignado sería inutilizado
    // yAxis.renderer.hidden = true;

    xAxis.dataFields.category = 'dayName';
    yAxis.dataFields.category = 'weekNumber';

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 20;
    // xAxis.renderer.labels.template.rotation = -30;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = 'dayName';
    series.dataFields.categoryY = 'weekNumber';
    series.dataFields.value = 'value';
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 2000;
    series.tooltip.dy = -10;
    series.tooltip.animationDuration = 0;
    series.tooltip.pointerOrientation = 'down';

    // Set up column appearance
    const columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 0;
    columnTemplate.strokeOpacity = 0.5;
    // columnTemplate.stroke = am4core.color('rgba(0, 0, 0, 0.5)');

    // Relleno de los cuadrados con valor null (el resto tendrán el color dictado por las heatmap rules)
    columnTemplate.fill = am4core.color('rgba(0, 0, 0, 0.01)');
    // columnTemplate.width = am4core.percent(100);
    // columnTemplate.height = am4core.percent(100);
    columnTemplate.column.cornerRadius(3, 3, 3, 3);

    // Tooltip siempre visible
    columnTemplate.tooltipText = `{value.formatNumber('#.## !a')}${getChartUnit()} - {dateStr.formatNumber('#')}`;

    // Estilos del tooltip
    // series.tooltip.getFillFromObject = false;
    // series.tooltip.label.propertyFields.fill = 'tooltipColor';
    // series.tooltip.background.propertyFields.stroke = 'tooltipColor';

    series.heatRules.push({
      property: 'fill',
      target: series.columns.template,
      min: am4core.color(COLORS.MIN_POSITIVE_VALUE),
      max: am4core.color(COLORS.MAX_POSITIVE_VALUE),
      minValue: minimum,
      maxValue: maximum,
      // logarithmic: true,
    });

    // Cambio el color de aquellos días con valor 0 para diferenciarlo de los días previos inventados
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Si el valor es 0
      if (target.dataItem._dataContext.value === 0) {
        // Si su valor es cero y es un elemento de relleno.
        if (target.dataItem.dataContext?.type === 'FILL') {
          return 'rgb(255, 255, 255)';
        }
        // Si su valor es cero pero no es de relleno asumo que es futuro.
        return 'rgb(224, 224, 224)';
      }
      return fill;
    });

    series.tooltip.adapter.add('disabled', function (disabled, target) {
      // Deshabilito el tooltip para los días previos agregados
      if (target.dataItem.dataContext?.type === 'FILL') {
        return true;
      }
      return disabled;
    });

    // Si el valor es 0 y no es de tipo FILL es un valor sin data (que también puede ser futuro)
    series.columns.template.adapter.add('tooltipText', function (text, target) {
      const data = target.tooltipDataItem.dataContext;
      if (data.value === 0 && data.type !== 'FILL') {
        return `Sin datos - ${data.dateStr}`;
      }
      return text;
    });

    // En caso de generación de energía (valor <0) pintar del verde que le corresponda
    series.columns.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem && target.dataItem?.values?.value?.value < 0) {
        // Si es menor que el mínimo retorno el color del mínimo
        if (target.dataItem?.values?.value?.value < minimum) {
          return am4core.color(COLORS.MIN_NEGATIVE_VALUE);
        }
        const color = getProportionalColor(
          target.dataItem?.values?.value?.value,
          minimum,
          0,
          COLORS.MIN_NEGATIVE_VALUE,
          COLORS.MAX_NEGATIVE_VALUE
        );
        return am4core.color(color);
      }
      return fill;
    });

    // Valor de energía dentro del cuadrado
    // const bullet2 = series.bullets.push(new am4charts.LabelBullet());
    // bullet2.label.text = '[bold]{value}[/]';
    // bullet2.label.fill = am4core.color('#000');
    // bullet2.zIndex = 1;
    // bullet2.fontSize = 10;
    // bullet2.interactionsEnabled = false;

    // chart.data = monthData;

    chart.data = processedItems;

    return () => {
      // console.log('Dispose');
      chart.dispose();
    };
  }, [month, monthData, filterOptions, minimum, maximum]);

  return (
    <Box className={classes.root}>
      <div id={`heatmap-month-chart-${month}`} style={{ height: '150px' }} />
    </Box>
  );
};

export default MonthChart;
