import React, { useState, useEffect } from 'react';

//Material ui
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
//Style
import clsx from 'clsx';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SpeedIcon from '@material-ui/icons/Speed';


let locationMobileStyles = {
  height: "50px",
  padding: "10px",
  marginBottom: "1px"
}
const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
    "@media (max-width: 700px)": {
      position: "relative",
      left: "-32px",
      width: 'calc(100% + 32px)',
    }
  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '&.MuiListItem-root.Mui-selected p': {
      color: theme.palette.common.white,
    },
  },
  nodeHeader: {
    display: 'flex',
    '& span': {
      fontSize: "larger",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    '& p': {
      marginLeft: '5px',
      fontSize: "larger"
    },
    "@media (max-width: 700px)": {
      flexDirection: " column",
      '& span': {
        marginLeft: '5px',
        fontSize: "small",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      '& p': {
        marginLeft: '5px',
        fontSize: "small",
      },
    },

  },
  empresa: {
    "@media (max-width: 700px)": {
      backgroundColor: theme.palette.primary.main,
      ...locationMobileStyles,
      color: "#e0e0e0"
    },
  },
  zona: {
    "@media (max-width: 700px)": {
      background: "#c1c1c1",
      ...locationMobileStyles
    }
  },
  localizacion: {
    "@media (max-width: 700px)": {
      background: "#e0e0e0",
      ...locationMobileStyles
    }
  },
  sublocaclizacion: {
    "@media (max-width: 700px)": {
      background: "#f1efef",
      ...locationMobileStyles
    }
  },
  visibilityIcon: {
    "@media (max-width: 700px)": {
      color: "#343538"
    }
  },
  iconMedidor: {
    "@media (max-width: 700px)": {
      marginRight: "30px"
    }
  }
}));

export default function Node({ node, selectedNode, handleSelectedNode, statusNodes }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [statusNode, setStatusNode] = useState(true);

  //Verifica status de el medidor.
  useEffect(() => {
    if (!node?.virtual) {
      let nodeId = node?.id
      let nodeAux = statusNodes?.find(e => e.node_id === nodeId)
      setStatusNode(nodeAux?.online)
    }
  }, [statusNodes, node])

  const handleArrows = () => {
    setOpen(!open);
  };

  //Funcion para darle una class depende q jerarquia posee el nodo.
  const classNodeSelect = (node) => {
    if (node.level_nombre === "empresa") {
      return classes.empresa
    }
    else if (node.level_nombre === "zona") {
      return classes.zona
    }
    else if (node.level_nombre === "localización") {
      return classes.localizacion
    }
    else if (node.level_nombre === "sublocalización") {
      return classes.sublocaclizacion
    }
    else {
      return ""
    }
  }

  return (
    <>
      <ListItem
        button
        selected={selectedNode ? selectedNode.id === node.id : false}
        className={clsx(classes.selected, classNodeSelect(node))}
        onClick={() => handleSelectedNode(node)}
        disabled={node.level_nombre !== 'localización'}
      >
        <ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={classes.nodeHeader} />


        {/* ICON MEDIDOR FISICO */}
        <Box width={30}
          className={clsx(classes.iconMedidor)}>
          {node.medidor &&
            <Tooltip title={`${node.nombre} tiene un medidor asociado`}>
              <IconButton	>
                <SpeedIcon style={statusNode ? { color: '#66bb6a' } : { color: '#ef5350' }} />
              </IconButton>
            </Tooltip>
          }
        </Box>


        {/* ICON OPEN CLOSE */}
        <ListItemSecondaryAction >
          <Box width={30}>
            {node.hijos &&
              node.hijos.length > 0 && node.level_nombre !== 'localización' &&
              (open ? (
                <IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
                  <ExpandLess />
                </IconButton>

              ) : (

                <IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
                  <ExpandMore />
                </IconButton>
              ))}
          </Box>
        </ListItemSecondaryAction>

      </ListItem>

      {node.level_nombre !== 'localización' && node.hijos ? (
        <Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
          <List dense component='div' disablePadding>
            {node.hijos.map(c => (
              <Node node={c} key={c.id} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} statusNodes={statusNodes} />
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}
