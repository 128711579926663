import React from 'react';
import TopNav from '../common/TopNav';

export default function Planta() {
	return (
		<>
			<TopNav titulo='Dashboards' subtitulo='| Planta' />
		</>
	);
}
