import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, withStyles, makeStyles } from '@material-ui/core';
import React from 'react'

import Paper from '@material-ui/core/Paper';
import { defaultCenter, getLat, getLng } from './common';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.hover,
    },
    '&:hover': {
      cursor: "pointer"
    },

  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(0),
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.light,

    '& .MuiTableCell-body': {
      color: 'white'
    }
  },
  unselectedRow: {

  }

}));

function LocationsTable({ data, handleSelection, setCenter, selectedId }) {
  const classes = useStyles();

  const handleClick = (row) => {
    handleSelection(row.hierarchy);
    const c = {
      lat: getLat(row.coordinates),
      lng: getLng(row.coordinates)
    }
    setCenter(c.lat === 0 && c.lng === 0 ? defaultCenter : c)
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="tabla de localizaciones" size="small">
        <TableHead>
          <TableRow >
            <StyledTableCell>Empresa</StyledTableCell>
            <StyledTableCell align="left">Zona</StyledTableCell>
            <StyledTableCell align="left">Localización</StyledTableCell>
            <StyledTableCell align="left">Dirección</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow className={selectedId === row.hierarchy ? classes.selectedRow : classes.unselectedRow} key={row.hierarchy} onClick={() => handleClick(row)}>
              <StyledTableCell component="th" scope="row">{row.company}</StyledTableCell>
              <StyledTableCell align="left">{row.zone}</StyledTableCell>
              <StyledTableCell align="left">{row.name}</StyledTableCell>
              <StyledTableCell align="left">{row.address}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LocationsTable