import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './config/theme';
import { UserProvider } from './context/UserContext';
import { NodesProvider } from './context/NodesContext';
import { NodesListProvider } from './context/NodesListContext';
import { TagsProvider } from './context/TagsContext';

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <UserProvider>
      <NodesProvider>
        <NodesListProvider>
          <TagsProvider>
            <App />
          </TagsProvider>
        </NodesListProvider>
      </NodesProvider>
    </UserProvider>
  </ThemeProvider>,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
