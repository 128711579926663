import React, { useEffect, useState } from 'react';
import TopNav from '../common/TopNav';
import BarChart from './BarChart';
import TimelineChart from './TimelineChart';
import { Box, Grid, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getConciliation } from '../../services/billing';
import ErrorAlert from '../common/ErrorAlert';
import ConciliationTable from './ConciliationTable';

const useStyles = makeStyles(theme => ({
	container: {
		marginLeft: theme.spacing(8),
		padding: "2px",
		"@media (max-width: 700px)": {
			marginLeft: 0,
			padding: 0,
		},
	},
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '15px',
	},
	recuadro: {
		padding: theme.spacing(2),
	},
	title: {
		fontSize: 20,
		fontWeight: 700,
		color: theme.palette.primary.main,
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
		"@media (max-width: 700px)": {
			fontSize: "14px",
		}
	},
	subtitle: {
		fontSize: 20,
		color: theme.palette.primary.main,
		"@media (max-width: 700px)": {
			fontSize: "14px",
		}
	},

}));

function Conciliation() {
	const classes = useStyles();

	const [conciliationData, setConciliationData] = useState([]);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [updateData, setUpdateData] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchConciliation = async url => {
			try {
				setLoading(true);
				const res = await getConciliation();
				if (res.data.error) {
					setErrorMessage(res.data.error);
					setShowErrorAlert(true)
				}
				else {
					setConciliationData(res.data);
					setShowErrorAlert(false);
					setLoading(false)
				}
			}
			catch (error) {
				setLoading(false);
				if (error.response) {
					setErrorMessage(`${error.response.data.msg}.`);
				} else {
					setErrorMessage(`Ocurrió un error inesperado`);
				}
				setShowErrorAlert(true);
			}
		};
		fetchConciliation();
		setUpdateData(false);
	}, [updateData]);

	return (
		<>
			<TopNav titulo='Facturación' subtitulo='| Conciliación' />

			<Box className={classes.container}>

				{showErrorAlert && <ErrorAlert message={errorMessage} />}
				{loading &&
					<div className={classes.circular}>
						<CircularProgress />
					</div>
				}
				{
					!loading && conciliationData.length > 0 &&
					<Box>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Box className={classes.recuadro}>
									<Box className={classes.title} display='inline'>
										Períodos
									</Box>
									<Box className={classes.subtitle} display='inline'>
										Facturados
									</Box>

									<TimelineChart data={conciliationData} />
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box className={classes.recuadro}>
									<Box className={classes.title} display='inline'>
										Factura
									</Box>
									<Box className={classes.subtitle} display='inline'>
										Real vs
									</Box>
									<Box className={classes.title} display='inline'>
										Factura
									</Box>
									<Box className={classes.subtitle} display='inline'>
										Calculada
									</Box>

									<BarChart data={conciliationData} />
								</Box>
							</Grid>

							<Grid item xs={12}>
								<Box className={classes.recuadro}>
									<Box className={classes.title} display='inline'>
										Conciliación
									</Box>
								</Box>

								<ConciliationTable data={conciliationData} setUpdateData={setUpdateData} />
							</Grid>
						</Grid>
					</Box>
				}
			</Box>

		</>
	);
}

export default Conciliation;
