import {
    Box,
    Button,
    makeStyles
} from '@material-ui/core';
import { Image } from '@material-ui/icons';
import Resizer from "react-image-file-resizer";

const useStyles = makeStyles(theme => ({
    containerImg: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // overflow: 'hidden',
    },
    image: {
        flex: 1,
        width: '100%',
        // objectFit: 'cover',
        // objectPosition: 'center',
        // maxHeight: '100%',
    },
}));


const ContainerUpdateImage = ({ handleChangeImg, stateComponent }) => {
    const classes = useStyles();
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                800,
                768,
                "JPEG",
                80,
                0,
                (uri) => {
                    handleChangeImg(uri)
                },
                "base64",
                200,
                200
            );
        });

    const handleSelectImg = async (e) => {
        try {
            const file = e.target.files[0];
            await resizeFile(file);
        }
        catch (err) {
            console.error(err);
        }
    }

    return (
        <Box style={{ width: '400px' }} >
            <Button
                variant='outlined'
                color='primary'
                component='label'
                fullWidth
                startIcon={<Image />}
                className={classes.btnImage}
            >
                Seleccionar
                <input
                    type='file'
                    onChange={handleSelectImg}
                    accept="image/png, image/jpeg"
                    hidden
                />
            </Button>

            <Box className={classes.containerImg} mt={2}>
                <img
                    className={classes.image}
                    src={stateComponent.file_base64 ? stateComponent.file_base64 : stateComponent.file_url}
                    alt=''
                />
            </Box>
        </Box>

    )
}
export default ContainerUpdateImage