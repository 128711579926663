import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../services/auth';

const ProtectedRoute = ({ component: Component, hasAccess, isLogingOut, setIsLogingOut, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				if (!getTokenFromLocalStorage()) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}

				if (!hasAccess) {
					return (
						<Redirect
							to={{
								pathname: '/unauthorized',
								state: {
									from: props.location,
								},
							}}
						/>
					);

				}
				if (isLogingOut) {
					setIsLogingOut(false);
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
				return <Component {...rest} {...props} />;

			}}
		/>
	);
};

export default ProtectedRoute;
