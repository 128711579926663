import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box, Button, Chip, Dialog, DialogTitle, FormControl, Grid, IconButton, InputLabel, List, MenuItem,
  Select, Step, StepLabel, Stepper, Tooltip,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import clsx from 'clsx';
import NodesContext from '../../context/NodesContext';
import DatePickerHeatmap from '../DatePicker/DatePickerHeatmap';
import Node from './Node';
import { subDays } from 'date-fns';
import { lastDayOfWeek } from 'date-fns';
import { VARIABLE_OPTIONS } from './helpers';

let scrollsSettings = {
  overflow: 'scroll',
  height: '50vh',
  overflowX: 'hidden',
};

let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};

let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },
  filterLabel: {
    marginLeft: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    labelDate: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  wrapperDatepicker: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  btnSpacing: {
    margin: theme.spacing(0, 1),
  },
  dialogTitle: {
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
  },
  switchLabel: {
    fontSize: '0.75rem',
  },
  rowSpace: {
    marginBottom: theme.spacing(2),
  },
  containerNodes: {
    paddingRight: '8px',
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  flexGap: {
    gap: theme.spacing(1),
  },
  formGrow: {
    flexGrow: 1,
  },
  steppersFilter: {
    '@media (max-width: 920px)': {
      display: 'none',
    },
    '&.MuiStep-horizontal': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  labelDate: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const Filter = ({ onSubmit, filterOptions, setFilterPanel }) => {
  const classes = useStyles();
  // const { plan } = useContext(UserContext);
  const [selectedNode, setSelectedNode] = useState({});
  const [showDevices, setShowDevices] = useState(false);
  // const [loadingNodes, setLoadingNodes] = useState(false);
  const { nodes } = useContext(NodesContext);
  const [filter, setFilter] = useState(filterOptions);
  const { dispositivo, dateFrom, dateTo, variable } = filter;
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Elija el dispositivo que desea graficar',
    'Elija la variable a graficar',
    'Elija el período a graficar',
    'Haga click en CONSULTAR',
  ];

  //Para recordar el valor seleccionado en caso de haber cerrado el panel de filtros
  useEffect(() => {
    if (filter.dispositivo.id) {
      setSelectedNode(filter.dispositivo);
      setActiveStep(4);
    }
  }, [filter.dispositivo]);

  const handleFilterChange = e => {
    // Si estaba en por día y paso a por hora quiero que las fechas sean semanales.
    if (filter.variable.includes('Dia') && e.target.value.includes('Hora')) {
      setFilter({
        ...filter,
        dateFrom: subDays(lastDayOfWeek(new Date()), 6),
        dateTo: lastDayOfWeek(new Date()),
        [e.target.name]: e.target.value,
      });
    }
    // En la otra transición no me interesa ya que casi siempre tendré dd/mm/yyyy con el año en curso por lo que será coherente al mostrar yyyy
    else {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    }
  };

  const handleCancelFilterDialog = () => {
    setFilterPanel(false);
  };

  // Submit en Dialog de dispositivos
  const handleDeviceAdded = () => {
    setFilter({ ...filter, dispositivo: selectedNode });
    setShowDevices(false);
    // Completado el dispositivo (activeStep->0).
    // Como variable(1), granularidad(2) y fecha(3) tienen valores por defecto los marco como completados.
    setActiveStep(4);
  };

  const nodeList = () => {
    return nodes.map(node => (
      <Node
        node={node}
        handleSelectedNode={handleSelectedNode}
        selectedNode={selectedNode}
        key={node.id}
      />
    ));
  };

  // Click en óvalo azul dentro del panel de filtrar
  const handleClickedSelectionItem = () => {
    // Mostrar lista de dispositivos
    setShowDevices(true);
    // Establezco el nodo seleccionado
    setSelectedNode(dispositivo);
  };
  // Click en la cruz del óvalo azul del panel de filtrar
  const handleDeleteSelectionItem = () => {
    // Limpio el nodo seleccionado.
    setSelectedNode({});
    //Actualizo filter para que el submit en Events tenga el valor actualizado si borré algo de la lista
    setFilter({ ...filter, dispositivo: {} });

    // Si borro el dispositivo vuelvo al paso inicial
    setActiveStep(0);
  };

  const handleSelectedNode = node => {
    // Si el nodo elegido es el mismo que el actualmente seleccionado lo deselecciono
    if (node === selectedNode) setSelectedNode({});
    else setSelectedNode(node);
  };

  const handleCloseDevices = () => {
    setShowDevices(false);
  };

  const handleDeleteNodeSelection = () => {
    setSelectedNode({});
  };

  return (
    <>
      <Stepper
        className={classes.steppersFilter}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(label => (
          <Step className={classes.steppersFilter} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form className={classes.root}>
        {/* Container agregar dispositivo */}
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          minHeight={40}
          mb={2}
        >
          {/* Tooltip dispositivo */}
          <Tooltip
            title="Elija el dispositivo que desea añadir al gráfico principal haciendo click en el botón +"
            arrow
            placement="right"
          >
            <Box
              className={clsx(
                classes.hint,
                classes.leftSpacing,
                {
                  [classes.active]: activeStep === 0,
                },
                {
                  [classes.tick]: activeStep > 0,
                }
              )}
            >
              1
            </Box>
          </Tooltip>
          <Box className={classes.devicesLabel}>Agregar dispositivo</Box>
          <Tooltip
            title="Haga click aquí para agregar dispositivo"
            arrow
            placement="right"
          >
            <span>
              <IconButton
                className={classes.addBtn}
                aria-label="agregar dispositivo"
                onClick={() => setShowDevices(true)}
                disabled={dispositivo.id ? true : false}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          {dispositivo.id && (
            <Chip
              icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
              label={dispositivo.nombre}
              clickable
              color="primary"
              onDelete={handleDeleteSelectionItem}
              className={classes.filterChip}
              onClick={handleClickedSelectionItem}
            />
          )}
        </Box>

        <Grid container>
          {/* Container variable */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" className={classes.flexGap}>
              {/* Tooltip variable */}
              <Tooltip
                title="Elija la variable a graficar"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(classes.hint, classes.leftSpacing, {
                    [classes.tick]: activeStep > 2,
                  })}
                >
                  2
                </Box>
              </Tooltip>
              {/* Variable */}
              <FormControl className={classes.formGrow}>
                <InputLabel id="variableLabel">Variable a graficar</InputLabel>
                <Select
                  labelId="variableLabel"
                  name="variable"
                  value={variable}
                  onChange={handleFilterChange}
                  disabled={dispositivo.id ? false : true}
                >
                  {VARIABLE_OPTIONS.map(option => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      // TODO Cuando tengamos los permisos utilizaremos !plan?.access[option.plan]
                      disabled={option.disabled}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {/* Container período */}
        <Box display="flex" alignItems="center" mt={2}>
          {/* Tooltip periodo */}
          <Tooltip title="Seleccione las fechas a consultar" arrow placement="right">
            <Box
              className={clsx(classes.hint, classes.leftSpacing, {
                [classes.tick]: activeStep > 3,
              })}
            >
              3
            </Box>
          </Tooltip>
          <label className={classes.filterLabel}>Seleccione las fechas a consultar</label>
        </Box>

        {/* DatePicker */}
        <Box className={classes.wrapperDatepicker}>
          <label className={classes.labelDate} >
            Desde / Hasta
          </label>
          <DatePickerHeatmap
            startDate={dateFrom}
            endDate={dateTo}
            setDate={setFilter}
            type={variable.includes('Hora') ? 'week' : 'year'}
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.btnSpacing}
            disabled={dispositivo.id ? false : true}
            onClick={() => onSubmit(filter)}
          >
            Consultar
          </Button>

          <Button
            variant="contained"
            onClick={handleCancelFilterDialog}
            className={classes.btnSpacing}
          >
            Cancelar
          </Button>
        </Box>
      </form>

      <Dialog
        open={showDevices}
        onClose={handleCloseDevices}
        aria-labelledby="form-dialog-title"
        fullWidth
        disableScrollLock
      >
        <Box padding="8px">
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Selección de Dispositivo
          </DialogTitle>
          <div className={classes.dialogSubtitle}>
            Seleccionar un elemento de la organización.
          </div>
          <Box display="flex" className={classes.selectedItemsContainer}>
            {selectedNode.nombre && dispositivo.nombre ? (
              <Chip
                icon={<AccountTreeIcon />}
                label={dispositivo.nombre}
                clickable
                color="primary"
                onDelete={handleDeleteNodeSelection}
              />
            ) : null}
          </Box>

          <Box component="label" display="block" className={classes.label}>
            Organización
          </Box>
          <Box className={classes.containerNodes}>
            {nodes.length === 0 && (
              <h2
                style={{
                  textAlign: 'center',
                  fontSize: '19px',
                  color: '#2586bc',
                  paddingTop: '20px',
                }}
              >
                Usted no posee ninguna localización configurada en su cuenta.
                <br></br>
                Por favor, configure al menos una localización para poder
                continuar.
                <NavLink
                  className={classes.navLink}
                  to={`/configuracion-organizacion`}
                >
                  [link]
                </NavLink>
              </h2>
            )}
            <List component="div" disablePadding dense>
              {nodeList()}
            </List>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              className={classes.devicesBtn}
              variant="contained"
              color="primary"
              onClick={handleDeviceAdded}
              disabled={selectedNode.nombre ? false : true}
            >
              Agregar
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseDevices}
              className={classes.devicesBtn}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Filter;
