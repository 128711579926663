import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Typography } from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';
import React, { useEffect, useState } from 'react';
import { putTagById, deleteTagById } from '../../services/hierarchy';

const useStyles = makeStyles(theme => ({
	title: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		fontSize: 23,
		fontWeight: 700,
		padding: '10px 15px',
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	btn: {
		margin: theme.spacing(3, 1),
	},
}));

export default function LabelInfo({ plan, selectedLabel, setSelectedLabel, treeLabelsChanges, setTreeLabelsChanges }) {
	const classes = useStyles();
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [labelData, setLabelData] = useState({});

	useEffect(() => {
		// console.log('selectedLabel', selectedLabel);
		setLabelData(selectedLabel);
	}, [selectedLabel]);

	const handleChange = e => {
		// console.log('handleChange - labelData', labelData);
		// labelData está compuesto de {nombre: 'luces', id:4} ante un cambio en el Input modifico sólo el nombre.
		setLabelData({ ...labelData, [e.target.name]: e.target.value });
	};
	const handleCancel = () => {
		// console.log('LabelInfo handleCancel');
		setSelectedLabel('');
	};
	const handleDelete = e => {
		// console.log('Delete');
		setConfirmOpen(true);
	};
	const handleSubmit = async e => {

		e.preventDefault();
		// console.log('labelData.nombre', labelData.nombre);
		try {
			await putTagById(labelData.id, { nombre: labelData.nombre, color: labelData.color });
			// console.log('labelData.nombre', labelData.nombre);
			// Quiero volver a dibujar la lista de etiquetas
			setTreeLabelsChanges(current => !current);
		} catch (error) {
			console.error(error);
		}
	};

	const handleConfirmClose = () => {
		// console.log('Modal cerrado');
		setConfirmOpen(false);
	};

	const handleConfirmDelete = async () => {
		// console.log('Confirmación de eliminación');

		try {
			await deleteTagById(labelData.id);
			// Quiero volver a dibujar la lista de etiquetas
			setTreeLabelsChanges(current => !current);
			setConfirmOpen(false);
			setSelectedLabel('');
		} catch (error) {
			console.error(error);
		}
	};

	const handleChangeColor = (value) => {

		try {
			if (value.css.backgroundColor.length === 7) {
				setLabelData({ ...labelData, color: value.css.backgroundColor });
			} else {
				setLabelData({ ...labelData, color: 'black' });
			}

		} catch (error) {
			console.error(error)
		}

	};

	// console.log(selectedLabel)

	return (
		<>
			<Typography variant='h4' className={classes.title}>
				Editar Etiqueta
			</Typography>
			<form onSubmit={handleSubmit} className={classes.form}>
				<Box component='label' display='block' className={classes.label}>
					Etiqueta
				</Box>
				<TextField
					fullWidth
					placeholder='Nombre'
					name='nombre'
					value={labelData.nombre || ''}
					onChange={e => handleChange(e)}
					color='primary'
					className={classes.input}
				/>

				<Box component='label' display='block' className={classes.label}>
					Color
					<ColorPicker value={labelData.color} onChange={handleChangeColor} />
				</Box>

				<Box display='flex' justifyContent='center'>
					{plan?.info?.plan !== "Demo" &&
						<Button type='submit' color='primary' variant='contained' className={classes.btn}>
							Guardar
						</Button>
					}

					<Button variant='contained' onClick={handleCancel} className={classes.btn}>
						Cancelar
					</Button>

					{plan?.info?.plan !== "Demo" &&
						<Button color='secondary' variant='contained' onClick={handleDelete} className={classes.btn}>
							Eliminar
						</Button>
					}

				</Box>
			</form>

			<Dialog open={confirmOpen} onClose={handleConfirmClose} aria-labelledby='form-dialog-title'>
				<DialogTitle id='form-dialog-title'>¿Desea eliminar el nodo?</DialogTitle>
				<DialogContent>En caso de confirmar la etiqueta será eliminada</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleConfirmClose}>
						Cancelar
					</Button>
					<Button type='submit' color='secondary' variant='outlined' onClick={handleConfirmDelete}>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
