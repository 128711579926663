import { useContext } from "react";
import UserContext from "../../context/UserContext";

export const SettingsData = [
  {
    title: 'Usuario',
    children: [
      {
        subtitle: 'Datos de la cuenta',
        path: '/configuracion-cuenta',
      },
      {
        subtitle: 'Subcuentas',
        path: '/configuracion-subcuentas',
      },
      {
        subtitle: 'Notificaciones',
        path: '/configuracion-notificaciones',
      },
      {
        subtitle: 'Integraciones',
        path: '/configuracion-integraciones',
      },
    ],
  },
  {
    title: 'General',
    children: [
      {
        subtitle: 'Organización',
        path: '/configuracion-organizacion',
      },
      {
        subtitle: 'Etiquetas',
        path: '/configuracion-etiquetas',
      },
      {
        subtitle: 'Dispositivos',
        path: '/configuracion-dispositivos',
      },
    ],
  },
  // {
  // 	title: 'Preferencias',
  // 	children: [
  // 		{
  // 			subtitle: 'Cuenta',
  // 			// path: '/cuenta',
  // 		},
  // 		{
  // 			subtitle: 'Notificaciones',
  // 			// path: '/notificaciones',
  // 		},
  // 		{
  // 			subtitle: 'Reportes',
  // 			// path: '/reportes',
  // 		},
  // 	],
  // },
  {
    title: 'Tarifas y Contratos',
    children: [
      {
        subtitle: 'Facturación',
        path: '/configuracion-facturacionContrato',
      },
      {
        subtitle: 'Subfacturación',
        path: '/configuracion-subFacturacionContrato',
      }

    ],
  },
  {
    title: 'Analisis',
    children: [
      {
        subtitle: 'Calidad de energía',
        path: '/configuracion-calidad',
      },
      // {
      //   subtitle: 'Stand-by',
      //   // path: '/stand-by',
      // },
    ],
  },
];

const useSettingsData = () => {
  const { plan } = useContext(UserContext);

  const addAccessField = (data) => {
    return data.map(section => ({
      ...section,
      children: section.children.map(child => {
        let accessField;
        switch (child.subtitle) {
          case 'Integraciones':
            accessField = plan?.access?.api_externa || false;
            break;    
          default:
            accessField = true;
        }

        return {
          ...child,
          access: accessField,
        };
      }),
    }));
  };

  return addAccessField(SettingsData);
};

export default useSettingsData;