import React, { useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import './Gauge.css';
import { makeStyles } from '@material-ui/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getFormattedNumber, getUnit } from '../../../helpers/common';

const useStyles = makeStyles(theme => ({
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
		color: '#666666',
		fontSize: '14px',
		textAlign: 'center',
		"@media (max-width: 900px)": {
			transform: 'translate(-50%,-50%)',
			fontSize: '12px',
		}
	},
	mainText: {
		fontSize: '16px',
		fontWeight: '700',
	},
	descriptionText: {
		fontSize: '12px',
	},
	chart: {
		height: "240px",
		"@media (max-width: 900px)": {
			position: "relative",
			bottom: theme.spacing(1),
			height: "200px",
		}
	}
}));

// import am4themes_animated from '@amcharts/amcharts4/themes/animated';
export default function Gauge({ id, color, tension, potencia, descripcion, medidor_virtual }) {
	const classes = useStyles();
	const chartRef = useRef(null);
	am4core.useTheme(am4themes_animated);


	useEffect(() => {
		let chart = am4core.create(id, am4charts.GaugeChart);
		chartRef.current = chart;

		if (chart.logo) {
			chart.logo.disabled = true
		}
		let axis = chart.xAxes.push(new am4charts.ValueAxis());

		axis.min = 0;
		axis.max = 311;
		axis.strictMinMax = true;

		// Hide labels
		axis.renderer.grid.template.disabled = true;
		axis.renderer.labels.template.disabled = true;

		chart.startAngle = -225;
		chart.endAngle = 45;
		chart.innerRadius = am4core.percent(88);

		// Add ranges
		const range1 = axis.axisRanges.create();
		range1.value = 0;
		// range1.endValue = tension;
		range1.axisFill.fillOpacity = 1;
		range1.axisFill.fill = am4core.color(color);
		//in 1 to make sure we don't see lines
		range1.axisFill.zIndex = 1;

		const range2 = axis.axisRanges.create();
		// range2.value = tension;
		range2.endValue = 311;
		range2.axisFill.fillOpacity = 1;
		range2.axisFill.fill = am4core.color('#eaeaea');
		//in 1 to make sure we don't see lines
		range2.axisFill.zIndex = 1;

		return () => {
			chart.dispose();
		};
	}, [color, id]);

	useEffect(() => {
    if (chartRef.current) {
      // Update the chart with the new tension value
      let range1 = chartRef.current.xAxes.getIndex(0).axisRanges.getIndex(0);
      let range2 = chartRef.current.xAxes.getIndex(0).axisRanges.getIndex(1);

      range1.endValue = tension;
      range2.value = tension;

      chartRef.current.validateData(); // Refresh the chart
    }
  }, [tension]);

	return (
		<>
			<div style={{ position: 'relative', }}>
				<div className={classes.chart} id={id} />
				<div className={classes.container}>
					<div className={classes.mainText}>{medidor_virtual ? 'Medidor Virtual' : `${tension}V`}</div>
					{/* <div>{potencia}W</div> */}
					<div>{getFormattedNumber(potencia)}{getUnit(potencia/1000)}W</div>
					<div className={classes.descriptionText}>{descripcion}</div>
				</div>
			</div>
		</>
	);
}
