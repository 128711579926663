import { Box, Divider, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import TopNav from '../common/TopNav';
import useSettingsData from './SettingsData';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: theme.spacing(8),
		padding: theme.spacing(8),
		color: theme.palette.primary.main,
		display: 'flex',
		gap: theme.spacing(1),
		"& > *": {
			flexBasis: '30%',
			minWidth: '16rem'
		},
		"@media (max-width: 700px)": {
			marginLeft: 0
		},
		"@media (max-width: 1230px)": {
			flexWrap: 'wrap',
		},
	},
	title: {
		fontSize: 14,
		textTransform: 'uppercase',
	},
	divider: {
		height: 2,
		width: 10,
	},
	navLink: {
		textDecoration: "none",
		color: "inherit"
	}
}));

export default function Settings() {
	const classes = useStyles();
	const settingsData = useSettingsData();
	return (
		<>
			<TopNav titulo='Configuración'></TopNav>

			<Box className={classes.root}>
				{settingsData.map((item, index) => {
					return (
						<Box key={index}>
							<Typography variant='h6' className={classes.title}>
								{item.title}
							</Typography>
							<Divider className={classes.divider} />
							<List dense>
								{item.children
									.filter((child) => child.access) // Filtrar children por access
									.map((child, index) => (
										<NavLink
											key={index}
											className={classes.navLink}
											to={child.path}
										>
											<ListItem button>
												<ListItemText primary={child.subtitle} />
											</ListItem>
										</NavLink>
									))}
							</List>
						</Box>
					);
				})}
			</Box>
		</>
	);
}
