import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { changeLigthColor } from "../../helpers/controllers";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
//Material UI
import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        minHeight: "500px",
        marginTop: "10px"
    },
    title: {
        color: theme.palette.primary.main,
        textAlign: "center"
    }
}));

function BarChartClass({ selectPeriod }) {
    const classes = useStyles();

    useEffect(() => {
        let root = am5.Root.new("chartdiv");
        root.setThemes([am5themes_Animated.new(root)]);
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout
            })
        );
        // SACAMOS LOGO DE AMCHARD
        chart.root._logo.dispose();
        // Ejemplo data
        // let data = [
        //     {
        //         category: "Costo total",
        //         value1: 480,
        //         value2: 520,
        //         value3: 500
        //     },        //   
        // ];
        let data = []
        let dataAux = {
            category: "Unidades funcionales"
        }
        const mergeData = () => {
            selectPeriod?.subdivision.forEach((element, i) => {
                let ind = i + 1
                dataAux["value" + ind] = Number(element?.factura?.total)
            });

            data = [dataAux]
        }
        mergeData()

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );
        yAxis.min = 0
        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "category"
            })
        );
        xAxis.data.setAll(data);

        const createSerie = () => {
            selectPeriod?.subdivision.forEach((element, i) => {
                let ind = i + 1
                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: element?.sublocation_name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value" + ind,
                        categoryXField: "category"

                    })
                );

                let colorNode = selectPeriod?.consumos[i].meter.color
                series.data.setAll(data);
                series.columns.template.setAll({
                    tooltipText: "{name}, {categoryX}: {valueY}",
                    strokeOpacity: 0,
                    //color del tooltip
                    fill: am5.color(colorNode)
                });
                //modificacion de colores para armar el gradient
                var color1 = changeLigthColor(colorNode, -20);
                var color2 = changeLigthColor(colorNode, 20);
                var color3 = changeLigthColor(colorNode, 30);
                //cambio color a gradient
                series.columns.template.set("fillGradient", am5.LinearGradient.new(root, {
                    stops: [
                        {
                            color: am5.color(color1)
                        },
                        {
                            color: am5.color(color2)
                        },
                        {
                            color: am5.color(color3)
                        }
                    ],
                }));
            });
        }
        createSerie()

//Legends
        let legend = chart.children.push(am5.Legend.new(root, {
            x: am5.percent(6),
            height: am5.percent(20),
            width: am5.percent(97),
            verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: "vertical"
            })
        }));
        legend.data.setAll(chart.series.values)

        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {}));
        //formateo de numero
        root.numberFormatter.set("numberFormat", "$ #,##0.00");
        //menu exportable de data
        am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            pdfOptions: {
                pageSize: "LETTER",
                pageOrientation: "landscape",
                pageMargins: [20, 20, 20, 40],
                imageFormat: "jpg",
                includeData: true
            },
            dataSource: data
        });
        return () => {
            chart.dispose();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <h3 className={classes.title}>Costo de energia</h3>
            <div id="chartdiv" className={classes.container}></div>
        </div>
    )
}

export default BarChartClass
