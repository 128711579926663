import { useEffect, useState } from 'react';

import { Box, Grid, Slider } from '@material-ui/core';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { COLORS, FIELDS_MAP, calculateMinMax, getChartUnit } from './helpers';

import { getFormattedNumber, getUnit } from '../../helpers/common';
import MonthChart from './MonthChart';

const YearChart = ({ data, filterOptions }) => {
  const auxData = data[0]?.data[FIELDS_MAP[filterOptions.variable]];

  const concatenatedValues = auxData
    .map(n => n.values)
    .flat()
    .map(obj => obj.valor * 1000);

  const { minimum, maximum, realMinimum, realMaximum } = calculateMinMax(concatenatedValues);
  const [minValue, setMinValue] = useState(minimum);
  const [maxValue, setMaxValue] = useState(maximum);
  const [minCandidate, setMinCandidate] = useState(minimum)
  const [maxCandidate, setMaxCandidate] = useState(maximum)
  useEffect(() => {
    // Initialize amCharts
    am4core.useTheme(am4themes_animated);

    // Create a chart instance
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    chart.logo.disabled = true;

    // Establecer notación ingeniería con k como mínima unidad.
    chart.numberFormatter.numberFormat = `#.## !a${getChartUnit(
      filterOptions.variable
    )}`;

    // Contenedor para almacenar los heatlegends
    const heatLegendsContainer = chart.chartContainer.createChild(
      am4core.Container
    );
    // Si no tengo generación quiero un layout vertical sino horizontal con legend2 legend1
    heatLegendsContainer.layout = minimum >= 0 ? 'vertical' : 'horizontal';
    heatLegendsContainer.width = am4core.percent(100);
    heatLegendsContainer.height = 50;
    heatLegendsContainer.x = am4core.percent(0);
    heatLegendsContainer.y = am4core.percent(0);

    // heat legend para los valores negativos
    const heatLegend2 = heatLegendsContainer.createChild(am4charts.HeatLegend);
    heatLegend2.width = am4core.percent(50);
    heatLegend2.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend2.valueAxis.renderer.minGridDistance = 60;
    // Si no tengo generación quiero ocultarla y que la otra ocupe el 100%.
    heatLegend2.disabled = minimum >= 0 ? true : false;

    heatLegend2.minColor = am4core.color(COLORS.MIN_NEGATIVE_VALUE);
    heatLegend2.maxColor = am4core.color(COLORS.MAX_NEGATIVE_VALUE);
    heatLegend2.minValue = minValue;
    heatLegend2.maxValue = 0;

    // heat legend para los valores positivos
    const heatLegend = heatLegendsContainer.createChild(am4charts.HeatLegend);
    // Si no tengo generación quiero que ocupe todo el ancho, sino la mitad.
    heatLegend.width = minimum >= 0 ? am4core.percent(100) : am4core.percent(50);
    heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend.valueAxis.renderer.minGridDistance = 60;

    heatLegend.minColor = am4core.color(COLORS.MIN_POSITIVE_VALUE);
    heatLegend.maxColor = am4core.color(COLORS.MAX_POSITIVE_VALUE);
    heatLegend.minValue = 0;
    heatLegend.maxValue = maxValue;

    // Clean up on component unmount
    return () => {
      chart.dispose();
    };
  }, [auxData, filterOptions, minimum, minValue, maxValue]);

  const confirmArrayValues = () => {
    setMinValue(minCandidate);
    setMaxValue(maxCandidate);
  };

  const handleSliderChange = (e, newValue) => {
    setMinCandidate(newValue[0]);
    setMaxCandidate(newValue[1])
    
    const role = e.target.getAttribute('role')

    if (role !== 'slider' && (newValue[1] === realMaximum || (realMinimum!==0 && newValue[0] === realMinimum))) {
      setMinValue(newValue[0]);
      setMaxValue(newValue[1]);
    }

  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {minimum < 0
          &&
          <Box display="flex" flexDirection="column" minWidth="120px" alignItems="center">
            <Box>MÍNIMO</Box>
            <Box>{getFormattedNumber(minCandidate / 1000)}{getUnit(minCandidate / 1000)}{getChartUnit(
              filterOptions.variable
            )}</Box>
          </Box>
        }
        <Box width="80%">
          <Slider
            min={realMinimum}
            max={realMaximum}
            step={100}
            value={[minCandidate, maxCandidate]}
            onChange={handleSliderChange}
            marks={[{ value: 0, label: '0' }]}
            onMouseUp={confirmArrayValues}
          />
        </Box>

        <Box display="flex" flexDirection="column" minWidth="120px" alignItems="center">
          <Box>MÁXIMO</Box>
          <Box>{getFormattedNumber(maxCandidate / 1000)}{getUnit(maxCandidate / 1000)}{getChartUnit(
            filterOptions.variable
          )}</Box>
        </Box>
      </Box>
      <Grid container>
        {auxData.map(({ values, month }) => (
          <Grid item xs={12} sm={3} key={month}>
            <MonthChart
              monthData={values}
              month={month}
              filterOptions={filterOptions}
              minimum={minValue}
              maximum={maxValue}
            />
          </Grid>
        ))}
      </Grid>
      <div id="chartdiv" style={{ width: '100%', height: '80px' }}></div>

    </Box>
  );
};

export default YearChart;
