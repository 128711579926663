
import {
    Box, Step, StepLabel,
    Stepper, Tooltip, Button, TextField,
    FormControl, Select, MenuItem, makeStyles
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import DoneIcon from '@material-ui/icons/Done';
//Helpers
import { blueBar } from '../../helpers/common'
//Componentes 
import DatePickerCommon from '../DatePicker/DatePickerCommon';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2, 4),
        marginLeft: theme.spacing(4),
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        "@media (max-width: 720px)": {
            padding: theme.spacing(0),
            marginLeft: theme.spacing(0),
        },
    },
    boldText: {
        color: "#2586bc",
        fontWeight: "bold",
        "@media (max-width: 700px)": {
            fontSize: "14px",
        }
    },
    normalText: {
        color: "#2586bc",
        "@media (max-width: 700px)": {
            fontSize: "14px",
        }
    },
    labelSelect: {
        marginRight: "5px",
        color: "#2586bc",
    },
    sublabelSelect: {
        marginRight: "50px",
        width: '55px',
        "@media (max-width: 700px)": {
            width: '70px',
        }
    },
    textField: {
        width: "50%"
    },
    filterLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    dateContainer: {
        '& .MuiFormControl-marginNormal': {
            marginTop: theme.spacing(0.4),
        },
        width: "200px"

    },
    dateContainerBox: {
        marginRight: "40px",

    },
    btn: {
        margin: theme.spacing(3, 1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        marginBottom: '10px',
        marginTop: '10px',
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    steppers: {
        "@media (max-width: 370px)": {
            position: "relative",
            display: "flex",
            flexWrap: "wrap"
        },
        "@media (max-width: 920px)": {
            display: "none",
        }
    },
    stepper: {
        marginTop: "22px"
    },
    errorLabel: {
        color: 'red',
        width: "90%"
    },
    symbol: {
        position: "relative",
        top: '17px',
        width: '60px',
        marginLeft: "10px"
    },
    SelectLocation: {
        maxWidth: "400px"
    },
    dateContainerMain: {
        display: "flex",
        flexWrap: "wrap"
    },
    containerSteepOne: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "20px",
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2),
    },

}))

const ModalFilter = ({
    locationFilterSelect, handleLocation, locations,
    valueVariable, valueFijo, activeStep,
    setActiveStep, showModal, handleSubmit,
    handleInputChangeCostoVariable, handleInputChangeCostoFijo,
    steps, data, setShowModal, setData
}) => {
    const classes = useStyles();
    const blueLine = blueBar()

    return (
        <Dialog
            open={showModal}
            aria-labelledby='form-dialog-title'
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle id='form-dialog-title'>Opciones</DialogTitle>
            <DialogContent>

                {/* STEPPERS */}
                <Stepper activeStep={activeStep} className={classes.steppers} alternativeLabel>
                    {steps.map(label => (
                        <Step className={classes.stepper} key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box className={classes.root}>

                    {blueLine}

                    {/* STEP 1 */}
                    {/* TOOLTIP LOCALIZACION */}
                    <Box mb={2} mt={2} className={classes.containerSteepOne}>
                        <Tooltip title='' arrow placement='right'>
                            <Box className={clsx(classes.hint, { [classes.tick]: activeStep >= 1 })}>
                                {activeStep >= 1 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
                            </Box>
                        </Tooltip>
                        <Box ml={2} className={classes.devicesLabel} >
                            Selecciona una Localización
                        </Box>
                    </Box>

                    {/* SELECT LOCALIZACION */}
                    <Box className={classes.labelSelect}>Medidor</Box>
                    <FormControl fullWidth>
                        <Select
                            className={classes.SelectLocation}
                            labelId='localization'
                            name='localization'
                            value={locationFilterSelect}
                            label="Age"
                            defaultValue=''
                            onChange={e => {
                                setActiveStep(1);
                                handleLocation(e);
                            }}
                        >
                            {locations
                                ? locations.map((location, i) => (
                                    <MenuItem key={i} value={location.id}>
                                        {location.nombre}
                                    </MenuItem>
                                ))
                                : ''}
                        </Select>
                    </FormControl>

                    {blueLine}

                    {/* STEP 2*/}
                    {/* TOOLTIP INPUTS COSTO FIJO VARIABLE */}
                    <Box mt={2} style={{ fontWeight: "400" }} display="flex" alignItems={"center"} >

                        <Tooltip title='' arrow placement='right'>
                            <Box className={clsx(classes.hint, { [classes.tick]: activeStep >= 1 })}>
                                {activeStep >= 1 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                            </Box>
                        </Tooltip>
                        <Box ml={2} className={classes.devicesLabel}>
                            Elija los costos de su tarifa
                        </Box>
                    </Box>

                    {/* INPUT COSTO FIJO */}
                    <Box display='flex'  >
                        <Box display='flex' >
                            <p className={clsx(classes.labelSelect, classes.normalText)}>Costo</p>
                            <p className={clsx(classes.sublabelSelect, classes.boldText)}>Fijo</p></Box>
                        <TextField
                            className={classes.textField}
                            value={valueFijo}
                            placeholder="0"
                            type="number"
                            onChange={(e) => {
                                handleInputChangeCostoFijo(e);
                                activeStep < 2 ? setActiveStep(1) : setActiveStep(activeStep)

                            }}
                        />
                        <Box className={clsx(classes.normalText, classes.symbol)}>$</Box>
                    </Box>

                    {/* INPUT COSTO VARIABLE */}
                    <Box display='flex'  >
                        <Box display='flex' >
                            <p className={clsx(classes.labelSelect, classes.normalText)}>Costo</p>
                            <p className={clsx(classes.sublabelSelect, classes.boldText)}>variable</p></Box>
                        <TextField

                            className={classes.textField}
                            value={valueVariable}
                            placeholder="0"
                            type="number"
                            onChange={(e) => {
                                handleInputChangeCostoVariable(e);
                                activeStep < 2 ? setActiveStep(1) : setActiveStep(activeStep)
                            }}
                        />
                        <Box className={clsx(classes.normalText, classes.symbol)}>$ / kwh</Box>
                    </Box>

                    {blueLine}

                    {/* STEP 3 */}
                    {/* SELECTOR DE FECHA */}
                    <Box display="flex" alignItems={"center"}>
                        <Tooltip title='' arrow placement='right'>
                            <Box className={clsx(classes.hint, { [classes.tick]: activeStep >= 2 })}>
                                {activeStep >= 2 ? <DoneIcon style={{ padding: '2px' }} /> : 3}
                            </Box>
                        </Tooltip>
                        <Box ml={2} className={classes.devicesLabel}>
                            Seleccione las fechas a consultar
                        </Box>
                    </Box>

                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' >
                        <DatePickerCommon
                            startDate={data.dateFrom}
                            endDate={data.dateTo}
                            setDate={setData}
                        />
                    </Box>


                    {/* BOTONES DE MODAL FILTRO OPCIONES*/}
                    <Box display='flex' justifyContent='center'>
                        <Button
                            onClick={handleSubmit}
                            variant='contained'
                            color='primary'
                            type='submit'
                            className={classes.btn}
                        // disabled={selectionList.length ? false : true}
                        >
                            Consultar
                        </Button>

                        <Button variant='contained' onClick={() => setShowModal(false)} className={classes.btn}>
                            Cancelar
                        </Button>
                    </Box>

                </Box>

            </DialogContent>
        </Dialog>
    )
}
export default ModalFilter