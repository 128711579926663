import React from 'react';
import { Link } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TopNav from '../common/TopNav';
import Container from '../Elements/Container';

const useStyles = makeStyles(theme => ({
	loginLink: {
		padding: theme.spacing(1, 2),
		background: 'white',
		textDecoration: 'none',
		borderRadius: '3px',
		marginLeft: theme.spacing(1),
		'&:visited': {
			color: theme.palette.primary.main,
		},
		'&:hover': {
			background: theme.palette.primary.main,
			color: 'white',
		},
	},
	wordCut: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",

	},
}));

export default function Control() {
	const classes = useStyles();

	return (
		<>
			<TopNav titulo='Control' />
			<Container>
				<Box display='flex' justifyContent='center' flexDirection='column'>
					<Alert severity='warning' className={classes.wordCut} >
						Sección en construcción.
						<Link to='/' className={classes.loginLink}>
							Volver al Inicio
						</Link>
					</Alert>
				</Box>
			</Container>
		</>
	);
}
