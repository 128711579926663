import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { Box, makeStyles } from '@material-ui/core';
import { useContext, useEffect } from 'react';
// Custom Hooks
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { DefaultDevice } from './Summary';

const useStyles = makeStyles(theme => ({
  chart: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: "pointer",
    height: "300px",
    "@media (max-width: 700px)": {
      height: "300px",
    }
  },
  tituloHeaderBox: {
    color: theme.palette.common.white,
    "@media (max-width: 700px)": {
    }
  },
  datesPie: {
    "@media (max-width: 700px)": {
      fontSize: "10px"
    }
  },
}));

export default function PieChart({ data }) {
    const classes = useStyles();
  const { isMobile } = useDeviceDetect();
  const defaultDevice = useContext(DefaultDevice);

  useEffect(() => {
    let chart = am4core.create("pie", am4charts.PieChart);
    if (chart.logo) {
      chart.logo.disabled = true
    }
    const auxData = data.map(item => {
      return {
        "phase": item.fase__envio_id,
        "value": item.kwh
      }
    })
    chart.data = auxData;
    const pieSeries = chart.series.push(new am4charts.PieSeries());

    if (defaultDevice.producto === 'SMART') {
      pieSeries.colors.list = [
        am4core.color("#00C3A5"),
        am4core.color("#ff3f67"),
        am4core.color("#ffb326"),
      ];
    }
    else {
      pieSeries.colors.list = [
        am4core.color("#594F96"),
        am4core.color("#ff3f67"),
      ];
    }

   
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "phase";
    pieSeries.strokeWidth = 3;
    pieSeries.fillOpacity = 1;
    // Oculto
    pieSeries.labels.template.text = "";
    pieSeries.slices.template.tooltipText = "[bold]Fase {category}[/]\n{value} KWh";
    // pieSeries.labels.template.text = "({category}){value}Kwh \n ({value.percent.formatNumber('#.0')}%)";
    pieSeries.labels.template.fillOpacity = 1;
    //ACA ES EL CAMBIO DE FLECHAS
    pieSeries.labels.template.disabled = isMobile
    //SELECTORES DE COLOR DE CADA VALUE DEL GRAPH
    chart.legend = new am4charts.Legend()
    //OPCION PARA MODIFICAR LOS TEXTOS DE LOS LEGEND
    pieSeries.legendSettings.valueText = "{value} KWh ";
    pieSeries.legendSettings.labelText = "Fase {phase}: ";
    //UBICACION DE LOS LEGEND
    // chart.legend.position = "left";
    if (!isMobile) {
      chart.legend.position = "right";
    }

    return () => {
      chart.dispose();
    };
  }, [data, isMobile, defaultDevice]);

  return (
    <Box>
      <Box className={classes.chart} id="pie" />
    </Box>
  )
}