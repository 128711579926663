
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Node from './Node';
import './styles.css'

export default function DragDropList({ setClose, setFlagEdit, flagEdit, array, handleSelectedNode, selectedNode, setTreeChanges,setNewChangesToPositions,collapse }) {
    //funcion q transforma el array en numeroco segun la cantidad q hay arma una lista de numeros
    const defaultList = numericArray(array?.length);
    // React state to track order of items
    const [itemList, setItemList] = useState(defaultList);
    //Toma el estado para devolver los valores completos para guardar el orden.
    useEffect(() => {
        setFlagEdit({
            ...flagEdit,
            position_children: itemList
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemList])

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        let updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList)
    };

    function numericArray(max) {
        const resultado = [];
        for (let i = 0; i < max; i++) {
            resultado.push(i.toString());
        }
        return resultado;
    }

    return (
        <div className="App">
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {itemList.map((item, index) => (
                                <Draggable key={item} draggableId={item} index={index}>
                                    {(provided) => (
                                        <div
                                            className="item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <div className="container-nodes">                                          
                                                <Node
                                                    setClose={setClose}
                                                    collapse={collapse}
                                                    setFlagEdit={setFlagEdit}
                                                    flagEdit={flagEdit}
                                                    node={array[parseInt(item)]}
                                                    handleSelectedNode={handleSelectedNode}
                                                    selectedNode={selectedNode}
                                                    key={item}
                                                    setTreeChanges={setTreeChanges}
                                                    setNewChangesToPositions={setNewChangesToPositions}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}