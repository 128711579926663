import React from 'react'

import { Box, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  section: {
    fontSize: 15,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }
}));

function Section({ name, enabled, children }) {
  const classes = useStyles();

  if (!enabled) {
    return <></>
  }

  return (
    <Grid item xs={12} xl={6}>
      <Box className={classes.section}>{name}</Box>
      {children}
    </Grid>
  )
}

export default Section