import { IntegrationsCreator } from "./IntegrationsCreator";
import TopNav from "../common/TopNav";

export function IntegrationsConfig() {
    return (
        <>
            <TopNav titulo="Configuración" subtitulo='| Integraciones' />             
            <IntegrationsCreator />
        </>
    )
}