import React, { useEffect, useState } from 'react';

import GoogleMapReact from 'google-map-react';

import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';

import TopNav from '../common/TopNav';
import Marker from './Marker';
import SummaryPanel from './SummaryPanel';
import ErrorAlert from '../common/ErrorAlert';
import Container from '../Elements/Container';
import LocationsTable from './LocationsTable';

import { getLocations, getSummary } from '../../services/hierarchy';
import { defaultCenter, getLat, getLng } from './common';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  circular: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    height: '50%',
  },
  mapContainer: {
    position: 'relative',
    minHeight: '70vh',
    height: '200px',
    '@media (max-width: 720px)': {
      height: '200px',
      minHeight: 0
    },
  },
}));

export default function Map() {
  const classes = useStyles();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);

  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summary, setSummary] = useState(null);

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  const [center, setCenter] = useState(defaultCenter);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchLocations = async url => {
      try {
        // console.log(url);
        setLoading(true);
        const res = await getLocations();
        setLoading(false);
        if (res.data.error) {
          setErrorMessage(res.data.error);
          setShowErrorAlert(true);
        } else {
          // console.log(res.data);
          setLocationData(res.data);

          const firstCenter = res.data.find(
            location => location.coordinates !== '0,0'
          );
          if (firstCenter) {
            setCenter({
              lat: getLat(firstCenter.coordinates),
              lng: getLng(firstCenter.coordinates),
            });
            handleSelection(firstCenter.hierarchy)
          }

          // Si al menos una coordenada no está cargada muestro el mensaje indicándolo.
          if (res.data.some(location => location.coordinates === '0,0')) {
            setInfoMessage(
              `Una o más localizaciones no tienen cargadas sus coordenadas.`
            );
            setShowInfoAlert(true);
          }
          // Todas las coordenadas están cargadas
          else {
            setShowInfoAlert(false);
          }

          // |(res.data);
          setShowErrorAlert(false);
        }
      } catch (error) {
        // console.error(error);
        setLoading(false);
        if (error.response) {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        } else {
          setErrorMessage(`Ocurrió un error inesperado`);
        }
        setShowErrorAlert(true);
      }
    };
    fetchLocations();
  }, []);


  const zoom = 18;

  const completedData = locationData.filter(
    location => location.coordinates !== '0,0'
  );

  const handleSelection = async id => {
    try {
      setLoadingSummary(true);
      setSelectedId(id);
      setSummary(null);
      const result = await getSummary(id);

      setSummary(result.data);
      setLoadingSummary(false);
    } catch (error) {
      // console.error(error);
      setLoadingSummary(false);
      if (error.response) {
        setErrorMessage(
          `${error.response.data.msg}.`
        );
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
      setShowErrorAlert(true);
    }
  };

  return (
    <>
      <TopNav titulo="Dashboards" subtitulo="| Mapa" />
      <Container>
        {showErrorAlert && (
          <Box>
            <ErrorAlert message={errorMessage} />
          </Box>
        )}
        {showInfoAlert && (
          <Box>
            <Alert severity="warning">
              {infoMessage}
              <Button
                color="primary"
                onClick={() => history.push(`/configuracion-organizacion`)}
              >
                Actualizar
              </Button>
            </Alert>
          </Box>
        )}

        <Box className={classes.mapContainer}>
          {loading && (
            <Box className={classes.circular}>
              <CircularProgress />
            </Box>
          )}

          {!loading && !showErrorAlert && (
            <>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_MAP_KEY,
                }}
                center={center}
                defaultZoom={zoom}
              >
                {completedData.map(location => (
                  <Marker
                    selected={selectedId === location.hierarchy}
                    key={location.hierarchy}
                    name={location.name}
                    lat={
                      getLat(location.coordinates) === '0'
                        ? center.lat
                        : getLat(location.coordinates)
                    }
                    lng={
                      getLng(location.coordinates) === '0'
                        ? center.lng
                        : getLng(location.coordinates)
                    }
                    onClick={() => handleSelection(location.hierarchy)}
                  />
                ))}
              </GoogleMapReact>
              <SummaryPanel data={summary} loading={loadingSummary} />
              <LocationsTable
                data={locationData}
                handleSelection={handleSelection}
                setCenter={setCenter}
                selectedId={selectedId}
              />
            </>
          )}
        </Box>
      </Container>
    </>
  );
}
