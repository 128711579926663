
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiGrid-root': {
			padding: theme.spacing(1),
		},
	},
	devicesLabel: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
	},
	devicesBtn: {
		margin: theme.spacing(3, 1),
	},
	addBtn: {
		margin: theme.spacing(1, 0),
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: theme.spacing(4),
		width: theme.spacing(4),
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	filterChip: {
		margin: theme.spacing(0.5),
	},
	dialogTitle: {
		paddingBottom: 0,
	},
	dialogSubtitle: {
		marginLeft: theme.spacing(3),
		color: theme.palette.primary.main,
		'@media (max-width: 700px)': {
			display: 'none',
		},
	},
	selectedItemsContainer: {
		height: '35px',
	},
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '15px',
	},
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '16px',
	},
	filterLabel: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
	},
	switchLabel: {
		fontSize: '0.75rem',
	},
	rowSpace: {
		marginBottom: theme.spacing(2),
	},
	btnSpacing: {
		margin: theme.spacing(0, 1),
	},
	tableTitle: {
		padding: theme.spacing(1),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.primary.light,
		color: 'white',
		height: 48,
	},
	tableItem: {
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	rightContainer: {
		paddingLeft: theme.spacing(1),
	},
}));

function DeleteBill({ setDeletePanel, handleDeletePaperBill, handleDeleteBill }) {
	const classes = useStyles();

	return (
		<>
			<form className={classes.root} >
				<Grid container >
					<Grid item xs={12} className={classes.label}>
						Desea borrar solamente los datos cargados en la factura eléctrica de su prestadora de energía o desea
						borrar el período de facturacion completo?
					</Grid>
				</Grid>

				<Box display='flex' justifyContent='center'>
					<Button variant='contained' color='secondary' onClick={handleDeletePaperBill} type='submit' className={classes.btnSpacing}>
						BORRAR factura papel
					</Button>
					<Button variant='contained' color='secondary' onClick={handleDeleteBill} className={classes.btnSpacing}>
						BORRAR periodo completo
					</Button>
					<Button variant='contained' onClick={() => setDeletePanel(false)} className={classes.btnSpacing}>
						Cancelar
					</Button>
				</Box>
			</form>
		</>
	);
}

export default DeleteBill;