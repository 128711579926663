import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import {
    makeStyles, Box
} from '@material-ui/core';
//Componentes
import { Alert } from '@material-ui/lab';
//Helpers
import { toFormattedNumber, } from '../../../helpers/common'
const useStyles = makeStyles(theme => ({
    containerMain: {
        width: "100%"
    },
    container: {
        width: "90%",
        height: "110px",
        margin: theme.spacing(3, 0, 0, 2),
    },
    title: {
        fontSize: theme.spacing(2.2),
        marginLeft: theme.spacing(3),
        fontWeight: "600",
        paddingTop: theme.spacing(1.5),
        color: theme.palette.grey.hairline,
    },
    alerts: {
        height: "",
        width: '100%',
    },

}));

export default function RankingGraph({ currentValue, sup }) {
    const classes = useStyles();

    // Por ahora vamos a poner fijos los valores de los humbrales, que deberian ser 20 kWh/m2 y 
    // 40 kWh/m2 (umbral verde-amarillo y amarillo-rojo respectivamente).

    useEffect(() => {
        if (sup > 1) {
            am5.ready(function () {
                // Create root element
                // https://www.amcharts.com/docs/v5/getting-started/#Root_element
                var root = am5.Root.new("chartdiv");

                // Set themes
                // https://www.amcharts.com/docs/v5/concepts/themes/
                root.setThemes([
                    am5themes_Animated.new(root)
                ]);

                // Create chart
                // https://www.amcharts.com/docs/v5/charts/xy-chart/
                var chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    layout: root.verticalLayout,
                    paddingRight: 30
                }));

                // SACAMOS LOGO DE AMCHARD
                chart.root._logo.dispose();

                // Add legend
                // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
                chart.children.push(
                    am5.Legend.new(root, {
                        centerX: am5.p50,
                        x: am5.p50
                    })
                );

                var data = [
                    {
                        category: "-1",
                        value: 70,
                        columnSettings: {
                            fill: am5.color("#FFFFFF")
                        }
                    },
                    {
                        category: "0",
                        value: 70,
                        columnSettings: {
                            fill: am5.color("#FFFFFF")
                        }
                    },
                    {
                        category: "1",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0x6bc352)
                        }
                    },
                    {
                        category: "2",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0x6bc352)
                        }
                    },
                    {
                        category: "3",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0x6bc352)
                        }
                    },
                    {
                        category: "4",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0x6bc352)
                        }
                    },
                    {
                        category: "5",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0x6bc352)
                        }
                    },
                    {
                        category: "6",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xfcc034)
                        }
                    },
                    {
                        category: "7",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xfcc034)
                        }
                    },
                    {
                        category: "8",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xfcc034)
                        }
                    },
                    {
                        category: "9",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xfcc034)
                        }
                    },
                    {
                        category: "10",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xfcc034)
                        }
                    },
                    {
                        category: "11",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xc6251a)
                        }
                    },
                    {
                        category: "12",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xc6251a)
                        }
                    },
                    {
                        category: "13",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xc6251a)
                        }
                    },
                    {
                        category: "14",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xc6251a)
                        }
                    },
                    {
                        category: "15",
                        value: 70,
                        columnSettings: {
                            fill: am5.color(0xc6251a)
                        }
                    },
                    {
                        category: "16",
                        value: 70,
                        columnSettings: {
                            fill: am5.color("#FFFFFF")
                        }
                    },

                ];

                //Objeto ejemplo de marcador.
                // let auxObj = {
                //     category: `${currentValue} kWh/m2`,
                //     value: 70,
                //     currentBullet: true,
                //     columnSettings: {
                //         fill: am5.color(0xfcc034)
                //     }
                // }

                const findIndex = () => {
                    let auxNumber = parseInt(currentValue);
                    if (auxNumber < 20) {
                        return "0";
                    } else if (auxNumber < 23) {
                        return "2";
                    } else if (auxNumber < 25) {
                        return "3";
                    } else if (auxNumber < 27) {
                        return "4";
                    } else if (auxNumber < 30) {
                        return "5";
                    } else if (auxNumber < 33) {
                        return "7";
                    } else if (auxNumber < 35) {
                        return "8";
                    } else if (auxNumber < 37) {
                        return "9";
                    } else if (auxNumber < 40) {
                        return "10";
                    } else if (auxNumber < 43) {
                        return "12";
                    } else if (auxNumber < 45) {
                        return "13";
                    } else if (auxNumber < 47) {
                        return "14";
                    } else {
                        return "15";
                    }
                };
                //Formateo numero de el marcador a nuestro formato. 
                let formatCurrentValue = toFormattedNumber(currentValue)
                //modifico el array original y coloco el marcador en el lugar donde esta ubicado segun la escala de 20 a 40.
                let newData = data.map((elem) => {
                    if (elem.category === findIndex()) {
                        elem.category = `${formatCurrentValue} kWh/m2`
                        elem.currentBullet = true
                        //si es menor a 20
                        if (parseInt(formatCurrentValue) < 20) {
                            elem.columnSettings = {
                                fill: am5.color(0x6bc352)
                            }
                        }
                        return elem
                    }
                    else {
                        return elem
                    }
                })

                data = newData

                // Create axes
                // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
                var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "category",
                    renderer: am5xy.AxisRendererX.new(root, {

                    }),
                    tooltip: am5.Tooltip.new(root, {})
                }));

                var xRenderer = xAxis.get("renderer");

                xRenderer.grid.template.set("forceHidden", true);
                xRenderer.labels.template.set("forceHidden", true);
                xAxis.data.setAll(data);

                var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    min: 0,
                    max: 400,
                    strictMinMax: true,
                    renderer: am5xy.AxisRendererY.new(root, {})
                }));

                var yRenderer = yAxis.get("renderer");

                yRenderer.grid.template.set("forceHidden", true);
                yRenderer.labels.template.set("forceHidden", true);

                // Add series
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "category",
                    maskBullets: false
                }));

                series.columns.template.setAll({
                    //tooltipText: "{categoryX}: {valueY}",
                    width: am5.p100,
                    tooltipY: 0,
                    strokeOpacity: 1,
                    strokeWidth: 2,
                    stroke: am5.color(0xffffff),
                    templateField: "columnSettings"
                });

                series.bullets.push(function (root, target, dataItem) {
                    if (dataItem.dataContext.currentBullet) {
                        var container = am5.Container.new(root, {});

                        container.children.push(am5.Graphics.new(root, {
                            fill: dataItem.dataContext.columnSettings.fill,
                            dy: -5,
                            centerY: am5.p100,
                            centerX: am5.p50,
                            svgPath: "M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z"
                        }));

                        container.children.push(am5.Label.new(root, {
                            text: dataItem.get("categoryX"),
                            dy: -38,
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true,
                            paddingTop: 5,
                            paddingRight: 5,
                            paddingBottom: 5,
                            paddingLeft: 5,
                            background: am5.RoundedRectangle.new(root, {
                                fill: am5.color(0xffffff),
                                cornerRadiusTL: 20,
                                cornerRadiusTR: 20,
                                cornerRadiusBR: 20,
                                cornerRadiusBL: 20,
                            })
                        }));

                        return am5.Bullet.new(root, {
                            locationY: 1,
                            sprite: container
                        });
                    }
                    else if (dataItem.dataContext.targetBullet) {
                        let container = am5.Container.new(root, {
                            dx: 15
                        });

                        // var circle = container.children.push(am5.Circle.new(root, {
                        //     radius: 34,
                        //     fill: am5.color(0x11326d),
                        // }));

                        container.children.push(am5.Label.new(root, {
                            // text: "GOAL\n[bold]ZERO[/]",
                            textAlign: "center",
                            //fontSize: "10",
                            fill: am5.color(0xffffff),
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true,
                        }));
                        return am5.Bullet.new(root, {
                            locationY: 0.5,
                            sprite: container
                        });
                    }
                    return false;
                });

                series.data.setAll(data);

                // Add labels
                function addAxisLabel(category, text) {
                    var rangeDataItem = xAxis.makeDataItem({
                        category: category
                    });

                    var range = xAxis.createAxisRange(rangeDataItem);

                    range.get("label").setAll({
                        //fill: am5.color(0xffffff),
                        text: text,
                        forceHidden: false
                    });

                    range.get("grid").setAll({
                        //stroke: color,
                        strokeOpacity: 1,
                        location: 1
                    });
                }

                addAxisLabel("11", "+40");
                addAxisLabel("6", "30");
                addAxisLabel("1", "20");

                // Make stuff animate on load
                // https://www.amcharts.com/docs/v5/concepts/animations/
                series.appear(1000, 100);
                chart.appear(1000, 100);

                if (sup === 1) {
                    root = ""
                }
                return () => {
                    // console.log('Dispose Chart');
                    chart.dispose();
                };
            })

        }
        // eslint-disable-next-line 
    }, [currentValue])

    return (
        <Box >
            {sup === 1 &&
                <Alert
                    className={classes.alerts}
                    severity={"error"}
                >
                    {"Debe tener configurada una superficie para poder ver el gráfico  de ranking de energía."}
                </Alert>
            }
            <Box className={classes.containerMain}>
                <Box className={classes.title}>
                    Ranking  <span style={{ fontWeight: '400' }}>{"| Consumo de energía mensual por superficie"}</span>
                </Box>
                <Box className={classes.container} id="chartdiv"></Box>
            </Box>
        </Box>
    )
}

