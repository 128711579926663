import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const useStyles = makeStyles(theme => ({
    containerMain: {
        marginTop: "18px"
    },
    containerDates: {
        fontSize: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.primary.main}`,
        width: "100%",
        boxShadow: '3px 3px 3px #cbc1c157',
        color: theme.palette.grey.main,
    },
    tituloHeaderBox: {
        paddingTop: "20px",
        paddingLeft: "18px",
        cursor: "default",
        "@media (max-width: 700px)": {
            fontSize: "12px"
        }
    },
    datesPie: {
        "@media (max-width: 700px)": {
            fontSize: "10px"
        }
    },
    boldText: {
        fontWeight: "bold",
        fontSize: "12px"
    },
    containerCircles: {
        position: "absolute"
    },
    fase: {
        padding: "3px",
        textAlign: "center",
        width: "25px",
        height: "25px",
        backgroundColor: "red",
        borderRadius: "50%",
        marginLeft: "14px",
        marginBottom: "5px",
        boxShadow: "2px 2px 2px #948c8ca1"
    },
    faseTcolor: {
        backgroundColor: "#6874d7c9"
    },
    faseRcolor: {
        backgroundColor: "#6abad9d4"
    },
    faseScolor: {
        backgroundColor: "#6997d8d6"
    }
}))

export default function PieChart({ chartData }) {
    const classes = useStyles();
    const [dates, setDates] = useState(false)

    //Funcion para identificar vista mobile.
    const onResize = () => {
        if (window.screen.width <= 700) {
            setDates(true)
            // console.log("mobile")
        }
        else {
            setDates(false)
            // console.log("desktop");
        }
    }
    useEffect(() => {
        // window.addEventListener("resize", onResize, { passive: true })
        onResize()
    })

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Create chart instance
        let chart = am4core.create("costPieChart", am4charts.PieChart);
        // SACAMOS LOGO DE AMCHARD
        if (chart.logo) {
            chart.logo.disabled = true
        }
        // Add data
        chart.data = chartData;
        chart.innerRadius = am4core.percent(40)
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "cost";
        pieSeries.dataFields.category = "parameter";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.truncate = true;
        pieSeries.labels.template.fontSize = 15;
        pieSeries.ticks.template.disabled = true;

        pieSeries.labels.template.disabled = dates         //ACA ES EL CAMBIO DE FLECHAS
        // pieSeries.labels.template.disabled = false
        // pieSeries.legendSettings.labelText = '{category}';
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -120;
        pieSeries.hiddenState.properties.startAngle = 100;

        // //SELECTORES DE COLOR DE CADA VALUE DEL GRAPH
        chart.legend = new am4charts.Legend()
        // chart.legend.itemContainers.template.paddingBottom = 10;
        chart.legend.position = 'left';

        return (() => chart.dispose())

    }, [chartData, dates]);

    return (
        <Box className={classes.containerMain}>
            <Box >
                <div id='costPieChart' style={{ height: '35vh' }} />
            </Box>
            {true &&
                <Box display="flex" justifyContent="space-around" className={classes.containerDates}>
                    <Box className={classes.tituloHeaderBox}>
                        {"Fase (R)"} <div className={classes.datesPie} style={{ fontWeight: '300' }}>{`${chartData[0].parameter}`}</div>
                    </Box>
                    <Box className={classes.tituloHeaderBox}>
                        {"Fase (S)"} <div className={classes.datesPie} style={{ fontWeight: '300' }}>{`${chartData[1].parameter}`}</div>
                    </Box>
                    <Box className={classes.tituloHeaderBox}>
                        {"Fase (T)"} <div className={classes.datesPie} style={{ fontWeight: '300' }}>{`${chartData[2].parameter}`}</div>
                    </Box>
                </Box>
            }
        </Box>
    );
}
