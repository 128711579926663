import React, { useEffect, useState } from 'react';
// import { NavLink } from "react-router-dom";
//Material UI 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import blue from '@material-ui/core/colors/blue';
import {
	Box, Button, Checkbox, Collapse,
	IconButton, makeStyles, Paper, Table,
	TableBody, TableCell, TableContainer,
	TableHead, TableRow,
} from '@material-ui/core';
// helpers
import { toFormattedNumber } from './../../helpers/common'
//Servicios
import { patchConciliation } from '../../services/billing';
//Components
import ModalShowBIll from './ModalShowBIll';

const useStyles = makeStyles(theme => ({
	alert: {
		position: 'sticky',
		marginLeft: theme.spacing(8),
		width: 300,
		bottom: 0,
	},
	tableContainer: {
		width: `calc(100% - ${theme.spacing(8)}px) -  ${theme.spacing(2)}px)`,
	},
	headerRow: {
		backgroundColor: theme.palette.primary.main,
	},
	headerCell: {
		width: '10%',
		fontSize: 14,
		color: theme.palette.common.white,
		fontWeight: 'bold',
	},
	headerBigCell: {
		width: '14%',
		fontSize: 14,
		color: theme.palette.common.white,
		padding: theme.spacing(0.5),
		fontWeight: 'bold',
	},
	headerNarrowCell: {
		width: '6%',
		fontSize: 14,
		color: theme.palette.common.white,
		padding: theme.spacing(0.5),
		fontWeight: 'bold',
	},
	narrowCell: {
		width: '6%',
		padding: theme.spacing(0.5),
	},
	normalCell: {
		width: '10%',
		padding: theme.spacing(0.5),
	},
	bigCell: {
		width: '14%',
		padding: theme.spacing(0.5),
	},
	conciliated: {
		background: blue[100],
	},
	diffHigh: {
		background: red[100],
	},
	diffLow: {
		background: yellow[100],
	},
	diffOk: {
		background: green[100],
	},
	btn: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

function ConciliationTable({ data, setUpdateData }) {
	const classes = useStyles();
	const [tableData, setTableData] = useState([]);
	const [notification, setNotification] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationSeverity, setNotificationSeverity] = useState('error');
	const [showBill, setShowBill] = useState(false)
	const [configModal, setConfigModal] = useState({})

	useEffect(() => {
		// Agrego campos auxiliares para el manejo de la tabla
		data.forEach(location => {
			location.isOpen = false;
			location.bills.forEach(bill => {
				bill.isChecked = bill.conciliation.status === 'conciliated' ? true : false;
			});
		});
		setTableData(data);
	}, [data]);

	useEffect(() => {
		if (notification) {
			setTimeout(() => {
				setNotification(false);
			}, 3000);
		}
	}, [notification]);

	// Actualizo los checkboxes individuales dentro de bills
	const handleSingleChange = (location, index) => e => {
		const auxData = [...tableData];

		auxData.forEach(l => {
			if (l.location === location.location) {
				l.bills[index].isChecked = !l.bills[index].isChecked;
			}
		});
		setTableData(auxData);
	};

	//Expando o contraigo facturas de localización
	const handleArrows = location => {
		const auxData = [...tableData];
		auxData.forEach(l => {
			if (l.location === location.location) {
				l.isOpen = !l.isOpen;
			}
		});
		setTableData(auxData);
	};

	//Actualizo los datos del servidor
	const handleSave = async () => {
		// Quiero mostrar notificación de cambios sólo si los hubo
		let changesNotification = false;
		for (const location of tableData) {
			for (const bill of location.bills) {
				// Si el valor actual es distinto del valor leído de la API
				if (
					(bill.conciliation.status === 'conciliated' && !bill.isChecked) ||
					(bill.conciliation.status !== 'conciliated' && bill.isChecked)
				) {
					// console.log(bill.conciliation);
					// console.log(location);
					// console.log(bill.conciliation.bill, bill.isChecked);
					try {
						const res = await patchConciliation(bill.conciliation.bill, {
							conciliated: bill.isChecked,
						});
						// console.log(res);
						if (res.status === 200) {
							changesNotification = true;
						}
					} catch (error) {
						console.error(error);
					}
				}
			}
		}

		setNotification(true);
		setUpdateData(true);
		if (changesNotification) {
			setNotificationSeverity('success');
			setNotificationMessage('Cambios guardados correctamente');
		} else {
			setNotificationSeverity('warning');
			setNotificationMessage('No se han registrado cambios');
		}
	};

	const togglePanelBill = (bill, location) => {
		setShowBill(!showBill)
		setConfigModal({
			bill: bill,
			location: location
		})
	};

	//funcion para ordenar lista de las facturas de cada localizacion.
	const orderDataList = (arry) => {
		arry.sort((a, b) => b.from.split('-')[1] - a.from.split('-')[1])
		return arry
	}

	return (
		<>
			{/* Modal de la factura a mostrar */}
			{showBill &&
				<ModalShowBIll
					showBill={showBill}
					togglePanelBill={togglePanelBill}
					configModal={configModal}
				/>
			}

			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow className={classes.headerRow}>
							<TableCell align='center' className={classes.headerNarrowCell}>
								Conciliada
							</TableCell>
							<TableCell align='center' className={classes.headerBigCell}>
								Localización
							</TableCell>
							<TableCell align='center' className={classes.headerBigCell}>
								Período
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Desde
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Hasta
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Fc. reconstruida
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Fc. real
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Dif. ($)
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Dif. (%)
							</TableCell>
							<TableCell align='center' className={classes.headerCell}>
								Más info
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>



						{tableData.map(location => (
							<React.Fragment key={location.location}>
								<TableRow hover>
									<TableCell align='center' component='th' scope='row' className={classes.narrowCell}>
										{/* Si no tengo facturas asociadas no hay nada para conciliar */}
										<Checkbox checked={false} name={location.location} color='primary' disabled={location.bills.length === 0} />
									</TableCell>
									<TableCell align='left' className={classes.bigCell}>
										{/* Si no tengo facturas asociadas no hay nada para desplegar */}

										<Box>
											{location.bills.length > 0 && (
												<IconButton aria-label='expandir o contraer fila' size='small' onClick={() => handleArrows(location)}>
													{location.isOpen ? (
														<KeyboardArrowUpIcon color='primary' />
													) : (
														<KeyboardArrowDownIcon color='primary' />
													)}
												</IconButton>
											)}
											{location.location}
										</Box>
									</TableCell>
									<TableCell align='center' className={classes.bigCell}>
										-
									</TableCell>
									<TableCell align='center' className={classes.normalCell}>
										-
									</TableCell>
									<TableCell align='center' className={classes.normalCell}>
										-
									</TableCell>
									<TableCell align='center' className={classes.normalCell}>
										{toFormattedNumber(location.total_calc, '$')}
									</TableCell>
									<TableCell align='center' className={classes.normalCell}>
										{toFormattedNumber(location.total_paper, '$')}
									</TableCell>
									<TableCell align='center' className={classes.normalCell}>
										{toFormattedNumber(location.dif_total, '$')}
									</TableCell>

									<TableCell align='center' className={classes.narrowCell}>
										{toFormattedNumber(location.pct_total, '%')}
									</TableCell>
									<TableCell align='center' className={classes.normalCell}></TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ padding: 0 }} colSpan={10}>
										<Collapse in={location.isOpen}>
											<Table size='small'>
												<TableBody>

													{orderDataList(location.bills).map((bill, index) => (
														<TableRow
															key={bill.conciliation.bill}
															className={clsx({
																[classes.diffHigh]: bill.conciliation.status === 'diff_high',
																[classes.diffLow]: bill.conciliation.status === 'diff_low',
																[classes.diffOk]: bill.conciliation.status === 'diff_ok',
																[classes.conciliated]: bill.conciliation.status === 'conciliated',
															})}
														>
															<TableCell align='center' className={classes.narrowCell}>
																<Checkbox
																	checked={bill.isChecked}
																	name={String(bill.conciliation.bill)}
																	color='primary'
																	onChange={handleSingleChange(location, index)}
																	style={{ marginLeft: '10px', padding: 0 }}
																/>
															</TableCell>
															<TableCell align='center' className={classes.bigCell}>
																{location.location}
															</TableCell>
															<TableCell align='center' className={classes.bigCell}>
																{bill.name}
															</TableCell>
															<TableCell align='center' className={classes.normalCell}>
																{bill.from}
															</TableCell>
															<TableCell align='center' className={classes.normalCell}>
																{bill.to}
															</TableCell>
															<TableCell align='center' className={classes.normalCell}>
																{toFormattedNumber(bill.conciliation.total_calc, '$')}
															</TableCell>
															<TableCell align='center' className={classes.normalCell}>
																{toFormattedNumber(bill.conciliation.total_paper, '$')}
															</TableCell>
															<TableCell align='center' className={classes.normalCell}>
																{toFormattedNumber(bill.conciliation.dif, '$')}
															</TableCell>
															<TableCell align='center' className={classes.normalCell}>
																{toFormattedNumber(bill.conciliation.pct, '%')}
															</TableCell>
															<TableCell align='center' className={classes.narrowCell}>

																{/* este modo es para abrir una url dinamica en lka seccion factura esta deshabilitado de momento */}
																{/* <NavLink to={`/show-factura/${bill.conciliation.bill}-${location?.location_id}-${location?.location}`}>
																	<Button variant='outlined' color='primary'>
																		Ver +
																	</Button>
																</NavLink> */}

																<Button variant='outlined' onClick={() => togglePanelBill(bill, location)} color='primary'>
																	Ver +
																</Button>

															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box display='flex' justifyContent='center'>
				<Button color='primary' variant='contained' onClick={handleSave} className={classes.btn}>
					Guardar
				</Button>
			</Box>
			{notification ? (
				<Box className={classes.alert} display='flex' justifyContent='center' flexDirection='column'>
					<Alert severity={notificationSeverity}>{notificationMessage}</Alert>
				</Box>
			) : null}
		</>
	);
	// return <></>;
}

export default ConciliationTable;
