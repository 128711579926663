import React, { useContext } from 'react';

import { Box, Button, ListItem, ListItemText, makeStyles } from '@material-ui/core';

import UserContext from '../../context/UserContext';
import { postNewTag } from '../../services/hierarchy';

const useStyles = makeStyles(theme => ({
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  btn: {
    marginBottom: '10px',
    '& .MuiButton-label': {
      fontSize: 11,
      textTransform: 'none',
    },
  },
}));

export default function Labels({handleSelectedLabel, selectedLabel, treeLabelsChanges, setTreeLabelsChanges,fullTagList}) {
  const classes = useStyles();

  const {plan} = useContext(UserContext);

  // console.log('fullTagList', fullTagList);

  const handleNewTag = async () => {
    // console.log('handleNewTag');
    const res = await postNewTag({nombre: 'Nueva Etiqueta'});
    // console.log(res.data);
    //Redibujo lista de etiquetas.
    setTreeLabelsChanges(current => !current);

    //Selecciono la nueva etiqueta creada cuando el endpoint retorne el id
    handleSelectedLabel(res.data.nombre, res.data.id, res.data.color);
  };

  return (
    <>
      {plan?.info?.plan !== 'Demo' && (
        <Button type='submit' color='primary' variant='contained' className={classes.btn} onClick={handleNewTag}>
          Nueva Etiqueta
        </Button>
      )}

      {fullTagList.map(tag => (
        <ListItem
          key={tag.id}
          button
          onClick={() => handleSelectedLabel(tag.nombre, tag.id, tag.color)}
          selected={selectedLabel ? selectedLabel.id === tag.id : false}
          className={classes.selected}
        >
          <Box bgcolor={tag.color} p={0.25} m={0.5} width={20} height={3}></Box>
          <ListItemText primary={tag.nombre} />
        </ListItem>
      ))}
    </>
  );
}
