import { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, CircularProgress, makeStyles, Box, IconButton
} from '@material-ui/core';
//Components
import CostCompareTable from '../Bill/CostCompareTable';
//Endpoints
import {
    getCostPowEnergy
} from '../../services/billing';
//Helpers 
import { formatDateStrDashToSlash } from '../../helpers/common';
import { format } from 'date-fns';
//Icon
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles(theme => ({
    containerLoading: {
        height: "100px"
    },
    containerBill: {
        border: "1px solid #5391b4",
        borderRadius: "4px",
        margin: theme.spacing(1, 1, 4, 1),
    },
    containerTable: {
        margin: theme.spacing(1),
    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
    },
    headeBill: {
        borderBottom: '2px solid #5391b4',
        display: "flex",
    },
    labelheadeBill: {
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: "bold",
        marginRight: "10px"
    },
    headeBillLeft: {
        padding: "14px 10px 14px 20px",
        "@media (max-width: 700px)": {
            padding: "10px 10px 10px 10px",
        }
    },
    labelDataBill: {
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: "bold",
        width: "90px"
    },
    titleHeader: {
        marginLeft: "20px",
        fontFamily: "Poppins",
        fontWeight: "bold",
        color: "#5d8e9a",
        width: "266px"
    },
    dataBill: {
        marginBottom: "10px",
        padding: "14px 10px 14px 20px",
        borderBottom: '2px solid #5391b4',
        "@media (max-width: 700px)": {
            padding: "10px 10px 10px 10px",
        }
    },
    buttonPrint: {
        margin: '5px 10px 0 0',
        "@media print": {
            display: 'none'
        }
    },

}));

export default function ModalShowBIll({ showBill, togglePanelBill, configModal }) {
    const classes = useStyles();
    const [costTableData, setCostTableData] = useState([]);
    const [billData, setBillData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        mergeData(configModal?.bill?.conciliation?.bill)
        // handleChangePeriod(configModal?.bill?.conciliation?.bill)
        // eslint-disable-next-line
    }, [])

    const mergeData = async (billId) => {
        try {
            const CostPowEnergy = await getCostPowEnergy(billId);
            setCostTableData(CostPowEnergy.data.costs);
            const getBill = await getCostPowEnergy(billId);
            let selectedBill = getBill.data
            setBillData(selectedBill);
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    };

    return (
        <>
            {/* POP UP FACTURA */}
            < Dialog
                open={showBill}
                onClose={togglePanelBill}
                aria-labelledby='form-dialog-title'
                maxWidth='lg'
                fullWidth
            >
                <Box display='flex' justifyContent='space-between'>
                    <DialogTitle id='form-dialog-title'>Factura</DialogTitle>
                    <Box className={classes.buttonPrint} >
                        <IconButton
                            onClick={togglePanelBill}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
                </Box>


                {loading ?
                    <Box className={classes.containerLoading}>
                        <div className={classes.circular}>
                            <CircularProgress />
                        </div>
                    </Box>
                    :
                    <Box className={classes.containerBill}>
                        <Box className={classes.headeBill} >
                            <Box className={classes.headeBillLeft}>
                                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                    <Box className={classes.labelheadeBill} >Factura electrica: </Box>
                                    <Box>{`${configModal?.location?.location}`}</Box>
                                </Box>
                                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                    <Box className={classes.labelheadeBill} > Fecha: </Box>
                                    <Box>{`${format(new Date(), 'dd-MM-yyyy')}`}</Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className={classes.dataBill} >
                            <Box style={{ display: "flex", flexWrap: "wrap", }}>
                                <Box className={classes.labelDataBill} >Nombre: </Box>
                                <Box>{`${configModal?.bill?.name}`}</Box>
                            </Box>
                            <Box style={{ display: "flex", flexWrap: "wrap", }} >
                                <Box className={classes.labelDataBill}>Periodo: </Box>
                                <Box>{` Desde: ${formatDateStrDashToSlash(configModal?.bill?.from)} -  Hasta: ${formatDateStrDashToSlash(configModal?.bill?.to)} `}</Box>
                            </Box>
                        </Box>

                        {costTableData.length > 0 &&
                            <Box className={classes.containerTable} >
                                <CostCompareTable
                                    conciliation={true}
                                    tableData={costTableData}
                                    billData={billData}
                                />
                            </Box>
                        }

                    </Box>
                }
            </Dialog >
        </>
    )
}