import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailIcon from '@material-ui/icons/Mail';
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import { Alert } from '@mui/material';
import NodesListContext from '../../../context/NodesListContext';
import UserContext from '../../../context/UserContext';
import { truncateWithEllipsis } from '../../../helpers/common';
import {
  deleteReport,
  generateReport,
  getLastReport,
  getReports,
  getReportsPage,
  testSendEmails,
} from '../../../services/reports';
import Container from '../../Elements/Container';
import TopNav from '../../common/TopNav';
import ConfirmationDialog from '../ConfirmationDialog';
import Filter from '../Filter';
import {
  DEFAULT_FILTER_OPTIONS,
  NO_REPORTS_MESSAGE,
  REPORT_STATUS_MESSAGES,
  SEND_STATUS_MESSAGES,
  WELCOME_MESSAGE,
} from '../helpers';
import { convertToTimeZone } from 'date-fns-timezone';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-sizeSmall': {
      'padding': theme.spacing(0.25)
    }
  },
  btn: {
    '& .MuiButton-label': {
      color: theme.palette.common.white,
      textTransform: 'none',
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  headerCell: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  spinning: {
    animation: `$spin 2s infinite`,
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '50%': { transform: 'rotate(180deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  circleList: {
    width: 5,
    height: 5,
    borderRadius: 300,
    marginRight: 10
  },
  detailsListItem: {
    padding: theme.spacing(1.2),
    display: 'flex',
    alignItems: 'center'
  },
  detailsListItemTitle: {
    color: theme.palette.primary.dark,
  },
  detailsListItemContent: {
    margin: theme.spacing(0, 0, 0, 3),
    textOverflow: "ellipsis",
  },
  dialog: {
    paddingBottom:5,
    '& .MuiDialog-container': {
      '@media (max-width: 1100px)': {
        margin: '0px 0px 0 0',
      }
    },
    '& .MuiDialog-paper': {
      margin: 0
    },
    '& .MuiDialog-paperScrollPaper': {
      color: '#423d3dde',
      maxHeight: '100vh',
      '@media (max-width: 900px)': {
        width: '100%',
        height: '100%',
      }
    }
  },
  title: {
    padding: theme.spacing(1),
    fontSize: 20,
    color: theme.palette.primary.dark,
    '@media (max-width: 900px)': {
      fontSize: 18,
    }
  },
  iconCLoseButton: {
    marginLeft: theme.spacing(2),
    height: "40px"
  },
}));

export default function Reports() {
  const classes = useStyles();
  const { nodesList } = useContext(NodesListContext);
  const { plan } = useContext(UserContext);
  //estado para abrir el panel del filtro
  const [isOpen, setIsOpen] = useState(false);
  //panel de confirmación
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  const [filterOptions, setFilterOptions] = useState(DEFAULT_FILTER_OPTIONS);

  const [notification, setNotification] = useState({
    isOpen: false,
    message: '',
    severity: 'warning',
  });

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const REPORT_ERROR = "REPORT_ERROR"
  const [reportWithError, setReportWithError] = useState(null);

  // Paginación
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const handlePageChange = (event, newPage) => {
    fetchReportsPage(newPage + 1);
    setPage(newPage);
  };

  useEffect(() => {
    fetchReports();
    // se llama a la función fetchReports cada 15 segundos
    const intervalId = setInterval(() => {
      fetchReports(false);
    }, 15000);
    // se limpia el intervalo
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setIsDeleteDialogOpen(true);
    }
  }, [selectedItem]);

  const fetchReports = async (showLoading = true) => {
    try {
      if (showLoading) {
        setLoading(true);
      }
      const res = await getReports();
      setReports(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchReportsPage = async page => {
    try {
      setLoading(true);
      const res = await getReportsPage(page);
      setReports(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleSubmit = filterNotification => {
    setNotification(filterNotification)
    fetchReports();
    setIsOpen(false);
  };

  const handleNewReport = () => {

    // Como en el helper no tengo el plan le adoso info dependiente de él.

    const initialFrequency = plan?.info?.plan === 'Gratuito' ? 'WEEKLY' : 'DAILY';

    const initialFrequencyCustom = plan?.info?.plan === 'Gratuito' ? 7 : 1;

    setFilterOptions({ ...DEFAULT_FILTER_OPTIONS, frequency: initialFrequency, frequency_custom: initialFrequencyCustom });
    setIsOpen(true);
  };

  const handleGenerate = async reportToGenerate => {
    try {
      updateStateOfReport(reportToGenerate.id, 'IN_PROCESS');
      await generateReport(reportToGenerate.id);
      setNotification({
        isOpen: true,
        message: 'Solicitud de generación de reporte enviada',
        severity: 'success',
      });
      setTimeout(() => {
        fetchReports();
      }, 1000);
    } catch (error) {
      let message = 'Ocurrió un error inesperado';
      if (error.response) {
        // TODO A futuro deberíamos recibir el mensaje de error en error.response.data.msg
        message = `${error.response.data.detail}.`
      }
      setNotification({
        isOpen: true,
        message,
        severity: 'error',
      });
    }
  };

  const updateStateOfReport = (reportId, status) => {
    //control para modificar el estado del reporte seleccionado
    let reportsList = reports?.results;
    let updatedReports = reportsList.map(report => {
      if (report.id === reportId) {
        return { ...report, report_status: status };
      } else {
        return report;
      }
    });
    setReports({
      ...reports,
      results: updatedReports
    })
  }

  const handleDownload = async report => {
    try {
      const res = await getLastReport(report.id);

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute(
        'download',
        `${report.report_name} ${report.last_report_date}.${report.format.toLowerCase()}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      let message = 'Ocurrió un error inesperado';

      // Por tratarse de un blob tendremos que hacer un tratamiento especial al error
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = function () {
          const errorMessage = reader.result.replace(/[[\]"]/g, '');
          // console.log(errorMessage);
          message = errorMessage;
          setNotification({
            isOpen: true,
            message,
            severity: 'error',
          });
        };
        reader.readAsText(error.response.data);
      }
    }
  };

  const handleTest = async reportToGenerate => {
    try {
      updateStateOfReport(reportToGenerate.id, 'IN_PROCESS');
      await testSendEmails(reportToGenerate.id);
      setNotification({
        isOpen: true,
        message: 'Mail de prueba enviado exitosamente',
        severity: 'success',
      });
      updateStateOfReport(reportToGenerate.id, 'OK');
    } catch (error) {
      let message = 'Ocurrió un error inesperado';
      if (error.response) {
        message = `${error.response.data.msg}.`;
      }
      setNotification({
        isOpen: true,
        message,
        severity: 'error',
      });
      updateStateOfReport(reportToGenerate.id, 'REPORT_ERROR');
    }
  };

  const handleEdit = selectedReport => {
    const {
      id,
      report_name,
      type,
      format,
      nodes,
      variable,
      frequency_date,
      chosen_range,
      report_automatic,
      reportAutomatic,
    } = selectedReport;

    // En nodes tengo ['1451'] y quiero obtener devices=[{id:1451, name: 'AMBA'}]
    const devices = [];
    nodesList.forEach(node => {
      if (nodes.includes(String(node.id))) {
        devices.push({ id: node.id, name: node.nombre });
      }
    });
    // Obtener tabValue
    const variables = plan?.sections?.['ALERTAS_REPORTES'];
    const variableArray = variable?.split(',')
    const selectedVariable = variables?.find(v => v.key === variableArray?.[0])
    const candidateIndex = selectedVariable?.periods?.findIndex(period => period.key === frequency_date)
    const selectedIndex = candidateIndex === -1 ? 0 : candidateIndex;

    // Calcular la fecha de inicio en la hora siguiente y configurando los minutos en 0
    const now = new Date();
    now.setMinutes(0);

    const a = convertToTimeZone(now, { timeZone: plan?.info?.tz })

    const oneHourLater = new Date(new Date(a).getTime() + 60 * 60 * 1000);


    // Valor inicial de frecuencia
    // Será usado mas abajo si no elijeron frequency_custom y no le dieron valor a frequency.
    const initialFrequency = plan?.info?.plan === 'Gratuito' ? 'WEEKLY' : 'DAILY';

    const initialFrequencyCustom = plan?.info?.plan === 'Gratuito' ? 7 : 1;

    setFilterOptions({
      ...filterOptions,
      id,
      report_name,
      type,
      format,
      devices,
      variable: variableArray,
      frequency_date,
      chosen_range,
      report_automatic,
      reportAutomatic,
      emails: reportAutomatic?.emails.split(',') || [],
      dateFrom: reportAutomatic?.start
        ? new Date(reportAutomatic?.start)
        : oneHourLater,


      frequency: reportAutomatic?.frequency_custom ? 'CUSTOM' : reportAutomatic?.frequency || initialFrequency,
      frequency_custom: reportAutomatic?.frequency_custom || initialFrequencyCustom,
      tabValue: selectedIndex
    });

    setIsOpen(true);
  };

  const handlePreDelete = report => {
    setSelectedItem(report);
  };

  const handleDelete = async () => {
    try {
      if (selectedItem?.id) {
        await deleteReport(selectedItem.id);
      }
      setNotification({
        isOpen: true,
        message: 'Reporte eliminado exitosamente',
        severity: 'success',
      });

      setIsDeleteDialogOpen(false);

      // Quiero mostrar la alerta y luego cierro.
      setTimeout(() => {
        setSelectedItem(null);
        fetchReports();
      }, 1000);
    } catch (error) {
      let message = 'Ocurrió un error inesperado';
      if (error.response) {
        // TODO A futuro deberíamos recibir el mensaje de error en error.response.data.msg
        message = `${error.response.data.detail}.`;
      }

      setNotification({
        isOpen: true,
        message,
        severity: 'error',
      });
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
    // Delay para evitar flickering (aparece undefined durante un instante)
    setTimeout(() => {
      setSelectedItem(null);
    }, 100);
  };

  const getAutomaticReportStatusText = report => {
    const { report_automatic, reportAutomatic } = report;
    if (!report_automatic) return 'Reporte no automático';

    const sendStatus = reportAutomatic?.send_status;
    const lastDate = reportAutomatic?.last_date;

    if (!sendStatus) {
      return 'Estado no disponible';
    }

    const message =
      SEND_STATUS_MESSAGES[sendStatus]?.message ||
      'Mensaje de estado no disponible';

    const nextDate = `Próximo envío ${reportAutomatic.start_format}`;

    if (lastDate) {
      let additionalMessage = '';
      if (sendStatus === 'SEND_ERROR') {
        additionalMessage = `Dirección de email: ${reportAutomatic.emails}. Último intento de envío: `;
      } else if (sendStatus === 'SEND_OK') {
        additionalMessage = 'Último reporte enviado: ';
      }
      return `${message} ${additionalMessage} ${lastDate}. ${nextDate}`;
    }

    return `${message} ${nextDate}`;
  };

  const getAutomaticReportStatusIcon = report => {
    // Si tengo el reporte automático deshabilitado
    if (!report.report_automatic) {
      return <TimerOffIcon />;
    }
    return (
      SEND_STATUS_MESSAGES[report.reportAutomatic?.send_status]?.icon || (
        <TimerIcon />
      )
    );
  };

  const getAutomaticReportStatusColor = report => {
    // Si tengo el reporte automático deshabilitado
    if (!report.report_automatic) {
      return null;
    }
    return SEND_STATUS_MESSAGES[report.reportAutomatic?.send_status]?.color;
  };

  const getReportStatusText = report => {
    const { last_report_date } = report;
    if (!last_report_date) {
      return 'Sin reportes generados';
    }
    return `Último reporte generado: ${last_report_date}`;
  };

  const handleOpenErrorDialog = (report) => {
    setReportWithError(report)
    if (report.report_status === REPORT_ERROR) {
      setErrorDialogOpen(true);
    }
  };

  const returnErrorModalLabel = (key, value) => {
    return (
      <Box>
        <Box className={classes.detailsListItem}
        >
          <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
          </Box>
          <Box display='flex' >
            <Box className={classes.detailsListItemTitle} >
              {key}
            </Box>
          </Box>
        </Box>
        <Box style={{ borderBottom: `solid 1px ${'#a19292de'}`, paddingBottom: '3px', padding:'5px' }} className={classes.detailsListItemContent}  >
          {value}
        </Box>
      </Box>
    )
  }


  return (
    <>
      <TopNav titulo="Alertas y Reportes">
        <Grid container justifyContent="flex-end" alignItems="center">
          {/* Nombre Localización */}
          <Button
            className={classes.btn}
            type="button"
            onClick={handleNewReport}
            size="small"
            startIcon={<AddIcon />}
          >
            Nuevo reporte
          </Button>
        </Grid>
      </TopNav>

      <Container>
        {
          notification.isOpen &&
          <Alert
            severity={notification.severity}
            action={
              <IconButton
                aria-label='close'
                size='small'
                onClick={() => {
                  setNotification({ ...notification, isOpen: false })
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {notification.message}
          </Alert>
        }
        {
          !notification.isOpen && <Alert severity="info">{WELCOME_MESSAGE}</Alert>
        }

        {!loading && reports?.results?.length === 0 && (
          <Alert severity="warning">{NO_REPORTS_MESSAGE}</Alert>
        )}

        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!loading && reports?.results?.length > 0 && (
          <Box m={1}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Tabla de reportes" className={classes.table}>
                <TableHead>
                  <TableRow className={classes.header}>
                    <TableCell align="center" className={classes.headerCell}>
                      Envío
                      Automático
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Reporte
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Último Reporte
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Estado
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Generar ahora
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Descargar
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Prueba
                      de envío
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Editar
                    </TableCell>
                    <TableCell align="center" className={classes.headerCell}>
                      Eliminar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports?.results?.map(report => (
                    <React.Fragment key={report.id}>
                      <TableRow hover>
                        {/* Envío automático */}
                        <TableCell align="center" component="th" scope="row" >
                          <Tooltip
                            title={getAutomaticReportStatusText(report)}
                            arrow
                            placement="bottom"
                          >
                            <IconButton onClick={() => handleEdit(report)}>
                              <Box
                                maxHeight={24}
                                color={getAutomaticReportStatusColor(report)}
                              >
                                {getAutomaticReportStatusIcon(report)}
                              </Box>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {/* Nombre del reporte */}
                        <TableCell align="center">
                          <Tooltip
                            title="Nombre del reporte"
                            arrow
                            placement="bottom"
                          >
                            <Button size="small">
                              {truncateWithEllipsis(report.report_name, 25)}
                            </Button>
                          </Tooltip>
                        </TableCell>

                        {/* Fecha último reporte */}
                        <TableCell align="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Tooltip
                              title={getReportStatusText(report)}
                              arrow
                              placement="bottom"
                            >
                              <span>{report.last_report_date ?? '-'}</span>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        {/* Estado */}
                        <TableCell align="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Tooltip
                              title={
                                REPORT_STATUS_MESSAGES[report.report_status]
                                  ?.message
                              }
                              arrow
                              placement="bottom"
                              onClick={() => handleOpenErrorDialog(report)}
                            >
                              <IconButton>
                                <Box                      
                                  maxHeight={24}
                                  color={
                                    REPORT_STATUS_MESSAGES[report.report_status]
                                      ?.color
                                  }
                                  className={report.report_status === 'IN_PROCESS' ? classes.spinning : ''}
                                >
                                  {
                                    REPORT_STATUS_MESSAGES[report.report_status]
                                      ?.icon
                                  }
                                </Box>
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        {/* Generar ahora */}
                        <TableCell align="center">
                          <Tooltip
                            title="Presione este botón para generar reporte"
                            arrow
                            placement="bottom"
                          >
                            <span>
                              <Button
                                variant="outlined"
                                onClick={() => handleGenerate(report)}
                                disabled={report.report_status === 'IN_PROCESS'}
                                size="small"
                              >
                                Generar
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                        {/* Descagar */}
                        <TableCell align="center">
                          <Tooltip
                            title="Descargar reporte"
                            arrow
                            placement="bottom"
                          >
                            <span>
                              <IconButton
                                onClick={() => handleDownload(report)}
                                disabled={report.report_status !== 'OK'}
                              >
                                <GetAppIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                        {/* Prueba de envío */}
                        <TableCell align="center">
                          <Tooltip
                            title="Prueba de envío"
                            arrow
                            placement="bottom"
                          >
                            <span>
                              <IconButton
                                onClick={() => handleTest(report)}
                                disabled={
                                  !report.report_automatic ||
                                  !report.reportAutomatic ||
                                  report.report_status === 'IN_PROCESS'
                                }
                              >
                                <MailIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                        {/* Editar */}
                        <TableCell align="center">
                          <Tooltip
                            title="Editar reporte"
                            arrow
                            placement="bottom"
                          >
                            <IconButton onClick={() => handleEdit(report)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {/* Eliminar */}
                        <TableCell align="center">
                          <Tooltip
                            title="Eliminar reporte"
                            arrow
                            placement="bottom"
                          >
                            <IconButton onClick={() => handlePreDelete(report)}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={reports?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              nextIconButtonText="Página siguiente"
              backIconButtonText="Página anterior"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Box>
        )}
      </Container>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
        disableScrollLock
      >
        <DialogTitle id="form-dialog-title">
          {!filterOptions.id ? 'Nuevo Reporte' : filterOptions?.report_name}
        </DialogTitle>
        <DialogContent>
          <Filter
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            togglePanel={togglePanel}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        handleConfirm={handleDelete}
        handleCancel={handleDeleteCancel}
        title={`¿Desea eliminar el reporte "${selectedItem?.report_name}"?`}
        content="En caso de confirmar el reporte será eliminado"
      />

      {/*Dialog para mostrar último error de reporte*/}
      <Dialog
        open={errorDialogOpen}
        className={classes.dialog}
        onClose={() => setErrorDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box style={{ borderBottom: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }}
          display={'flex'}
          justifyContent={'flex-start'}
          pt={1}
          pb={1}
        >
          <IconButton
            className={classes.iconCLoseButton}
            onClick={() => setErrorDialogOpen(false)}
          >
            <CancelIcon />
          </IconButton>
        </Box>

        <Box className={classes.title} style={{ borderBottom: `solid 1px ${'#47a6db'}`, width: '100%', textAlign: 'center' }}>
          {reportWithError?.error_automatic ? 'Error generado al ejecutar proceso automático' : 'Error al generar el reporte'}
        </Box>


        <Box style={{paddingBottom:25}}>
          {returnErrorModalLabel('Nombre del reporte:', reportWithError?.report_name)}

          {returnErrorModalLabel('Fecha de creación:', reportWithError?.history_errors[0]?.created_format)}

          {returnErrorModalLabel('Observaciones:', reportWithError?.history_errors[0]?.observation)}
        </Box>

        <Box display={'flex'} justifyContent={'center'} marginBottom={2}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setErrorDialogOpen(false)}
          >
            Aceptar
          </Button>
        </Box>    
      </Dialog>
    </>
  );
}
