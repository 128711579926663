import { useEffect, useState } from 'react';
import {
    Box, makeStyles,
} from '@material-ui/core';
//Helpers
import { OPTIONS_DEVICES } from './helpers'
import { blueBar } from '../../helpers/common'
//Componentes
import ButtonComponent from "./ButtonComponent";
// //servicios 
import { putControlSetting } from '../../services/meters'
const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 1, 0),
        border: "0.5px solid #B6B1B1",
        borderRadius: "4px",
        padding: theme.spacing(1),
        width: "100%",

    },
    title: {
        fontSize: theme.spacing(1.9),
        fontWeight: '600',
        color: theme.palette.primary.main
    },
    subTitle: {
        color: theme.palette.grey.hairline,
        fontWeight: '400'
    }

}));


export default function PhaseControl({
    setLoading, setAlertState, options, dataPhases
}) {
    const classes = useStyles();
    const blueLine = blueBar()
    const [dataMap, setDataMap] = useState([])

    useEffect(() => {
        if (options.plan === OPTIONS_DEVICES.SMART) {
            setDataMap(["R", "S", "T"])
        }
        if (options.plan === OPTIONS_DEVICES.HOME) {
            setDataMap(["R", "S"])
        }
        // eslint-disable-next-line
    }, [])

    //Envia la data seteada para el timer rele.
    const HandleSubmit = async (element) => {
        setLoading(true)
        let type = "success"
        let msg = "Ocurrio un error inesperado"
        try {
            let filterPhase = dataPhases.find(e => e.envio_id === element)
            let auxBody = {
                fase_id: filterPhase?.id,
                key: "REVERSE_PHASE"
            }
            await putControlSetting(options?.id, auxBody)
            msg = "exitoso"
        }
        catch (error) {
            type = "error"
            if (error?.response?.data) {
                msg = error?.response?.data
            }
        }
        setAlertState({
            close: true,
            msg: msg,
            type: type
        })
        setLoading(false)
    }

    return (
        <Box style={options.plan === OPTIONS_DEVICES.HOME ? { height: "301px", display: "inline-grid" } : {}} className={classes.root}>

            {/* titulo */}
            <Box ml={1} mt={2} mb={2} className={classes.title} >
                {"Control"}
                <span className={classes.subTitle} >
                    {` | ${"de fases"}`}
                </span>
            </Box>

            {blueLine}

            {/* Botones invertir fase */}
            <Box display='flex' justifyContent='center' flexDirection={"column"} alignItems={"center"}>
                {
                    dataMap.map((element, indx) => {
                        return (
                            < ButtonComponent
                                key={indx}
                                children={
                                    <>
                                        <Box  > {`invertir`}</Box>
                                        <Box ml={1} style={{ fontWeight: "bold" }}> {`Fase ${element}`}</Box>
                                    </>
                                }
                                HandleSubmit={() => { HandleSubmit(element) }}
                                backgroundColorButton={"primary"}
                            />

                        )
                    })
                }
            </Box>
        </Box>
    )
}






