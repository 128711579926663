import DateFnsUtils from '@date-io/date-fns';
//Material UI
import {
	Grid, DialogTitle, DialogContent, Box, makeStyles,
	Button, Chip, CircularProgress, Dialog
} from '@material-ui/core';
// Material Icons
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import LocationOnIcon from '@mui/icons-material/LocationOn';
//Notifications
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
// Endpoints
import {
	deleteBillById, getBillByIdLoc, postBill,
	getCostPowEnergy, putBillById, datePickerInfoDisabled
} from '../../services/billing';
//Componentes
import AlertComponent from '../Elements/AlertComponent';
import TopNav from '../common/TopNav';
import CostCompareTable from './CostCompareTable';
import CostPieChart from './CostPieChart';
import DeleteBill from './DeleteBill';
import EnergyTable from './EnergyTable';
import Filter from './Filter';
import LoadingComponentBlur from '../common/LoadingComponentBlur';
// import PowerBarChart from './PowerBarChart';
import clsx from 'clsx';
import Container from '../Elements/Container'
//Services
import { formatDateStrDashToSlashReverse } from '../../helpers/common';
//params router 
import { useParams } from 'react-router';
import EnergyChordChart from './EnergyChordChart';

const useStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
	},
	dateContainer: {
		padding: 0,
		color: theme.palette.common.white,
		fontSize: 12,
		'& .MuiButtonBase-root': {
			color: theme.palette.common.white,
		},
		'& .MuiInputBase-root': {
			color: theme.palette.common.white,
		},
		'& .MuiInputBase-input': {
			padding: 0,
			fontSize: 14,
		},
		'& .MuiFormLabel-root': {
			color: theme.palette.common.white,
		},
		'& .MuiFormControl-marginNormal': {
			margin: 0,
		},
	},
	date: {
		'& .MuiInputBase-root': {
			width: "150px",
		},
	},
	nextDayBtn: {
		'&.Mui-disabled': {
			opacity: '0.5',
		},
	},
	btn: {
		margin: theme.spacing(2, 1),
		'& .MuiButton-label': {
			textTransform: 'none',
			fontWeight: 700,
			color: theme.palette.primary.main,
		},
		'&.MuiButton-root': {
			backgroundColor: theme.palette.common.white,
		},
	},
	btnSaveEditCancel: {
		margin: theme.spacing(2, 1),
		'& .MuiButton-label': {
			textTransform: 'none',
			fontWeight: 700,
		},
		'& .MuiButton-root': {
			color: '#666666',
		},
	},
	containerMain: {
		paddingBottom: "140px",
		paddingTop: "30px"
	},
	marginAuto: {
		margin: "auto"
	},
	chip: {
		marginRight: theme.spacing(1),
	},
	tuneIcon: {
		'& .MuiSvgIcon-root': {
			fontSize: '1.5em',
			paddingTop: theme.spacing(0.5),
		},
	},
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '90px',
	},
	container: {
		marginLeft: 73,
		width: 'calc(100vw - 73px)',
	},
	rightPanel: {
		background: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	itemTitle: {
		padding: theme.spacing(1),
		textAlign: 'center',
		background: theme.palette.primary.main,
		color: 'white',
		fontWeight: 700,
	},
	itemValue: {
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.primary,
	},
	subContainerMain: {
		width: "97%",
		backgroundColor: "#f7f7f7b8",
	},
	blueFooterMain: {
		height: "5px",
		backgroundColor: theme.palette.primary.main,
	},
	blueDividerBar: {
		height: "20px",
		backgroundColor: theme.palette.primary.main,
	},
	blueHeaderMain: {
		height: "60px",
		backgroundColor: theme.palette.primary.main,
		zIndex: "2"
	},
	tituloHeaderBox: {
		paddingTop: "20px",
		paddingLeft: "30px",
		color: theme.palette.common.white,
		cursor: "default"
	},
	containerTop: {
		display: "flex",
		"@media (max-width: 1640px)": {
			flexWrap: "wrap",
			width: "100%",
		}
	},
	containerTopOut: {
		backgroundColor: "#f7f7f7b8",
		border: "solid 1px #d9dcde",
		width: "97%",
		margin: "auto",
	},
	tableFacturaReal: {
		width: "65%",
		"@media (max-width: 1640px)": {
			width: "97%",
		}
	},
	GraphFacturaReal: {
		width: "35%",
		backgroundColor: "#f7f7f7b8",
		"@media (max-width: 1640px)": {
			width: "97%",
		}
	}
}));
export default function Bill({ showFilter }) {
	const classes = useStyles();
	const { id } = useParams();
	// Variables de estado
	const [filterPanel, setFilterPanel] = useState(showFilter);
	const [filterOptions, setFilterOptions] = useState({
		bill: {},
		selectedDate: new Date(),
	});
	//COST DATA
	const [costTableData, setCostTableData] = useState([]);
	const [costPieChartData, setCostPieChartData] = useState([]);
	//Energy Table
	const [energyTableData, setEnergyTableData] = useState({});
	//EnergyChordData
	const [energyChordData, setEnergyChordData] = useState([]);
	//POWER DATA
	const [powerBarRates, setPowerBarRates] = useState([]);

	//Bill Data
	const initialBillData = {
		name: '',
		date_from: format(new Date(), 'yyyy-MM-dd'),
		date_to: format(new Date(), 'yyyy-MM-dd'),
		factura: {
			energy_use: {
				total: 0,
				slots: [
					{
						name: 'hora valle',
						value: 0,
					},
				],
			},
			energy_cost: {
				total: 0,
				slots: [
					{
						name: 'hora valle',
						value: 0,
					},
				],
			},
			hired_demand_cost: {
				total: 0,
				slots: [
					{
						name: 'hora pico',
						value: 0,
					},
					{
						name: 'hora regular',
						value: 0,
					},
				],
			},
			hired_demand: {
				slots: [
					{
						name: 'hora pico',
						value: 0,
					},
					{
						name: 'hora regular',
						value: 0,
					},
				],
			},
			peak_demand: {
				slots: [
					{
						name: 'hora pico',
						value: 0,
					},
					{
						name: 'hora regular',
						value: 0,
					},
				],
			},
			peak_demand_cost: {
				total: 0,
				slots: [
					{
						name: 'hora pico',
						value: 0,
					},
					{
						name: 'hora regular',
						value: 0,
					},
				],
			},
			fixed_charge: 0,
			total: 0,
		},
		factura_papel: {
			energy_use: {
				total: 0,
				slots: [
					{
						name: 'Pico',
						value: 0,
					},
					{
						name: 'Resto',
						value: 0,
					},
					{
						name: 'Valle',
						value: 0,
					},
				],
			},
			energy_cost: {
				total: 0,
				slots: [
					{
						name: 'Pico',
						value: 0,
					},
					{
						name: 'Resto',
						value: 0,
					},
					{
						name: 'Valle',
						value: 0,
					},
				],
			},
			hired_demand_cost: {
				total: 0,
				slots: [
					{
						name: 'Unica',
						value: 0,
					},

				],
			},
			hired_demand: {
				total: 0,
				slots: [
					{
						name: 'Unica',
						value: 0,
					},

				],
			},
			peak_demand: {
				total: 0,
				slots: [
					{
						name: 'Unica',
						value: 0,
					},

				],
			},
			peak_demand_cost: {
				total: 0,
				slots: [
					{
						name: 'Unica',
						value: 0,
					},

				],
			},
			fixed_charge: 0,
			total: 0,
		},
		location: 7,
	};
	const [billData, setBillData] = useState(initialBillData);
	const [initialBill, setInitialBill] = useState();
	//Variable que indica que tengo que cargar una factura nueva
	const [newBill, setNewBill] = useState(false);
	//Variable que indica el submit del Filter
	const [submit, setSubmit] = useState(false);
	//Variable que indica que tengo que abrir el panel de DeleteBill
	const [deletePanel, setDeletePanel] = useState(false);
	//Variable que indica si tengo que graficar o no
	const [drawGraphics, setDrawGraphics] = useState(false);
	//En locations cargo los nodes que tengan level_nombre='localizacion'
	const [locations, setLocations] = useState([]);
	//En locationname cargo la localizacion actual seleccionada
	const [location, setLocation] = useState({});
	//En bills tengo los id y name de todas las facturas
	const [bills, setBills] = useState([]);
	const [loadingCircular, setLoadingCircular] = useState(false);
	const [loadingPeriod, setLoadingPeriod] = useState(false)
	const [datePickerDisabled, setDatePickerDisabled] = useState({
		no_disponibles: []
	});
	//Estados q manejan el inicia y final de el datePicker
	const [endDate, setEndDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date());
	//Estado q maneja el disabled del input de las fechas
	const [displayDisabled, setDisplayDisabled] = useState(true)
	//Estados alert
	const [close, setClose] = useState(false)
	const [msg, setMsg] = useState({
		msg: "",
		type: ""
	})
	//Alerta de informacion instrucciones
	const [showAlerInfo, setShowAlertInfo] = useState(true)
	const [openModalTree, setOpenModalTree] = useState(false)
	//Loading
	const [loadingBlurState, setLoadingBlurState] = useState(false)


	useEffect(() => {
		if (id) {
			setLoadingCircular(true)
			let ids = id.split('-')
			setSubmit(true);
			let billData = {
				id: ids[0]
			}
			handleChangeLocalization(ids[1])
			handleChangePeriod("", ids[0])
			searchGraphics(billData)
		}
		// eslint-disable-next-line
	}, [])

	const makePieChartData = costTable => {
		//Cost Pie Chart Data
		const pieChartData = [
			{
				parameter: 'Costo Fijo',
				cost: costTable[0].data[1],
				color: "#6abad9d4"

			},
			{
				parameter: 'Energia activa',
				cost: costTable[1].data[1],
				color: "#3999bdd4"
			},
			{
				parameter: 'Potencia Contratada',
				cost: costTable[2].data[1],
				color: "#6874d7c9"
			},
			{
				parameter: 'Potencia Adquirida',
				cost: costTable[3].data[1],
				color: "#8437f1d6"
			},
		];

		setCostPieChartData(pieChartData);
	};

	const makePowerBarChart = powerTable => {
		//Power Bar Chart Data
		let powerBarData = [{ type: 'Factura' }, { type: 'Localizacion' }];
		let powerRates = [];
		powerTable.paper?.slots?.power?.forEach(slot => {
			let realSlot = {
				[slot.name]: slot.value,
			};
			Object.assign(powerBarData[0], realSlot);
			//Los distintos rates de power los saco de "paper", podria hacerlo tambien de real o tags
			powerRates.push(slot.name);
		});

		powerTable.real?.slots?.power?.forEach(slot => {
			let realSlot = {
				[slot.name]: slot.value,
			};
			Object.assign(powerBarData[1], realSlot);
		})
		//!!!!!ERROR SIN SOLUCIONAR!!!!!!
		// powerTable?.tags?.forEach((tag, idx) => {
		// 	powerBarData.push({ type: tag.name });

		// 	tag?.slots?.power?.forEach(slot => {
		// 		let realSlot = {
		// 			[slot.name]: slot.value,
		// 		};
		// 		Object.assign(powerBarData[idx + 2], realSlot);
		// 	});
		// });
		// setPowerBarData(powerBarData.reverse());
		setPowerBarRates(powerRates);
	};

	const togglePanel = () => {
		setFilterPanel(!filterPanel);
		setBillData(initialBillData)
		setDrawGraphics(false);
		setShowAlertInfo(true)
		setBills([])
		setLocation({})
	};

	//Cancelar Filter
	const handleCancelFilterDialog = () => {
		setFilterPanel(false);
		setEndDate(new Date())
		setStartDate(new Date())
		setBillData(initialBillData)
		setBills([])
		setLocation({})
		setNewBill(false)
	};

	const disabledDateFunction = async (elem) => {
		//ACA ES LA REUEST PARA TRAER DISABLED SI TIENE CONTRATOS SI NO ROMPE.
		const datePickerDisabledData = await datePickerInfoDisabled(elem);
		setDatePickerDisabled(datePickerDisabledData.data)
		setDisplayDisabled(false)
	}

	//Selecciono una localizacion
	const handleChangeLocalization = async (elem) => {
		setDisplayDisabled(true)
		setLoadingPeriod(true)
		setOpenModalTree(false)
		setEndDate(new Date())
		setStartDate(new Date())

		try {
			//limpio todas las bills que haya
			setBills([]);
			//Obtengo todas las facturas de esa localizacion
			let loc = locations.filter(loc => loc.hierarchy === elem);
			//Esto es si el componente es dinamico y viene una request por params de conciliacion
			if (id) {
				let ids = id.split('-')
				let loc = {
					name: ids[2]
				}
				setLocation(loc);
			}
			else {
				setLocation(loc[0]);
			}

			const res = await getBillByIdLoc(elem);
			disabledDateFunction(elem)

			let billsAux = [...res.data]
			//ordeno de mayor a menor las facturas. 
			billsAux.sort(function (a, b) {
				return new Date(b.date_from) - new Date(a.date_from);
			});
			if (res.data.length > 0) {
				// console.log('HAY FACTURAS');
				setNewBill(false);
				setBills(billsAux)
			}
			else {
				// console.log('NO HAY FACTURAS');
				setNewBill(true);
			}
			//Tengo que agregar en la nueva factura, la localizacion
			setBillData({ ...billData, location: loc[0].hierarchy, name: '' });
			setLoadingPeriod(false)
		}
		catch (error) {
		}
	};

	//Dentro de todas las facturas, selecciono un periodo
	const handleChangePeriod = async (e, automatic) => {
		setLoadingBlurState(true)
		// setDisplayDisabled(true)
		let billId = ""
		if (automatic) {
			billId = automatic;
		}
		else {
			billId = e.target.value;
		}
		const getBill = await getCostPowEnergy(billId);
		let selectedBill = getBill.data
		setFilterOptions({ ...filterOptions, bill: selectedBill });
		setBillData(selectedBill);
		let from = formatDateStrDashToSlashReverse(selectedBill.date_from)
		let to = formatDateStrDashToSlashReverse(selectedBill.date_to)
		setStartDate(new Date(from))
		setEndDate(new Date(to))
		const copiaProfunda = JSON.parse(JSON.stringify(selectedBill));
		setInitialBill(copiaProfunda);
		setLoadingBlurState(false)
		setDisplayDisabled(true)
	};

	const searchGraphics = async bill => {
		try {
			const CostPowEnergy = await getCostPowEnergy(bill.id);
			setCostTableData(CostPowEnergy?.data.costs);
			makePieChartData(CostPowEnergy?.data.costs);
			setEnergyTableData(CostPowEnergy?.data.enery_tags);
			// //Con la data de ENERGY genero el CHORD CHART
			setEnergyChordData(CostPowEnergy?.data.enery_tags.chord);
			// setPowerTableData(CostPowEnergy?.data.power_tags);
			// //Con la data de POWER genero el POWER BAR CHART
			makePowerBarChart(CostPowEnergy?.data.power_tags);
			setDrawGraphics(true);
			setSubmit(false);
		}
		catch (error) {
			setLoadingCircular(false)
			//alert
			setClose(true)
			if (error?.response?.data?.msg) {
				setMsg({
					msg: error?.response?.data?.msg,
					type: "error"
				})
			}
			else {
				setMsg({
					msg: "Ocurrio un error inesperado",
					type: "error"
				})
			}
			setDrawGraphics(false);
			setShowAlertInfo(true)
		}
	};

	//Funcion Submit del FILTER
	const handleSubmitFilter = (e) => {
		e.preventDefault();
		setLoadingCircular(true)
		setSubmit(true);
		const fetchGraphicsData = async () => {
			setShowAlertInfo(false)
			try {
				if (bills && !newBill) {
					// console.log('Factura existente')
					searchGraphics(billData);
					//Guardo la factura inicial por si el usuario cambia algo pero despues coloca CANCELAR		
					const copiaProfunda = JSON.parse(JSON.stringify(billData));
					setInitialBill(copiaProfunda);
				}
				else if (newBill) {
					// console.log('Factura nueva')
					//Si es una nueva factura, hago el POST y guardo el idFactura
					let saveBill = billData;
					if (newBill) {
						//Debo hacer POST
						let newB = await postBill({
							name: saveBill.name,
							date_from: saveBill.date_from,
							date_to: saveBill.date_to,
							location: saveBill.location,
						});
						//Cambio las fechas por que sino en el dateContainer me resta un dia
						setBillData({ ...newB.data, date_from: newB.data.date_from, date_to: newB.data.date_to });
						searchGraphics(newB.data);
						setClose(true)
						//Guardo la factura inicial por si el usuario cambia algo pero despues coloca CANCELAR		
						const copiaProfunda = JSON.parse(JSON.stringify(newB.data));
						setInitialBill(copiaProfunda);
						setMsg({
							msg: "Se creó correctamente la factura",
							type: "success"
						})
						setNewBill(false)
						setSubmit(true);
					}
				}
			}
			catch (error) {
				setClose(true)
				setMsg({
					msg: error?.response?.data?.msg,
					type: "error"
				})
				setDrawGraphics(false);
				setShowAlertInfo(true)
				// setFilterPanel(true)
				setSubmit(false);
				setLoadingCircular(false)
			}
		};
		fetchGraphicsData();
		//Cierro el panel de Filtros
		setFilterPanel(false);
		// Borro los datos solo en caso de que haya habido cambios, así muestro el <CircularProgress/>
		// setLocations([]);
		// setTimeout(() => {
		// 	setBills([]);
		// }, [300])
	};

	//MANEJO DE DATES VALIDOS PARA BILL y FILTER
	const handleDateFromChange = e => {
		setBillData(billData => ({ ...billData, date_from: format(new Date(e), 'yyyy-MM-dd') }));
	};

	const handleDateToChange = e => {
		setBillData(billData => ({ ...billData, date_to: format(new Date(e), 'yyyy-MM-dd') }));
	};

	//Cambio en el form del 'fixed_charge' o del 'name' de la factura
	const handleBillData = e => {
		let name = e.target.name;
		// let value = e.target.value;
		let value = e.target.value.replace(/,/g, '')
		if (e.target.value === "") {
			value = 0
		}
		//Calculo el total de la factura_papel
		let totalBill = parseFloat(billData.factura_papel.energy_cost.total)
			+ parseFloat(billData.factura_papel.hired_demand_cost.total)
			+ parseFloat(billData.factura_papel.peak_demand_cost.total) + parseFloat(value)

		if (name === 'fixed_charge') {
			setBillData(billData => ({ ...billData, factura_papel: { ...billData.factura_papel, total: totalBill, sub_total: totalBill, fixed_charge: value } }));
		}
		else if (name === 'name') {
			setBillData(billData => ({ ...billData, name: value }));
		}
	};

	//Cambio en el form de la data (energy o power)
	const handlePowerEnergy = (e, slot, idx) => {
		let name = e.target.name;
		let value = e.target.value.replace(/,/g, '')
		if (e.target.value === "") {
			value = 0
		}
		if (name === 'hired_demand') {
			let slots_arr = billData.factura_papel.hired_demand.slots;
			//Reemplazo el slot original por el nuevo objeto
			slots_arr[idx] = { name: slot.name, value: value };
			//Actualizo el total
			let totalHired = 0;
			slots_arr.forEach(element => {
				let valor = element.value ? parseFloat(element.value) : 0
				totalHired += valor;
			});
			setBillData(billData => ({
				...billData,
				factura_papel: { ...billData.factura_papel, hired_demand: { ...billData.factura_papel.hired_demand, slots: slots_arr, total: totalHired } },
			}));
		}
		else if (name === 'hired_demand_cost') {
			let slots_arr = billData.factura_papel.hired_demand_cost.slots;
			//Reemplazo el slot original por el nuevo objeto
			slots_arr[idx] = { name: slot.name, value: value };
			//Actualizo el total
			let totalHiredCost = 0;
			slots_arr.forEach(element => {
				let valor = parseFloat(element.value)
				totalHiredCost += valor;
			});
			//Calculo el total de la factura_papel factura real.
			let totalBill = parseFloat(billData.factura_papel.energy_cost.total)
				+ parseFloat(billData.factura_papel.fixed_charge)
				+ parseFloat(billData.factura_papel.peak_demand_cost.total)
				+ parseFloat(totalHiredCost);
			setBillData(billData => ({
				...billData,
				factura_papel: {
					...billData.factura_papel,
					sub_total: totalBill,
					total: totalBill,
					hired_demand_cost: {
						...billData.factura_papel.hired_demand_cost,
						slots: slots_arr,
						total: totalHiredCost
					}
				},
			}));
		}
		else if (name === 'peak_demand') {
			let slots_arr = billData.factura_papel.peak_demand.slots;
			//Reemplazo el slot original por el nuevo objeto
			slots_arr[idx] = { name: slot.name, value: value };
			//Actualizo el total
			let totalDemand = 0;
			slots_arr.forEach(element => {
				let valor = element.value ? parseFloat(element.value) : 0
				totalDemand += valor;
			});
			setBillData(billData => ({
				...billData,
				factura_papel: {
					...billData.factura_papel,
					peak_demand: { ...billData.factura_papel.peak_demand, slots: slots_arr, total: totalDemand }
				},
			}));
		}
		else if (name === 'peak_demand_cost') {
			let slots_arr = billData.factura_papel.peak_demand_cost.slots;
			//Reemplazo el slot original por el nuevo objeto
			slots_arr[idx] = { name: slot.name, value: value };
			let totalDemandCost = 0;
			slots_arr.forEach(element => {
				//Actualizo el total
				let valor = element.value ? parseFloat(element.value) : 0
				totalDemandCost += valor;
			});
			//Calculo el total de la factura_papel
			let totalBill = parseFloat(billData.factura_papel.energy_cost.total)
				+ parseFloat(billData.factura_papel.hired_demand_cost.total)
				+ parseFloat(billData.factura_papel.fixed_charge)
				+ parseFloat(totalDemandCost);

			setBillData(billData => ({
				...billData,
				factura_papel: {
					...billData.factura_papel,
					sub_total: totalBill,
					total: totalBill,
					peak_demand_cost: { ...billData.factura_papel.peak_demand_cost, slots: slots_arr, total: totalDemandCost }
				},
			}));
		}
		else if (name === 'energy_use') {
			let slots_arr = billData.factura_papel.energy_use.slots;
			//Reemplazo el slot original por el nuevo objeto
			slots_arr[idx] = { name: slot.name, value: value };
			//Actualizo el total
			let totalEnergy = 0;
			slots_arr.forEach(element => {
				let valor = element.value ? parseFloat(element.value) : 0
				totalEnergy += valor;
			});
			setBillData(billData => ({
				...billData,
				factura_papel: {
					...billData.factura_papel,
					energy_use: { ...billData.factura_papel.energy_use, slots: slots_arr, total: totalEnergy }
				},
			}));
		}
		else if (name === 'energy_cost') {
			let slots_arr = billData.factura_papel.energy_cost.slots;
			//Reemplazo el slot original por el nuevo objeto
			slots_arr[idx] = { name: slot.name, value: value };
			//este es el total de los tres valores de energia
			let totalEnergy = 0;
			slots_arr.forEach(element => {
				let valor = element.value ? parseFloat(element.value) : 0
				totalEnergy += valor;
			});
			//Calculo el total de la factura_papel
			let totalBill = parseFloat(billData.factura_papel.fixed_charge)
				+ parseFloat(billData.factura_papel.hired_demand_cost.total)
				+ parseFloat(billData.factura_papel.peak_demand_cost.total)
				+ parseFloat(totalEnergy);
			//Actualizo el total
			setBillData(billData => ({
				...billData,
				factura_papel: {
					...billData.factura_papel, sub_total: totalBill,
					total: totalBill,
					energy_cost: { ...billData.factura_papel.energy_cost, slots: slots_arr, total: totalEnergy }
				},
			}));
		}
	};

	//Guardo la factura
	const handleSaveBill = async e => {
		setLoadingBlurState(true)
		e.preventDefault()
		let saveBill = billData;
		try {
			//Coloco la variable "user_input_data" = true para indicar que el usuario realizo cambios
			saveBill.factura_papel.user_input_data = true;
			//Siempre hago PUT por que por mas que sea nueva el POST se hizo en el FILTER.
			const res = await putBillById(billData.id, saveBill);
			setClose(true)
			setMsg({
				msg: "Se modificó correctamente la factura",
				type: "success"
			})
			setBills([]);
			setLocations([]);
			setBillData(res.data)
			const copiaProfunda = JSON.parse(JSON.stringify(res.data));
			setInitialBill(copiaProfunda);
			// setInitialBill(res.data)
			setCostTableData(res.data.costs);
		}
		catch (error) {
			// setLoadingCircular(false)
			setClose(true)
			setMsg({
				msg: error?.response?.data?.msg,
				type: "error"
			})
		}
		setLoadingBlurState(false)
	};

	const handleCancelBill = async () => {
		setLoadingBlurState(true)
		const CostPowEnergy = await getCostPowEnergy(initialBill.id)
		setBillData(CostPowEnergy.data)
		setLoadingBlurState(false)
	};

	const handleDeletePanel = () => {
		setDeletePanel(true);
	};

	const handleDeleteBill = async e => {
		e.preventDefault();
		try {
			setLoadingBlurState(true)
			if (!newBill) {
				//Si es una factura que ya estaba guardada, hago el DELETE
				await deleteBillById(billData.id);
				setClose(true)
				setMsg({
					msg: "Se borró correctamente la factura",
					type: "error"
				})
				setDrawGraphics(false);
				setShowAlertInfo(true)
				setFilterPanel(true)
				setBillData(initialBill);
				setBills([])
				setLocation([])
			}
			setSubmit(false);
			//Borro todo
			setBills([]);
			setBillData(initialBillData);
			setLocations([]);
			setEndDate(new Date())
			setStartDate(new Date())
		}
		catch (error) {
			setClose(true)
			setMsg({
				msg: error?.response?.data?.msg,
				type: "error"
			})
		}
		setDeletePanel(false);
		setLoadingBlurState(false)
	};

	const handleDeletePaperBill = async e => {
		e.preventDefault();
		let saveBill = initialBill
		try {
			saveBill.factura_papel = initialBillData.factura_papel
			await putBillById(billData.id, saveBill)

			setClose(true)
			setMsg({
				msg: "Se elimino Factura Real",
				type: "error"
			})
			setBillData(saveBill);
		}
		catch (error) {
			setClose(true)
			setMsg({
				msg: error?.response?.data?.msg,
				type: "error"
			})
		}
		setDeletePanel(false);
	};

	return (
		<>
			<TopNav titulo='Facturación' subtitulo='| Factura'>
				<Grid container justifyContent='flex-end' alignItems='center'>
					<Box display='flex' alignItems='center'>
						<Box mr={5} display='flex' alignItems='center'>
							{location?.name?.length > 1 &&
								<Chip
									className={classes.chip}

									icon={<LocationOnIcon />}
									label={location.name}
								// clickable
								// onClick={() => setFilterPanel(true)}
								/>}

							{billData?.name?.length > 1 &&
								<Chip
									className={classes.chip}
									label={billData.name}
								// clickable
								// onClick={() => setFilterPanel(true)}
								/>}
						</Box>

						<Box className={classes.dateContainer}>
							<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
								<KeyboardDatePicker
									className={classes.date}
									autoOk
									disableToolbar
									variant='inline'
									format='dd/MM/yyyy'
									margin='normal'
									readOnly={true}
									value={billData.date_from ? formatDateStrDashToSlashReverse(billData.date_from) : new Date()}
									onChange={e => handleDateFromChange(e)}
									KeyboardButtonProps={{
										'aria-label': 'cambiar fecha',
									}}
									InputProps={{
										disableUnderline: true,
										readOnly: true,
									}}
								/>
							</MuiPickersUtilsProvider>
						</Box>
						<Box className={classes.dateContainer}>
							<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
								<KeyboardDatePicker
									className={classes.date}
									autoOk
									disableToolbar
									variant='inline'
									format='dd/MM/yyyy'
									margin='normal'
									// id='date-picker-inline'
									readOnly={true}
									// label='Hasta: '
									value={
										billData.date_to ? formatDateStrDashToSlashReverse(billData.date_to) : new Date()
									}
									onChange={e => handleDateToChange(e)}
									KeyboardButtonProps={{
										'aria-label': 'cambiar fecha',
									}}
									InputProps={{
										disableUnderline: true,
										readOnly: true,
									}}
								// keyboardIcon={false}
								/>
							</MuiPickersUtilsProvider>
						</Box>
					</Box>

					{showFilter &&
						<Button
							className={classes.btn}
							type='button'
							onClick={togglePanel}
							size='small'
							startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
						>
							| Filtrar
						</Button>}
				</Grid>
			</TopNav>

			<LoadingComponentBlur
				loadingState={loadingBlurState}
			/>

			{/* ALERTAS */}
			<AlertComponent
				severity={msg.type}
				msg={msg.msg}
				setClose={setClose}
				close={close}
			/>

			<Container>

				{/* ALERT INFO */}
				{showFilter && showAlerInfo &&
					<Alert severity='info'>
						Bienvenido a Factura. En esta sección podrá consultar la información de facturación. Para comenzar haga click
						en el botón
						<span className={classes.tuneIcon}>
							<TuneIcon />
						</span>
						, ingrese los parámetros y haga click en CONSULTAR.
					</Alert>
				}

				{/* Filtro */}

				<Dialog
					open={filterPanel}
					onClose={handleCancelFilterDialog}
					aria-labelledby='form-dialog-title'
					maxWidth='lg'
					fullWidth
				>
					<DialogTitle id='form-dialog-title'>Opciones</DialogTitle>
					<DialogContent>
						<Filter
							onSubmitFilter={handleSubmitFilter}
							handleChangeLocalization={handleChangeLocalization}
							handleChangePeriod={handleChangePeriod}
							bills={bills}
							billData={billData}
							location={location}
							setLocations={setLocations}
							newBill={newBill}
							setNewBill={setNewBill}
							handleCancelFilterDialog={handleCancelFilterDialog}
							handleBillData={handleBillData}
							setBillData={setBillData}
							datePickerDisabled={datePickerDisabled}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							displayDisabled={displayDisabled}
							// setDisplayDisabled={setDisplayDisabled}
							openModalTree={openModalTree}
							setOpenModalTree={setOpenModalTree}
							loadingPeriod={loadingPeriod}
						/>
					</DialogContent>
				</Dialog>

				{/* Panel Delete */}
				<Dialog open={deletePanel} onClose={handleDeletePanel} aria-labelledby='form-dialog-title' fullWidth>
					<DialogTitle id='form-dialog-title'>Eliminar</DialogTitle>
					<DialogContent>
						<DeleteBill
							deletePanel={deletePanel}
							setDeletePanel={setDeletePanel}
							handleDeletePaperBill={handleDeletePaperBill}
							handleDeleteBill={handleDeleteBill}
						/>
					</DialogContent>
				</Dialog>

				{/* Container Principal Tablas */}
				{drawGraphics && bills && !submit && powerBarRates ? (
					<Box className={classes.containerMain}>

						<Box className={classes.containerTopOut}>
							<Box className={clsx(classes.blueFooterMain)}></Box>
							<Box className={classes.containerTop} >
								{/* TABLA FACTURA REAL VS FACTURA RECONSTRUIDA */}
								<Grid className={clsx(classes.subContainerMain, classes.marginAuto, classes.tableFacturaReal)}>
									<Box mb={"10px"}>
										<CostCompareTable
											tableData={costTableData}
											handleBillData={handleBillData}
											handlePowerEnergy={handlePowerEnergy}
											billData={billData}
											handleCancelBill={handleCancelBill}
											handleDeletePanel={handleDeletePanel}
											handleSaveBill={handleSaveBill}
										/>
									</Box>
								</Grid>
								{/* GRAPH FACTURA REAL VS FACTURA RECONSTRUIDA */}
								<Grid className={clsx(classes.marginAuto, classes.GraphFacturaReal, classes.subContainerMain)}>
									<CostPieChart chartData={costPieChartData} />
								</Grid>
							</Box>
							<Box className={clsx(classes.blueFooterMain)}></Box>
						</Box>



						{/* TABLA ENERGIA ACTIVA */}
						<Grid style={{ marginTop: "70px" }} className={clsx(classes.subContainerMain, classes.marginAuto)}>
							<Box className={clsx(classes.blueFooterMain)}></Box>
							<EnergyTable tableData={energyTableData} billData={billData} />
						</Grid>


						{/* GRAPH ENERGIA ACTIVA */}

						{energyChordData.length > 0 ? (
							<Grid className={clsx(classes.subContainerMain, classes.marginAuto)}>
								<EnergyChordChart chartData={energyChordData} />
								<Box className={classes.blueFooterMain}></Box>
							</Grid>
						) : (
							''
						)}

						{/* TABLA POTENCIA ADQUIRIDA */}

						<Grid style={{ marginTop: "70px" }} className={clsx(classes.subContainerMain, classes.marginAuto)}>
							{/* <Box className={clsx(classes.blueFooterMain)}></Box> */}
							{/* <PowerTable tableData={powerTableData} /> */}
						</Grid>
						{/* 
						<Grid className={clsx(classes.subContainerMain, classes.marginAuto)}>
							<PowerBarChart chartData={powerBarData} powerRates={powerBarRates} />
							<Box className={clsx(classes.blueFooterMain)}></Box>
						</Grid> */}

					</Box>
				)
					:
					submit && loadingCircular ? (
						<>
							<div className={classes.circular}>
								<CircularProgress />
							</div>
						</>
					) : (
						''
					)}

			</Container>

		</>
	);
}
