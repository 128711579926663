import {
    Box, IconButton, Paper,
    Switch, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Tooltip, makeStyles
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';


const useStyles = makeStyles(theme => ({
    table: {
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.25),
        },
    },
    header: {
        background: theme.palette.primary.main,
    },
    headerCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    noResultsBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px', // Ajusta esta altura según sea necesario
        textAlign: 'center',
    },
    estadoSyle: {
        fontSize: theme.spacing(1.8),
        cursor: 'pointer'
    },
    spinning: {
        animation: `$spin 2s infinite`,
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '50%': { transform: 'rotate(180deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
}));

const AlertPlcTable = ({ plcComponent, labelsTableHeader, dataList,
    setSelectedAlert, setIsDeleteDialogOpen, handleAlertState, handleAlertRecord, handleStateDiagramButton }) => {
    const classes = useStyles()

    //Devuelve el icon segun el estado.
    const returnIconState = (element) => {
        if (element === 'UNSPECIFIED') {
            return <ErrorOutlineIcon style={{ color: '#3ed73e' }} />
        }
        if (element === 'OK') {
            return <CheckCircleOutlineIcon style={{ color: '#3ed73e' }} />
        }
        if (element === 'PENDING_LOAD') {
            return <HourglassFullIcon className={classes.spinning} style={{ color: '#FFAA33' }} />
        }
        if (element === 'PROCESS_LOAD') {
            return <HourglassFullIcon className={classes.spinning} style={{ color: '#FFAA33' }} />
        }
        if (element === 'ERROR_COMUNICATION') {
            return <FilterListOffIcon style={{ color: '#f75200' }} />
        }
        if (element === 'ERROR_BLOCKS') {
            return <ErrorOutlineIcon style={{ color: '#f75200' }} />
        }
    }

    const handleSetAlertState = (alert, activateValue) => {
        if (!plcComponent) {
            alert.activate = activateValue;
            handleAlertState(alert.id, activateValue);
        }
    }

    



    const returnButtonState = (element) => {
        const stateButtons = {
            RUNNING: [
                { title: 'Parar', icon: <StopIcon />, newState: 'DETENIDO' },
                { title: 'Pausar', icon: <PauseIcon />, newState: 'PAUSADO' }
            ],
            STOP: [
                { title: 'Encender', icon: <PlayArrowIcon />, newState: 'INICIADO' }
            ],
            PAUSE: [
                { title: 'Encender', icon: <PlayArrowIcon />, newState: 'INICIADO' },
                { title: 'Parar', icon: <StopIcon />, newState: 'DETENIDO' }
            ]
        };
        const buttons = stateButtons[element?.estado] || [];
        return (
            <Box display='flex'>
                {buttons.map((btn, index) => (
                    <Box key={index}>
                        <Tooltip title={btn.title} arrow placement='bottom'>
                            <IconButton onClick={() => handleStateDiagramButton(element?.id, btn.newState)}>
                                {btn.icon}
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
            </Box>
        );
    }
    return (
        <Box m={1}>
            {dataList ? <TableContainer component={Paper}>
                <Table
                    size='small'
                    aria-label='Tabla de alertas'
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow className={classes.header}>
                            {labelsTableHeader?.map(label => {
                                return (
                                    <TableCell key={label} align='center' className={classes.headerCell}>
                                        {label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataList?.map(alert => {
                            return (
                                <TableRow key={alert.id} hover>

                                    <TableCell align='center'>
                                        {
                                            plcComponent ?
                                                <Tooltip
                                                    title={`Estado ${alert?.diagram?.state_value}`}
                                                    arrow
                                                >
                                                    <Box style={{ cursor: 'pointer' }}>
                                                        {returnIconState(alert?.diagram?.state)}
                                                    </Box>
                                                </Tooltip>
                                                :
                                                <Switch color='primary' onChange={(event) => {
                                                    handleSetAlertState(alert, event?.target?.checked);
                                                }} checked={plcComponent ? alert.activo : alert.activate}></Switch>
                                        }
                                    </TableCell>

                                    {
                                        plcComponent &&
                                        <TableCell align='center'>
                                            <Box display={'flex'} justifyContent={'center'}>
                                                {returnButtonState(alert)}
                                            </Box>

                                        </TableCell>
                                    }

                                    <TableCell align='center'>
                                        {plcComponent ? alert.nombre : alert.name}
                                    </TableCell>

                                    <TableCell align='center'>
                                        {plcComponent ? alert.fecha_alta_local : alert.created_format}
                                    </TableCell>

                                    {!plcComponent &&
                                        <TableCell align='center'>
                                            <Tooltip
                                                title={'Historial'}
                                                arrow
                                                placement='bottom'
                                            >
                                                <IconButton onClick={() => handleAlertRecord(alert)}>
                                                    <HistoryIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    }
                                    <TableCell align='center'>
                                        <Tooltip
                                            title='Editar alerta'
                                            arrow
                                            placement='bottom'
                                        >
                                            <NavLink
                                                className={classes.navLink}
                                                to={`/${plcComponent ? 'plc' : 'alert'}/${alert.id}`}
                                            >
                                                <IconButton >
                                                    <EditIcon />
                                                </IconButton>
                                            </NavLink>
                                        </Tooltip>
                                    </TableCell>

                                    {!plcComponent &&
                                        <TableCell align='center'>
                                            <Tooltip
                                                title={plcComponent ? 'Eliminar diagrama' : 'Eliminar alerta'}
                                                arrow
                                                placement='bottom'
                                            >
                                                <IconButton onClick={() => {
                                                    setSelectedAlert(alert)
                                                    setIsDeleteDialogOpen(true);
                                                }}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    }

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer> :
                <Box className={classes.noResultsBox}>
                    No hay resultados.
                </Box>
            }
        </Box >
    )
}
export default AlertPlcTable;