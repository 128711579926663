import instance from './testing.instance';

export const getPlc_automate_24v_list = () => instance.get(`/automates/`);

export const getPlc_automate_24v_state = (id_automate, id_diagram) => instance.get(`/automates/${id_automate}/diagram/${id_diagram}/state-diagram/`);

export const getPlc_automate_24v_byId = (id) => instance.get(`/automates/${id}/`);

export const postPlc_automate_24v_byId = (params_id, body) => instance.post(`/automates/${params_id}/diagram/`, body);

export const putPlc_automate_24v_byId = (params_id, id_diagram, body) => instance.put(`/automates/${params_id}/diagram/${id_diagram}/`, body);

//Edita el estado de el diagrama STOP, PAUSA, PLAY
export const putPlc_automate_24v_state_action = (id_automate, body) => instance.put(`/automates/${id_automate}/action-state/`, body);

//Descarga archivo json.
export const getExportDiagramPlcJson = (id_automate, id_diagram) => instance.get(`/automates/${id_automate}/diagram/${id_diagram}/export-diagram-plc/ `);




