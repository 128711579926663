import React from 'react';
import { useState } from 'react';
//Material UI
import {
    Grid, DialogTitle, DialogContent,
    Box, makeStyles, Button, Chip,
    CircularProgress, Dialog
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Material Icons
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import LocationOnIcon from '@mui/icons-material/LocationOn';
//Componentes
import TopNav from '../common/TopNav';
import Container from '../Elements/Container';
import Filter from './Filter';
import PieChart from './PieChart';
import TableData from './TableData';
import AlertComponent from '../Elements/AlertComponent';
import BarChartClass from './BarChartClass';
import SubBillTable from './SubBillTable';
import DeletePanel from './DeletePanel';
//Date
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { formatDateStrDashToSlashReverse } from '../../helpers/common';
//Servicios
import {
    deleteSublocalizationPeriod
} from '../../services/billing';
//style 
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    btn: {
        margin: theme.spacing(2, 1),
        '& .MuiButton-label': {
            textTransform: 'none',
            // fontSize: 11,
            fontWeight: 700,
            color: theme.palette.primary.main,
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.common.white,
        },
    },
    btnDeleteAccount: {
        margin: "10px",
        "@media print": {
            display: 'none'
        }
    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(10),
    },
    tuneIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.5em',
            paddingTop: theme.spacing(0.5),
        },
    },
    containerMain: {
        paddingBottom: "30px",
        paddingTop: "30px",
        margin: "3px",
    },
    containerBottom: {
        display: "flex",
        width: "98%",
        margin: "auto",
    },
    containerTop: {
        display: "flex",
        width: "98%",
        margin: "auto",
        flexWrap: "wrap",
    },
    subContainerMain: {
        width: "98%",
        backgroundColor: "#f7f7f7b8",
        border: "solid 1px #d9dcde",
        "@media print": {
            display: 'none'
        }
    },
    subContainerBottom: {
        width: "100%",
        marginTop: "20px",
        backgroundColor: "#f7f7f7b8",
        border: "solid 1px #d9dcde",
        "@media print": {
            display: 'none'
        }

    },
    blueFooterMain: {
        height: "5px",
        backgroundColor: theme.palette.primary.main,
    },
    graphPieContainer: {
        width: "40%",
        "@media (max-width: 940px)": {
            width: "100%",
        },
        "@media print": {
            display: 'none'
        }
    },
    graphPie: {
        width: "100%",
        "@media print": {
            display: 'none'
        }
    },
    graphHistoryContainer: {
        width: "60%",
        "@media (max-width: 940px)": {
            width: "100%",
        },
    },
    graphHistory: {
        width: "100%",
    },
    containerTable: {
        width: "100%"
    },
    dateContainer: {
        padding: 0,
        color: theme.palette.common.white,
        fontSize: 12,
        '& .MuiButtonBase-root': {
            color: theme.palette.common.white,
        },
        '& .MuiInputBase-root': {
            color: theme.palette.common.white,
        },
        '& .MuiInputBase-input': {
            padding: 0,
            fontSize: 14,
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.common.white,
        },
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
    date: {
        '& .MuiInputBase-root': {
            width: "150px",
        },
    },
    chip: {
        marginRight: theme.spacing(2),
        "@media (max-width: 920px)": {
            margin: "10px",
        }
    },
    containerChip: {
        display: 'flex',
        flexWrap: "wrap",
        "@media (max-width: 920px)": {
            width: " 100%",
            justifyContent: "space-around"

        }
    },
    containerDialogBill: {
        //estilos pra funcion print().
        '& .MuiDialog-paperFullWidth ': {
            "@media print": {
                width: '100%'
            }
        },

        '& .MuiDialog-paperWidthMd ': {
            "@media print": {
                maxWidth: '100%',
                height: "100vh",
            }
        },
        '& .MuiDialog-paper ': {
            "@media print": {
                margin: '0'
            }
        },

    }
}));

export default function SubBill() {
    const classes = useStyles();
    //Alerta de informacion instrucciones
    const [showAlerInfo, setShowAlertInfo] = useState(true)
    //En locationname cargo la localizacion actual seleccionada
    const [location, setLocation] = useState({});
    //estado auxiliar para los nombres de los selectores
    const [locationAux, setLocationAux] = useState({});
    //estado para abrir el panel del filtro
    const [filterPanel, setFilterPanel] = useState(true);
    //estado de el loading de el contenido principal
    const [loadingCointainerMain, setLoadingCointainerMain] = useState(false);
    //estado q maneja el container principal de la page.
    const [showContentMain, setShowContentMain] = useState(false);
    //Toda la informacion del filtro
    const [filterState, setFilterState] = useState({});
    //Estados alert
    const [close, setClose] = useState(false)
    //estado de el periodo seleccionado todos los datos
    const [selectPeriod, setSelectPeriod] = useState({})
    // Estado encargado de abrir el panel de eliminacion de Dashboard.
    const [openDeletePanel, setOpenDeletePanel] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: ""
    })

    //estado para abrir el panel del la factura
    const [billPanel, setBillPanel] = useState({
        open: false,
        bill: {}
    });

    const togglePanel = () => {
        // setLoadingCointainerMain(false)
        setFilterPanel(!filterPanel);
        setLocationAux({})
    };

    const blueSeparatorLine = () => {
        return (
            <Box className={classes.blueFooterMain}></Box>
        )
    }
    const blueline = blueSeparatorLine()

    const deletePeriod = async () => {
        try {
            await deleteSublocalizationPeriod(selectPeriod?.id)
            setClose(true)
            setMsg({
                msg: "Se elimino el periodo con exito",
                type: "success"
            })
            setFilterState({})
            setLocation({})
            setLocationAux({})
            setSelectPeriod({})
            setShowAlertInfo(true)
            setShowContentMain(false)
            handleOpenCloseDeletePanel()
        }
        catch (error) {
            setClose(true)
            let msg = "Ocurrio un error inesperado."
            if (error?.response?.data?.msg) {
                msg = error?.response?.data?.msg
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
    }

    //Handle encargado de abrir y cerrar el panel de eliminacion de dashboard.
    const handleOpenCloseDeletePanel = () => {
        setOpenDeletePanel(!openDeletePanel)
    }

    return (
        <>
            {/* Panel de eliminacion dashboard. */}
            <DeletePanel
                openDeletePanel={openDeletePanel}
                handleOpenCloseDeletePanel={handleOpenCloseDeletePanel}
                deleteLocal={deletePeriod}
            />
            {/* POP UP FACTURA */}
            <Dialog
                className={classes.containerDialogBill}
                open={billPanel.open}
                onClose={() => setBillPanel({
                    ...billPanel,
                    open: false
                })}
                aria-labelledby='form-dialog-title'
                maxWidth='md'
                fullWidth
            >
                <SubBillTable
                    setBillPanel={setBillPanel}
                    selectPeriod={selectPeriod}
                    billPanel={billPanel}
                    location={location}
                />
            </Dialog>

            <TopNav titulo='Subfacturación' subtitulo={`| Subfactura `}>

                <Grid container justifyContent='flex-end' alignItems='center'>

                    {/* Nombre de periodo */}
                    <Box className={classes.containerChip}  >
                        {location?.nombre?.length > 1 && !showAlerInfo &&
                            <Chip
                                className={classes.chip}
                                icon={<LocationOnIcon />}
                                label={location.nombre}
                            // clickable
                            // onClick={() => setFilterPanel(true)}
                            />
                        }
                        {selectPeriod?.name?.length > 1 && !showAlerInfo &&
                            <Chip
                                className={classes.chip}
                                label={selectPeriod.name}
                            // clickable
                            // onClick={() => setFilterPanel(true)}
                            />
                        }
                    </Box>

                    {/* Fechas nav */}
                    {selectPeriod?.id && !showAlerInfo &&
                        <>
                            <Box className={classes.dateContainer}>
                                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        autoOk
                                        disableToolbar
                                        variant='inline'
                                        format='dd/MM/yyyy'
                                        margin='normal'
                                        readOnly={true}
                                        value={formatDateStrDashToSlashReverse(selectPeriod?.date_from)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'cambiar fecha',
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                    // keyboardIcon={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>

                            <Box className={classes.dateContainer}>
                                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        autoOk
                                        disableToolbar
                                        variant='inline'
                                        format='dd/MM/yyyy'
                                        margin='normal'
                                        readOnly={true}
                                        value={formatDateStrDashToSlashReverse(selectPeriod?.date_to)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'cambiar fecha',
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                        }}
                                    // keyboardIcon={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        </>
                    }

                    <Button
                        className={classes.btn}
                        type='button'
                        onClick={togglePanel}
                        size='small'
                        startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
                    >
                        | Filtrar
                    </Button>

                </Grid>
            </TopNav>

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container>

                {/* ALERT INFO */}
                {showAlerInfo &&
                    <Alert className={classes.alert} severity='info'>
                        Bienvenido a Subfactura. En esta sección podrá consultar la información de Subfacturación. Para comenzar haga click
                        en el botón
                        <span className={classes.tuneIcon}>
                            <TuneIcon />
                        </span>
                        , ingrese los parámetros y haga click en CONSULTAR.
                    </Alert>
                }

                {/* FILTRO */}
                <Dialog
                    open={filterPanel}
                    aria-labelledby='form-dialog-title'
                    onClose={togglePanel}
                    maxWidth='lg'
                    fullWidth
                    disableScrollLock
                >

                    <DialogTitle id='form-dialog-title'>Opciones</DialogTitle>

                    {/* POP UP FILTRO */}
                    <DialogContent>
                        <Filter
                            setClose={setClose}
                            setMsg={setMsg}
                            location={location}
                            setLocationAux={setLocationAux}
                            locationAux={locationAux}
                            setLocation={setLocation}
                            setFilterPanel={setFilterPanel}
                            filterPanel={filterPanel}
                            setLoadingCointainerMain={setLoadingCointainerMain}
                            setShowAlertInfo={setShowAlertInfo}
                            setShowContentMain={setShowContentMain}
                            setFilterState={setFilterState}
                            filterState={filterState}
                            setSelectPeriod={setSelectPeriod}
                            selectPeriod={selectPeriod}
                        />
                    </DialogContent>

                </Dialog>


                {loadingCointainerMain ?

                    <div className={classes.circular}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {/* CONTAINER PRINCIPAL */}
                        {showContentMain &&

                            <Box className={classes.containerMain}>
                                <Box className={classes.containerTop} >
                                    {/* CONTAINER GRAPH HISTORICOS */}
                                    <Grid className={clsx(classes.subContainerMain, classes.graphHistoryContainer,)}>
                                        {blueline}
                                        <Box className={clsx(classes.graphHistory,)}>
                                            <BarChartClass
                                                location={location}
                                                selectPeriod={selectPeriod}
                                            />
                                        </Box>
                                        {blueline}
                                    </Grid>

                                    {/* GRAPH FACTURA REAL VS FACTURA RECONSTRUIDA */}
                                    <Grid className={clsx(classes.subContainerMain, classes.graphPieContainer,)}>
                                        {blueline}
                                        <Box className={clsx(classes.graphPie,)}>
                                            <PieChart
                                                selectPeriod={selectPeriod}
                                            />
                                        </Box>
                                        {blueline}
                                    </Grid>
                                </Box>

                                <Box className={classes.containerBottom} >
                                    {/* TABLA DE DATOS */}
                                    <Box className={clsx(classes.subContainerBottom)}>
                                        {blueline}
                                        <Box className={clsx(classes.containerTable)}>
                                            <TableData
                                                selectPeriod={selectPeriod}
                                                setBillPanel={setBillPanel}
                                                billPanel={billPanel}
                                            />
                                        </Box>
                                        {blueline}
                                    </Box>
                                </Box>

                                <Box width={"100%"} display="flex" justifyContent={"center"}>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={handleOpenCloseDeletePanel}
                                        className={classes.btnDeleteAccount}
                                    >
                                        Eliminar Periodo
                                    </Button>
                                </Box>

                            </Box>
                        }
                    </>
                }
            </Container>

        </>
    );
}
