import React, { useState } from "react";
// @material-ui
import { Box, Collapse, Grid, IconButton, List, ListItem, ListItemText, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles(theme => ({
  itemTitle: {
    padding: theme.spacing(1),
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 700,
  },
  itemValue: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  headerTable: {
    color: theme.palette.primary.main,
  },
}));

export default function QualityTable({ data }) {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect();
  // const isMobile = false;
  const [open, setOpen] = useState(false);
  //Si el número es negativo retorno "-". 
  //Si el número es positivo le agrego la unidad recibida o 'V' en su defecto.
  //si el numero pertenece a la ultima fila Desequilibrio agrego porcentaje '%'.
  const formatNumber = (number, row) => {
    let desiredUnit = 'V'
    let percent = '%'
    if (number < 0) {
      return "-"
    }
    if (row?.titulo === 'Desequilibrio') {

      return number + ' ' + percent
    }
    else {
      return number + ' ' + desiredUnit
    }
  }

  const getCellText = (title) => {
    switch (title) {
      case "Sobretensión":
        return `${title} (>${data.conf.sobretension_valor} V) `
      case "Subtensión":
        return `${title} (<${data.conf.subtension_valor} V) `
      case "Tensión media":
        return `${title} `
      case "Desequilibrio":
        return `${title} (>${data.conf.desequilibrio_valor} %) `
      default:
        return ""
    }
  }

  const toggleSubMenu = (i) => {
    i !== open ? setOpen(i) : setOpen(10)
  }

  return (
    <div style={{ marginBottom: "40px", border: "solid 1px #d9dcde", }}>
      {!isMobile && data?.tension_tabla && (
        <Grid container item>
          <Grid item xs={3} className={classes.itemTitle}></Grid>
          <Grid item xs={3} className={classes.itemTitle}>
            Máximo
          </Grid>
          <Grid item xs={3} className={classes.itemTitle}>
            Promedio
          </Grid>
          <Grid item xs={3} className={classes.itemTitle}>
            Ocurrencia
          </Grid >

          {data?.tension_tabla.map(row => (
            <React.Fragment key={row.titulo} >
              <Grid item xs={3} className={classes.itemTitle} >
                {getCellText(row.titulo)}
              </Grid>
              {/* Maximo */}
              <Grid item xs={3} className={classes.itemValue}>
                {data?.tension_tabla.map((elem) => {
                  return row.titulo === elem.titulo && formatNumber(row.max?.toFixed(2), row)
                })}
              </Grid>
              {/* Promedio */}
              <Grid item xs={3} className={classes.itemValue}>
                {data?.tension_tabla.map((elem) => {
                  return row.titulo === elem.titulo && formatNumber(row.avg?.toFixed(2), row)
                })}
              </Grid>
              {/* Ocurrencia */}
              <Grid item xs={3} className={classes.itemValue}>
                {data?.tension_tabla.map((elem) => {
                  return row.titulo === elem.titulo && row.ocurrencia?.toFixed(2) + ' %'
                })}
              </Grid>

            </React.Fragment>
          ))}
        </Grid>
      )
      }
      {isMobile && data?.tension_tabla &&
        <Table>
          <TableBody>
            {data?.tension_tabla.map((row, i) => (
              <TableRow key={row.titulo}>
                <TableCell component="th" scope="row">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => toggleSubMenu(i)}
                  >
                    {open === i ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />}
                    <Box className={classes.headerTable}>
                      {getCellText(row.titulo)}
                    </Box>
                  </IconButton>

                  <Collapse in={open === i} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem dense>
                        <ListItemText primary="Máximo" secondary={formatNumber(row.max?.toFixed(2), row)} />
                      </ListItem>
                      <ListItem dense>
                        <ListItemText primary="Promedio" secondary={formatNumber(row.avg?.toFixed(2), row)} />
                      </ListItem>
                      <ListItem dense>
                        <ListItemText primary="Ocurrencia" secondary={formatNumber(row.ocurrencia?.toFixed(2), row)} />
                      </ListItem>
                    </List>
                  </Collapse>
                </TableCell>

              </TableRow>
            ))}

          </TableBody>
        </Table>
      }
    </div>
  )

}
