import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline';
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { getLastDayOfMonthFromStr } from '../../helpers/common';
//Servicios
import { getUserConfig } from '../../services/client';

const useStyles = makeStyles(theme => ({
  chart: {
    height: '50vh',
  },
}));

export default function Chart({ eventsData, filterOptions }) {
  const classes = useStyles();

  useEffect(() => {
    let chart = am4core.create('timelineChart', am4plugins_timeline.SerpentineChart);
    // SACAMOS LOGO DE AMCHARD
    if (chart.logo) {
      chart.logo.disabled = true
    }

    //Envuelvo todo en una funcion async para esperar antes q nada la respuesta de la consulta sobre el timeZone de el cliente.
    const getUserTimeZone = async () => {
      //Busco el timezone de el cliente.
      let res = await getUserConfig()
      // //Configuro el timezone (GMT) de el cliente.
      chart.dateFormatter.timezone = res?.data?.timezone
      chart.dateFormatter.dateFormat = 'yyyy-MM-ddTHH:mm:ssZ';
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ssZ';

      chart.curveContainer.padding(20, 20, 20, 20);
      chart.levelCount = 8;
      chart.orientation = 'horizontal';
      chart.fontSize = 11;

      const title = chart.titles.create();
      title.text = 'Cortes de Energía';

      let colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.6;

      const auxLinearArray = [];

      eventsData.page.forEach(event => {
        auxLinearArray.push(event.event);
        //Si el campo to es null le pongo el mismo valor que el from
        if (!event.event.to) {
          auxLinearArray[auxLinearArray.length - 1].to = auxLinearArray[auxLinearArray.length - 1].from;
        }

        auxLinearArray[auxLinearArray.length - 1].d1 = format(new Date(event.event.from), 'dd/MM/yyyy ppp');

        if (event.event.duration === 'En curso') {
          auxLinearArray[auxLinearArray.length - 1].to = new Date(filterOptions.dateTo).toISOString();
          auxLinearArray[auxLinearArray.length - 1].color = am4core.color('#3C3744');
          auxLinearArray[auxLinearArray.length - 1].duration = '-';
          auxLinearArray[auxLinearArray.length - 1].description = 'En curso';
        }
        else {
          if (event.event.title === 'Corte de energía')
            auxLinearArray[auxLinearArray.length - 1].color = colorSet.getIndex(5);
          else if (event.event.title === 'Corte de conectividad')
            auxLinearArray[auxLinearArray.length - 1].color = colorSet.getIndex(2);
          else if (event.event.title === 'Alarma') auxLinearArray[auxLinearArray.length - 1].color = colorSet.getIndex(8);
        }
      })

      chart.data = auxLinearArray;

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'title';
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.paddingRight = 25;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.innerRadius = -60;
      categoryAxis.renderer.radius = 60;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };
      dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';

      // Obtengo un objeto Date con el último día del mes a partir de un string con la forma 2022-05-01
      const last = getLastDayOfMonthFromStr(filterOptions.dateTo);
      // Como quiero asegurarme de mostrar todos los eventos del día configuro la hora a las 23:59
      last.setHours(23, 59, 0, 0);

      dateAxis.max = last.getTime();

      // 2022-05-01 lo toma como Sat Apr 30 2022 21:00:00
      const firstAux = new Date(filterOptions.dateFrom);

      // Obtengo la zona horaria
      const userTimezoneOffset = firstAux.getTimezoneOffset() * 60000;
      // Se la sumo para obtener la fecha Sun May 01 2022 00:00:00
      const first = new Date(firstAux.getTime() + userTimezoneOffset);

      dateAxis.min = first.getTime();

      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.startLocation = -0.5;
      dateAxis.renderer.line.strokeDasharray = '1,4';
      dateAxis.renderer.line.strokeOpacity = 0.7;
      dateAxis.tooltip.background.fillOpacity = 0.2;
      dateAxis.tooltip.background.cornerRadius = 5;
      dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
      dateAxis.tooltip.label.paddingTop = 7;

      let labelTemplate = dateAxis.renderer.labels.template;
      labelTemplate.verticalCenter = 'middle';
      labelTemplate.fillOpacity = 0.7;
      labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor('background');
      labelTemplate.background.fillOpacity = 1;
      labelTemplate.padding(7, 7, 7, 7);

      let categoryAxisLabelTemplate = categoryAxis.renderer.labels.template;
      categoryAxisLabelTemplate.fontSize = 10;
      categoryAxisLabelTemplate.paddingBottom = 25;

      categoryAxisLabelTemplate.horizontalCenter = 'left';
      categoryAxisLabelTemplate.adapter.add('rotation', function (rotation, target) {
        let position = dateAxis.valueToPosition(dateAxis.min);
        return dateAxis.renderer.positionToAngle(position) + 90;
      });

      let series1 = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
      series1.columns.template.height = am4core.percent(10);
      series1.columns.template.tooltipText = '[bold]{title}[/] \n Inicio: {from_formated}\n Duración: {duration_str}\n {description} ';

      series1.dataFields.openDateX = 'from';
      series1.dataFields.dateX = 'to';
      series1.dataFields.categoryY = 'title';
      series1.columns.template.propertyFields.fill = 'color'; // get color from data
      series1.columns.template.propertyFields.stroke = 'color';
      series1.columns.template.strokeOpacity = 0;

      let bullet = new am4charts.CircleBullet();
      series1.bullets.push(bullet);
      bullet.circle.radius = 1;
      bullet.circle.strokeOpacity = 0;
      bullet.propertyFields.fill = 'color';
      bullet.locationX = 0;

      let bullet2 = new am4charts.CircleBullet();
      series1.bullets.push(bullet2);
      bullet2.circle.radius = 3;
      bullet2.circle.strokeOpacity = 0;
      bullet2.propertyFields.fill = 'color';
      bullet2.locationX = 1;

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.align = 'center';
      chart.scrollbarX.width = am4core.percent(80);

      let cursor = new am4plugins_timeline.CurveCursor();
      chart.cursor = cursor;
      // chart.cursor.snapToSeries = [series1];

      cursor.xAxis = dateAxis;
      cursor.yAxis = categoryAxis;
      cursor.lineY.disabled = true;
      cursor.lineX.strokeDasharray = '1,4';
      cursor.lineX.strokeOpacity = 1;

      dateAxis.renderer.tooltipLocation = 200;
      categoryAxis.cursorTooltipEnabled = false;
    }
    getUserTimeZone()
    return () => {
      chart.dispose();
    };
  }, [eventsData, filterOptions]);
  return (
    <>
      <div id='timelineChart' className={classes.chart} />
    </>
  );
}
