import { makeStyles, } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
    Box, Dialog, IconButton
} from '@material-ui/core';
import Link from '@mui/material/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-container': {
            '@media (max-width: 1100px)': {
                margin: '0px 0px 0 0',
            }
        },
        '& .MuiDialog-paper': {
            margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
            color: '#423d3dde',
            maxHeight: '100vh',
            '@media (max-width: 900px)': {
                width: '100%',
                height: '100%',
            }
        }
    },
    root: {
        padding: theme.spacing(2, 3, 3, 3),
        '@media (max-width: 900px)': {
            padding: theme.spacing(2, 2, 2, 2),
        }
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 20,
        color: theme.palette.primary.dark,
        margin: 'auto',
        '@media (max-width: 900px)': {
            fontSize: 18,
        }
    },
    description: {
        fontSize: 16,
        lineHeight: '33px',
        '@media (max-width: 900px)': {
            fontSize: 14,
        }
    },
    iconCLoseButton: {
        marginLeft: theme.spacing(2),
        height: "40px"
    },
    circleList: {
        width: 5,
        height: 5,
        borderRadius: 300,
        marginRight: 10
    },
    detailsListItem: {
        padding: theme.spacing(1.2),
        display: 'flex',
        alignItems: 'center'
    },
    detailsListItemTitle: {
        color: theme.palette.primary.dark,
    },
    detailsListItemContent: {
        margin: theme.spacing(0, 0, 0, 3),
        textOverflow: "ellipsis",
        // overflow: "hidden",
        // whiteSpace: "nowrap"
    },
}));

const NotificationContent = ({ notificationSelect, openModalNotificationContent, handleCloseNotificationContent }) => {
    const classes = useStyles()
    const [optionsData, setOptionData] = useState({})
    const { isMobile } = useDeviceDetect(920);
    useEffect(() => {
        editDataContent(notificationSelect)
    }, [notificationSelect])

    let configDetailBox = [
        {
            name: 'Tipo de alarma:',
            payload: optionsData?.type?.content
        },
        {
            name: 'Fecha y hora notificacion:',
            payload: notificationSelect?.formated_timestamp
        },
        {
            name: 'Dispositivo:',
            payload: notificationSelect?.titulo
        }
    ]

    const editDataContent = (notificationSelect) => {
        let option = notificationSelect?.contenido_largo
        if (option) {
            let type = option[0]
            let dateAndTime = option[1]
            let duration = option[2]
            let device = option[3]
            let userEmail = option[4]
            setOptionData({
                type,
                dateAndTime,
                duration,
                device,
                userEmail
            })
        }
    }

    //Funcion encargada de poner espacios en los textos si hay una barra para q puedan ser formateados con exito.
    function splitWithSpaces(string) {
        if (typeof string === 'string' && string.includes("/")) {
            return string.split("/").join(" / ");
        } else {
            return string;
        }
    }

    return (
        <Dialog
            className={classes.dialog}
            open={openModalNotificationContent}
            onClose={handleCloseNotificationContent}
            aria-labelledby='form-dialog-title'
            maxWidth='sm'
            fullWidth
        >
            <Box style={{ borderBottom: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }}
                display={'flex'}
                justifyContent={'flex-start'}
                pt={1}
                pb={1}
            >
                <IconButton
                    className={classes.iconCLoseButton}
                    onClick={handleCloseNotificationContent}
                >
                    <CancelIcon />
                </IconButton>
            </Box>

            {/* Titulo */}
            <Box className={classes.title} style={{ borderBottom: `solid 1px ${'#47a6db'}`, width: '100%', textAlign: 'center' }}>
                {notificationSelect?.titulo}
            </Box>

            {/* Detalles */}
            <Box style={{ width: '100%' }} >
                {
                    configDetailBox.map(((element, indx) => {
                        return (
                            <Box key={indx}>
                                <Box className={classes.detailsListItem}
                                >
                                    <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
                                    </Box>

                                    <Box display='flex' >
                                        <Box className={classes.detailsListItemTitle} >
                                            {element.name}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ borderBottom: `solid 1px ${'#a19292de'}`, paddingBottom: '3px' }} className={classes.detailsListItemContent}  >
                                    {splitWithSpaces(element.payload)}
                                </Box>
                            </Box>
                        )
                    }))
                }
            </Box>

            <Box className={classes.root} width={'100%'} height={'100%'} >
                {/* Descripcion */}
                {Array.isArray(notificationSelect?.contenido_largo) &&
                    <Box className={classes.description} mt={2} >
                        {
                            notificationSelect?.contenido_largo?.map((element, indx) => {
                                return (
                                    <Box key={indx} display='flex' style={isMobile ? { flexDirection: 'column' } : {}} >
                                        <Box style={{ fontWeight: 'bold', minWidth: element.title === "Tipo de alarma" ? '132px' : '' }} >{`${element.title}:`}</Box>
                                        <Box ml={1} >{splitWithSpaces(element.content)}</Box>
                                    </Box>
                                )
                            })
                        }
                        <Box mt={1}   >
                            {splitWithSpaces(notificationSelect.contenido)}
                        </Box>
                    </Box>}

                {/* Footer */}
                <Box >
                    <Box mt={6} style={{ borderTop: `solid 1px ${'#a19292de'}`, paddingTop: '5px' }}  >
                        Para mayor información puede visitar:
                    </Box>
                    <Link target="_blank" href={`https://powermate.powermeter.com.ar`}>
                        {`https://powermate.powermeter.com.ar`}
                    </Link>
                </Box>
            </Box>

        </Dialog >
    )
}



export default NotificationContent