import instance from './testing.instance';

//RATE
export const getProviders = () => instance.get(`billing/rates/providers/`);
export const getRate = (id) => instance.get(`/billing/rates/?provider=${id}&?show_all=true`);
export const getBestRate = (provider, type, from, to, hierarchyId) => instance.get(`/billing/contract/get_rate/?provider=${provider}&type=${type}&from=${from}&to=${to}&hierarchy=${hierarchyId}`);
export const getRateConfig = (id) => instance.get(`/billing/rates/${id}/`);
export const deleteRateConfig = (id) => instance.delete(`/billing/rates/${id}/`);
export const putRateConfig = (id, body) => instance.put(`/billing/rates/${id}/`, body);

//creacion de franja horaria de potencia
export const putPowerRates = (id, body) => instance.put(`/billing/rates/power/${id}/`, body);

//creacion de franja horaria de energia
export const putEnergyRates = (id, body) => instance.put(`/billing/rates/energy/${id}/`, body);

//Contrato
export const getContractByIdLoc = (idLoc) => instance.get(`/billing/contract/?location=${idLoc}`);
export const postNewContract = (body) => instance.post(`/billing/contract/`, body);
export const postNewContractPower = (body) => instance.post(`/billing/contract/power/`, body)
export const putContract = (id, body) => instance.put(`/billing/contract/${id}/`, body);
export const patchContract = (id, body) => instance.patch(`/billing/contract/${id}/`, body);
export const deleteContract = (id) => instance.delete(`/billing/contract/${id}/`);

//contrato listSubRate/
export const getContractSubRateByIdLoc = (IDJerarquia) => instance.get(`/billing/contract/${IDJerarquia}/listSubRate/`);
export const getPrivateRate = (IDJerarquia) => instance.get(`/billing/rates/${IDJerarquia}/privaterate/`);

//franja de potencia contratada
export const getContractPower = (id) => instance.get(`/billing/contract/power/${id}/`);
export const postContractPower = (body) => instance.post(`/billing/contract/power/`, body);
export const putContractPower = (id, body) => instance.put(`/billing/contract/power/${id}/`, body);
export const deleteContractPower = (id) => instance.delete(`/billing/contract/power/${id}/`);

//BILL
//nueva
export const getBillByIdLoc = (idLoc) => instance.get(`/billing/bill/listcompact/?location=${idLoc}`);
//nueva
export const getCostPowEnergy = idBill => instance.get(`/billing/bill/${idBill}/`);
export const getCostById = idBill => instance.get(`/billing/bill/${idBill}/costs/`);
export const getEnergyById = idBill => instance.get(`/billing/bill/${idBill}/energy/`);
export const getPowerById = idBill => instance.get(`/billing/bill/${idBill}/power/`);
export const putBillById = (idBill, body) => instance.put(`/billing/bill/${idBill}/`, body);
export const deleteBillById = (idBill) => instance.delete(`/billing/bill/${idBill}/`);
export const postBill = (body) => instance.post(`/billing/bill/`, body);

// DatePickerBill Data disabled
export const datePickerInfoDisabled = (id_jerarquia) => instance.get(`/billing/contract/${id_jerarquia}/enabled_fechas`);

//Conciliation
export const getConciliation = () => instance.get(`/billing/bill/conciliation/`);
export const patchConciliation = (id, body) => instance.patch(`/billing/bill/${id}/`, body);

//SimpleCalculator
export const getSingleLozalization = (idLocalizacion, costoF, costoV, dateFrom, dateTo) => instance.get(`/billing/bill/${idLocalizacion}/freebill/?costoF=${costoF}&costoV=${costoV}&from=${dateFrom}&to=${dateTo}`);

//SubBill
//creo un periodo
export const createSublocalizationPeriod = (body) => instance.post(`/billing/subdivision/`, body);

//Obtengo datos de el periodo de  esa subLocalizacion
export const getSublocalizationPeriod = (IDSubdivision) => instance.get(`/billing/subdivision/${IDSubdivision}/`);

//elimino un periodo Seccion (Subfacturacion)
export const deleteSublocalizationPeriod = (IDSubdivision) => instance.delete(`/billing/subdivision/${IDSubdivision}/`);

//Trae las lista de periodos de sublocalizaciónes pertenecientes a una localización
export const getListPeriodLocalization = IDLocation => instance.get(`/billing/subdivision/list_period/?location=${IDLocation}`);

//Fechas desabilitadas en filtro subfactura
export const getDisabledDaysFilterUnderBilling = (IDJerarquia) => instance.get(`/billing/subdivision/${IDJerarquia}/enabled_fechas/`);

//Descarga archivo csv de la subfactura en seccion subfacturacion.
export const downloadCsvSubBill = (IDSubdivision, IDsubfactura) => instance.get(`/billing/subdivision/${IDSubdivision}/subfactura/${IDsubfactura}/download_csv/`, { responseType: 'blob' });

//Descarga archivo xlsx de la subfactura en seccion subfacturacion.
export const downloadXlsxSubBill = (IDSubdivision, IDsubfactura) => instance.get(`/billing/subdivision/${IDSubdivision}/subfactura/${IDsubfactura}/download_xlsx/`, { responseType: 'blob' });

//Descarga archivo csv de la Localizacio en seccion subfacturacion.
export const downloadCsvSubBillResumenGeneral = (IDSubdivision) => instance.get(`/billing/subdivision/${IDSubdivision}/download_csv/`, { responseType: 'blob' });

//Descarga archivo xlsx de la Localizacio en seccion subfacturacion.
export const downloadXlsxSubBillResumenGeneral = (IDSubdivision) => instance.get(`/billing/subdivision/${IDSubdivision}/download_xlsx/`, { responseType: 'blob' });

