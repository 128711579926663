import { Box, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';

const useStyles = makeStyles(theme => ({
    dateContainer: {
        padding: '0 8px',
        color: theme.palette.common.white,
        fontSize: 16,
        '& .MuiButtonBase-root': {
            color: theme.palette.common.white,
        },
        '& .MuiInputBase-root': {
            color: theme.palette.common.white,
            width: 125,
        },
        '& .MuiInputBase-input': {
            textAlign: 'center',
        },
        "@media (max-width: 900px)": {
            display: "none"
        }
    },
    date: {
        '& .MuiInputBase-root': {
            width: 150,
        },
    },
    nextDayBtn: {
        '&.Mui-disabled': {
            opacity: '0.5',
        },
    },
}));

const DateContent = ({ data }) => {
    const classes = useStyles();
    return (
        <Box className={classes.dateContainer} display='flex' justifyContent='center'>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.date}
                    autoOk
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    disabled={true}
                    value={data.dateFrom}
                    // label='Date picker inline'
                    // onChange={e => handleDateChange(e, 'selectedInitialDate')}
                    KeyboardButtonProps={{
                        'aria-label': 'cambiar fecha',
                    }}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    readOnly={true}
                />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.date}
                    autoOk
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    disabled={true}
                    value={data.dateTo}
                    // label='Date picker inline'
                    // onChange={e => handleDateChange(e, 'selectedEndDate')}
                    KeyboardButtonProps={{
                        'aria-label': 'cambiar fecha',
                    }}
                    InputProps={{
                        disableUnderline: true

                    }}
                    readOnly={true}
                />
            </MuiPickersUtilsProvider>
        </Box>
    )
}

export default DateContent