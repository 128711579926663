import { Box, Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, FormControlLabel, Switch, FormGroup } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
//Servicios
import { editNotificationNodeSettings } from '../../services/notifications';

let locationMobileStyles = {
	height: "50px",
	padding: "10px",
	marginBottom: "1px"
}
const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
		"@media (max-width: 700px)": {
			position: "relative",
			left: "-32px",
			width: 'calc(100% + 32px)',
		},
	},
	selected: {
		'&.MuiListItem-root.Mui-selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		'&.MuiListItem-root.Mui-selected p': {
			color: theme.palette.common.white,
		},
	},
	nodeHeader: {
		display: 'flex',
		'& span': {
			fontSize: "larger",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
		},
		'& p': {
			marginLeft: '5px',
			fontSize: "larger"
		},
		"@media (max-width: 700px)": {
			flexDirection: " column",
			'& span': {
				marginLeft: '5px',
				fontSize: "small",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
			},
			'& p': {
				marginLeft: '5px',
				fontSize: "small",
			},
		},

	},
	empresa: {
		"@media (max-width: 700px)": {
			// backgroundColor: theme.palette.primary.main,
			...locationMobileStyles
		},
	},
	zona: {
		"@media (max-width: 700px)": {
			// background: "#c1c1c1",
			// marginLeft: theme.spacing(1),
			...locationMobileStyles

		}
	},
	localizacion: {
		"@media (max-width: 700px)": {
			// background: "#e0e0e0",
			// marginLeft: theme.spacing(2),
			...locationMobileStyles
		}
	},
	sublocaclizacion: {
		"@media (max-width: 700px)": {
			// background: "#f1efef",
			// marginLeft: theme.spacing(3),
			...locationMobileStyles
		}
	},
	wordCut: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	switch: {
		"@media (max-width: 700px)": {
			marginRight: theme.spacing(4)
		}
	}
}));

export default function NodeNotificationSettings({ node, settingNotificationNodeState }) {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const [check, setCheck] = useState(node?.preferencias_medidor[settingNotificationNodeState?.type]?.estado);

	const handleArrows = () => {
		setOpen(!open);
	};

	const classNodeSelect = (node) => {
		if (node.level_nombre === "empresa") {
			return classes.empresa
		}
		else if (node.level_nombre === "zona") {
			return classes.zona
		}
		else if (node.level_nombre === "localización") {
			return classes.localizacion
		}
		else if (node.level_nombre === "sublocalización") {
			return classes.sublocaclizacion
		}
		else {
			return ""
		}

	}

	const handleChangeSwitch = async () => {
		try {
			setCheck(!check)
			let filterElement = node?.preferencias_medidor[settingNotificationNodeState?.type]
			let body = { "enabled": !check }
			await editNotificationNodeSettings(filterElement?.edit_id, body)
		}
		catch (error) {
		}
	}

	return (
		<Box style={{ listStyleType: 'none' }}>
			<ListItem
				button
				selected={false}
				className={clsx(classes.selected, classNodeSelect(node))}
			// onClick={() => handleSelectedNode(node)}
			// disabled={node.level_nombre !== 'empresa'}
			>
				<ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={classes.nodeHeader} />

				<Box className={classes.switch} width={40}>
					<FormGroup
					>
						<FormControlLabel
							control={
								<Switch name={'test'}
									// disabled={(general ? false : !disabledOption[elem.name]) || (elem.name === 'telegram_notification')}
									checked={check}
									onChange={handleChangeSwitch}
									color='primary' />
							}
						/>
					</FormGroup>
				</Box>

				{/* ICON OPEN CLOSE */}
				<ListItemSecondaryAction >
					<Box width={30}>
						{node.hijos &&
							node.hijos.length > 0 &&
							(open ? (
								<IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
									<ExpandLess />
								</IconButton>

							) : (

								<IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
									<ExpandMore />
								</IconButton>
							))}
					</Box>
				</ListItemSecondaryAction>

			</ListItem >

			{
				node.hijos ? (
					<Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
						<List dense component='div' disablePadding>
							{node.hijos.map(c => (
								<NodeNotificationSettings
									settingNotificationNodeState={settingNotificationNodeState}
									node={c}
									key={c.id}
								/>
							))}
						</List>
					</Collapse>
				)
					:
					null
			}
		</Box >
	);
}
