import {Box, makeStyles} from '@material-ui/core'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React from 'react'

const useStyles = makeStyles(theme => ({
  selected: {
    color: theme.palette.primary.main,
  },
  unselected: {
    color: theme.palette.secondary.main,
  },
  markerContainer: {
    marginTop: '-35px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: 'bold',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.6
    }
  },
  unselectedMarkerCaption: {
    color: theme.palette.secondary.light,
    background: 'white',
    borderRadius: theme.spacing(0.75),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  selectedMarkerCaption: {
    color: theme.palette.primary.light,
    background: 'white',
    borderRadius: theme.spacing(0.75),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  
}))

function Marker({name, onClick, selected}) {
  const classes = useStyles()

  return (
    <Box className={classes.markerContainer} onClick={onClick}>
      <LocationOnIcon className={selected ? classes.selected : classes.unselected} fontSize='large' />
      <Box className={selected ? classes.selectedMarkerCaption : classes.unselectedMarkerCaption}>{name}</Box>
    </Box>
  )
}

export default Marker
