import instance from './testing.instance';

export const getUserConfig = () => instance.get(`/client/config/`);
export const putUserConfig = body => instance.put(`/client/config/edit/`, body);

export const getSubaccounts = () => instance.get(`/client/subaccounts/`);
export const addSubaccount = (email, query) => instance.post(`/client/subaccounts/add/?email=${email}&${query}`);
export const editSubaccount = (subaccountId, query) => instance.put(`/client/subaccounts/${subaccountId}/edit/?${query}`);
export const deleteSubaccount = id => instance.post(`/client/subaccounts/${id}/delete/`);

export const getPlanInfo = () => instance.get('/client/plan/info/');
export const getSubaccountInvites = () => instance.get('/client/subaccounts/invites/');
export const inviteRevoke = subaccountId => instance.put(`/client/subaccounts/${subaccountId}/inviteRevoke/`);
export const getSubaccountEmails = () => instance.get('/client/subaccounts/listsubaccountsemails/');
//Borrar cuenta
export const deleteAccount = () => instance.post('/client/subaccounts/deactivate/');
//cambiar contraseña.
export const changePassword = (body) => instance.post('/client/config/resetpassword/', body);