import axios from "axios";

// console.log(process.env.REACT_APP_API_URL_BACK_NOTIFICATION)
// console.log(process.env.REACT_APP_PUBLIC_VAPID_KEY)

const API_URL_BACK_NOTIFICATION = 'https://notifications.powermeter.com.ar'
const PUBLIC_VAPID_KEY = "BPEGjTKfo585Sr5suPhbySRT_COnojjzrG3lifQts3KDiKthZpp1k55PJw0LGrufsU9LsNOOK2hVM1FJIQRXabU"

//Eliminar service worker .
export const unregisterServiceWorkers = async (name) => {
    try {
        // Obtener la lista de Service Workers registrados
        const registrations = await navigator.serviceWorker.getRegistrations()
        // Eliminar todos los Service Workers registrados
        registrations.forEach(async (registration) => {
            const subscription = await registration.pushManager.getSubscription()
            // Si hay una suscripción, puedes acceder a los datos aquí.
            if (subscription) {
                await axios.delete(`${API_URL_BACK_NOTIFICATION}/subscriptions/unsubscribe`,
                    {
                        data: { subscription: JSON.stringify(subscription) }
                    }
                )
            }
            registration.unregister();
        });

    }
    catch (error) {
    }

}

//Creo el service worker /powermeter_worker.js'.
export const registerUser = async () => {
    let register = await navigator.serviceWorker.register('/powermeter_worker.js', {
        scope: '/'
    })
    return register
}

//Funcion q suscribe al cliente y manda al back el token q conecta con el service wirker.
export const subscription = async (plan, token) => {
    try {
        let register = await registerUser()
        let device_type = ''
        // 'La resolucion de la pantalla es menor o igual a 920.
        let mobileAux = document.body.clientWidth <= 920
        if (mobileAux) {
            // Verificar si la aplicación se está ejecutando como una PWA
            if (window.matchMedia('(display-mode: standalone)').matches) {
                // 'La aplicación se está ejecutando como una PWA'
                device_type = 'pwa_mobile'
            }
            else {
                // 'La aplicación se está ejecutando en el navegador de un dispositivo móvil'   
                device_type = 'navegador_mobile'
            }
        }
        else {
            device_type = 'desktop'
        }
        //Suscribe al usuario
        const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: PUBLIC_VAPID_KEY
        })
        let objPost = {
            email: plan?.info?.email,
            subscription: JSON.stringify(subscription),
            token: token,
            device_type: device_type
        }
        await axios.post(`${API_URL_BACK_NOTIFICATION}/subscriptions/subscribe`,
            objPost
        )
    }
    catch (error) {
    }
}
