import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import {
	FormControl,
	MenuItem,
	Select,
} from '@material-ui/core';
//Notificaciones
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Endpoints
import { getNodesTree, getQualityConfig, putQualityConfig } from '../../services/hierarchy';
// Componentes
import TopNav from '../common/TopNav';
import QualityNode from './QualityNode';
import Container from '../Elements/Container';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";


let scrollsSettings = {
	overflow: "scroll",
	height: "90vh",
	overflowX: "hidden",
}
let webkitScrollbar = {
	background: "white",
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: "#beb1b18f",
	color: "red",
	width: "10px"
}
let webkitscrollbarTrack = {
	background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		display: "flex",
	},
	leftContainer: {
		padding: theme.spacing(2),
		//son los estilos de las barras scroll en una variable.
		...scrollsSettings,
		'&::-webkit-scrollbar': {
			//son los estilos de las barras scroll en una variable.
			...webkitScrollbar
		},
		'&::-webkit-scrollbar-track': {
			//son los estilos de las barras scroll en una variable.
			...webkitscrollbarTrack
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.primary.main,
		},
		"@media (max-width: 700px)": {
			padding: theme.spacing(0, 0, 0, 1),
		}
	},
	rightContainer: {
		padding: theme.spacing(2),
		paddingTop: "40px",
	},
	form: {
		marginLeft: theme.spacing(2),
		'& .MuiInputBase-root': {
			color: '#666666',
		},
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	btn: {
		margin: theme.spacing(3, 1),
	},
	tableContainer: {},
	tableTitle: {
		padding: theme.spacing(1),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.primary.light,
		color: 'white',
	},
	tableItem: {
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	selectTension: {
		textAlign: "initial",
		width: "245px",
		marginLeft: "9px",
		color: "black"
	},
	containerSelectTension: {
		display: "flex",
		flexDirection: "row"
	},
	buttonBack: {
		position: "relative",
		bottom: "16px",
		fontSize: "20px",
		backgroundColor: "#f3f3f3",
		width: "30px",
		borderRadius: "1em",
		textAlign: "center",
		fontWeight: "bold"
	},

}));
function ConfigQuality() {
	const classes = useStyles();

	const { isMobile } = useDeviceDetect(959);

	const [nodes, setNodes] = useState([]);
	const [selectedNode, setSelectedNode] = useState({});
	const [config, setConfig] = useState({});

	//Notifications
	const [notificationSuccess, setNotificationSuccess] = useState();
	const [notificationError, setNotificationError] = useState({ visible: false, msg: "" });
	const [loading, setLoading] = useState(true)
	const [showRightBlock, setShowRightBlock] = useState(true)
	const [showLeftBlock, setShowLeftBlock] = useState(true)

	useEffect(() => {

		if (isMobile) {
			// console.log("mobile")

			setShowRightBlock(false)
		}
		else {
			// console.log("desktop");

			setShowRightBlock(true)
		}


	}, [isMobile])

	const fetchNodesTreeData = async () => {
		try {
			// console.log('fetchNodesTreeData');
			const res = await getNodesTree();
			setNodes(res.data);
			// console.log(res.data);
			setLoading(false)
		} catch (error) {
			// console.error(error);
		}
	};

	useEffect(() => {
		fetchNodesTreeData();
	}, []);

	useEffect(() => {
		const fetchQualityConfig = async () => {
			try {
				// console.log('fetchQualityConfig');
				const res = await getQualityConfig(selectedNode.id);
				setConfig(res.data);
				// console.log(res.data);
			} catch (error) {
				console.error(error);
			}
		};
		// Si tengo un nodo seleccionado quiero obtener su configuración
		if (selectedNode.id) {
			fetchQualityConfig();
		}
	}, [selectedNode]);

	const nodeList = () => {
		return nodes.map(node => <QualityNode node={node} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} key={node.id} />);
	};

	const getErrors = () => {
		let errors = false;

		//TODO Chequear que todos los campos sean numéricos

		return errors;
	};

	const handleSelectedNode = node => {
		setSelectedNode(node);

		//Limpio la variable de estado para mostrar el CircularProgress luego de clickear un nuevo nodo
		setConfig({});

		if (isMobile) {
			setShowLeftBlock(false)
			setShowRightBlock(true)
		}
	};

	const handleChange = e => {
		// console.log('NodeInfo handleChange');
		// console.log(config)
		setConfig({ ...config, [e.target.name]: e.target.value });
	};

	const handleCancel = () => {
		setSelectedNode({});
		setConfig({});
	};

	const handleSubmit = async e => {
		// console.log('handleSubmit');
		e.preventDefault();

		try {
			//Actualizo los valores ingresados
			await putQualityConfig(selectedNode.id, {
				sobretension_valor: config.sobretension_valor,
				subtension_valor: config.subtension_valor,
				tension_promedio_valor: config.tension_promedio_valor,
				desequilibrio_valor: config.desequilibrio_valor,
				sobretension_ocurrencia: config.sobretension_ocurrencia,
				subtension_ocurrencia: config.subtension_ocurrencia,
				tension_promedio_ocurrencia: config.tension_promedio_ocurrencia,
				desequilibrio_ocurrencia: config.desequilibrio_ocurrencia,
				calcular: config.calcular,
			});
			setNotificationSuccess(true);
		} catch (error) {
			console.error(error);
			setNotificationError({ visible: true, msg: `${error.response.data.msg} (${error.response.data.error_code})` })
		}
	};
	const BackPageMobile = () => {
		setShowLeftBlock(true)
		setShowRightBlock(false)
	}

	return (
		<>

			<TopNav titulo='Configuración' subtitulo='| Calidad de Energía'></TopNav>


			<Container>
				{loading ?

					<Box display='flex' justifyContent='center' mt={2}>
						<CircularProgress />
					</Box>
					:

					<Grid className={classes.root} container>

						{showLeftBlock &&
							<Grid item xs={12} md={6} className={classes.leftContainer}>

								{nodes.length === 0 &&
									<h1 style={{ textAlign: "center", fontSize: "19px" }}>
										Usted no posee ninguna localización configurada en su cuenta.<br></br>
										Por favor, configure al menos una localización para poder continuar.
										<NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
											[link]
										</NavLink>
									</h1>
								}

								<List>
									<div>{nodeList()}</div>
								</List>
							</Grid>
						}
						{showRightBlock &&

							<Grid item xs={12} md={6} className={classes.rightContainer}>

								{isMobile &&

									<div className={classes.buttonBack} onClick={BackPageMobile}>{'<'}</div>

								}

								{/* Si tengo un nodo seleccionado pero config es {} muestro <CircularProgress /> */}
								{selectedNode.id && !Object.keys(config).length ? (
									<Box display='flex' justifyContent='center' mt={2}>
										<CircularProgress />
									</Box>
								) : null}

								{/* Notificaciones */}
								<Collapse in={notificationSuccess}>
									<Alert severity='success' action={
										<IconButton aria-label="close" color="inherit" size="small" onClick={() => {
											setNotificationSuccess(false);
										}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}>Se guardó correctamente la configuración
									</Alert>
								</Collapse>
								<Collapse in={notificationError.visible}>
									<Alert severity='error' action={
										<IconButton aria-label="close" color="inherit" size="small" onClick={() => {
											setNotificationError({ visible: false, msg: "" });
										}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}>
										{notificationError.msg}
									</Alert>
								</Collapse>
								{/* Si tengo un valor de config lo muestro */}
								{Object.keys(config).length ? (
									<>
										<form onSubmit={handleSubmit} className={classes.form}>
											<Grid container className={classes.tableContainer}>
												<Grid item xs={4} className={classes.tableTitle}>
													Parámetro
												</Grid>
												<Grid item xs={4} className={classes.tableTitle}>
													Umbral de valor
												</Grid>
												<Grid item xs={4} className={classes.tableTitle}>
													Umbral de ocurrencia
												</Grid>
												<Grid item xs={4} className={classes.tableTitle}>
													Sobre-tensión
												</Grid>
												<Grid item xs={4} className={classes.tableItem}>
													<TextField
														fullWidth
														placeholder='sobretension_valor'
														name='sobretension_valor'
														value={config.sobretension_valor || ''}
														onChange={e => handleChange(e)}
														color='primary'
														className={classes.input}
														InputProps={{
															endAdornment: <InputAdornment position='end'>V</InputAdornment>,
														}}
													/>
												</Grid>
												<Grid item xs={4} className={classes.tableItem}>
													<TextField
														fullWidth
														placeholder='sobretension_ocurrencia'
														name='sobretension_ocurrencia'
														value={config.sobretension_ocurrencia || ''}
														onChange={e => handleChange(e)}
														color='primary'
														className={classes.input}
														InputProps={{
															endAdornment: <InputAdornment position='end'>%</InputAdornment>,
														}}
													/>
												</Grid>
												<Grid item xs={4} className={classes.tableTitle}>
													Sub-tensión
												</Grid>
												<Grid item xs={4} className={classes.tableItem}>
													<TextField
														fullWidth
														placeholder='subtension_valor'
														name='subtension_valor'
														value={config.subtension_valor || ''}
														onChange={e => handleChange(e)}
														color='primary'
														className={classes.input}
														InputProps={{
															endAdornment: <InputAdornment position='end'>V</InputAdornment>,
														}}
													/>
												</Grid>
												<Grid item xs={4} className={classes.tableItem}>
													<TextField
														fullWidth
														placeholder='subtension_ocurrencia'
														name='subtension_ocurrencia'
														value={config.subtension_ocurrencia || ''}
														onChange={e => handleChange(e)}
														color='primary'
														className={classes.input}
														InputProps={{
															endAdornment: <InputAdornment position='end'>%</InputAdornment>,
														}}
													/>
												</Grid>
												<Grid item xs={4} className={classes.tableTitle}>
													Tensión promedio
												</Grid>


												<Grid item xs={4} >
													<FormControl fullWidth className={classes.containerSelectTension}  >
														<Select
															labelId='granularityLabel'
															name='tension_promedio_valor'
															value={config.tension_promedio_valor || ''}
															onChange={e => handleChange(e)}
															className={classes.selectTension}
														>
															{[220, 110].map(elem => (
																<MenuItem key={elem} value={elem}>
																	{elem}
																</MenuItem>
															))}
														</Select>
														<p style={{ fontSize: "16px", marginRight: "8px", color: "rgb(0 0 0 / 54%)" }}>V</p>
													</FormControl>
												</Grid>

												<Grid item xs={4} className={classes.tableItem}>
													{/* <TextField
											fullWidth
											placeholder='tension_promedio_ocurrencia'
											name='tension_promedio_ocurrencia'
											value={config.tension_promedio_ocurrencia || ''}
											onChange={e => handleChange(e)}
											color='primary'
											className={classes.input}
											InputProps={{
												endAdornment: <InputAdornment position='end'>%</InputAdornment>,
											}}
										/> */}
												</Grid>
												<Grid item xs={4} className={classes.tableTitle}>
													Desequilibrio
												</Grid>
												<Grid item xs={4} className={classes.tableItem}>
													<TextField
														fullWidth
														placeholder='desequilibrio_valor'
														name='desequilibrio_valor'
														value={config.desequilibrio_valor || ''}
														onChange={e => handleChange(e)}
														color='primary'
														className={classes.input}
														InputProps={{
															endAdornment: <InputAdornment position='end'>%</InputAdornment>,
														}}
													/>
												</Grid>
												<Grid item xs={4} className={classes.tableItem}>
													<TextField
														fullWidth
														placeholder='desequilibrio_ocurrencia'
														name='desequilibrio_ocurrencia'
														value={config.desequilibrio_ocurrencia || ''}
														onChange={e => handleChange(e)}
														color='primary'
														className={classes.input}
														InputProps={{
															endAdornment: <InputAdornment position='end'>%</InputAdornment>,
														}}
													/>
												</Grid>
											</Grid>
											<Box display='flex' justifyContent='center'>
												<Button type='submit' color='primary' variant='contained' className={classes.btn} disabled={getErrors()}>
													Guardar
												</Button>
												<Button variant='contained' onClick={handleCancel} className={classes.btn}>
													Cancelar
												</Button>
											</Box>
										</form>
									</>
								) : null}
							</Grid>
						}
					</Grid>
				}
			</Container>
		</>
	);
}
export default ConfigQuality;
