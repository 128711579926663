import { useEffect } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
//Helpers
import { getFormattedNumber, getUnit, truncateWithEllipsis } from '../../../helpers/common';
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(theme => ({
  container: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  title: {
    color: theme.palette.grey.hairline,
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(1.5, 0, 0.5, 2),
    fontWeight: 600,
    padding: theme.spacing(1, 0, 1, 0),
  },
  subtitle: {
    fontWeight: 400,
  },
  chart: {
    width: '100%',
    flexGrow: 1,
  },
}));

function VerticalBar({ children, name, title, subtitle, data }) {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const chart = am4core.create(name, am4charts.XYChart);

    if (chart.logo) {
      chart.logo.disabled = true;
    }

    let formattedData = [];
    if (name === 'vbar') {
      formattedData = data.map(row => ({
        ...row,
        formattedKwh: `${getFormattedNumber(row.kwh)} ${getUnit(row.kwh)}Wh`,

      }));
    }

    if (name === 'powerDemand') {
      formattedData = data.map(row => ({
        ...row,
        formattedPower: `${getFormattedNumber(row.power_max_month)} ${getUnit(
          row.power_max_month
        )}W`,
      }));
    }

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // Oculto grid
    categoryAxis.renderer.grid.template.disabled = true;


    categoryAxis.renderer.minGridDistance = 5;
    categoryAxis.renderer.labels.template.fill = am4core.color('#9e9e9e');
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.rotation = -30;


    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // Oculto grid
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.title.text = null;

    if (name === 'vbar') {
      categoryAxis.dataFields.category = 'tag';

      // Configuro unidad y multiplicador del eje Y
      valueAxis.renderer.labels.template.adapter.add('text', (text, target) => {
        const value = Number(text) || 0;
        return `${getFormattedNumber(value/1000)}${getUnit(value)}Wh`;
      });
    }
    if (name === 'powerDemand') {
      categoryAxis.dataFields.category = 'name';
      // categoryAxis.renderer.labels.template.disabled = true; 

      categoryAxis.renderer.labels.template.fontSize = 10;

      // Configuro unidad y multiplicador del eje Y
      valueAxis.renderer.labels.template.adapter.add('text', (text, target) => {
        const value = Number(text) || 0;
        return `${getFormattedNumber(value)}${getUnit(value)}W`;
      });
    }

    // Add an adapter to override the labels
    categoryAxis.renderer.labels.template.adapter.add("textOutput", function (text) {
      if (!text) {
        return ''
      }
      return truncateWithEllipsis(text, data?.length > 5 ? 10 : 22); // Truncate labels to a maximum of 10 characters
    });


    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0.8;
    valueAxis.renderer.labels.template.fill = am4core.color('#9e9e9e');
    valueAxis.renderer.labels.template.fontSize = 12;




    const series1 = chart.series.push(new am4charts.ColumnSeries());



    // Add label
    let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
    labelBullet.locationY = 1;
    labelBullet.paddingBottom = 25;
    labelBullet.locationX = 0.5;

    labelBullet.label.hideOversized = false; // Show the complete label even if it exceeds the available space
    // labelBullet.label.dx = 50; // Adjust the value to change the horizontal position
    labelBullet.label.dy = -40; // Adjust the value to change the vertical position
    labelBullet.label.truncate = false; // Prevent truncation of label text

    labelBullet.label.rotation = -90;

    // labelBullet.label.fill = am4core.color("#9e9e9e");
    labelBullet.label.fontSize = 11;



    if (name === 'vbar') {
      series1.dataFields.categoryX = 'tag';
      series1.dataFields.valueY = 'kwh';
      series1.columns.template.tooltipText = '{categoryX}: {formattedKwh}';
      series1.stacked = true;
      labelBullet.label.text = "{formattedKwh}";

    }
    if (name === 'powerDemand') {
      series1.dataFields.categoryX = 'name';
      series1.dataFields.valueY = 'power_max_month';
      series1.columns.template.tooltipText = '{categoryX}: {formattedPower}';
      labelBullet.label.text = "{formattedPower}";

    }


    // Label adapter function to hide label when the value is zero
    labelBullet.label.adapter.add("visible", (visible, target) => {
      const value = target.dataItem?.valueY || 0;
      return value !== 0; // Return true to show the label if the value is not zero
    });

    series1.columns.template.strokeOpacity = 0;

    // Configure column appearance based on color property
    series1.columns.template.adapter.add("fill", (fill, target) => {
      const color = target.dataItem?.dataContext.color;
      return am4core.color(color)
    });


    series1.columns.template.fillOpacity = 0.7;

    chart.data = formattedData;
    chart.paddingTop = 10;
    chart.paddingBottom = 0;
    chart.numberFormatter.numberFormat = '#.##';
    chart.language.locale = am4lang_es_ES;
    return () => {
      chart.dispose();
    };
  }, [data, name]);


  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {title}
        <span className={classes.subtitle}> | {subtitle}</span>
      </Box>
      {children}
      {data.length > 0 && <Box id={name} className={classes.chart} />}
    </Box>
  );
}

export default VerticalBar;
