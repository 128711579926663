import React, { useEffect, useState } from 'react';
//Material UI
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
// Material Icons
import ClearIcon from '@material-ui/icons/Clear';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import TuneIcon from '@material-ui/icons/Tune';
//Componentes
import {
  formatDateObjToSlash,
  formatDateStrDashToSlash,
  getLastDayOfMonthFromStr,
} from '../../helpers/common';
import { getEvents, getQualityCalc } from '../../services/meters';
import TopNav from '../common/TopNav';
import Container from '../Elements/Container';
import Filter from './Filter';
import LineChart from './LineChart';
import SummaryOutages from './SummaryOutages';
import Table from './Table';
import TimelineChart from './TimelineChart';

const PAGEsIZEbACK = 500;
const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  devicesText: {
    marginLeft: theme.spacing(9),
    marginRight: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  container: {
    marginLeft: 30,
    width: 'calc(100vw - 73px - (100vw - 100%))',
  },
  rightPanel: {
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  conf: {
    color: 'red',
  },
  alerts: {
    height: '60px',
    paddingLeft: '4%',
    paddingRight: '2%',
    fontSize: 'large',
    position: 'absolute',
    top: '119px',
    width: 'calc(100% - 55px)',
    zIndex: '2',
    '@media (max-width: 920px)': {
      width: '100%',
    },
  },
}));

export default function Quality() {
  const classes = useStyles();
  // Variables de estado
  const [filterPanel, setFilterPanel] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    dispositivo: {},
    dateFrom: '',
    dateTo: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [qualityData, setQualityData] = useState([]);

  const [errorMessageEvent, setErrorMessageEvent] = useState('');
  const [showErrorAlertEvent, setShowErrorAlertEvent] = useState(false);

  const [eventsData, setEventsData] = useState({});
  // const [severity, setSeverity] = useState('error');

  useEffect(() => {
    const fetchQualityCalc = async url => {
      try {
        const res = await getQualityCalc(url);
        if (res.data.error) {
          setErrorMessage(res.data.error);
          setShowErrorAlert(true);
        } else {
          setQualityData(res.data);
          setShowErrorAlert(false);
        }
      } catch (error) {
        console.error(error);
        if (error.response) {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        }
         else {
          setErrorMessage(`Ocurrió un error inesperado`);
        }
        setShowErrorAlert(true);
      }
    };

    const fetchEvents = async url => {
      try {
        const res = await getEvents(url);
        if (!res.data.page.length) {
          setErrorMessageEvent(
            `La búsqueda realizada no devolvió ningún resultado.`
          );
          setShowErrorAlertEvent(true);
        } else {
          setEventsData(res.data);
          setShowErrorAlertEvent(false);
        }
      } catch (error) {
        // console.error(error);
        if (error.response) {
          // Considero que un 404 implica que no hay eventos por lo tanto éxito
          if (error.response.status === 404) {
            // setSeverity('success');
          } else {
            // setSeverity('error');
          }
          setErrorMessageEvent(
            `${error.response.data.msg}.`
          );
        } else {
          // setSeverity('error');
          setErrorMessageEvent(`Ocurrió un error inesperado`);
        }
        setShowErrorAlertEvent(true);
      }
    };

    // Si tengo un dispositivo seleccionado
    if (filterOptions.dispositivo.id) {
      const { dispositivo, dateFrom, dateTo, representant } = filterOptions;
      let url = `filter=${dispositivo.id}`;
      if (dateFrom === dateTo) {
        url += `&period=${formatDateStrDashToSlash(dateFrom)}`;
      } else {
        url += `&from=${formatDateStrDashToSlash(dateFrom)}`;
        url += `&to=${formatDateStrDashToSlash(dateTo)}`;
      }

      fetchQualityCalc(url);

      let url2 = `filter=${representant}`;
      url2 += `&alarmas=False&conectividad=False&cortes=True`;
      url2 += `&from=${formatDateStrDashToSlash(dateFrom)}`;

      // console.log(dateTo);

      // Tengo una fecha 2022-05-01 y quiero un objeto Date con el último día del mes.
      const lastDay = getLastDayOfMonthFromStr(dateTo);
      // console.log(lastDay);

      // Quiero una fecha con el formato 31/5/2022
      const dateObjSlash = formatDateObjToSlash(lastDay);

      url2 += `&to=${dateObjSlash}`;

      url2 += `&page=1`;
      url2 += `&per_page=${PAGEsIZEbACK}`;

      fetchEvents(url2);
    }
  }, [filterOptions]);

  // Funciones
  const handleSubmit = (e, filter) => {
    e.preventDefault();
    // console.log(filter)

    if (filter !== filterOptions) {
      // Borro los datos solo en caso de que haya habido cambios, así muestro el <CircularProgress/>
      setQualityData([]);

      //Oculto el mensaje de error en caso de estar mostrando uno
      setShowErrorAlert(false);
    }

    //Cierro el panel de Filtros
    setFilterPanel(false);

    setFilterOptions(filter);
  };
  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };
  const handleClosePanel = () => {
    setFilterPanel(false);
  };

  // console.log({ qualityData });
  return (
    <>
      <TopNav titulo="Análisis" subtitulo="| Calidad">
        <Grid container justifyContent="flex-end">
          <Button
            className={classes.btn}
            type="button"
            onClick={togglePanel}
            size="small"
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>
        </Grid>
      </TopNav>
      <Container>
        <Dialog
          open={filterPanel}
          onClose={handleClosePanel}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Filtrar</DialogTitle>
          <DialogContent>
            <Filter
              onSubmit={handleSubmit}
              filterOptions={filterOptions}
              setFilterPanel={setFilterPanel}
            />
          </DialogContent>
        </Dialog>

        {showErrorAlertEvent && (
          <Alert
            // className={classes.alerts}
            severity="error"
          >
            {errorMessageEvent}
          </Alert>
        )}
        {showErrorAlert && (
          <Alert
            // className={classes.alerts}
            severity="error"
          >
            {errorMessage}
          </Alert>
        )}

        {filterOptions.dispositivo.id && !showErrorAlert ? (
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <p className={classes.devicesText}>Dispositivos a graficar:</p>
            <Chip
              className={classes.chip}
              key={filterOptions.dispositivo.id}
              icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
              label={filterOptions.dispositivo.nombre}
              clickable
              color="primary"
              onClick={() => setFilterPanel(true)}
            />
          </Box>
        ) : (
          <Alert severity="info">
            Bienvenido a Calidad. En esta sección podrá consultar la información
            de calidad de energía de sus dispositivos. Para comenzar haga click
            en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>
        )}

        {filterOptions.dispositivo.id &&
          !showErrorAlertEvent &&
          (!eventsData.page ? (
            <div className={classes.circular}>
              <CircularProgress />
            </div>
          ) : (
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={9}>
                <TimelineChart
                  eventsData={eventsData}
                  filterOptions={filterOptions}
                />
              </Grid>
              <SummaryOutages eventsData={eventsData} />
            </Grid>
          ))}

        {filterOptions.dispositivo.id &&
          !showErrorAlert &&
          (!qualityData.conf ? (
            <div className={classes.circular}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                  <LineChart
                    chartName="voltage"
                    qualityData={qualityData}
                    filterOptions={filterOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LineChart
                    chartName="imbalance"
                    qualityData={qualityData}
                    filterOptions={filterOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Table data={qualityData} />
                </Grid>
              </Grid>
            </>
          ))}
      </Container>
    </>
  );
}
