import GoogleMapReact from 'google-map-react';

import { Box, Button, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Alert } from '@mui/material';

import Bubble from './Bubble';

import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  locationsList: {
    position: "absolute",
    top: theme.spacing(9),
    right: theme.spacing(1),
    zIndex: '10',
    height: '220px',
    overflow: 'auto',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #cbc1c157',
    boxShadow: '3px 3px 3px #cbc1c157',
    '@media (max-width: 720px)': {
      display: 'none'
    },
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },

  title: {
    fontSize: theme.spacing(2),
    textAlign: 'center',
  },
  selectedLocation: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },

    '&.MuiListItem-root.Mui-selected p': {
      color: theme.palette.common.white,
    },

  },
}));

function BubbleMap({ data }) {
  const classes = useStyles();

  const [map, setMap] = useState(null);
  const history = useHistory();

  const [zoom, setZoom] = useState(15);
  const [center, setCenter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const options = {
    styles: [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#93c47d',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#447530',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#f4f4f4',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#61afd2',
          },
        ],
      },
    ],
  };
  const handleZoomChange = ({ zoom }) => {
    setZoom(zoom);
  };

  const chargedData = data.filter(location => location.coordinates !== '0,0');

  const handleLocationClick = location => {
    setSelectedLocation(location);
    setCenter({ lat: location.lat, lng: location.lng });
    setZoom(15);
  };

  useEffect(() => {
    // Contador de direcciones cargadas.
    let places = 0;
    if (data.length > 0 && map !== null) {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach(location => {
        if (location.coordinates !== '0,0') {
          // Incrementa el contador de direcciones válidas cargadas.s
          places++;
          bounds.extend(
            new window.google.maps.LatLng(location.lat, location.lng)
          );
        }
      });

      // Establezco los límites del mapa para que incluyan todas las direcciones.
      map.fitBounds(bounds);

      // Si sólo tengo una dirección establezco el zoom manualmente.
      if (places === 1) {
        map.setZoom(13);
      }

    }
  }, [data, map]);
  return (
    <Box style={{ height: '100%' }}>
      {chargedData.length === 0 && (
        <Box>
          <Alert severity="error">
            Ninguna localización tiene cargadas sus coordenadas.
            <Button
              color="primary"
              onClick={() => history.push(`/configuracion-organizacion`)}
            >
              Actualizar
            </Button>
          </Alert>
        </Box>
      )}
      {chargedData.length > 0 && chargedData.length < data.length && (
        <Box>
          <Alert severity="warning">
            Una o más localizaciones no tienen cargadas sus coordenadas.
            <Button
              color="primary"
              onClick={() => history.push(`/configuracion-organizacion`)}
            >
              Actualizar
            </Button>
          </Alert>
        </Box>
      )}
      {chargedData.length > 0 && (
        // <Box style={{ height: unchargedData ? "185.5px" : "250px" }}>
        <Box
          style={{
            height:
              chargedData.length < data.length ? 'calc(100% - 64.5px)' : '100%',
          }}
        >
          <Box className={classes.locationsList}>
            <Box className={classes.header} px={1} py={0.5}>
              <Box className={classes.title}>Localizaciones ({chargedData.length})</Box>
            </Box>
            <List dense>
              {data.map(location => {
                if (location.coordinates === '0,0') return null;

                return (
                  <ListItem
                    button
                    key={location.id}
                    onClick={() => handleLocationClick(location)}
                    selected={selectedLocation && selectedLocation.id === location.id}
                    className={classes.selectedLocation}
                  >
                    <ListItemText primary={location.name} />
                  </ListItem>
                );
              })}
            </List>
          </Box>


          <GoogleMapReact
            defaultCenter={{ lat: 0, lng: 0 }}
            center={center}
            zoom={zoom}
            onChange={handleZoomChange}
            options={options}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => setMap(map)}
          >
            {data.map(location => {
              if (location.coordinates === '0,0') return null;

              return (
                <Bubble
                  key={location.id}
                  name={location.name}
                  zoom={zoom}
                  setZoom={setZoom}
                  lat={location.lat}
                  lng={location.lng}
                  setCenter={setCenter}
                  surface={location.surface}
                />
              );
            })}
          </GoogleMapReact>
        </Box>
      )}
    </Box>
  );
}

export default BubbleMap;
