import { useEffect, useState } from 'react';
// Material UI
import {
  Box, Button, Chip, CircularProgress,
  Dialog, IconButton, DialogTitle,
  List, FormControl, Grid, InputLabel,
  makeStyles, MenuItem, Select, Step, StepLabel, Stepper, Tooltip

} from '@material-ui/core';
//Material Icons
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import DoneIcon from '@material-ui/icons/Done';
//Style
import clsx from 'clsx';
//Helpers
import { getNodesTree } from '../../services/hierarchy';
import { getTables } from '../../services/meters';
//Componentes
import Node from './Node'
//Servicios
import { getStatusNodes } from '../../services/meters';

let scrollsSettings = {
  overflow: "scroll",
  height: "50vh",
  overflowX: "hidden",
}
let webkitScrollbar = {
  background: "white",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#beb1b18f",
  color: "red",
  width: "9px"
}
let webkitscrollbarTrack = {
  background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4),

    '& .MuiGrid-root': {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
    },
  },
  containerDevices: {
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    "@media (max-width: 1100px)": {
      width: "100%",
    },
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
  addBtn: {
    margin: theme.spacing(1, 0),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  dialogTitle: {
    "@media (max-width: 700px)": {
      textAlign: "center",
    }
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  container: {
    padding: 0,
  },
  selectedItemsContainer: {
    height: '35px',
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: "12px",
    '@media (max-width: 700px)': {
      marginLeft: "0"
    },
  },
  filterLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },

  switchLabel: {
    fontSize: '0.75rem',
  },
  rowSpace: {
    marginBottom: theme.spacing(2),
  },
  btnSpacing: {
    margin: theme.spacing(0, 1),
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },

  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  containerStepTwo: {
    marginTop: theme.spacing(2),
  },
}));

function Filter({ onSubmit, filterOptions, setFilterPanel }) {
  const classes = useStyles();
  const [selectedNode, setSelectedNode] = useState({});
  const [showDevices, setShowDevices] = useState(false);
  // En nodes cargo el árbol de nodos del cliente
  const [nodes, setNodes] = useState([]);
  const [loadingNodes, setLoadingNodes] = useState(false);
  const [filter, setFilter] = useState(filterOptions);
  const { dispositivo, dateFrom, dateTo } = filter;
  const [tables, setTables] = useState([]);
  //Steeps options
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Seleccione dispositivo.',
    'Seleccione las fechas a consultar',
    'Presione el boton CONSULTAR.',
  ];

  const [statusNodes, setStatusNodes] = useState([]);

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      setStatusNodes(arry)
    }
    getStatusNodesAux()
  }, [])

  useEffect(() => {
    fetchNodesTreeData();

  }, []);

  useEffect(() => {
    setFilter(filterOptions);
  }, [filterOptions]);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const res = await getTables(dispositivo.id);
        setTables(res.data);
        setFilter(filter => ({ ...filter, representant: res.data.representant }))
      } catch (error) {
        console.error(error);
      }
    };
    if (dispositivo.id) {
      fetchTables();
    }
  }, [dispositivo.id]);

  const fetchNodesTreeData = async () => {
    try {
      setLoadingNodes(true);
      const res = await getNodesTree();
      setNodes(res.data);
      setLoadingNodes(false);
    } catch (error) {
      setLoadingNodes(false);
      console.error(error);
    }
  };

  // Funciones
  const handleDateChange = e => {
    setActiveStep(2)
    // Si cambio la fecha inicial en principio pongo que la fecha final sea la misma.
    if (e.target.name === 'dateFrom') {
      setFilter({ ...filter, dateFrom: e.target.value, dateTo: e.target.value });
    }
    // Si cambio la fecha final sólo cambio a ella
    else {
      setFilter({ ...filter, dateTo: e.target.value });
    }
  };

  const handleCancelFilterDialog = () => {
    setFilterPanel(false);
  };

  const handleSelectedNode = node => {
    // Si el nodo elegido es el mismo que el actualmente seleccionado lo deselecciono
    setActiveStep(1)
    if (node === selectedNode) setSelectedNode({});
    else setSelectedNode(node);
  };

  const handleCloseDevices = () => {
    setShowDevices(false);
  };

  const handleDeleteNodeSelection = () => {
    setSelectedNode({});
  };

  // Submit en Dialog de dispositivos
  const handleDeviceAdded = () => {
    setFilter({ ...filter, dispositivo: selectedNode });
    //Limpio la selección realizada
    setSelectedNode({});
    setShowDevices(false);
  };

  const nodeList = () => {
    return nodes.map(node => (
      <Node node={node} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} key={node.id} statusNodes={statusNodes} />
    ));
  };

  // Click en óvalo azul dentro del panel de filtrar
  const handleClickedSelectionItem = () => {
    // Mostrar lista de dispositivos
    setShowDevices(true);
    // Establezco el nodo seleccionado
    setSelectedNode(dispositivo);
  };

  // Click en la cruz del óvalo azul del panel de filtrar
  const handleDeleteSelectionItem = () => {
    //Actualizo filter para que el submit en Events tenga el valor actualizado si borré algo de la lista
    setFilter({ ...filter, dispositivo: {} });
  };

  return (
    <>
      <Stepper activeStep={activeStep} className={classes.steppersFilter} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form className={classes.root} onSubmit={e => onSubmit(e, filter)}>
        {
          loadingNodes ?
            <div className={classes.circular}>
              <CircularProgress />
            </div>
            :
            !nodes.length && <div className={classes.devicesLabel}>No se encontraron medidores asignados a su cuenta</div>
        }
        {nodes.length !== 0 &&
          <>
            {/* SPET 1      */}
            <Box display={'flex'} alignItems={'center'} >
              <Tooltip
                title='Seleccione un dispositivo.'
                arrow
                placement='right'
              >
                <Box className={clsx(classes.hint, { [classes.tick]: activeStep >= 1 })}  >
                  {activeStep >= 1 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
                </Box>
              </Tooltip>
              <Box className={classes.devicesLabel} mr={2}>Agregar dispositivos</Box>
              <IconButton className={classes.addBtn} aria-label='delete' onClick={() => setShowDevices(true)}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>


            {dispositivo.id && (
              <Chip
                icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                label={dispositivo.nombre}
                clickable
                color='primary'
                onDelete={handleDeleteSelectionItem}
                className={classes.filterChip}
                onClick={handleClickedSelectionItem}
              />
            )}
            {tables.periods ? (
              <>
                {/* SPET 2      */}
                <Box className={classes.containerStepTwo} display={'flex'} alignItems={'center'} >
                  <Tooltip
                    title='Seleccione un dispositivo.'
                    arrow
                    placement='right'
                  >
                    <Box className={clsx(classes.hint, { [classes.tick]: activeStep >= 2 })}  >
                      {activeStep >= 2 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                    </Box>
                  </Tooltip>
                  <Box className={classes.filterLabel}>Seleccionar fecha:</Box>
                </Box>

                <Grid container className={classes.container}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id='dateFromLabel'>Período Inicial</InputLabel>
                      <Select labelId='dateFromLabel' name='dateFrom' value={dateFrom} onChange={handleDateChange}>
                        <MenuItem value='' />
                        {tables.periods.map(period => (
                          <MenuItem key={period} value={period}>
                            {period}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id='dateToLabel'>Período Final</InputLabel>
                      <Select labelId='dateToLabel' name='dateTo' value={dateTo} onChange={handleDateChange}>
                        <MenuItem value='' />
                        {tables.periods.map(period => (
                          <MenuItem key={period} value={period}>
                            {period}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : null}

            <Box display='flex' justifyContent='center' mt={2} >
              <Button
                variant='contained'
                color='primary'
                type='submit'
                className={classes.btnSpacing}
                disabled={!dispositivo.id || !dateTo || !dateFrom}
              >
                Consultar
              </Button>

              <Button variant='contained' onClick={handleCancelFilterDialog} className={classes.btnSpacing}>
                Cancelar
              </Button>
            </Box>
          </>
        }

      </form >

      <Dialog open={showDevices} onClose={handleCloseDevices} aria-labelledby='form-dialog-title' fullWidth>
        <Box padding="8px" >
          <DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
            Selección de Dispositivo
          </DialogTitle>
          <div className={classes.dialogSubtitle}>
            Seleccionar un elemento de la organización y de manera opcional una etiqueta.
          </div>
          <Box display='flex' className={classes.selectedItemsContainer}>
            {/* Quiero que si "elimino" el ovalo en la seleccion de dispositivos, lo oculte */}
            {selectedNode.nombre && dispositivo.nombre ? (
              <Chip
                icon={<AccountTreeIcon />}
                label={dispositivo.nombre}
                clickable
                color='primary'
                onDelete={handleDeleteNodeSelection}
              />
            ) : null}
          </Box>
          <Box>
            <Box component='label' display='block' className={classes.label}>
              Organización
            </Box>

            {loadingNodes ? (
              <div className={classes.circular}>
                <CircularProgress />
              </div>
            ) : (
              <Box className={classes.containerDevices}>
                <List component='div' disablePadding dense className={classes.scrollableContainer}>
                  {nodeList()}
                </List>
              </Box>

            )}
          </Box>

          <Box display='flex' justifyContent='center' className={classes.containerButtons} >
            <Button
              className={classes.devicesBtn}
              variant='contained'
              color='primary'
              onClick={handleDeviceAdded}
              disabled={selectedNode.nombre ? false : true}
            >
              Agregar
            </Button>
            <Button variant='contained' onClick={handleCloseDevices} className={classes.devicesBtn}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default Filter;
