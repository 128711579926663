import { useState } from 'react';
import {
    Box, makeStyles, Dialog, DialogTitle, DialogActions, DialogContent, Button
} from '@material-ui/core';
//Helpers
import { blueBar } from '../../helpers/common'
import { OPTIONS_DEVICES } from './helpers'
//Componentes
import ButtonComponent from "./ButtonComponent";
// //servicios 
import { putControlSetting } from '../../services/meters'

const useStyles = makeStyles(theme => ({
    root: {
        border: "0.5px solid #B6B1B1",
        borderRadius: "4px",
        padding: theme.spacing(1),
        width: "100%",
    },
    title: {
        fontSize: theme.spacing(1.9),
        fontWeight: '600',
        color: theme.palette.primary.main
    },
    subTitle: {
        color: theme.palette.grey.hairline,
        fontWeight: '400'
    }

}));

export default function DeleteControl({
    setLoading, setAlertState, options
}) {
    const classes = useStyles();
    const blueLine = blueBar()
    const buttonData = ["Reset"]
    const [confirmOpen, setConfirmOpen] = useState({
        modalState: false,
        action: ""
    });
    const [textAlert, seTextAlert] = useState({
        title: 'resetear su'
    });

    const HandleSubmit = async () => {
        setConfirmOpen({
            ...confirmOpen,
            modalState: false
        })
        setLoading(true)
        let auxKey = ""
        let msg = "Ocurrio un error inesperado"
        let type = "error"
        if (confirmOpen.action === 'Reset') {
            auxKey = "RESET"
        }
        if (confirmOpen.action === 'Borrar Datos') {
            auxKey = "RESET"
        }
        try {
            let auxBody = {
                key: auxKey
            }
            await putControlSetting(options?.id, auxBody)
            msg = "Exitoso"
            type = "success"
        }
        catch (error) {
            if (error?.response?.data) {
                msg = error?.response?.data
            }
        }
        setAlertState({
            close: true,
            msg: msg,
            type: type
        })
        setLoading(false)
    }

    const handleButtonFirstAction = element => {
        if (element === buttonData[1]) {
            seTextAlert({
                title: 'borrar los datos de su',
                text: `Tenga en cuenta que esta acción puede permitir la perdida de información en su dispositivo`
            })
        }
        else {
            seTextAlert({
                title: 'resetear su',
                text: 'Tenga en cuenta que esta accion volverá a cero los contadores de energia de su dispositivo fisico'
            })
        }
        setConfirmOpen({
            modalState: true,
            action: element
        });
    };

    return (
        <Box style={options.plan === OPTIONS_DEVICES.HOME ? { height: "254px", display: "inline-grid" } : {}} className={classes.root}>

            <Dialog open={confirmOpen.modalState}
                onClose={() => {
                    setConfirmOpen({
                        ...confirmOpen,
                        modalState: false
                    })
                }}
                aria-labelledby='form-dialog-title'>

                <DialogTitle id='form-dialog-title'>{`¿Está  seguro que desea ${textAlert.title} dispositivo?`}</DialogTitle>

                <DialogContent>
                    {textAlert.text}
                </DialogContent>

                <DialogActions>
                    <Button color='default'
                        onClick={() => {
                            setConfirmOpen({
                                ...confirmOpen,
                                modalState: false
                            })
                        }}>
                        Cancelar
                    </Button>
                    <Button type='submit' color='secondary' variant='outlined' onClick={HandleSubmit}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* titulo */}
            <Box ml={1} mt={2} mb={2} className={classes.title} >
                {"Control"}
                <span className={classes.subTitle} >
                    {` | ${"eliminar datos"}`}
                </span>
            </Box>

            {blueLine}

            {/* Botones invertir fase */}
            <Box display='flex' justifyContent='center' flexDirection={"column"} alignItems={"center"}>
                {
                    buttonData.map((element, indx) => {
                        return (
                            < ButtonComponent
                                key={indx}
                                children={
                                    <>
                                        <Box ml={1} style={{ fontWeight: "bold" }}> {element}</Box>
                                    </>
                                }
                                HandleSubmit={() => { handleButtonFirstAction(element) }}
                                backgroundColorButton={"secondary"}
                            />

                        )
                    })
                }
            </Box>
        </Box>
    )
}






