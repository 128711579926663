import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline';

import { makeStyles } from '@material-ui/core';
import { getStatusColor } from '../../helpers/colors';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { toFormattedNumber } from '../../helpers/common';

const useStyles = makeStyles(theme => ({
	chart: {
		// height: '200px',
		// marginLeft: theme.spacing(8),
		// width: `calc(100% - ${theme.spacing(8)}px)`,
	},
}));

export default function TimelineChart({ data }) {
	const classes = useStyles();
	useEffect(() => {
		let chart = am4core.create('timelineChart', am4plugins_timeline.CurveChart);
		// SACAMOS LOGO DE AMCHARD
		if (chart.logo) {
			chart.logo.disabled = true
		}
		// chart.curveContainer.padding(20, 20, 20, 20);
		chart.orientation = 'horizontal';
		chart.fontSize = 11;
		chart.language.locale = am4lang_es_ES;

		chart.marginTop = 0;
		chart.marginBottom = 50;
		chart.marginLeft = 0;
		chart.marginRight = 0;
		chart.paddingTop = 0;
		chart.paddingBottom = 10;
		chart.paddingLeft = 35;
		chart.paddingRight = 0;
		// const title = chart.titles.create();
		// title.text = 'Conciliación por Localización';
		// let colorSet = new am4core.ColorSet();
		// colorSet.saturation = 0.6;
		const auxLinearArray = [];

		data.forEach(location => {
			const { bills } = location;
			bills.forEach(bill => {
				const { from, to, conciliation } = bill;
				auxLinearArray.push({
					location: location.location,
					from,
					to,
					color: getStatusColor(bill.conciliation.status),
					total: toFormattedNumber(conciliation.total_calc, '$')
				});
			});
		});

		chart.data = auxLinearArray;
		// Formateo de la fecha
		chart.dateFormatter.dateFormat = 'yyyy-MM-dd';
		chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
		// Configuración categoría
		let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		// Variable que almacena nombre categoría
		categoryAxis.dataFields.category = 'location';
		// categoryAxis.renderer.grid.template.disabled = false;
		categoryAxis.renderer.labels.template.paddingRight = 25;
		// Separar los datos del eje X del contenido del gráfico
		categoryAxis.renderer.labels.template.paddingTop = 4;
		categoryAxis.renderer.minGridDistance = 10;
		categoryAxis.renderer.innerRadius = 0;
		categoryAxis.renderer.radius = 100;
		categoryAxis.renderer.grid.template.location = 1;
		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.renderer.minGridDistance = 30;
		dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };
		dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';

		dateAxis.renderer.tooltipLocation = 20;
		dateAxis.renderer.line.strokeDasharray = '1,4';
		dateAxis.renderer.labels.template.rotation = 0;
		//Opacidad de línea entre fechas
		dateAxis.renderer.line.strokeOpacity = 1;
		dateAxis.tooltip.background.fillOpacity = 0.2;
		dateAxis.tooltip.background.cornerRadius = 5;
		dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
		//Desplazamiento de tooltip 20px hacia abajo
		dateAxis.tooltip.dy = 20;
		//Puntero del tooltip hacia arriba
		dateAxis.tooltip.pointerOrientation = 'up';
		// TODO Implementar función que detecte mínimos y máximos de las localizaciones disponibles.
		dateAxis.min = new Date(new Date().getFullYear(), 0, 1).getTime();
		dateAxis.max = new Date(new Date().getFullYear(), 11, 31).getTime();

		//Config dateAxis
		let labelTemplate = dateAxis.renderer.labels.template;
		labelTemplate.verticalCenter = 'middle';
		labelTemplate.fillOpacity = 0.5;
		labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor('background');
		labelTemplate.background.fillOpacity = 1;
		labelTemplate.padding(7, 7, 7, 7);
		labelTemplate.horizontalCenter = 'right'
		//Config categoryAxis
		let categoryAxisLabelTemplate = categoryAxis.renderer.labels.template;
		// console.log(categoryAxisLabelTemplate)
		categoryAxisLabelTemplate.fontSize = 13;
		categoryAxisLabelTemplate.horizontalCenter = 'center';
		// categoryAxis.renderer.labels.template.location = 1
		categoryAxisLabelTemplate.location = 0.5

		let series1 = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
		series1.columns.template.height = am4core.percent(8);
		series1.columns.template.paddingTop = 10

		series1.columns.template.tooltipText = '[bold]{location}[/]\nDesde: {openDateX.formatDate("dd/MM/YYYY")}\nHasta {dateX.formatDate("dd/MM/YYYY")}\nTotal: {total}';

		series1.dataFields.openDateX = 'from';
		series1.dataFields.dateX = 'to';
		series1.dataFields.categoryY = 'location';

		series1.columns.template.propertyFields.fill = 'color';
		series1.columns.template.propertyFields.stroke = 'color';

		let bullet = new am4charts.CircleBullet();
		series1.bullets.push(bullet);
		bullet.circle.radius = 6;
		bullet.circle.strokeOpacity = 0;
		bullet.propertyFields.fill = 'color';
		bullet.locationX = 0;
		bullet.paddingTop = 10

		let bullet2 = new am4charts.CircleBullet();
		series1.bullets.push(bullet2);
		bullet2.circle.radius = 6;
		bullet2.circle.strokeOpacity = 0;
		bullet2.propertyFields.fill = 'color';
		bullet2.locationX = 1;
		bullet2.paddingTop = 10

		chart.scrollbarX = new am4core.Scrollbar();
		chart.scrollbarX.align = 'center';
		chart.scrollbarX.width = am4core.percent(90);
		// chart.scrollbarX.background.fill = am4core.color('#2586bc');
		chart.scrollbarX.background.fillOpacity = 0.5;

		chart.scrollbarX.background.fill = am4core.color('#2586bc');
		chart.scrollbarX.thumb.background.fill = am4core.color('#2586bc');
		chart.scrollbarX.startGrip.background.fill = am4core.color('#61afd2');
		chart.scrollbarX.endGrip.background.fill = am4core.color('#61afd2');
		// chart.scrollbarX.stroke = am4core.color('#2586bc');

		// Configuro el botón de zoom out
		chart.zoomOutButton.background.fill = am4core.color('#2586bc');
		chart.zoomOutButton.background.states.getKey('hover').properties.fill = am4core.color('#61afd2');
		chart.zoomOutButton.background.states.getKey('down').properties.fill = am4core.color('#c7566a');

		let cursor = new am4plugins_timeline.CurveCursor();
		chart.cursor = cursor;
		cursor.xAxis = dateAxis;
		cursor.yAxis = categoryAxis;
		cursor.lineY.disabled = true;
		cursor.lineX.strokeDasharray = '1,4';
		cursor.lineX.strokeOpacity = 1;

		dateAxis.renderer.tooltipLocation = 200;
		categoryAxis.cursorTooltipEnabled = false;

		return () => {
			chart.dispose();
		};
	}, [data]);
	return (
		<>
			<div id='timelineChart' className={classes.chart} />
		</>
	);
}
