import { useEffect, useState } from 'react';
//Material ui
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useHistory } from 'react-router-dom';

import { getExtraInfo } from '../../../services/hierarchy';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  locationImage: {
    flex: 1,
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    maxHeight: '100%',
  },
}));

export default function ContainerImg({ location }) {
  const DEFAULTiMAGE = './images/dashboard/img-default-localizacion.png';

  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState('');




  useEffect(() => {
    const fetchExtraInfo = async () => {
      try {
        // Para testear error de fetching
        //   throw new Error('error')
        setLoading(true);
        setError('');
        const res = await getExtraInfo(location?.id);
        setImageUrl(res.data?.image);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        let msg = 'Ocurrió un error al obtener la imagen de la localización.';
        if (error?.response?.data?.msg) {
          msg = error?.response?.data?.msg;
        }
        setError({ msg, type: 'error' });
      }
    };
    fetchExtraInfo();
  }, [location]);

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {!loading && error !== '' && imageUrl === null && <Alert severity="error">{error.msg}</Alert>}

      {!loading && error === '' && imageUrl === null && (
        <Box className={classes.container}>
          <Box>
            <Alert severity="warning">
              Se muestra la imagen por defecto.
              <Button
                color="primary"
                onClick={() => history.push(`/configuracion-organizacion`)}
              >
                Actualizar
              </Button>
            </Alert>
          </Box>

          <img
            className={classes.locationImage}
            src={DEFAULTiMAGE}
            alt="Imagen por defecto"
          />
        </Box>
      )}

      {!loading && error === '' && imageUrl !== null && (
        <Box className={classes.container}>
          <img
            src={`${imageUrl}`}
            className={classes.locationImage}
            alt={`Localización ${location?.nombre}`}
          />
        </Box>
      )}
    </>
  );
}
