import React, { useEffect, useState, useContext } from 'react';
import UserContext from '../../context/UserContext';
// Material UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
// Componentes
import TopNav from '../common/TopNav';
// Endpoints
import { getPlanInfo, getUserConfig, putUserConfig } from '../../services/client';
// Static Data
import { timezones } from '../../helpers/TimezonesData';
import Container from '../Elements/Container';
import AlertComponent from '../Elements/AlertComponent';
// services
import { deleteAccount } from '../../services/client';
import { saveUserInfoInLocalStorage } from '../../services/auth';
import PasswordChange from './PasswordChange';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "40px",
    color: theme.palette.primary.main,
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  btnSpacing: {
    margin: theme.spacing(0, 1),
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
  },
  btnDeleteAccount: {
    margin: "20px 40px"
  }
}));
function UserConfig({ handleLogout }) {
  const classes = useStyles();
  const { plan } = useContext(UserContext)
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [demo, setDemo] = useState(true)
  const [deletePanel, setDeletePanel] = useState(false);
  const [stringVerification, setStingVerification] = useState(true)
  //Estados alerts
  const [close, setClose] = useState(false)
  const [msg, setMsg] = useState({
    msg: "",
    type: ""
  })
  const [openPasswordChange, setOpenPasswordChange] = useState(false)


  useEffect(() => {
    if (plan?.info?.plan !== "Demo") {
      setDemo(false)
    } else {
      setDemo(true)
    }
  }, [plan])

  useEffect(() => {
    const fetchUserConfig = async () => {
      try {
        setLoading(true);
        const res = await getUserConfig();
        setConfig(res.data);
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchUserConfig();
  }, []);

  const handleDeletePanel = () => {
    setClose(true);
  };
  const handleChange = e => {
    // console.log('NodeInfo handleChange');
    setConfig({ ...config, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      //Actualizo los valores ingresados
      await putUserConfig(config);
      setLoading(false);
      // Actualizo el plan del usuario
      const res2 = await getPlanInfo()
      saveUserInfoInLocalStorage(res2.data)
      setClose(true)
      setMsg({
        msg: 'Se guardó correctamente la configuración.',
        type: "success"
      })
    }
    catch (error) {
      console.error(error);
      let msg = "Ocurrio un error inesperado."
      if (error?.response?.data?.msg) {
        msg = error?.response?.data?.msg
      }
      setClose(true)
      setMsg({
        msg: msg,
        type: 'error'
      })
    }
  };

  const handleDeletChange = (e) => {
    if (e.target.value === "Eliminar cuenta") {
      setStingVerification(false)
    } else {
      setStingVerification(true)
    }
  }

  const handleDeletSubmit = async () => {
    try {
      await deleteAccount();
      handleLogout()
    }
    catch (error) {
      setClose(true)
      setMsg({
        msg: error.response.data.msg,
        type: "error"
      })
    }
  }
  const getErrors = () => {
    let errors = false;
    if (config.email === '') {
      errors = true;
    }
    return errors;
  };

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleOpenPasswordChange = () => {
    setOpenPasswordChange(!openPasswordChange)
  }


  return (
    <>
      <TopNav titulo='Preferencias' subtitulo='| '></TopNav>

      {/* ALERTAS */}
      <AlertComponent
        severity={msg.type}
        msg={msg.msg}
        setClose={setClose}
        close={close}
      />


      {/* <Snackbar open={notificationSuccess} autoHideDuration={2000} onClose={() => setNotificationSuccess(false)}>

        <Alert severity='success' onClose={() => setNotificationSuccess(false)}>
          Se guardó correctamente la configuración
        </Alert>

      </Snackbar> */}


      {/* PANEL CAMBIO DE CONTRASEÑA */}
      <Dialog
        open={openPasswordChange}
        onClose={handleOpenPasswordChange}
        aria-labelledby="form-dialog-title"
        // maxWidth="lg"
        fullWidth
        disableScrollLock
      >
        <DialogTitle id="form-dialog-title">
          Cambiar contraseña.
        </DialogTitle>
        <DialogContent>
          <PasswordChange
            handleOpenPasswordChange={handleOpenPasswordChange}
            setMsg={setMsg}
            setClose={setClose}
          />
        </DialogContent>
      </Dialog>

      {/* CONTAINER */}
      <Container>
        {plan?.info?.plan !== "Demo" &&
          <Box width={"100%"} display="flex" justifyContent={"flex-end"}>

            <Button
              color='secondary'
              variant='contained'

              onClick={() => {
                setDeletePanel(true)
              }}
              className={classes.btnDeleteAccount}
            >
              Eliminar Cuenta
            </Button>
          </Box>
        }

        {/* Panel Delete cuenta */}
        <Dialog open={deletePanel} onClose={handleDeletePanel} aria-labelledby='form-dialog-title' fullWidth>
          <DialogTitle id='form-dialog-title'>Eliminar</DialogTitle>
          <DialogContent>

            <Grid className={classes.label}>
              *El proceso de eliminación es irreversible y  representará la perdida total de la información asociada a la cuenta, incluyendo las mediciones de sus medidores.
              <br></br>
              *En el caso de eliminar su cuenta únicamente se podrá crear una cuenta con el mismo correo electrónico pasado un periodo de 15 días luego de la eliminación.
            </Grid>

            <p>Por favor para eliminar su cuenta escriba "Eliminar cuenta"</p>

            {/* INPUT VERIFICACION */}
            <input
              onChange={handleDeletChange}
            />
            <Box display='flex' justifyContent='center' marginTop={"20px"}>
              <Button
                disabled={stringVerification}
                onClick={handleDeletSubmit}
                variant='outlined' color='secondary' className={classes.btnSpacing}>
                Eliminar Cuenta
              </Button>
              <Button
                onClick={() => {
                  setDeletePanel(false)
                  setStingVerification(true)
                }}
                variant='contained' className={classes.btnSpacing}>
                Cancelar
              </Button>
            </Box>
          </DialogContent>
        </Dialog>


        {loading ? (
          <div className={classes.circular}>
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid display='flex' justifyContent='center' className={classes.root} container>

              {Object.keys(config)
                .filter(key => key !== 'timezone')
                .map(key => {
                  return (
                    <Grid item xs={8} key={key}>
                      <TextField
                        disabled={key === 'email' ? true : demo}
                        className={classes.input}
                        placeholder={capitalizeFirstLetter(key)}
                        label={capitalizeFirstLetter(key)}
                        name={key}
                        value={config[key]}
                        onChange={handleChange}
                        fullWidth
                        color='primary'
                      />
                    </Grid>
                  );
                })}

              <Grid item xs={8}>
                <FormControl fullWidth>
                  <InputLabel htmlFor='timezone'>Timezone</InputLabel>

                  <Select disabled={demo} labelId='timezone' name='timezone' value={config.timezone || ''} onChange={handleChange}>
                    <MenuItem value='' />
                    {Object.keys(timezones).map(key => {
                      return (
                        <MenuItem key={key} value={timezones[key]}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <Box mt={2} >
                  <Button variant="outlined" size="small" onClick={handleOpenPasswordChange}>
                    Cambiar contraseña
                  </Button>
                </Box>
              </Grid>

            </Grid>

            <Box display='flex' justifyContent='center' marginTop="30px">
              {plan?.info?.plan !== "Demo" &&
                <Button type='submit' color='primary' variant='contained' disabled={getErrors()}>
                  Guardar
                </Button>
              }
            </Box>
          </form>
        )}
      </Container >
    </>
  );
}
export default UserConfig;
