import instance from './testing.instance';

//trae los tipo de tarifa y sus respectivos id
export const getRates = () => instance.get(`/billing/rates/types/`);


//trae tarifas personalizadas en el caso de q tenga tarifas en el caso q no me devuelve un array vacio
export const getRatesFromHierarchy = (herarchyId) => instance.get(`billing/rates/${herarchyId}/privaterate/`);

//Crea una trifa 
export const createRate = (body) => instance.post(`billing/rates/`, body);

//Edita una tarifa
export const editRate = (rateId, body) => instance.patch(`billing/rates/${rateId}/`, body);

//Elimina una tarifa
export const deleteRate = (rateId) => instance.delete(`/billing/rates/${rateId}/`);

//Crea una franja horaria de ENERGIA 
export const createTimeZoneEnergy = (body) => instance.post(`/billing/rates/energy/`, body);

//Edit una franja horaria de ENERGIA 
export const editTimeZoneEnergy = (IDFranjaEnergia, body) => instance.patch(`/billing/rates/energy/${IDFranjaEnergia}/`, body);

//Borra una franja horaria de ENERGIA 
export const deleteTimeZoneEnergy = (IDFranjaEnergia) => instance.delete(`/billing/rates/energy/${IDFranjaEnergia}/`);

//Crea una franja horaria de POTENCIA 
export const createTimeZonePower = (body) => instance.post(`/billing/rates/power/`, body);

//Edita una franja horaria de POTENCIA 
export const editTimeZonePower = (IDFranjaPotencia, body) => instance.patch(`/billing/rates/power/${IDFranjaPotencia}/`, body);

//Borra una franja horaria de POTENCIA 
export const deleteTimeZonePower = (IDFranjaPotencia) => instance.delete(`/billing/rates/power/${IDFranjaPotencia}/`);









