import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
	devicesList: {
		margin: theme.spacing(1),
	},
	devicesTitle: {
		fontSize: '13px',
		color: theme.palette.grey.main,
		backgroundColor: theme.palette.grey.dark,
		padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
		borderRadius: theme.spacing(1),
		textAlign: 'center',
		margin: theme.spacing(1),
	},
	metersList: {
		background: theme.palette.background.default,
		padding: `${theme.spacing(0.5)}px ${theme.spacing(5)}px`,
		height: theme.spacing(10),
		overflowY: 'scroll',
	},
	meter: {
		borderBottom: `1px dashed ${theme.palette.grey.hairline}`,
		textAlign: 'center',
		fontSize: '15px',
		color: theme.palette.text.primary,
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
		'&:hover': {
			color: '#fff',
			background: theme.palette.primary.main,
		},
	},
	selectedMeter: {
		fontSize: '15px',
		textAlign: 'center',
		background: theme.palette.primary.main,
		color: '#fff',
	},
	active: {
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
	},
	flechas: {
		padding: 0,
		margin: 'auto',
	},
}));

export default function DevicesList({ savedMeter, savedMeterName, selectedMeter, selectedMeterName, unassignedMeters, setSelectedMeter, setSelectedMeterName }) {
	const classes = useStyles();
	const [freeMeterSelected, setFreeMeterSelected] = useState({ id: 0, name: "" });



	const handleSelectedMeter = (meterId, meterName) => {
		setSelectedMeter(meterId);
		setSelectedMeterName(meterName);
	};

	const toggleSelected = (meter, meterName) => {
		if (freeMeterSelected.id !== meter) {
			setFreeMeterSelected({ id: meter, name: meterName });
		} else {
			setFreeMeterSelected({ id: 0, name: "" });
		}
	};
	// console.log('selectedMeter', selectedMeter);
	// console.log('unassignedMeters', unassignedMeters);
	// console.log('savedMeter', savedMeter);
	return (
		<>
			<Grid container spacing={1} justifyContent='center' alignItems='center' className={classes.DevicesList}>
				<Grid item xs={4} className={classes.devicesTitle}>
					Dispositivos libres:
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={4} className={classes.devicesTitle}>
					Dispositivo asignado:
				</Grid>
			</Grid>
			<Grid container spacing={1} justifyContent='center' alignItems='flex-start' className={classes.metersRow}>
				<Grid item xs={4} className={classes.metersList}>
					{savedMeter && selectedMeter !== savedMeter ? (
						<div
							className={`${classes.meter} ${freeMeterSelected.id === savedMeter ? classes.active : null}`}
							onClick={() => toggleSelected(savedMeter, savedMeterName)}
						>
							{savedMeterName}
						</div>
					) : null}

					{unassignedMeters
						? unassignedMeters.map(meter => {
							return selectedMeter !== meter.id ? (
								<div
									className={`${classes.meter} ${freeMeterSelected.id === meter.id ? classes.active : null}`}
									key={meter.id}
									onClick={() => toggleSelected(meter.id, meter.nombre)}
								>
									{meter.nombre}
								</div>
							) : null;
						})
						: null}
				</Grid>
				<Grid item xs={1}>
					<Box display='flex' alignItems='flex-start' flexDirection='column'>
						<IconButton
							aria-label='seleccionar'
							className={classes.flechas}
							onClick={() => {
								setFreeMeterSelected({ id: 0, name: "" });
								handleSelectedMeter(freeMeterSelected.id, freeMeterSelected.name);
							}}
							disabled={Boolean(selectedMeter) || !Boolean(freeMeterSelected.id)}
						>
							<ChevronRightIcon fontSize='inherit' />
						</IconButton>
						<IconButton
							aria-label='deseleccionar'
							className={classes.flechas}
							onClick={() => handleSelectedMeter(0, "")}
							disabled={!Boolean(selectedMeter)}
						>
							<ChevronLeftIcon fontSize='inherit' />
						</IconButton>
					</Box>
				</Grid>
				<Grid item xs={4}>
					{selectedMeter ? (
						<div className={classes.selectedMeter}>{selectedMeterName}</div>
					) : (
						<div className={classes.selectedMeter}>Ningún dispositivo seleccionado</div>
					)}
				</Grid>
			</Grid>
		</>
	);
}
