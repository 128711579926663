import { useHistory } from 'react-router-dom';

import { Box, Button, CircularProgress, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import Container from '../components/Elements/Container';
import UserContext from '../context/UserContext';
import { saveUserInfoInLocalStorage } from '../services/auth';
import { getPlanInfo } from '../services/client';

import TopNav from './common/TopNav';

import RefreshIcon from '@material-ui/icons/Refresh';
import Question from './FAQ/Question';
import Section from './FAQ/Section';
import Video from './FAQ/Video';

const useStyles = makeStyles(theme => ({
	alert: {
		display: 'flex',
		alignItems: 'center'
	},
	btn: {
		display: 'inline',
		marginLeft: theme.spacing(1.5),
		'@media (max-width: 720px)': {
			marginLeft: theme.spacing(0.5),
			fontSize: 'smaller'
		},
	},
	small: {
		display: 'inline-block',
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(1),
		color: theme.palette.primary.main,
		margin: theme.spacing(1),
	},
	circle: {
		display: 'inline-block',
		paddingTop: theme.spacing(0.5),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		textAlign: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.38)',
		color: theme.palette.common.white,
		height: theme.spacing(3),
		width: theme.spacing(3),
		borderRadius: '50%',
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},

	stand_out: {
		fontWeight: 'bold'
	},
	content: {
		fontSize: 13,
		color: 'rgba(0, 0, 0, 0.54)',
	},
	answerTitle: {
		marginLeft: theme.spacing(1),
		fontWeight: 'bold',
		textDecoration: 'underline'
	}

}));

const Landing = () => {
	const { setPlan, plan } = useContext(UserContext)
	const classes = useStyles();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showErrorAlert, setShowErrorAlert] = useState(false);

	const [expandedItems, setExpandedItems] = useState([]);

	const toggleExpanded = (index) => {
		const newExpandedItems = [...expandedItems];
		newExpandedItems[index] = !newExpandedItems[index];
		setExpandedItems(newExpandedItems);
	};



	const handleRefresh = async e => {
		try {
			setLoading(true)
			setShowErrorAlert(false);
			setErrorMessage('');
			const res = await getPlanInfo()
			setLoading(false);
			saveUserInfoInLocalStorage(res.data)
			setPlan(res.data)

		} catch (error) {
			if (error.response) {
				setErrorMessage(`${error.response.data.msg}.`);
			} else {
				setErrorMessage(`Ocurrió un error inesperado`);
			}
			setShowErrorAlert(true);
		}
	};
	return (
		<>
			<TopNav titulo='Inicio' />
			<Container>
				<Box>
					<Alert severity='info' className={classes.alert}>
						Bienvenido a Powermate.

						<Box className={classes.btn}>
							<Button

								color="primary"
								variant="contained"
								onClick={() => history.push(`/resumen`)}
							>
								Ver mis dispositivos
							</Button>
						</Box>
					</Alert>
				</Box>
				{loading &&
					<Box display='flex' justifyContent='center'>
						<CircularProgress />
					</Box>
				}
				{!loading && plan?.info?.plan === 'No verificado' &&
					<Box display='flex' justifyContent='center' flexDirection="column" alignItems="center" mt={2}>
						<Box fontSize={16}>El correo electrónico no fue verificado, es necesario este paso para continuar.</Box>
						<IconButton aria-label="refrescar" onClick={handleRefresh} >
							<RefreshIcon />
						</IconButton>
					</Box>
				}
				{showErrorAlert && (
					<Alert severity='error'>
						{errorMessage}
					</Alert>
				)}

				<Grid container spacing={2}>
					<Section name="Inicio - Powermate" enabled={true}>
						<Question enabled={false} id={101} title="Introducción" isExpanded={expandedItems[101]} toggle={toggleExpanded}>
						</Question>
						<Question enabled={true} id={102} title="¿Cómo crear mi cuenta Powermate?" isExpanded={expandedItems[102]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>
								<p>
									<span className={classes.circle}>1</span>Ingresar a <a href="http://powermate.powermeter.com.ar">powermate.powermeter.com.ar</a>.
								</p>

								<p>
									<span className={classes.circle}>2</span>Seleccionar la opción <span className={classes.stand_out}>“Crear cuenta”</span>.
								</p>


								<p>
									<span className={classes.circle}>3</span>Introducir los datos requeridos (cuenta de mail y contraseña).
								</p>


								<p>
									<span className={classes.circle}>4</span>Recibirá un correo electrónico de confirmación de alta de cuenta con un botón de confirmación que deberá acceder y presionar para que se complete el proceso.
								</p>
							</Grid>


							<Grid item xs={12} className={classes.content}>
								<Video title="Creación de tu cuenta Powermate" videoId="Rs18B505FkQ" width={853} height={480} />
							</Grid>
						</Question>
						<Question enabled={true} id={103} title="¿Cómo asociar un dispositivo a Powermate?" isExpanded={expandedItems[103]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>
								<p>
									<span className={classes.circle}>1</span> Una vez instalado su dispositivo, energizarlo.
								</p>

								<p>
									<span className={classes.circle}>2</span> Con su dispositivo móvil acceder a la red wifi que emite su equipo Powermeter.
								</p>
								<Box display="flex" justifyContent="center" alignItems="center">
									<Box className={classes.small} >
										<Box>SSID: Powermeter_XXXXXXX</Box>
										<Box>Contraseña: powermeter</Box>
									</Box>
								</Box>


								<p>
									NOTA: algunos dispositivos móviles muestran una advertencia indicando que la red WiFi a la que está conectado no posee acceso a internet y le pregunta si desea permanecer conectado. Es indispensable que usted confirme que SI desea permanecer conectado para continuar con el proceso.
								</p>
								<p>
									<span className={classes.circle}>3</span>Las credenciales de acceso al portal de configuración son:
								</p>

								<Box display="flex" justifyContent="center" alignItems="center">
									<Box className={classes.small}>
										<Box>Usuario: admin</Box>
										<Box>Contraseña: powermeter</Box>
									</Box>
								</Box>

								<p>
									<span className={classes.circle}>4</span> Presione el botón <span className={classes.stand_out}>“Asistente de configuración”</span>.
								</p>
								<p>
									<span className={classes.circle}>5</span> Ingrese el mail con el que creó su cuenta Powermate, y un nombre amigable para identificar fácilmente su dispositivo en Powermate.
								</p>
								<p>
									<span className={classes.circle}>6</span> Seleccione la red WiFi a la que va a asociar su dispositivo Powermeter e introduzca la contraseña de dicha red.
								</p>

								<p>
									<span className={classes.circle}>7</span> Usted recibirá un correo de confirmación de alta de equipo con un botón de confirmación que deberá acceder y presionar para que se complete el proceso.
								</p>

								<div>
									NOTA: para que el mail de confirmación se dispare, el dispositivo debe lograr asociarse correctamente a su red WiFi y poder comunicarse exitosamente con el servidor Powermate. Si hay problemas de conectividad o puertos bloqueados el proceso podría verse interrumpido hasta tanto se resuelvan dichos inconvenientes.
								</div>
							</Grid>
							<Grid item xs={12} className={classes.content}>
								<Video title="Cómo asociar tu dispositivo Powermeter a la red WiFi" videoId="mhyMShDrJLo" width={901} height={507} />
							</Grid>
						</Question>
						<Question enabled={true} id={104} title="Primeros pasos en Powermate" isExpanded={expandedItems[104]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

								<p>
									<span className={classes.circle}>1</span> Acceder a la pantalla de configuración (ícono de configuración en esquina superior derecha).
								</p>
								<p>
									<span className={classes.circle}>2</span> Seleccione la opción <span className={classes.stand_out}>“Dispositivos”</span> dentro del apartado de <span className={classes.stand_out}>“Organización”</span>.
								</p>
								<p>
									<span className={classes.circle}>3</span> Presione el botón <span className={classes.stand_out}>“Nueva empresa”</span>. Aparecerá en pantalla el nivel de Empresa creado para que usted pueda proceder a su configuración.
								</p>

								<div>
									IMPORTANTE: Los dispositivos dentro de Powermate se organizan en forma de árbol jerárquico, donde los primeros 2 niveles (“Empresa” y “Zona”) responden a cuestiones organizacionales o administrativas del usuario, mientras que el tercer nivel en adelante (“Localización”, “Sublocalización”, etc) responden al diagrama unifilar o instalación eléctrica real del sitio. El nivel “Localización” coincide con el punto de entrada de suministro eléctrico de la distribuidora de energía (medidor tarifador) en la instalación del cliente.
								</div>
								<p>
									<span className={classes.circle}>4</span> Sobre el nivel de <span className={classes.stand_out}>Empresa</span> recientemente creada presione el botón “+” que aparece sobre el lado derecho para crear una nueva “Zona”. Aparecerá en pantalla el nivel del Zona creado para que usted pueda proceder a su configuración.
								</p>
								<p>
									<span className={classes.circle}>5</span> Sobre el nivel de <span className={classes.stand_out}>Zona</span> recientemente creada presione el botón “+” que aparece sobre el lado derecho para crear una nueva “Localización”. Aparecerá en pantalla el nivel de Localización creado para que usted pueda proceder a su configuración.
								</p>
								<p>
									<span className={classes.circle}>6</span>Sobre el nivel de <span className={classes.stand_out}>Localización</span> usted podrá asociar un dispositivo físico o bien continuar creando niveles jerárquicos inferiores utilizando el mismo mecanismo (botón “+”).
								</p>
								<p>
									<span className={classes.circle}>7</span> Al presionar cualquiera de los nodos creados se accede al menú contextual del nodo donde usted podrá configurar diferentes parámetros de su organización, como el nombre, descripción, dispositivo asociado, etiquetas, etc. La operación debe finalizar tocando el botón <span className={classes.stand_out}>GUARDAR</span> para que los cambios tengan efecto.
								</p>
							</Grid>
							<Grid item xs={12} className={classes.content}>
								<Video title="Primeros pasos en Powermate" videoId="QJ0dsEScXTs" width={901} height={507} />
							</Grid>
						</Question>
						<Question enabled={true} id={105} title="¿Cómo descargar la app Powermate?" isExpanded={expandedItems[105]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

								<h3 className={classes.answerTitle}>Android</h3>

								<p>
									<span className={classes.circle}>1</span> Ingresar con el navegador del dispositivo móvil a <a href="https://powermate.powermeter.com.ar">powermate.powermeter.com.ar</a>.
								</p>
								<p>
									<span className={classes.circle}>2</span> Introducir las credenciales de la cuenta Powermate.
								</p>
								<p>
									<span className={classes.circle}>3</span> Tocar el botón <span className={classes.stand_out}>"Ingresar"</span>.
								</p>
								<p>
									<span className={classes.circle}>4</span>	Desplegar las opciones del navegador (tres puntitos en esquina superior derecha de la pantalla).
								</p>
								<p>
									<span className={classes.circle}>5</span> Seleccionar la opción <span className={classes.stand_out}>“Descargar app”</span>.
								</p>
								<p>
									<span className={classes.circle}>6</span>En el popup de confirmación que se despliega tocar la opción <span className={classes.stand_out}>“Instalar”</span>.
								</p>
							</Grid>
							<Grid item xs={12} className={classes.content}>
								<Video title="Instalación App Powermate para Android" videoId="iripyNR5bCA" width="320" height="560" />
							</Grid>

							<Grid item xs={12} className={classes.content}>
								<h3 className={classes.answerTitle}>iOS</h3>
								<p>
									<span className={classes.circle}>1</span>Ingresar con el navegador del dispositivo móvil a <a href="https://powermate.powermeter.com.ar">powermate.powermeter.com.ar</a>.
								</p>

								<p>
									<span className={classes.circle}>2</span>Introducir las credenciales de la cuenta Powermate.
								</p>
								<p>
									<span className={classes.circle}>3</span>Tocar el botón <span className={classes.stand_out}>"Ingresar"</span>.
								</p>
								<p>
									<span className={classes.circle}>4</span>Desplegar las opciones del navegador (opción <span className={classes.stand_out}>“Compartir”</span> en la parte inferior central de la pantalla).
								</p>
								<p>
									<span className={classes.circle}>5</span>Seleccionar la opción <span className={classes.stand_out}>“Agregar a inicio”</span>.
								</p>
								<p>
									<span className={classes.circle}>6</span>En el popup de confirmación que se despliega tocar la opción <span className={classes.stand_out}>“Agregar”</span>.
								</p>
							</Grid>
							<Grid item xs={12} className={classes.content}>
								<Video title="Instalación App Powermate para iOS" videoId="u6Jp7zfqQNE" width="320" height="560" />
							</Grid>

							<Grid item xs={12} className={classes.content}>
								<h3 className={classes.answerTitle}>Desktop (Chrome)</h3>
								<p>
									<span className={classes.circle}>1</span>Ingresar con el navegador del dispositivo móvil a <a href="https://powermate.powermeter.com.ar">powermate.powermeter.com.ar</a>.
								</p>
								<p>
									<span className={classes.circle}>2</span>Introducir las credenciales de la cuenta Powermate.
								</p>
								<p>
									<span className={classes.circle}>3</span>Tocar el botón <span className={classes.stand_out}>"Ingresar"</span>.
								</p>
								<p>
									<span className={classes.circle}>4</span>Clickear el ícono de <span className={classes.stand_out}>“Instalar aplicación”</span> ubicado sobre el lado derecho de la barra de navegación (Chrome).
								</p>
								<p>
									<span className={classes.circle}>5</span>Elegir la opción <span className={classes.stand_out}>“Instalar”</span>.
								</p>
							</Grid>
							<Grid item xs={12} className={classes.content}>
								<Video title="Instalación App Powermate para escritorio" videoId="x-d3G4hcf4I" width={853} height={480} />
							</Grid>
						</Question>
						<Question enabled={false} id={106} title="Planes disponibles" isExpanded={expandedItems[106]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
					</Section>

					<Section name="Manual de usuario - Powermate" enabled={false}>

						<Question enabled={true} id={201} title="Dashboards" isExpanded={expandedItems[201]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={202} title="Auditor" isExpanded={expandedItems[202]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={203} title="Facturación" isExpanded={expandedItems[203]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={204} title="Análisis" isExpanded={expandedItems[204]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={205} title="Alertas y reportes" isExpanded={expandedItems[205]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={206} title="Control" isExpanded={expandedItems[206]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
					</Section>

					<Section name="Ayuda" enabled={false} >
						<Question enabled={true} id={301} title="Webinars" isExpanded={expandedItems[301]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={302} title="Soporte técnico" isExpanded={expandedItems[302]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
					</Section>

					<Section name="FAQ" enabled={false}>
						<Question enabled={true} id={401} title="Pregunta 1" isExpanded={expandedItems[401]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
						<Question enabled={true} id={402} title="Pregunta 2" isExpanded={expandedItems[402]} toggle={toggleExpanded}>
							<Grid item xs={12} className={classes.content}>

							</Grid>
						</Question>
					</Section>
				</Grid>
			</Container >
		</>
	);
};

export default Landing;
