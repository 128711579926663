import VerticalBar from './VerticalBar';

function PowerDemand({ title, subtitle, data }) {

  // TODO para cuando el back entregue los valores reales.
  /*
  Para cada localización comparar power_max_month con power_contract y asignarle el color a la barra:
  Verde: no la superó
  Rojo: la superó
  Gris: no hay contrato definido para la localización  
  */
  return (
    <VerticalBar
      name="powerDemand"
      title={title}
      subtitle={subtitle}
      data={data}
    />
  );
}

export default PowerDemand;
