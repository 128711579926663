import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  logoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	logo: {
		width: '350px',
		margin: theme.spacing(0, 5),
  },
  text: {
    color: theme.palette.grey[800]
  },
  link: {
		padding: theme.spacing(1, 2),
		background: theme.palette.primary.main,
		textDecoration: 'none',
		borderRadius: '3px',
    marginLeft: theme.spacing(1),
    color: 'white',
    transition: 'background 0.3s ease-in-out',
    border: `1px solid white`,
		'&:visited': {
			color: 'white',
		},
		'&:hover': {
			background: 'white',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
		},
	},
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (

    <Box className={classes.root}>
      						<img className={classes.logo} src='/images/logo.svg' alt='logo' />

      <Typography className={classes.text} variant="h1" component="h2" color="primary">
        404
      </Typography>
      <Typography className={classes.text} variant="h5" component="h2" gutterBottom>
        Página no encontrada
      </Typography>
        <Link to="/" className={classes.link}>Inicio</Link>


       
    </Box>
  );
}
  
export default PageNotFound;