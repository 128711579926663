import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { changeLigthColor } from "../../helpers/controllers";
const useStyles = makeStyles(theme => ({
    chart: {
        width: "100%",
        height: "500px",
        "@media (max-width: 1040px)": {
            // marginTop: "39px",
            height: "500px"
        },
    },
    title: {
        color: theme.palette.primary.main,
        textAlign: "center"
    }
}));
export default function PieChart({ selectPeriod }) {
    const [chartData, setChartData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        let arry = []
        selectPeriod?.consumos.forEach(element => {
            let obj = {
                color: element?.meter?.color,
                cost: element?.cost,
                parameter: element?.meter?.nombre
            }
            arry.push(obj)
        });
        // // Ejemplo data
        let data = [
            {
                color: am4core.color("#2196f3"),
                cost: selectPeriod?.consumo_sub,
                parameter: "Usuarios finales"
            },
            {
                color: am4core.color("#5460ca"),
                cost: selectPeriod?.consumo_areas_comunes,
                parameter: "Areas comunes"
            },
        ];
        setChartData([...data])
        return () => {
            setChartData([])
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Create chart instance
        let chart = am4core.create("costPieChart", am4charts.PieChart);
        // SACAMOS LOGO DE AMCHARD
        if (chart.logo) {
            chart.logo.disabled = true
        }
        // Add data
        chart.data = chartData;
        chart.innerRadius = am4core.percent(40)
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "cost";
        pieSeries.dataFields.category = "parameter";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.truncate = true;
        pieSeries.labels.template.fontSize = 13;
        //desaparece los valores de las flechas
        // pieSeries.labels.template.disabled = true
        // pieSeries.slices.template.propertyFields.fill = "color";
        // pieSeries.slices.template.propertyFields.fillOpacity = 0.7;
        // pieSeries.slices.template.propertyFields.stroke = am4core.color("#ffffff");
        // pieSeries.slices.template.events.on("over", (event) => {
        //     event.target.dataItem.slice.fill = am4core.color("#000000");
        //     event.target.dataItem.slice.fillOpacity = 1;
        // });
        // pieSeries.slices.template.events.on("out", (event) => {
        //     event.target.dataItem.slice.fill = event.target.dataItem.dataContext.color;
        //     event.target.dataItem.slice.fillOpacity = 0.7;
        // });
        // Create a linear gradient for each slice
        pieSeries.slices.template.adapter.add("fill", (fill, target) => {
            const gradient = new am4core.LinearGradient();

            gradient.addColor(target.dataItem.dataContext.color, 0.4);

            gradient.addColor(am4core.color(target.dataItem.dataContext.color, 10));

            return gradient;
        });
        pieSeries.alignLabels = true;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        //SELECTORES DE COLOR DE CADA VALUE DEL GRAPH
        chart.legend = new am4charts.Legend()
        chart.legend.itemContainers.template.paddingBottom = 10;
        //OPCION PARA MODIFICAR LOS TEXTOS DE LOS LEGEND
        pieSeries.legendSettings.valueText = "$ {cost} ";
        pieSeries.legendSettings.labelText = "([/bold]{parameter}[/bold])";
        return () => {
            chart.dispose();
        };
    }, [chartData]);

    return (
        <div >
            <h3 className={classes.title}>Costo de energia</h3>
            <div id='costPieChart' className={classes.chart} />
        </div>

    );

}





