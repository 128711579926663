import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function EVDOD
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={33}
            showLabelInput={true}
            mTopValid={1}
            LabelInputRight={'43px'}
            userConfiguration={true}
        />
    );
}
export default EVDOD