import { createContext, useState } from 'react';
import { getTagsFromLocalStorage } from '../services/auth';

const TagsContext = createContext();

export const TagsProvider = ({ children }) => {
  const [tags, setTags] = useState(getTagsFromLocalStorage);

  return (
    <TagsContext.Provider value={{ tags, setTags }}>
      {children}
    </TagsContext.Provider>
  );
};

export default TagsContext;
