//Material ui
import { Box, makeStyles } from '@material-ui/core';
//icons
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
// import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
// import { toFormattedNumber } from '../../../helpers/common';
//Helpers
import { getFormattedNumber, getUnit } from '../../../helpers/common';
const useStyles = makeStyles(theme => ({
  container: {
    // borderRadius: theme.spacing(1.2),
    backgroundColor: theme.palette.common.white,
    border: '1px solid #cbc1c157',
    boxShadow: '3px 3px 3px #cbc1c157',
    '@media (max-width: 720px)': {
      height: '100%',
      marginTop: theme.spacing(2),
    },
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
  // info: {
  //   marginLeft: theme.spacing(1),
  // },
  title: {
    fontSize: theme.spacing(3),
    // paddingBottom: theme.spacing(0.5),
    // marginBottom: theme.spacing(0.5),
    // borderBottom: `solid 1px ${theme.palette.common.white}`,
    fontWeight: 600,
    textAlign: 'center',
  },
  containerBody: {
    padding: theme.spacing(1),
  },
  sectionsContainer: {
    display: 'flex',
    borderBottom: `solid 1px ${theme.palette.primary.light}`,
  },
  leftLine: {
    borderRight: `solid 1px ${theme.palette.primary.light}`,
    marginRight: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    flex: 1,
  },
  valueContainer: {
    // marginTop: theme.spacing(0.5),
    // marginBottom: theme.spacing(1),
    color: theme.palette.grey[500],
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    fontSize: theme.spacing(3.2),
    fontWeight: 800,
  },
  bigValue: {
    fontSize: theme.spacing(3.6),
    fontWeight: 800,
  },
  unit: {
    fontSize: theme.spacing(2.5),
    //   marginBottom: -theme.spacing(0.5)
  },
  superscript: {
    fontSize: theme.spacing(1.5),
    verticalAlign: 'super',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.light,
    marginTop: theme.spacing(1),
  },
}));

export default function NodeData({ data }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.header} px={1} py={0.5}>
        <Box className={classes.title}>{data.company}</Box>
        {/* <Box display="flex" alignItems="center">
          <RoomOutlinedIcon style={{ fontSize: "18px" }} />
          <span className={classes.info}>Jose Mármol 782</span>
        </Box>
        <Box mt={0.5} display="flex" alignItems="center">
          <MessageOutlinedIcon style={{ fontSize: "18px" }} />
          <span className={classes.info}>+54 9 11 60180133</span>
        </Box> */}
      </Box>

      <Box className={classes.containerBody}>
        {/* contenedor superior del cuerpo de la carta */}
        <Box className={classes.sectionsContainer}>
          <Box className={classes.leftLine}>
            <Box className={classes.sectionTitle}>
              <RoomOutlinedIcon style={{ fontSize: '18px' }} />
              <span style={{ marginLeft: '4px' }}>Localizaciones</span>
            </Box>
            <Box className={classes.valueContainer}>
              <Box className={classes.value}>{data.locations_count}</Box>
            </Box>
          </Box>

          <Box style={{flex:1}}>
            <Box className={classes.sectionTitle} ml={1}>
              <OpenInNewOffIcon style={{ fontSize: '18px' }} />
              <span style={{ marginLeft: '4px' }}>Sup. Total</span>
            </Box>

            <Box className={classes.valueContainer}>
              <Box className={classes.value}>{data.surface}</Box>
              <Box className={classes.unit}>
                m<span className={classes.superscript}>2</span>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* contenedor inferiro del cuerpo de la carta */}
        <Box>
          <Box mt={1}>
            <Box className={classes.sectionTitle}>
              <ElectricBoltOutlinedIcon style={{ fontSize: '18px' }} />
              <span style={{ marginLeft: '4px' }}>Consumo Anual</span>
            </Box>
            <Box className={classes.valueContainer} mt={1}>
              <Box className={classes.bigValue}>
                {getFormattedNumber(data.energy_total_year)}
              </Box>
              <Box className={classes.unit}>{getUnit(data.energy_total_year)}Wh</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
