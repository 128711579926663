import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	chart: {
		// height: '200px',
		// marginLeft: theme.spacing(8),
	},
}));

export default function BarChart({ data }) {
	const classes = useStyles();

	useEffect(() => {
		let chart = am4core.create('barChart', am4charts.XYChart);
		// console.log(chart)

		// SACAMOS LOGO DE AMCHARD
		if (chart.logo) {
			chart.logo.disabled = true
		}
		const auxLinearArray = [];

		data.forEach(location => {
			const { bills } = location;
			bills.forEach(bill => {
				// console.log(bill);
				const { from, to } = bill;
				auxLinearArray.push({
					location: location.location,
					from,
					to,
					dif: location.dif_total > 0 ? location.dif_total : -location.dif_total,
				});
			});
		});

		chart.data = auxLinearArray;

		// chart.legend = new am4charts.Legend();
		// chart.legend.position = 'right';

		// Configuro categoryAxis
		let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = 'location';
		categoryAxis.renderer.cellStartLocation = 0.2;
		categoryAxis.renderer.cellEndLocation = 0.5;
		categoryAxis.renderer.fontSize = 13;
		//Oculto el grid
		categoryAxis.renderer.grid.template.opacity = 0;
		categoryAxis.renderer.labels.template.verticalCenter = 'middle'
		categoryAxis.renderer.labels.template.location = 0.35;

		//Configuro valueAxis
		let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
		// valueAxis.min = 0;
		// valueAxis.renderer.inside = true;
		valueAxis.renderer.grid.template.opacity = 0;
		valueAxis.renderer.line.strokeOpacity = 1;
		valueAxis.renderer.baseGrid.disabled = false;
		valueAxis.renderer.minGridDistance = 120;
		valueAxis.renderer.fontSize = 13;

		//Para que el grafico pueda calcular automaticamente los totales
		// valueAxis.calculateTotals = true;

		valueAxis.renderer.labels.template.adapter.add('text', function (text) {
			return '$' + text;
		});

		// Creación de serie
		let series = chart.series.push(new am4charts.ColumnSeries());

		series.columns.template.height = am4core.percent(100);

		series.dataFields.valueX = 'dif';
		series.dataFields.categoryY = 'location';
		// series.stacked = true;
		series.name = 'location';

		series.columns.template.stroke = am4core.color('#2586bc');
		series.columns.template.fill = am4core.color('#61afd2');

		let labelBullet = series.bullets.push(new am4charts.LabelBullet());
		// labelBullet.locationX = 0.05;
		labelBullet.label.horizontalCenter = 'left';

		labelBullet.label.text = `\${valueX}`;
		labelBullet.label.fill = am4core.color('#000');
		labelBullet.label.dx = 10;
		labelBullet.label.fontSize = 13;

		return () => {
			chart.dispose();
		};
	}, [data]);
	return (
		<>
			<div id='barChart' className={classes.chart} />
		</>
	);
}
