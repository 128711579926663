//Funciones encargadas de mergear data a tipo mqtt.
export const OPTIONS_DEVICES = {
    SMART: 'SMART',
    HOME: 'HOME'
}

export const HOME_DATA = {
    VOLTAGE: {
        R: { min: '', max: '' },
        S: { min: '', max: '' },
    },
    CURRENT: {
        R: { min: '', max: '' },
        S: { min: '', max: '' },
    },
    POWER: {
        R: { min: '', max: '' },
        S: { min: '', max: '' },
        Σ: { min: '', max: '' }
    }
}

export const SMART_DATA = {
    VOLTAGE: {
        R: { min: '', max: '' },
        S: { min: '', max: '' },
        T: { min: '', max: '' },
    },
    CURRENT: {
        R: { min: '', max: '' },
        S: { min: '', max: '' },
        T: { min: '', max: '' },
    },
    POWER: {
        R: { min: '', max: '' },
        S: { min: '', max: '' },
        T: { min: '', max: '' },
        Σ: { min: '', max: '' }
    }
}

const findElement = (stringName, obj) => {
    let res = obj.find(objeto => objeto.name === stringName)
    return res.value
}

export const mergeDataAlarmSmart = (objData) => {
    let res = {
        VOLTAGE: {
            R: { min: findElement('r_vmin', objData), max: findElement('r_vmax', objData) },
            S: { min: findElement('s_vmin', objData), max: findElement('s_vmax', objData) },
            T: { min: findElement('t_vmin', objData), max: findElement('t_vmax', objData) },
        },
        CURRENT: {
            R: { min: findElement('r_imin', objData), max: findElement('r_imax', objData) },
            S: { min: findElement('s_imin', objData), max: findElement('s_imax', objData) },
            T: { min: findElement('t_imin', objData), max: findElement('t_imax', objData) },
        },
        POWER: {
            R: { min: findElement('r_pmin', objData), max: findElement('r_pmax', objData) },
            S: { min: findElement('s_pmin', objData), max: findElement('s_pmax', objData) },
            T: { min: findElement('t_pmin', objData), max: findElement('t_pmax', objData) },
            Σ: { min: findElement('tot_pmin', objData), max: findElement('tot_pmax', objData) },
        }
    }
    return res
}

export const mergeDataAlarmHome = (objData) => {
    let res = {
        VOLTAGE: {
            R: { min: findElement('r_vmin', objData), max: findElement('r_vmax', objData) },
            S: { min: findElement('s_vmin', objData), max: findElement('s_vmax', objData) },
        },
        CURRENT: {
            R: { min: findElement('r_imin', objData), max: findElement('r_imax', objData) },
            S: { min: findElement('s_imin', objData), max: findElement('s_imax', objData) },
        },
        POWER: {
            R: { min: findElement('r_pmin', objData), max: findElement('r_pmax', objData) },
            S: { min: findElement('s_pmin', objData), max: findElement('s_pmax', objData) },
            Σ: { min: findElement('tot_pmin', objData), max: findElement('tot_pmax', objData) },
        }
    }
    return res
}


