import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import {
    Box, Button, Tooltip,
    Grid, makeStyles, Chip, CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';
//Componentes
import ShowcaseLayout from "./ShowcaseLayout";
import AlertComponent from '../Elements/AlertComponent';
import Container from "../Elements/Container"
import TopNav from '../common/TopNav'
import ModalName from "./ModalName";
import FilterComponent from "./FilterComponent";
import ModalShareDashboard from "./ModalShareDashboard";
import DeletePanel from "./DeletePanel";
//Plan user
import UserContext from '../../context/UserContext';
//Params router 
import { useParams } from 'react-router';
//Services
import { postDashboard, deleteDashboard, getDashboard, putDashboard } from '../../services/dashboard'
//icons
import ShareIcon from '@mui/icons-material/Share';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles(theme => ({
    contanerMain: {
        padding: theme.spacing(0.5),
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    btnDisabled: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',

        },
        '&.MuiButton-root': {
            backgroundColor: '#a6b7bd',
        },
    },
    alertIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.5em',
            paddingTop: theme.spacing(0.5),
        },
    },
    alert: {
        '@media (max-width: 920px)': {
            marginLeft: 0,
        },
    },
    chip: {
        marginRight: theme.spacing(3),
    },
    mobileDisable: {
        '@media (max-width: 920px)': {
            display: 'none',
        },
    },
}));

const DashboardContainer = ({
    personalized, setRefresh
}) => {
    const classes = useStyles();
    const { plan } = useContext(UserContext);
    const { params_id } = useParams();
    const history = useHistory();
    const { isMobile } = useDeviceDetect();
    const initialData = {
        name: "Ingrese un nombre",
        is_public: false,
        id: "",
        positions: [
            { i: "0", x: 0, y: 0, w: 3, h: 2, minH: 2, minW: 3 }
        ],
        components: [
            { name: "" }
        ]
    }
    //Estado de la alerta dinamica
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [stateData, setStateData] = useState(initialData);
    const [stateDataCancel, setStateDataCancel] = useState(initialData);
    const [componentState, setComponentState] = useState([])
    //Estado del componente imagen para tomar el estado del zoom.
    const [stateDataComponentsImageZoom, setStateDataComponentsImageZoom] = useState([]);
    //Loading principal
    const [loading, setLoading] = useState(false);
    //estado q abre el panel de filtro
    const [filterPanelComponent, setFilterPanelComponent] = useState({
        state: false,
        indexBox: 0
    });
    //estado q abre el modal de nombre
    const [editName, setEditName] = useState(personalized ? false : true)
    //estado para habilitar la edicion
    const [edit, setEdit] = useState(personalized ? false : true)
    //Modal configuracion dashboard publicos.
    const [openModalPublicConfig, seTopenModalPublicConfig] = useState(false)
    //Estado q deshabilita el boton editar si todavia no se guardo la configuracion de el dashboard para evitar 
    //spamm antes q tome los cambios para el publico.
    const [disabledButtonEdit, setDisabledButtonEdit] = useState(false)
    // Estado encargado de abrir el panel de eliminacion de Dashboard.
    const [openDeletePanel, setOpenDeletePanel] = useState(false)
    //Textos de los tooltip.
    const TOOLTIP_TEXT = {
        ADD: "Agregue una caja editable para establecer un nuevo componente.",
        EDIT: "Permite editar su configuración.",
        SAVE: "Guarda su nueva configuración.",
        DELETE: "Elimina su configuración.",
        CANCEL: "Cancela modo edición."
    }

    useEffect(() => {
        setLoading(false)
        let intervalId
        if (personalized) {
            //Dashboard q ya existe.
            setEdit(false)
            getDataDashboard()
            //Cada 15min actualiza el dashboard.
            intervalId = setInterval(getDataDashboard, 900000);
        }
        else {
            //Dashboard constructor de uno nuevo.
            setLoading(true)
        }
        // Limpia el intervalo cuando el componente se desmonta para evitar fugas de memoria
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line 
    }, [params_id])

    //Funcion q deshabilita el edit cuando la imagen se esta editando.
    const editFunctionImg = (positionsAux, index, disabledState) => {
        let positionAux = positionsAux.map((elem, indx) => {
            if (index === indx) {
                return {
                    ...elem,
                    static: !edit ? true : disabledState
                }
            }
            else {
                return elem
            }
        })
        setStateData({
            ...stateData,
            positions: positionAux
        })
    }

    //Funcion de el boton editar y cancelar.
    const editFunction = () => {
        let positionAux = stateDataCancel?.positions?.map((elem) => {
            return {
                ...elem,
                static: edit
            }
        })
        setStateData({
            ...stateDataCancel,
            positions: positionAux
        })
    }

    //Funcion get para traer los datos de el dashboard.
    const getDataDashboard = async (value) => {
        try {
            let res = {}
            if (!value) {
                res = await getDashboard(params_id)
            }
            else {
                res = value
            }

            res = await getDashboard(params_id)
            let auxComponents = res.data.components.map((elem) => {
                //Objeto q mergea la data de el back.
                let aux = {
                    ...elem,
                    nodes: elem.nodes,
                    id: elem.device_id,
                    mainId: elem.id,
                    nameDevice: elem.device_name,
                    indexBox: elem.index_box,
                }
                return aux
            })

            let positionAux = res.data.positions.map((elem) => {
                return {
                    ...elem,
                    static: true
                }
            })

            let auxObj = {
                name: res.data.name,
                is_public: res.data.is_public,
                token: res.data.token,
                components: auxComponents,
                positions: positionAux
            }
            setStateDataCancel(auxObj)
            setStateData(auxObj)
        }
        catch (error) {
            let msg = "Ocurrio un error inesperado"
            if (error?.response?.data) {
                msg = error?.response?.data
            }
            setClose(true)
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setLoading(true)
    }

    //Añade un bloque vacio.
    const addBox = () => {
        let newBox = {
            name: "",
        }
        let dashNew = stateData
        dashNew.positions = componentState
        setStateData({
            ...dashNew,
            positions: [...dashNew.positions, { i: dashNew.positions.length.toString(), x: 0, y: -1, w: 3, h: 2, minH: 2, minW: 3 },],
            components: [...dashNew.components, newBox]
        })
    }

    const removeFileUrl = (objeto) => {
        // Verifica si el objeto es nulo o no es un objeto
        if (objeto === null || typeof objeto !== 'object') {
            return;
        }
        // Recorre todas las propiedades del objeto
        for (let clave in objeto) {
            // Verifica si la propiedad actual es file_base64
            if (clave === 'file_base64') {
                // Si la propiedad file_base64 existe y la propiedad file_url también existe, elimina file_url
                if (objeto.hasOwnProperty('file_url')) {
                    delete objeto['file_url'];
                }
            }
            // Si la propiedad actual es un objeto, llama recursivamente la función para ese objeto
            if (typeof objeto[clave] === 'object') {
                removeFileUrl(objeto[clave]);
            }
        }
    }

    //Guarda configuracion.
    const saveConfig = async (is_publicProp) => {
        //deshabilito el boton editar para q el usuario no pueda editar multiples veces antes de guardar el ultimo dashboard.
        setDisabledButtonEdit(true)
        try {
            if (stateData.name === "Ingrese un nombre") {
                setClose(true)
                setMsg({
                    msg: "Debe ingresar un nombre para poder crear su dashboard.",
                    type: "error"
                })
                return
            }
            //coloca todas las cajas en static.
            let auxComponents = stateData.components.map((elem, index) => {
                let aux = {}

                //Si el componente es "Contenedor de imagen" tiene una configuracion diferente necesita el id.
                if (elem.name === "Contenedor de imagen") {
                    aux = {
                        ...elem,
                        id: elem.mainId,
                        nodes: elem.nodes,
                        device_id: elem.id,
                        device_name: elem.nameDevice,
                        index_box: elem.indexBox,
                    }

                    //Si se realizo un cambio en algun zoom de un componente imagen.
                    if (stateDataComponentsImageZoom[index]?.id === elem?.id) {
                        let zoomSettings = stateDataComponentsImageZoom[index].image_zoom
                        aux.image_zoom = zoomSettings
                    }

                    //Elimino la propiedad file_url para q el put funcione.
                    removeFileUrl(aux)

                }
                else {
                    aux = {
                        ...elem,
                        nodes: elem.nodes,
                        device_id: elem.id,
                        device_name: elem.nameDevice,
                        index_box: elem.indexBox,
                    }
                }
                return aux
            })
            //Coloco todas las cajas en static para q no se puedan editar hasta q termine de guardar el dashboard.
            let positionAux = componentState.map((elem) => {
                return {
                    ...elem,
                    static: true
                }
            })
            let auxObj = {
                client: plan?.info?.client_id,
                name: stateData.name,
                is_public: stateData?.is_public,
                token: stateData?.token,
                components: auxComponents,
                positions: positionAux
            }
            //Guardo el estado para q se aplique el static a todas las cajas para evitar el time de la request.
            setStateData(auxObj)
            setStateDataCancel(auxObj)

            //Si recibe este argumento toma este valor para pasar de publico a no publico.
            if (typeof is_publicProp === 'boolean') {
                auxObj.is_public = is_publicProp
            }

            if (!personalized) {
                let res = await postDashboard(auxObj)
                history.push(`/personalizado/${res.data.id}`)
                setRefresh(prevRefresh => !prevRefresh);
            }
            else {
                //Solo vuelvo a guardar el dashboard y cargo los datos de la respuesta nuevos para el cancel.
                setEdit(false)
                let msg = "Se han guardado sus cambios con éxito."
                let type = "success"
                await putDashboard(params_id, auxObj)
                setClose(true)
                setMsg({
                    msg: msg,
                    type: type
                })
            }
        }
        catch (error) {
            let msg = "Ocurrio un error inesperado."
            let type = "error"
            if (error?.response?.data) {
                msg = error?.response?.data.msg
            }
            setClose(true)
            setMsg({
                msg: msg,
                type: type
            })
        }
        //Habilito el boton editar luego q ya se guardo correctamente el dashboard.
        setDisabledButtonEdit(false)
    }

    //Guarda elimina toda la data local
    const deleteLocal = async () => {
        try {
            await deleteDashboard(params_id)
            setRefresh(prevRefresh => !prevRefresh);
            setClose(true)
            handleOpenCloseDeletePanel()
            setMsg({
                msg: "Se elimino su configuración",
                type: "success"
            })
            history.push('/crear-personalizado')
        }
        catch (error) {
            let msg = "Ocurrio un error inesperado."
            let type = "error"
            if (error?.response?.data) {
                msg = error?.response?.data.MSJ
            }
            setClose(true)
            setMsg({
                msg: msg,
                type: type
            })
        }
    }

    //Open filter.
    const togglePanel = (index) => {
        setFilterPanelComponent({
            indexBox: index,
            state: !filterPanelComponent.state
        })
    };

    //Handle encargado de abrir y cerrar el panel de eliminacion de dashboard.
    const handleOpenCloseDeletePanel = () => {
        setOpenDeletePanel(!openDeletePanel)
    }

    return (
        <>
            {/* Panel de eliminacion dashboard. */}
            <DeletePanel
                openDeletePanel={openDeletePanel}
                handleOpenCloseDeletePanel={handleOpenCloseDeletePanel}
                deleteLocal={deleteLocal}
            />

            <TopNav
                titulo='Dashboards'
                subtitulo={stateData?.name === initialData.name ? `| Personalizado ` : `| ${stateData?.name} `}>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    {!personalized && <Chip
                        className={classes.chip}
                        label={stateData?.name === initialData.name ? initialData.name : `Nombre: ${stateData?.name}`}
                        clickable
                        onClick={() => setEditName(true)}
                    />
                    }
                    {edit &&
                        <Tooltip
                            title={TOOLTIP_TEXT.ADD}
                            arrow
                            placement="right"
                        >
                            <Button
                                style={{ marginLeft: "10px" }}
                                className={classes.btn}
                                type="button"
                                onClick={addBox}
                                size="small"
                            >
                                {`+ Agregar contenedor`}
                            </Button>
                        </Tooltip>
                    }
                    {personalized &&
                        <Tooltip
                            title={edit ? TOOLTIP_TEXT.CANCEL : TOOLTIP_TEXT.EDIT}
                            arrow
                            placement="right"
                        >
                            <span>
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    className={clsx(
                                        classes.mobileDisable,
                                        disabledButtonEdit ? classes.btnDisabled : classes.btn
                                    )}
                                    type="button"
                                    onClick={() => {
                                        setEdit(!edit)
                                        editFunction()
                                    }}
                                    size="small"
                                    disabled={disabledButtonEdit}
                                >
                                    {disabledButtonEdit ? <CircularProgress size={22} /> : (edit ? `Cancelar` : `Editar`)}
                                </Button>
                            </span>
                        </Tooltip>
                    }
                    {edit &&
                        <>
                            <Tooltip
                                title={TOOLTIP_TEXT.SAVE}
                                arrow
                                placement="right"
                            >
                                <span>
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        className={classes.btn}
                                        type="button"
                                        onClick={saveConfig}
                                        size="small"
                                    >
                                        {`Guardar`}
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    }
                    {personalized &&
                        <>
                            {!isMobile &&
                                <Tooltip
                                    title={TOOLTIP_TEXT.DELETE}
                                    arrow
                                    placement="right"
                                >
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        className={classes.btn}
                                        type="button"
                                        onClick={handleOpenCloseDeletePanel}
                                        size="small"
                                    >
                                        {`Eliminar`}
                                    </Button>
                                </Tooltip>
                            }
                            {
                                <Button
                                    style={{ marginLeft: "10px", }}
                                    className={classes.btn}
                                    size='small'
                                    type="button"
                                    onClick={() => {
                                        seTopenModalPublicConfig(true)
                                    }}
                                >
                                    <ShareIcon style={{ marginRight: "2px", fontSize: '17px' }} />
                                    {`  Compartir`}
                                </Button>
                            }
                        </>
                    }
                </Grid>
            </TopNav >

            {/* Modal de configuracion de dashboard publico. */}
            < ModalShareDashboard
                saveConfig={saveConfig}
                is_public={stateData?.is_public}
                setPublicState={setStateData}
                url={`/dashboard-publico/${stateData?.token}`
                }
                open={openModalPublicConfig}
                setOpen={seTopenModalPublicConfig}
            />

            {/* Modal para elegir nombre de el dashboard personalizado. */}
            < ModalName
                editName={editName}
                personalized={personalized}
                setStateData={setStateData}
                setEditName={setEditName}
            />

            <FilterComponent
                filterPanelComponent={filterPanelComponent}
                togglePanel={togglePanel}
                setStateData={setStateData}
                stateData={stateData}
            />

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container>
                {!loading &&
                    <Box height='500px' display='flex' justifyContent='center' alignItems={'center'}>
                        <CircularProgress />
                    </Box>
                }

                {/* Contenedor Grid */}
                {loading &&
                    <Box className={classes.contanerMain}>
                        <ShowcaseLayout
                            setComponentState={setComponentState}
                            togglePanel={togglePanel}
                            setEdit={setEdit}
                            edit={edit}
                            editFunctionImg={editFunctionImg}
                            stateData={stateData}
                            setStateDataComponentsImageZoom={setStateDataComponentsImageZoom}
                            setStateData={setStateData}
                            setMsg={setMsg}
                            setClose={setClose}
                        />
                    </Box>
                }
            </Container>
        </>
    )
}
export default DashboardContainer