import React, { useEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { makeStyles } from '@material-ui/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box } from '@material-ui/core'
import { toFormattedNumber } from '../../../helpers/common';
import { utcConverter } from '../../../helpers/controllers';

let stylesDescriptionCut = {
    fontSize: '13px'
}

let wordCut = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
}
const useStyles = makeStyles(theme => ({
    container: {
        position: 'absolute',
        top: '53%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        color: '#666666',
        textAlign: 'center',
    },
    containerChart: {
        position: 'relative',
        height: '85%',
        bottom: '10px'
    },
    containerValueUnity: {
        margin: theme.spacing(4, 0, 0,),
    },
    unity: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(3)
    },
    value: {
        color: theme.palette.grey.hairline,
        fontWeight: "600",
        fontSize: theme.spacing(3),
    },
    variableName: {
        color: theme.palette.grey.hairline,
        margin: theme.spacing(1, 0, 0, 0),
        fontSize: theme.spacing(2)
    },
    faseName: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.5)
    },
    chart: {
        height: "100%",
        width: "100%"
    },
    mainTitle: {
        padding: theme.spacing(2, 1, 0, 4),
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(1.7),
        fontWeight: "600",
        height: '45px'
    },
    subTitle: {
        fontWeight: '400',
    },
    dateFooter: {
        fontSize: theme.spacing(1.5),
        color: '#595555',
    },
}))

export default function DynamicGauge({ id, color, descripcion, title, min, max, value, unityConfig, positions, timestamp }) {
    const classes = useStyles();
    const chartRef = useRef(null);
    am4core.useTheme(am4themes_animated);

    const [dinamycSize, setDinamycSize] = useState({
        title: false,
        unity: false,
        description: false
    })

    useEffect(() => {
        let chart = am4core.create(id, am4charts.GaugeChart);
        chartRef.current = chart;

        if (chart.logo) {
            chart.logo.disabled = true
        }
        let axis = chart.xAxes.push(new am4charts.ValueAxis());
        //Agrego la unidad consumida por catalogo segun la variable seleccionada.
        if (unityConfig.base_unit === 'A') {
            chart.numberFormatter.numberFormat = `#.##'A`
        }
        //Si la unidad base (base_unit) posee una  k (kwh) en principio quiere decir q minimamente ya va a estar formateada en formato *1000
        //por ende pongo la unidad manual.
        else if (unityConfig.base_unit[0] === 'k') {
            chart.numberFormatter.numberFormat = `#.##  ${unityConfig.base_unit}`;
        }
        //Si la unidad base (base_unit) esta "" no se agrega unidad.
        else if (unityConfig.base_unit === '') {
            chart.numberFormatter.numberFormat = `#.##`;
        }
        else {
            chart.numberFormatter.numberFormat = `#.##a${unityConfig.base_unit}`;
        }
        axis.min = min;
        axis.max = max;
        // axis.strictMinMax = true;
        // Hide labels
        axis.renderer.grid.template.disabled = true;
        // axis.renderer.labels.template.disabled = true;
        axis.renderer.labels.template.radius = am4core.percent(3)
        axis.renderer.labels.template.fontSize = 12
        //distancia de los numeros q estan en el contorno de el gauge.
        axis.renderer.minGridDistance = 100
        chart.startAngle = -225;
        chart.endAngle = 45;
        chart.innerRadius = am4core.percent(88);
        //range1 es la superficie gris de atras.
        const range1 = axis.axisRanges.create();
        range1.value = min;
        range1.endValue = max + 100;
        ///////////////////////
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = am4core.color('#d2d0d0b3');
        //in 1 to make sure we don't see lines
        range1.axisFill.zIndex = 1;
        //range2 es  el valor indicado con color..
        const range2 = axis.axisRanges.create();
        // // range2.value = tension;
        range2.endValue = value;
        //////////////////
        range2.axisFill.fillOpacity = 1;
        range2.axisFill.fill = am4core.color(color);
        //in 1 to make sure we don't see lines
        range2.axisFill.zIndex = 1;

        return () => {
            chart.dispose();
        };
        // eslint-disable-next-line 
    }, [color, id, min, max]);

    useEffect(() => {
        if (chartRef.current) {
            // Update the chart with the new value
            let range2 = chartRef.current.xAxes.getIndex(0).axisRanges.getIndex(1);
            range2.endValue = value;
            chartRef.current.validateData(); // Refresh the chart
        }
        // eslint-disable-next-line 
    }, [value]);

    //Controla el tamaño de el contenedor y aplica estilos segun tamaño.
    useEffect(() => {
        let height = positions?.h
        let sizes = {
            title: false,
            unity: false,
            description: false,
            date: false
        }

        if (height > 2) {
            sizes.title = true
            sizes.unity = true
            sizes.description = true
        }
        if (height < 3) {
            sizes.date = true
        }

        setDinamycSize(sizes)
        // eslint-disable-next-line 
    }, [positions])

    //Formatea valor numerico del gauge.
    const getFormattedNumber = x => {
        if (x < 1000) {
            return x
        }
        else {
            const abs = Math.abs(x);
            const magnitude = Math.log(abs) / Math.log(10);
            if (magnitude < 3) return toFormattedNumber(0.001 * x);
            if (magnitude < 6) return toFormattedNumber(0.001 * x);
            if (magnitude < 9) return toFormattedNumber(0.000001 * x);
            return toFormattedNumber(1 * x);
        }
    };

    //Aplica la unidad del gauge formateada.
    const getUnit = x => {
        if (x < 1000) {
            return `${unityConfig.base_unit}`
        }
        else {
            const abs = Math.abs(x);
            const magnitude = Math.log(abs) / Math.log(10);
            if (magnitude < 3) return `${unityConfig.base_unit}`;
            if (magnitude < 6) return `K${unityConfig.base_unit}`;
            if (magnitude < 9) return `M${unityConfig.base_unit}`;
            return toFormattedNumber(1 * x);
        }
    };

    //Funcion q devulve el label de la variable formateado.
    const variableLabel = (descripcion) => {
        return descripcion?.split(' ', 2)[0] + ' ' + descripcion?.split(' ', 2)[1].replace(/\bFase\b/i, '')
    }

    //Funcion q devuelve la letra de la fase formateada.
    const faseLabel = (descripcion) => {
        return `Fase (${descripcion?.split(" ").pop()})`
    }

    return (
        <Box height={'100%'}>

            <Box
                className={classes.mainTitle}
                style={dinamycSize.date ? { ...wordCut } : {}} >
                {`${title} |`} <span className={classes.subTitle} >{descripcion}</span>
            </Box>

            <Box className={classes.containerChart} >

                {/* Grafico Gaugue */}
                <Box className={classes.chart} id={id} />

                <Box className={classes.container}>
                    {/* Valor Unidad */}
                    <Box className={classes.containerValueUnity} >
                        <Box
                            className={classes.value}
                            style={dinamycSize.title ? { fontSize: '40px' } : {}} >
                            {`${getFormattedNumber(value)}`}
                        </Box>
                        <Box
                            className={classes.unity}
                            style={dinamycSize.unity ? { fontSize: '35px' } : { fontSize: '19px' }} >
                            {getUnit(value)}
                        </Box>
                    </Box>

                    {/* Nombre variable */}
                    <Box
                        style={dinamycSize.description ? { fontSize: '19px' } : { ...stylesDescriptionCut }}
                        className={classes.variableName}>
                        {variableLabel(descripcion)}
                    </Box>

                    {/* Letra de la fase*/}
                    <Box
                        className={classes.variableName}
                        style={dinamycSize.description ? { fontSize: '19px' } : { fontSize: '16px' }}
                    >
                        {faseLabel(descripcion)}
                    </Box>
                </Box>
            </Box>

            {/* Hora y fecha */}
            <Box
                className={classes.dateFooter}
                style={dinamycSize.date ? { position: 'relative', bottom: '19px' } : {}}
                display='flex'
                justifyContent={'center'}
                width={'100%'}>
                {utcConverter(timestamp)}
            </Box>
        </Box>
    );
}


