import { useState, useEffect } from 'react';
//Material ui
import {
    Box, makeStyles, DialogTitle,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper,
    Grid, CircularProgress, IconButton
} from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Style 
import clsx from 'clsx';
//icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CancelIcon from '@mui/icons-material/Cancel';
//helpers 
import { formatDateStrDashToSlash } from '../../helpers/common';
import { format } from 'date-fns';
import { toFormattedNumber } from '../../helpers/common'
//Servicios
import { getExtraInfo } from '../../services/hierarchy';
import { downloadCsvSubBill, downloadXlsxSubBill } from '../../services/billing';

let fontSizePrint = '22'
let tableFontSize = {
    "@media print": {
        fontSize: '16px'
    }
}
const useStyles = makeStyles(theme => ({
    containerMain: {
        margin: "8px",
        border: "2px solid #5391b4",
        "@media (max-width: 700px)": {
            padding: "0px"
        },
        "@media print": {
            marginTop: "15%",
        }
    },
    headeBill: {
        borderBottom: '2px solid #5391b4',
        display: "flex",
    },
    labelheadeBill: {
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: "bold",
        marginRight: "10px",
        "@media print": {
            fontSize: fontSizePrint
        }
    },
    headeBillLeft: {
        padding: "14px 10px 14px 20px",
        "@media (max-width: 700px)": {
            padding: "10px 10px 10px 10px",
        }
    },
    dataBill: {
        marginBottom: "10px",
        padding: "14px 10px 14px 20px",
        borderBottom: '2px solid #5391b4',
        "@media (max-width: 700px)": {
            padding: "10px 10px 10px 10px",
        },
    },
    labelDataBill: {
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: "bold",
        width: "90px",
        "@media print": {
            fontSize: fontSizePrint
        }

    },
    titleHeader: {
        marginLeft: "20px",
        fontFamily: "Poppins",
        fontWeight: "bold",
        color: "#5d8e9a",
        width: "266px"
    },
    borderSolidBlue: {
        border: "1px solid #5391b4",
    },
    headerTable: {
        BorderStyle: "none",
        backgroundColor: theme.palette.primary.light,
    },
    table: {
        cursor: "default",
        margin: "auto",
        marginBottom: "30px",
        '&.MuiTableContainer-root': {
            width: "98%"
        },
    },
    colorHeaderText: {
        position: "relative",
        color: "white",
        "@media (max-width: 700px)": {
            fontSize: "13px",
        }
    },
    boldText: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        "@media (max-width: 700px)": {
            fontSize: "10px",
        },
        ...tableFontSize


    },
    normalText: {
        color: theme.palette.primary.main,
        "@media (max-width: 700px)": {
            fontSize: "10px",
        },
        ...tableFontSize
    },
    white: {
        backgroundColor: "white",
    },
    tableCell: {
        '&.MuiTableCell-sizeSmall': {
            padding: "3px 24px 1px 16px",
        },
    },
    firstRow: {
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        "@media (max-width: 500px)": {
            borderRight: `1px solid ${"#2586bc00"}`,
            justifyContent: 'center',
        }
    },
    secondtRow: {
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        "@media (max-width: 500px)": {
            borderRight: `1px solid ${"#2586bc00"}`,
            justifyContent: 'center',
        }
    },
    borderBottom: {
        borderBottom: "1px solid #2586bc"
    },
    highlighted: {
        backgroundColor: "#00678026",

    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
    },
    containerLoading: {
        height: "100px"
    },
    buttonPrint: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: "10px",
        "@media print": {
            display: 'none'
        }
    },
    titleBill: {
        "@media print": {
            display: 'none'
        }
    },

}))

const UnderBillTable = ({
    selectPeriod, location, billPanel, setBillPanel
}) => {
    const classes = useStyles();
    const titleHeader = ["Concepto", "Valor", "Tarifa", "Subtotal"]
    const bill = billPanel?.bill?.factura
    const [infoLocalization, setInfoLocalization] = useState({});
    const [loading, setLoading] = useState(true);
    const [showOptionsDownload, setShowOptionsDownload] = useState(false);

    useEffect(() => {
        const getInfoNodeFunction = async () => {
            let res = await getExtraInfo(location?.id)
            setInfoLocalization(res?.data)
            setLoading(false)
        }
        getInfoNodeFunction()
    }, [location])

    const boldFunction = (bold) => {
        if (bold) {
            return classes.boldText
        } else {
            return ""
        }
    }

    const sumSlotTotal = (values) => {
        let arry = values?.slots
        let res = 0
        arry.forEach(element => {
            res += element.value
        })
        return res
    }
    const componentInputFile = (name, value, valueUnity, rate, rateUnity, subtotal, bold, sizeRateName) => {
        return (
            <TableRow key={name}>

                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.tableCell, boldFunction(bold),)} align="left">
                    {name}
                </TableCell>

                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.tableCell, boldFunction(bold),)} align="center">
                    <Grid container >
                        <Grid item xs className={classes.firstRow}>
                            {toFormattedNumber(value)}
                        </Grid>
                        <Grid item xs className={classes.secondtRow}>
                            {valueUnity}
                        </Grid>
                    </Grid >
                </TableCell>

                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.tableCell, boldFunction(bold),)} align="center">
                    <Grid container >
                        <Grid item xs className={classes.firstRow}>
                            {toFormattedNumber(rate)}
                        </Grid>
                        <Grid item xs className={classes.secondtRow}>
                            {rateUnity}
                        </Grid>
                    </Grid >
                </TableCell>

                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.tableCell, boldFunction(bold),)} align="right">
                    $  {toFormattedNumber(subtotal)}
                </TableCell>
            </TableRow>
        )
    }

    const componentInputTotals = (name, subtotal, bold) => {
        return (
            <TableRow key={name}>
                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.highlighted, classes.tableCell, boldFunction(bold))} align="left">
                    {name}
                </TableCell>

                <TableCell className={clsx(classes.borderBottom, classes.highlighted, classes.tableCell)} align="center">
                </TableCell>

                <TableCell className={clsx(classes.borderBottom, classes.highlighted, classes.tableCell)} align="center">
                </TableCell>

                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.highlighted, boldFunction(bold))} align="right">
                    $  {toFormattedNumber(subtotal)}
                </TableCell>
            </TableRow>
        )
    }

    const openOptions = () => {
        setShowOptionsDownload(!showOptionsDownload)
    }

    //Toma un print nativo de el browser.
    const takePrint = () => {
        window.print();
        setShowOptionsDownload(false)
    }

    const xlsxDownload = async () => {
        try {
            let response = await downloadXlsxSubBill(billPanel.bill.bill, billPanel.bill.id)
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'archivo.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) {
        }
        finally {
            setShowOptionsDownload(false)
        }
    }

    const csvDownload = async () => {
        try {
            let res = await downloadCsvSubBill(billPanel.bill.bill, billPanel.bill.id)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download', 'archivo.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (error) {
        }
        finally {
            setShowOptionsDownload(false)
        }
    }

    return (
        <>
            <Box display='flex' justifyContent='space-between'>

                <DialogTitle
                    className={classes.titleBill}
                    id='form-dialog-title'
                >
                    Factura
                </DialogTitle>

                <Box className={classes.buttonPrint}  >
                    <Box m={1} >
                        <IconButton
                            onClick={openOptions}
                        >
                            <CloudDownloadIcon />
                        </IconButton>
                    </Box>
                    <Box m={1}>
                        <IconButton
                            onClick={() => {
                                setBillPanel({
                                    ...billPanel,
                                    open: false
                                })
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
                    {showOptionsDownload &&
                        <Box sx={{ width: '100px', position: 'absolute', bgcolor: '#ede7e7', marginRight: '120px', marginTop: '8px', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.5)' }}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemText
                                            onClick={takePrint}
                                            primary="pdf" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component="a" href="#simple-list">
                                        <ListItemText
                                            onClick={xlsxDownload}
                                            primary="xlsx" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component="a" href="#simple-list">
                                        <ListItemText
                                            onClick={csvDownload}
                                            primary="csv" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    }
                </Box>
            </Box>

            {loading ?
                <Box className={classes.containerLoading}>
                    <div className={classes.circular}>
                        <CircularProgress />
                    </div>
                </Box>
                :

                <Box className={classes.containerMain} >

                    <Box className={classes.headeBill} >
                        <Box className={classes.headeBillLeft}>
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                <Box className={classes.labelheadeBill} > Factura electrica: </Box>
                                <Box>{`${location?.nombre}`}</Box>
                            </Box>

                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                <Box className={classes.labelheadeBill} > Direccion: </Box>
                                <Box>{`${infoLocalization?.address}`}</Box>
                            </Box>

                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                <Box className={classes.labelheadeBill} > Fecha: </Box>
                                <Box>{`${format(new Date(), 'dd-MM-yyyy')}`}</Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.dataBill} >
                        <Box style={{ display: "flex", flexWrap: "wrap", }}>
                            <Box className={classes.labelDataBill} > Usuario: </Box>
                            <Box>{`${billPanel?.bill?.sublocation_name}`}</Box>
                        </Box>

                        <Box style={{ display: "flex", flexWrap: "wrap", }}>
                            <Box className={classes.labelDataBill}> Tarifa :</Box>
                            <Box> {`${billPanel?.bill?.rate}`}</Box>
                        </Box>

                        <Box style={{ display: "flex", flexWrap: "wrap", }} >
                            <Box className={classes.labelDataBill}>Periodo: </Box>
                            <Box>{`${selectPeriod?.name}  ( Desde: ${formatDateStrDashToSlash(selectPeriod?.date_from)} -  Hasta: ${formatDateStrDashToSlash(selectPeriod?.date_to)} )`}</Box>
                        </Box>
                    </Box>

                    {/* CONTAINER PRINCIPAL TABLA */}
                    <TableContainer className={classes.table} component={Paper}>

                        <Table size="small" aria-label="simple table">

                            {/* Header Table */}
                            <TableHead >
                                <TableRow className={classes.headerTable}>
                                    {titleHeader.map((elem, i) => {
                                        return (
                                            <TableCell key={i} className={clsx(classes.colorHeaderText, classes.borderSolidBlue)} align="center">
                                                {elem}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>

                            {/* Body */}
                            <TableBody>

                                {/* ENERGIA ACTIVA */}
                                {componentInputFile("Energia activa", bill?.energy_use?.total, "kWh", false, "", bill?.energy_cost?.total, true)}

                                {/* franjas de energia */}
                                {
                                    bill?.energy_use?.slots.map((elem, i) => {
                                        let name = elem.name
                                        let value = elem.value
                                        let rate = bill?.energy_cost?.slots[i]?.cost
                                        let subTotal = bill?.energy_cost?.slots[i]?.value
                                        return (
                                            componentInputFile(`- ${name}`, value, "kWh", rate, "$/kWh", subTotal, false, true)
                                        )
                                    })
                                }

                                {/* POTENCIA CONTRATADA */}
                                {componentInputFile("Potencia contratada", sumSlotTotal(bill?.hired_demand), "kW", false, "", bill?.hired_demand_cost?.total, true)}

                                {/* franjas de potencia contratada*/}
                                {
                                    bill?.hired_demand?.slots.map((elem, i) => {
                                        let name = elem.name
                                        let value = elem.value
                                        let rate = bill?.hired_demand_cost?.slots[i]?.cost
                                        let subTotal = bill?.hired_demand_cost?.slots[i].value
                                        return (
                                            componentInputFile(`- ${name}`, value, "kW", rate, "$/kW", subTotal, false, true)
                                        )
                                    })
                                }

                                {/* POTENCIA REGISTRADA */}
                                {componentInputFile("Potencia registrada", sumSlotTotal(bill?.peak_demand), "kW", false, "", bill?.peak_demand_cost?.total, true)}

                                {/* franjas de potencia registrada*/}
                                {
                                    bill?.peak_demand?.slots.map((elem, i) => {
                                        let name = elem.name
                                        let value = elem.value
                                        let rate = bill?.peak_demand_cost?.slots[i]?.cost
                                        let subTotal = bill?.peak_demand_cost?.slots[i].value
                                        return (
                                            componentInputFile(`- ${name}`, value, "kW", rate, "$/kW", subTotal, false, true)
                                        )
                                    })
                                }

                                {/* POTENCIA REACTIVA*/}
                                {componentInputFile("Potencia reactiva", 0, "kVARh", false, "", 0, true)}

                                {/* POTENCIA EXCEDIDA*/}
                                {componentInputFile("Potencia excedida", 0, "kW", false, "", 0, true)}

                                {/* SUBTOTAL */}
                                {componentInputTotals("Subtotal", bill?.sub_total, true)}

                                {/* IMPUESTO 1*/}
                                {componentInputFile("Impuesto 1", 0, "kW", 0, "$/kW", 0)}

                                {/* IMPUESTO n°*/}
                                {componentInputFile("Impuesto n", 0, "kW", 0, "$/kW", 0)}

                                {/* TOTAL */}
                                {componentInputTotals("Total", bill?.total, true)}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box >
            }
        </>
    )
}
export default UnderBillTable




