import React, { useState } from 'react';
import { Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip, Box } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeedIcon from '@material-ui/icons/Speed';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WifiIcon from '@mui/icons-material/Wifi';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  empresa: {
    "@media (max-width: 720px)": {
      height: "50px",
      marginBottom: "1px",
      padding: "10px",
    }
  },
  localizacion: {
    "@media (max-width: 720px)": {
      height: "50px",
      marginBottom: "1px",
      padding: "10px",
    }
  },
  meterWithoutChild: {
    marginRight: 11,
    "@media (max-width: 720px)": {
      padding: '6px'
    }
  },
  nested: {
    "@media (max-width: 720px)": {
      left: "-32px",
      position: "relative",
      width: 'calc(100% + 32px)'
    },
    paddingLeft: theme.spacing(4)
  },
  nodeHeader: {
    '& p': {
      fontSize: "larger",
      marginLeft: '5px'
    },
    '& span': {
      fontSize: "larger",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    display: 'flex',
    marginRight: "100px",
    "@media (max-width: 720px)": {
      maxWidth: '50%',
      marginRight: "120px",
      '& p': {
        fontSize: "small",
        marginLeft: '5px'
      },
      '& span': {
        fontSize: "small",
        marginLeft: '5px',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      },
      flexDirection: " column"
    },

  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    '&.MuiListItem-root.Mui-selected p': {
      color: theme.palette.common.white
    },
  },
  sublocaclizacion: {
    "@media (max-width: 720px)": {
      height: "50px",
      marginBottom: "1px",
      padding: "10px",
    }
  },
  zona: {
    "@media (max-width: 720px)": {
      height: "50px",
      marginBottom: "1px",
      padding: "10px",
    }
  },
  button: {
    '&.MuiIconButton-edgeEnd': {
      marginRight: -theme.spacing(3)
    }
  },
  smallPadding: {
    padding: '6px'
  }
}));

export default function StateNode({ node, selectedNode, handleSelectedNode, status }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const nodeStatus = status.find(n => n.node_id === node.id)

  const handleArrows = () => {
    setOpen(!open);
  };

  const classNodeSelect = (node) => {
    if (node.level_nombre === "empresa") {
      return classes.empresa
    }
    else if (node.level_nombre === "zona") {
      return classes.zona
    }
    else if (node.level_nombre === "localización") {
      return classes.localizacion
    }
    else if (node.level_nombre === "sublocalización") {
      return classes.sublocaclizacion
    }
    else {
      return ""
    }
  }

  return (
    <>
      <ListItem
        button
        selected={selectedNode ? selectedNode.id === node.id : false}
        className={clsx(classes.selected, classNodeSelect(node))}
        onClick={() => handleSelectedNode(node)}
        disabled={!node.medidor}
      >
        {!node.owner && (
          <Tooltip title={`El nodo ${node.nombre} es compartido`}>
            <IconButton edge='end' aria-label='nodo compartido' size='small' color="primary" className={classes.smallPadding}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}
        <Box bgcolor={node.color} p={0.25} m={0.5} width={20} height={3} />
        <ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={classes.nodeHeader} />
        <ListItemSecondaryAction>

          {/* TODO: Agregar lógica que según valor de rssi muestre Wifi1BarIcon, Wifi2BarIcon o WifiIcon*/}
          {
            nodeStatus?.alert &&
            <Tooltip title={`${node.nombre} tiene alertas`}>
              <IconButton
                edge='end'
                aria-label='el medidor tiene alertas'
                className={classes.smallPadding}>
                <ReportProblemIcon />
              </IconButton>
            </Tooltip>
          }
          {
            nodeStatus?.rssi === "-10dB" &&
            <Tooltip title={`${node.nombre} tiene excelente señal de WiFi`}>
              <IconButton
                edge='end'
                aria-label='la señal de wifi es excelente'
                className={classes.smallPadding}>
                <WifiIcon />
              </IconButton>
            </Tooltip>
          }

          {node.medidor ? (
            <Tooltip title={`${node.nombre} tiene un medidor asociado ${nodeStatus?.online ? 'online' : 'offline'}`}>
              <IconButton
                edge='end'
                aria-label='medidores asociados'
                classes={!node.hijos.length ? { root: classes.meterWithoutChild } : {}}

              >
                <SpeedIcon style={nodeStatus?.online ? { color: '#66bb6a' } : { color: '#ef5350' }} />
              </IconButton>
            </Tooltip>
          ) : null}
          {node.hijos &&
            node.hijos.length > 0 &&
            (open ? (
              <IconButton edge='end' aria-label='contraer' onClick={handleArrows} className={classes.button}>
                <ExpandLessIcon />
              </IconButton>
            ) : (
              <IconButton edge='end' aria-label='expandir' onClick={handleArrows} className={classes.button}>
                <ExpandMoreIcon />
              </IconButton>
            ))}
        </ListItemSecondaryAction>

      </ListItem>

      {node.hijos && (
        <Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
          <List component='div' disablePadding>
            {node.hijos.map(c => (
              <StateNode
                node={c}
                key={c.id}
                handleSelectedNode={handleSelectedNode}
                selectedNode={selectedNode}
                status={status}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
