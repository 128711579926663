import { useEffect, useState } from 'react';
// Material UI
import {
    Box, Button, Grid, makeStyles,
    Dialog, DialogContent, DialogTitle, CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "40px",
        color: theme.palette.primary.main,
    },
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '16px',
    },
    btnDeleteAccount: {
        margin: "20px 40px"
    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
}));


const DeletePanel = ({ openDeletePanel, handleOpenCloseDeletePanel, deleteLocal }) => {
    const classes = useStyles();
    const [login, setLogin] = useState(false)

    useEffect(() => {
        if (openDeletePanel === true) {
            setLogin(false)
        }
    }, [openDeletePanel])

    const deleteSimulatorTime = () => {
        setLogin(true)
    }

    return (
        <Dialog open={openDeletePanel} onClose={handleOpenCloseDeletePanel} aria-labelledby='form-dialog-title' fullWidth>
            <DialogTitle id='form-dialog-title'>Eliminar</DialogTitle>
            <DialogContent>
                {login ?
                    <div className={classes.circular}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <Grid className={classes.label}>
                            El proceso de eliminación es irreversible y  representará la perdida total de el periodo.
                        </Grid>
                        <p>Para eliminar su periodo haga click en "Eliminar"</p>
                        <Box display='flex' justifyContent='center' mt={1} mb={1}>
                            <Button
                                onClick={() => { deleteLocal(); deleteSimulatorTime() }}
                                variant='outlined' color='secondary' className={classes.btnSpacing}>
                                Eliminar
                            </Button>
                            <Button
                                onClick={handleOpenCloseDeletePanel}
                                variant='contained' className={classes.btnSpacing}>
                                Cancelar
                            </Button>
                        </Box>
                    </>
                }
            </DialogContent>
        </Dialog >
    )
}
export default DeletePanel 