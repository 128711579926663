export const FIELDS_MAP = {
  energiaHora: 'KWH',
  energiaDia: 'kwh',
  adquiridaHora: 'KW',
  adquiridaDia: 'kw_max',
};

export const COLORS = {
  MAX_POSITIVE_VALUE: '#fe131a',
  MIN_POSITIVE_VALUE: '#fee56e',
  MAX_NEGATIVE_VALUE: '#83ebc6',
  MIN_NEGATIVE_VALUE: '#167d3e',
};

export const VARIABLE_OPTIONS = [
  {
    label: 'Energía por hora',
    value: 'energiaHora',
    // TODO Descomentar cuando tengamos plan?.access?.analisis_heatmap
    // disabled: !plan?.access?.analisis_heatmap_energiaactiva,
    disabled: false,
  },
  {
    label: 'Energía por día',
    value: 'energiaDia',
    // TODO Descomentar cuando tengamos plan?.access?.analisis_heatmap
    // disabled: !plan?.access?.analisis_heatmap_energiaactiva,
    disabled: false,
  },
  {
    label: 'Potencia adquirida por hora',
    value: 'adquiridaHora',
    // TODO Descomentar cuando tengamos plan?.access?.analisis_heatmap
    // disabled: !plan?.access?.analisis_heatmap_energiaactiva,
    disabled: false,
  },
  {
    label: 'Potencia adquirida máxima por día',
    value: 'adquiridaDia',
    // TODO Descomentar cuando tengamos plan?.access?.analisis_heatmap
    // disabled: !plan?.access?.analisis_heatmap_energiaactiva,
    disabled: false,
  },
];

export const CALENDAR_INITIAL_CONFIG = {
  views: ['days', 'year'],
  disabled: false,
  format: 'dd/MM/yyyy',
};

export const getLabelText = value => {
  const object = VARIABLE_OPTIONS.find(option => option.value === value);

  return object.label;
};

export const calculateMinMax = array => {
  // Calculate mean
  const sum = array.reduce((acc, curr) => acc + curr, 0);
  const mean = sum / array.length;

  // Calculate standard deviation
  const squaredDifferences = array.map(item => Math.pow(item - mean, 2));

  const variance =
    squaredDifferences.reduce((acc, curr) => acc + curr, 0) / array.length;

  const stdev = Math.sqrt(variance);

  // / Calculate minimum and maximum
  const minimum = Math.max(mean - 2 * stdev, Math.min(...array));

  const maximum = mean + 2 * stdev;

  const realMaximum = Math.max(...array)
  const realMinimum = Math.min(...array)

  return { minimum, maximum, realMinimum, realMaximum };
};

// Calcular el color basándonos en el valor actual y enlos mínimos y máximos.
export function getProportionalColor(
  value,
  minValue,
  maxValue,
  minColor,
  maxColor
) {
  // Normalizar el valor dentro del rango
  const normalizedValue = (value - minValue) / (maxValue - minValue);

  // Extract RGB components
  const rMin = parseInt(minColor.substr(1, 2), 16);
  const gMin = parseInt(minColor.substr(3, 2), 16);
  const bMin = parseInt(minColor.substr(5, 2), 16);

  const rMax = parseInt(maxColor.substr(1, 2), 16);
  const gMax = parseInt(maxColor.substr(3, 2), 16);
  const bMax = parseInt(maxColor.substr(5, 2), 16);

  // Interpolar el color entre minColor y maxColor
  const r = Math.round((rMax - rMin) * normalizedValue + rMin);
  const g = Math.round((gMax - gMin) * normalizedValue + gMin);
  const b = Math.round((bMax - bMin) * normalizedValue + bMin);
  return `rgb(${r},${g},${b})`;
}

export const getChartUnit = variable => {
  if (variable.includes('energia')) return 'Wh';
  if (variable.includes('adquirida')) return 'W';
};
