import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
    Box, Button, CircularProgress,
    Grid, makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Material Icons
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
//Componentes
import TopNav from '../../common/TopNav';
import Container from '../../Elements/Container';
import CardNodeData from '../ComponentsDashboards/CardNodeData';
import IndictorCard from '../ComponentsDashboards/IndicatorCard';
import ContainerImg from '../ComponentsDashboards/ContainerImg';
import RankingGraph from '../ComponentsDashboards/RankingGraph';
import PieChart from '../ComponentsDashboards/PieChart';
import Filter from './Filter'
import ContainerTagsChart from '../ComponentsDashboards/ContainerTagsChart';
import ContainerChartHistorical from '../ComponentsDashboards/ContainerChartHistorical';
import ContainerChartInstant from '../ComponentsDashboards/ContainerChartInstant';
import AlertComponent from '../../Elements/AlertComponent';
import SummaryOutages from '../../Quality/SummaryOutages';
import SunburstChart from '../ComponentsDashboards/SunBurstChart';
// Custom Hooks
import useDeviceDetect from '../../../hooks/useDeviceDetect';
//Style
import clsx from 'clsx';
//Servicios
import { getSummaryLocation } from '../../../services/hierarchy';
//Helpers
import { toFormattedNumber, returnDateString } from '../../../helpers/common'
import ContainerCards from '../ComponentsDashboards/ContainerCards';

const useStyles = makeStyles(theme => ({
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    btnMobile: {
        '& .MuiButton-startIcon': {
            marginRight: 0,
        },
    },
    buttonNav: {
        '@media (max-width: 920px)': {
            margin: theme.spacing(0, 0, 1, 1),
            paddingTop: theme.spacing(2),
        },
    },
    textValues: {
        fontSize: '34px',
        color: '#9d9898',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center'
    },
    firstContainer: {
        padding: '0px 5px 6px 5px',
        '@media (max-width: 920px)': {
            padding: '0px',
        }
    },
    secondContainer: {
        padding: '0px 5px 0px 5px',
        '@media (max-width: 920px)': {
            padding: '0px',
        }
    },
    firstContainerTopBoxes: {
        width: '100%',
        display: 'flex',
        minHeight: '380px',
        // height: '44vh',
        height: '445px',
        '@media (max-width: 820px)': {
            flexWrap: 'wrap',
            height: '100%',
        }
    },
    firstContainerBottomBoxes: {
        minHeight: '425px',
        display: 'flex',
        height: '53vh',
        '@media (max-width: 1300px)': {
            flexDirection: 'column',
            height: '100%',
        }
    },
    boxes: {
        border: '1px solid #cbc1c157',
        boxShadow: '3px 3px 3px #cbc1c157',
        margin: theme.spacing(0.4),
    },
    smallBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '50%',
        '@media (max-width: 720px)': {
            width: '100%',
            height: '260px'
        }
    },
    cardDataContainer: {
        width: '440px',
        '@media (max-width: 820px)': {
            width: '100%',
        }
    },
    pieChardCarbonBoxesContainer: {
        width: 'calc(50% + 9px)',
        '@media (max-width: 1300px)': {
            width: '100%',
        }
    },
    carbonBoxesContainer: {
        width: 'calc(100% - 3px)',
        height: '100%'
    },
    pieChartsContainer: {
        width: '50%',
        height: '100%',
        '@media (max-width: 1300px)': {
            width: 'calc(100% - 9px)',
            height: '400px'
        }
    },
    imgNodeContainer: {
        width: '100%',
        '@media (max-width: 1300px)': {
            width: '100%'
        }
    },
    graphCarbonStyleContainer: {
        height: '42%',
        '@media (max-width: 720px)': {
            height: '35%',
        }
    },
    boxesBottomContainer: {
        display: 'flex',
        height: '58%',
        // minHeight:''
        '@media (max-width: 720px)': {
            flexDirection: 'column',
            width: 'calc(100% - 9px)',
        }
    },
    chartHistoricTagsContainer: {
        width: '100%',
        display: 'flex',
        '@media (max-width: 1300px)': {
            flexDirection: 'column'
        }
    },
    powerBarChartStyle: {
        width: '50%',
        height: '350px',
        minHeight: '240px',
        '@media (max-width: 1300px)': {
            width: 'calc(100% - 9px)',
        }

    },
    secondContainerBoxesMed: {
        display: 'flex',
        '@media (max-width: 1300px)': {
            flexDirection: 'column'
        }
    },
    boxesPowerSecond: {
        width: '50%',
        display: 'flex',
        height: '29vh',
        minHeight: '240px',
        '@media (max-width: 1300px)': {
            width: 'calc(100% - 1px)',
        },
        '@media (max-width: 720px)': {
            flexDirection: 'column',
            width: 'calc(100% - 9px)',
            height: '100%'
        }
    },
}));


const SummaryLocation = () => {
    const classes = useStyles();
    const history = useHistory();
    const { isMobile } = useDeviceDetect();
    const locationHook = useLocation();
    const initialValue = locationHook?.state || {};
    //estado para abrir el panel del filtro
    const [filterPanel, setFilterPanel] = useState(locationHook.state ? false : true);
    const [location, setLocation] = useState(initialValue);
    //estado q muestra page principal o alert de bienvenida
    const [showMain, setShowMain] = useState(false);
    //Loading principal
    const [loadingMain, setLoadingMain] = useState(false);
    //estado general de data.
    const [stateData, setStateData] = useState({});
    //Estados alert
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: '',
        type: 'error'
    })

    useEffect(() => {
        const dataSumaryLocation = async (id) => {
            try {
                setShowMain(true)
                setLoadingMain(false)
                let res = await getSummaryLocation(id)
                setStateData(res.data)
                setLoadingMain(true)
            }
            catch (error) {
                setShowMain(false)
                setClose(true)
                let msg = 'Ocurrio un error inesperado.'
                if (error?.response?.data?.msg) {
                    msg = error?.response?.data?.msg
                }
                setMsg({
                    msg: msg,
                    type: 'error'
                })
                return
            }
        }

        // Limpio el historial para que si vengo de dashboard general al refrescar no persista localización inicial.
        history.replace({ ...location, state: undefined }); // Clear the location state

        //Si existe un id hago la busqueda de datos
        if (location?.id) {
            dataSumaryLocation(location?.id)
        }
    }, [location, history])

    const togglePanel = () => {
        setFilterPanel(!filterPanel);
    };

    return (
        <>
            <TopNav titulo='Dashboards' subtitulo={`| Resumen localización `}>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    {/* Nombre Localización */}
                    <Button
                        className={
                            isMobile ? clsx(classes.btn, classes.btnMobile) : classes.btn
                        }
                        type='button'
                        onClick={togglePanel}
                        size='small'
                        startIcon={<LabelImportantIcon />}
                    >
                        {!isMobile ? (location?.nombre || `Seleccionar Localización`) : ''}
                    </Button>
                </Grid>
            </TopNav>

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />
            { /* CONTENEDOR PRINCIPAL */}
            < Container >

                {/* Alert bienvenido */}
                {!showMain &&
                    <Box >
                        <Alert className={classes.alert} severity='info'>
                            Bienvenido al Dashboard Resumen Localización. En esta sección podrá consultar la información de sus Localizaciones para seleccionar una, haga click en
                            el botón
                            <span className={classes.alertIcon}>
                                <LabelImportantIcon style={{ fontSize: '18px' }} />
                            </span>
                            , elija la localización y haga click en Aceptar.
                        </Alert>
                    </Box>
                }

                {/* Filtro */}
                <Filter
                    isOpen={filterPanel}
                    setIsOpen={setFilterPanel}
                    setLocation={setLocation}
                    location={location}
                />

                {/* CONTAINER PAGE */}
                {showMain &&
                    <Box className={classes.firstContainer}>

                        {loadingMain ?
                            <>
                                <Box className={classes.firstContainerTopBoxes}>
                                    {/* Carta datos localizacion*/}
                                    <Box className={clsx(classes.boxes, classes.cardDataContainer)}>
                                        <CardNodeData
                                            stateData={stateData}
                                            location={location}
                                        />
                                    </Box>
                                    {/* Contenedor imagen localizacion*/}
                                    <Box className={clsx(classes.boxes, classes.imgNodeContainer)}>
                                        <ContainerImg location={location} />
                                    </Box>
                                </Box>

                                <Box className={classes.firstContainerBottomBoxes} >
                                    <Box className={clsx(classes.pieChardCarbonBoxesContainer)}>
                                        <Box className={clsx(classes.carbonBoxesContainer)}>
                                            {/* Grafico carbon */}
                                            <Box
                                                className={clsx(classes.boxes, classes.graphCarbonStyleContainer)} >
                                                <RankingGraph
                                                    currentValue={stateData?.energy_x_sup}
                                                    sup={stateData?.sup}
                                                />
                                            </Box>
                                            <Box className={clsx(classes.boxesBottomContainer)} >
                                                {/* Carta consumo mensual*/}
                                                <Box className={clsx(classes.smallBox, classes.boxes)}>

                                                    <ContainerCards
                                                        config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_TOTAL-VALIDATE_HISTORY-ESTE_MES-MONTHLY"
                                                        id={location.id}
                                                        variable="Energía activa consumida"
                                                        subtitle={returnDateString('m')}
                                                        title="Energía consumida |"
                                                        size={false}
                                                        marginTitle={1}
                                                    />
                                                </Box>
                                                {/* Carta Energía compara este mes mes anterior (energía proyectada)*/}
                                                <Box className={clsx(classes.smallBox, classes.boxes)}>

                                                    <ContainerCards
                                                        config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_PROYECTADA_TOTAL-FORECAST-HOY-DAILY"
                                                        id={location.id}
                                                        variable="Energía activa proyectada"
                                                        subtitle={returnDateString('m')}
                                                        title="Consumo proyectado |"
                                                        size={false}
                                                        marginTitle={1}
                                                    />
                                                </Box >
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* Grafico de torta */}
                                    <Box className={clsx(classes.boxes, classes.pieChartsContainer)}>
                                        {stateData.energy_sector.length > 0 ?
                                            <SunburstChart
                                                stateData={stateData}
                                                location={location}
                                                subTitle={returnDateString('m')}
                                            />
                                            :
                                            <PieChart
                                                location={location}
                                                subTitle={returnDateString('m')}
                                            />
                                        }
                                    </Box>
                                </Box>
                            </>
                            :
                            <>
                                <Box display='flex' justifyContent='center' mt={2}>
                                    <CircularProgress />
                                </Box>
                            </>
                        }
                    </Box>}

                {loadingMain &&
                    <Box className={classes.secondContainer}>
                        <Box className={clsx(classes.chartHistoricTagsContainer)}>
                            {/* Grafico energía por tag */}
                            <Box className={clsx(classes.boxes, classes.powerBarChartStyle)}>
                                <ContainerTagsChart
                                    titleDisplay={true}
                                    tags={stateData?.tags}
                                    id={location?.id}
                                />
                            </Box>
                            {/* Grafico Historico este mes */}
                            <Box className={clsx(classes.boxes, classes.powerBarChartStyle)}>
                                < ContainerChartHistorical
                                    height={'93%'}
                                    config_key={"DASHBOARDS_HISTORY-ENERGIA_ACTIVA_TOTAL-VALIDATE_HISTORY-ESTE_MES-DAILY"}
                                    ky={'1'}
                                    title={'Energía diaria'}
                                    id={location?.id}
                                />
                            </Box>
                        </Box>
                        <Box >
                            <Box className={classes.secondContainerBoxesMed}>
                                <Box className={classes.boxesPowerSecond}>
                                    {/* Carta cosFi*/}
                                    <Box className={clsx(classes.smallBox, classes.boxes)}>
                                        <ContainerCards
                                            config_key="DASHBOARDS_INDICATOR-COS_(FI)_TOTAL-VALIDATE_HISTORY-ESTE_MES-MONTHLY"
                                            id={location.id}
                                            variable="Cos (fi)"
                                            subtitle={returnDateString('m')}
                                            title="cos(fi) de este mes |"
                                            size={false}
                                            marginTitle={2}
                                        />
                                    </Box>
                                    {/* Carta potencia compara este mes mes anterior*/}
                                    <Box className={clsx(classes.smallBox, classes.boxes)}>
                                        <ContainerCards
                                            config_key="DASHBOARDS_INDICATOR-POTENCIA_ADQUIRIDA_MAXIMA-COMPARISON-ESTE_MES-MONTHLY"
                                            id={location.id}
                                            variable="Potencia adquirida maxima"
                                            subtitle={returnDateString('m')}
                                            title="Potencia máxima |"
                                            size={false}
                                            marginTitle={2}
                                        />
                                    </Box >
                                </Box>
                                <Box className={classes.boxesPowerSecond} >
                                    <Box className={clsx(classes.smallBox, classes.boxes)}>
                                        <IndictorCard
                                            title='Calidad |'
                                            subTitle='de energía'
                                            value={toFormattedNumber(stateData?.vmed?.value)}
                                            unity={'v'}
                                            quality={stateData}
                                            marginTitle={2}
                                        />
                                    </Box>
                                    <Box className={clsx(classes.smallBox, classes.boxes)}>
                                        <SummaryOutages
                                            powerCutCant={stateData?.power_cuts}
                                            powerCutTime={stateData?.power_cuts_time}
                                            dashboard={true}
                                            eventsData={
                                                {
                                                    page: [
                                                        {
                                                            event: {
                                                                duration: stateData?.power_cuts_time

                                                            }

                                                        }
                                                    ]
                                                }
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={clsx(classes.chartHistoricTagsContainer)} >
                                {/* Grafico instant este mes */}
                                <Box className={clsx(classes.boxes, classes.powerBarChartStyle)} >
                                    < ContainerChartInstant
                                        height={'90%'}
                                        config_key={"DASHBOARDS_INSTANT-POTENCIA_ACTIVA_TOTAL-VALIDATE_INSTANT-ESTE_MES-60_MINUTES"}
                                        title={'Demanda de potencia'}
                                        id={location?.id}
                                        variable={'Potencia activa total'}
                                    />
                                </Box>
                                {/* Grafico Historico este Año */}
                                <Box className={clsx(classes.boxes, classes.powerBarChartStyle)}  >
                                    < ContainerChartHistorical
                                        height={'93%'}
                                        config_key={"DASHBOARDS_HISTORY-ENERGIA_ACTIVA_TOTAL-VALIDATE_HISTORY-ESTE_ANO-MONTHLY"}
                                        ky={'2'}
                                        title={'Energía Mensual'}
                                        date={'Mensual'}
                                        id={location?.id}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            </Container>
        </>
    );
}

export default SummaryLocation;