import { useEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_sliceGrouper from '@amcharts/amcharts4/plugins/sliceGrouper';

import { Box, makeStyles } from '@material-ui/core';
import { getFormattedNumber, getUnit } from '../../../helpers/common';

am4core.useTheme(am4themes_animated);

// import { changeLigthColor } from "../../helpers/controllers";
const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    border: '1px solid #cbc1c157',
    boxShadow: '3px 3px 3px #cbc1c157',
  },
  title: {
    color: theme.palette.grey.hairline,
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(1.5, 0, 0.5, 2),
    fontWeight: 600,
    padding: theme.spacing(1, 0, 1, 0),
  },
  subtitle: {
    fontWeight: 400,
  },
  chart: {
    margin: theme.spacing(1),
    flexGrow: 1,
    width: '100%',
  },
}));

function DashPie({ name, title, subtitle, data, variable }) {
  const classes = useStyles();

  useEffect(() => {
    let chart = am4core.create(name, am4charts.PieChart);

    if (chart.logo) {
      chart.logo.disabled = true;
    }
    
    const filteredData = data.filter(row => row[variable]!==0)

    const formattedData = filteredData.map(row => ({
      ...row,
      formatted: `${getFormattedNumber(row[variable])} ${getUnit(row[variable])}Wh`,
    }));

    chart.data = [...formattedData];

    chart.innerRadius = am4core.percent(30);
    chart.radius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = variable;
    pieSeries.dataFields.category = 'name';

    // pieSeries.slices.template.stroke = am4core.color('#000');
    pieSeries.slices.template.strokeWidth = 0;
    pieSeries.slices.template.strokeOpacity = 0;
    pieSeries.slices.template.propertyFields.fill = 'color';

    pieSeries.labels.template.paddingTop = 2;
    pieSeries.labels.template.paddingBottom = 2;
    pieSeries.labels.template.fontSize = 13;

    // pieSeries.labels.template.truncate = true;
    pieSeries.labels.template.wrap = true;
    pieSeries.labels.template.maxWidth = 200;

    pieSeries.labels.template.radius = am4core.percent(5);
    pieSeries.labels.template.relativeRotation = 90;
    pieSeries.labels.template.fill = am4core.color('#9e9e9e');
    pieSeries.labels.template.fontSize = 12;
    pieSeries.labels.template.maxWidth = 150;


    pieSeries.labels.template.text = "{category}: [bold]{value.percent.formatNumber('#.#')}%[/]";

    pieSeries.slices.template.fillOpacity = 0.7;

    // pieSeries.ticks.template.disabled = true; // disable ticks

    pieSeries.slices.template.tooltipText = "[bold]{category}[/]\n{formatted}";

    const grouper = pieSeries.plugins.push(
      new am4plugins_sliceGrouper.SliceGrouper()
    );
    grouper.clickBehavior = 'zoom';
    grouper.groupName = 'Otros';
    grouper.threshold = 5;

    // Hide slices with value 0
    pieSeries.hideZeroValue = true;

    // chart.legend = new am4charts.Legend();
    // // Ocultar leyenda
    // chart.legend.itemContainers.template.height = 0;

    return () => {
      chart.dispose();
    };
  }, [data, name, variable]);

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {title}
        <span className={classes.subtitle}> | {subtitle}</span>
      </Box>

      <Box id={name} className={classes.chart} />
    </Box>
  );
}

export default DashPie;
