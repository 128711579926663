import { useState, useEffect } from 'react';
import {
    Box, makeStyles, Tooltip,
    TextField, FormGroup, Switch, FormControlLabel
} from '@material-ui/core';
//Icons
import HelpIcon from '@mui/icons-material/Help';
//Helpers
import { blueBar } from '../../helpers/common'
//Componentes
import ButtonComponent from "./ButtonComponent";
// //servicios 
import { putControlSetting } from '../../services/meters'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 1, 0),
        border: "0.5px solid #B6B1B1",
        borderRadius: "4px",
        padding: theme.spacing(1),
        width: "100%"
    },
    title: {
        fontSize: theme.spacing(1.9),
        fontWeight: '600',
        color: theme.palette.primary.main
    },
    subTitle: {
        color: theme.palette.grey.hairline,
        fontWeight: '400'
    },
    titleGrey: {
        fontSize: theme.spacing(1.6),
        fontWeight: '600',
        color: theme.palette.grey.hairline,
    },
    labelTimerInput: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(1),
        color: theme.palette.primary.main
    },
    inputCost: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    }
}));

export default function ReleControl({
    setLoading,
    setAlertState,
    options,
    stateData
}) {
    const classes = useStyles();
    const blueLine = blueBar()
    const [releState, setReleState] = useState(false)
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        const { TIMER_GET, RELAY_STATUS } = stateData;
        if (TIMER_GET) {
            setTimer(TIMER_GET.MSJ);
        }
        if (RELAY_STATUS) {
            if (RELAY_STATUS.MSJ === "on") {
                setReleState(true);
            } else {
                setReleState(false);
            }
        }
        // eslint-disable-next-line  
    }, []);

    //maneja el estado de el switch relay.
    const handleSwitch = async () => {
        setReleState(!releState)
        setLoading(true)
        let auxKey = "RELAY_OFF"
        let msg = "Ocurrio un error inesperado"
        let type = "error"
        if (!releState) {
            auxKey = "REALY_ON"
        }
        try {
            let auxBody = {
                key: auxKey
            }
            let res = await putControlSetting(options?.id, auxBody)
            msg = res.data
            type = "success"
        }
        catch (error) {
            if (error?.response?.data) {
                msg = error?.response?.data
            }
        }
        setAlertState({
            close: true,
            msg: msg,
            type: type
        })
        setLoading(false)
    }

    //Controlador del timer
    const handleTimerChange = (e) => {
        setTimer(e.target.value)
    }
    //Envia la data seteada para el timer rele.
    const HandleSubmit = async () => {
        setLoading(true)
        let msg = "Ocurrio un error inesperado"
        let type = "error"
        try {
            let auxBody = {
                "key": "MIN_TIMER",
                params: timer
            }
            let res = await putControlSetting(options?.id, auxBody)
            if (res?.data && res.data.length > 0) {
                msg = res.data[0];
                type = "success";
            }
        }
        catch (error) {
            if (error?.response?.data) {
                msg = error?.response?.data
            }
        }
        setAlertState({
            close: true,
            msg: msg,
            type: type
        })
        setLoading(false)
    }
    return (
        <Box className={classes.root}>

            {/* titulo */}
            <Box ml={2} mb={2} className={classes.title} >
                {"Control"}
                <span className={classes.subTitle} >
                    {` | ${"salida auxiliar (contacto seco)"}`}
                </span>
            </Box>

            {blueLine}


            {/*Timer*/}
            <Box ml={1} mt={2} className={classes.titleGrey} >
                {"Timer"}
                <span className={classes.subTitle} >
                    {` | ${"activacion minima"}`}
                </span>
            </Box>

            {/* CARGO FIJO INPUT */}
            <Box ml={1} style={{ display: "flex", marginBottom: "14px" }}>
                <Box mr={1} component='label' display='block' className={classes.labelTimerInput}>
                    MIN
                </Box>


                <Box mt={1} mr={2} style={{ cursor: "pointer" }}>
                    <Tooltip title={"Tiempo mínimo de permanencia en estado de activación (en segundos) cuando se dispara cualquier alarma configurada en el dispositivo. NOTA: en 0 segundos significa que la salida auxiliar no se activa nunca por disparo de alarmas."}>
                        <HelpIcon sx={{ fontSize: 20 }} color="disabled" />
                    </Tooltip>
                </Box>

                <TextField
                    className={classes.inputCost}
                    autoFocus
                    type="number"
                    placeholder={"0"}
                    name='name'
                    value={timer}
                    onChange={handleTimerChange}
                    color='primary'
                // disabled={disabledInputFunction()}
                />
            </Box>

            <Box display='flex' justifyContent='center' flexDirection={"column"} alignItems={"center"}>
                < ButtonComponent
                    children={
                        <>
                            <Box ml={1} style={{ fontWeight: "bold" }}> {"Enviar"}</Box>
                        </>
                    }
                    HandleSubmit={HandleSubmit}
                    backgroundColorButton={"primary"}
                />
            </Box>

            {blueLine}

            {/*Estado del rele*/}
            <Box ml={1} mt={2} mb={1} className={classes.titleGrey} >
                {"Estado"}
                <span className={classes.subTitle} >
                    {` | ${"actual"}`}
                </span>
            </Box>

            <Box mt={1} display="flex">
                <Box width={"30%"} m={1} mr={5} style={{ fontWeight: "bold", color: releState ? "#4CBF39" : "#af2c47" }}> {releState ? "Activado" : "Desactivado"}</Box>

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch name='showPhase'
                                // disabled={disabledInputFunction()}
                                checked={releState}
                                onChange={handleSwitch}
                                color='primary' />
                        }
                    />
                </FormGroup>
            </Box>


        </Box >
    )
}






