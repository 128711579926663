import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: "485px",
    },
    blockColor: {
        width: "20px",
        height: "20px",
        backgroundColor: "#6874d7c9"
    },
    chart: {
        height: 400,
        marginTop: 70,
        marginBottom: 40,

    }
}));

export default function CostPieChart({ chartData }) {
    const classes = useStyles();

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Create chart instance
        let chart = am4core.create("costPieChart", am4charts.PieChart);
        // SACAMOS LOGO DE AMCHARD
        if (chart.logo) {
            chart.logo.disabled = true
        }
        // Add data
        chart.data = chartData;
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "cost";
        pieSeries.dataFields.category = "parameter";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.truncate = true;
        pieSeries.labels.template.fontSize = 13;


        pieSeries.alignLabels = true;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;


        //SELECTORES DE COLOR DE CADA VALUE DEL GRAPH
        chart.legend = new am4charts.Legend()
        chart.legend.itemContainers.template.paddingBottom = 10;

        //OPCION PARA MODIFICAR LOS TEXTOS DE LOS LEGEND
        pieSeries.legendSettings.valueText = "$ {cost}";
        pieSeries.legendSettings.labelText = "([/bold]{parameter}[/bold])";


    }, [chartData]);

    return (
        <Box className={classes.container}>
            <div id='costPieChart' className={classes.chart} />
        </Box>
    );

}





