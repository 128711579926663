import {
    Box, makeStyles,
    TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    labelTimerInput: {
        fontSize: 12,
        fontWeight: 700,
        marginTop: theme.spacing(1.2),
        color: theme.palette.grey.hairline,
    },
    input: {
        width: "60px",
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    }
}));

export default function TableInputsComponent({ element, handleChange }) {
    const classes = useStyles();
    return (

        <Box ml={1} mt={0} style={{ display: "flex", marginBottom: "14px", justifyContent: "space-between" }}>

            <Box style={{ display: "flex" }} >
                <Box ml={1} mr={2} component='label' display='block' className={classes.labelTimerInput}>
                    {`${element?.name} | MIN`}
                </Box>
                <TextField
                    className={classes.input}
                    autoFocus
                    type="number"
                    placeholder={"0"}
                    name='min'
                    value={element?.values?.min}
                    onChange={(e) => handleChange(e, element?.name)}
                    color='primary'
                />
            </Box>

            <Box mr={2} style={{ display: "flex" }}>
                <Box ml={1} mr={2} component='label' display='block' className={classes.labelTimerInput}>
                    MAX
                </Box>
                <TextField
                    className={classes.input}
                    autoFocus
                    type="number"
                    placeholder={"0"}
                    name='max'
                    value={element?.values?.max}
                    onChange={(e) => handleChange(e, element?.name)}
                    color='primary'
                />
            </Box>
        </Box>
    )
}
