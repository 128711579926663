import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const baseURL = REACT_APP_API_URL;

export const getAPIToken = body =>
  axios.post(`${baseURL}/auth/api-token/`, body);

const TOKEN_KEY = 'UI_TOKEN';
const ROLES = 'UI_ROLES';
const DEFAULT_DEVICE = 'UI_DEFAULT';
const NODES = 'UI_NODES';
const NODES_LIST = 'UI_NODES_LIST';
const TAGS = 'UI_TAGS';
const FILTER_HEATMAP = 'UI_FILTER_HEATMAP';

//dashboard
export function refreshDashboard() {
  localStorage.setItem('DASHREFRESH', { data: true });
}

// Token
export function saveTokenInLocalStorage(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem(TOKEN_KEY);
}

export function deleteTokenFromLocalStorage() {
  localStorage.removeItem(TOKEN_KEY);
}

// Roles
export function saveUserInfoInLocalStorage(roles) {
  localStorage.setItem(ROLES, JSON.stringify(roles));
}

export function getUserInfoFromLocalStorage() {
  return localStorage.getItem(ROLES)!=='undefined'?JSON.parse(localStorage.getItem(ROLES)):null;
}

export function deleteUserInfoFromLocalStorage() {
  localStorage.removeItem(ROLES);
}

// Dashboard Default Device
export function getDefaultDeviceFromLocalStorage() {
  return JSON.parse(localStorage.getItem(DEFAULT_DEVICE));
}

export function saveDefaultDeviceInLocalStorage(device) {
  localStorage.setItem(DEFAULT_DEVICE, JSON.stringify(device));
}

export function deleteDefaultDeviceFromLocalStorage() {
  localStorage.removeItem(DEFAULT_DEVICE);
}

// NodesTree
export function saveNodesInLocalStorage(nodes) {
  localStorage.setItem(NODES, JSON.stringify(nodes));
}

export function getNodesFromLocalStorage() {
  let data = localStorage.getItem(NODES)
  if (data === 'undefined') {
    deleteNodesFromLocalStorage()
    deleteNodesListFromLocalStorage()
    deleteTagsFromLocalStorage()
  }
  else {
    let res = JSON.parse(data);
    return res
  }
}

export function deleteNodesFromLocalStorage() {
  localStorage.removeItem(NODES);
}

//NodesList
export function saveNodesListInLocalStorage(nodes) {
  localStorage.setItem(NODES_LIST, JSON.stringify(nodes));
}

export function getNodesListFromLocalStorage() {
  return JSON.parse(localStorage.getItem(NODES_LIST));
}

export function deleteNodesListFromLocalStorage() {
  localStorage.removeItem(NODES_LIST);
}

// Labels
export function saveTagsInLocalStorage(tags) {
  localStorage.setItem(TAGS, JSON.stringify(tags));
}

export function getTagsFromLocalStorage() {
  return JSON.parse(localStorage.getItem(TAGS));
}

export function deleteTagsFromLocalStorage() {
  localStorage.removeItem(TAGS);
}

// FilterOptions
export function saveFilterOptionsInLocalStorage(device) {
  localStorage.setItem(FILTER_HEATMAP, JSON.stringify(device));
}

export function getFilterOptionsFromLocalStorage() {
  return JSON.parse(localStorage.getItem(FILTER_HEATMAP));
}

export function deleteFilterOptionsFromLocalStorage() {
  localStorage.removeItem(FILTER_HEATMAP);
}
