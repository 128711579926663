import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
}));


export default function EnergyChordChart({ chartData }) {
    const classes = useStyles();

    useEffect(() => {
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create("energyChordChart", am4charts.ChordDiagram);
        // SACAMOS LOGO DE AMCHARD
        if (chart.logo) {
            chart.logo.disabled = true
        }
        chart.hiddenState.properties.opacity = 0;

        chart.data = chartData;
        // chart.data = [
        //     { "from": "A", "to": "D", "value": 10 },
        //     { "from": "B", "to": "D", "value": 8 },
        //     { "from": "B", "to": "E", "value": 4 },
        //     { "from": "B", "to": "C", "value": 2 },
        //     { "from": "C", "to": "E", "value": 14 },
        //     { "from": "E", "to": "D", "value": 8 },
        //     { "from": "C", "to": "A", "value": 4 },
        //     { "from": "G", "to": "A", "value": 7 },
        //     { "from": "D", "to": "B", "value": 1 }
        // ];
        chart.dataFields.fromName = "from";
        chart.dataFields.toName = "to";
        chart.dataFields.value = "value";

        //Ordeno el chord por nombre
        chart.sortBy = "name";
        // make nodes draggable
        let nodeTemplate = chart.nodes.template;
        nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer


    });

    return (
        <Box className={classes.root}>
            <div id='energyChordChart' style={{ height: 250, marginTop: "30px" }} />
        </Box>
    );

}

