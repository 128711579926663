import { createContext, useState } from "react";
import { getNodesListFromLocalStorage } from "../services/auth";

const NodesListContext = createContext()

export const NodesListProvider = ({ children }) => {
  const [nodesList, setNodesList] = useState(getNodesListFromLocalStorage)

  return (
    <NodesListContext.Provider value={{ nodesList, setNodesList }}>
      {children}
    </NodesListContext.Provider>
  )
}

export default NodesListContext;