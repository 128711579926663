import { Box } from '@material-ui/core'
import React from 'react'

function Video({ videoId, width, height }) {
  return (
    <Box margin={2}>
      <iframe width={width} height={height} src={`https://www.youtube.com/embed/${videoId}`} title={videoId} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen  style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%', maxHeight: '50vw' }}></iframe>
    </Box>

  )
}

export default Video