import React, { useContext, useEffect, useState } from 'react';
// material-ui
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  List,
  makeStyles,
} from '@material-ui/core';
// icons
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { NavLink } from 'react-router-dom';
// components
// context
import NodesContext from '../../context/NodesContext';
import MultiNode from '../Instant/MultiNode';

const scrollsSettings = {
  overflow: 'scroll',
  height: '50vh',
  overflowX: 'hidden',
};

const webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  width: '9px',
};

const webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: "12px",
    // '@media (max-width: 700px)': {
    //   marginLeft: '0px'
    // },
  },
  containerNodes: {
    paddingRight: '8px',
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  navLink: {
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
}));

function DevicesDialog({ isOpen, setIsOpen, filterOptions, setFilterOptions,statusNodes }) {
  const classes = useStyles();
  const { nodes } = useContext(NodesContext);
  const [selectedNodes, setSelectedNodes] = useState([]);

  useEffect(() => {
    setSelectedNodes(filterOptions.devices);
  }, [filterOptions]);

  const handleSelectedNode = node => {
    setSelectedNodes(prevSelectedNodes => {
      const nodeIndex = prevSelectedNodes.findIndex(
        selectedNode => selectedNode.id === node.id
      );
      // Si el nodo está en la lista lo elimino
      if (nodeIndex !== -1) {
        return prevSelectedNodes.filter(
          selectedNode => selectedNode.id !== node.id
        );
      } else {
        // Si el nodo no está en la lista lo agrego.

        const newSelectedNodes = [
          ...prevSelectedNodes,
          { id: node.id, name: node.nombre, virtual: node.virtual },
        ];
        return newSelectedNodes;
      }
    });
  };

  // Submit en Dialog de dispositivos
  const handleAdd = () => {
    // Al actualizar la lista de dispositivos limpio el array de variables elegidas para no quedar en situaciones inválidas.
    setFilterOptions({ ...filterOptions, devices: selectedNodes, variable: [] });
    setSelectedNodes([]);
    handleClose();
  };

  const handleClose = () => {
    setSelectedNodes(filterOptions.devices);
    setIsOpen(false);
  };

  const handleDeleteNode = id => {
    setSelectedNodes(prevNodes => prevNodes.filter(node => node.id !== id));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableScrollLock
    >
      <Box padding="8px" >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          Selección de Dispositivo
        </DialogTitle>
        <div className={classes.dialogSubtitle}>
          Seleccionar un elemento de la organización y de manera opcional una
          etiqueta.
        </div>
        <Box display="flex" className={classes.selectedItemsContainer}>
          {selectedNodes.length > 0
            ? selectedNodes.map(node => (
              <Chip
                key={node.id}
                icon={<AccountTreeIcon />}
                label={node.name}
                clickable
                color="primary"
                onDelete={() => handleDeleteNode(node.id)}
              />
            ))
            : null}
        </Box>

        <Box component="label" display="block" className={classes.label}>
          Organización
        </Box>
        <Box >
          {nodes.length === 0 && (
            <h2
              style={{
                textAlign: 'center',
                fontSize: '19px',
                color: '#2586bc',
                paddingTop: '20px',
              }}
            >
              Usted no posee ninguna localización configurada en su cuenta.
              <br></br>
              Por favor, configure al menos una localización para poder
              continuar.
              <NavLink
                className={classes.navLink}
                to={`/configuracion-organizacion`}
              >
                [link]
              </NavLink>
            </h2>
          )}
          <Box className={classes.containerNodes}>
            <List component="div" disablePadding dense>
              {nodes.map(node => (
                <MultiNode
                  key={node.id}
                  node={node}
                  handleSelectedNode={handleSelectedNode}
                  selectedNodes={selectedNodes}
                  statusNodes={statusNodes}
                />
              ))}
            </List>
          </Box>

        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            className={classes.devicesBtn}
            variant="contained"
            color="primary"
            onClick={handleAdd}
            disabled={selectedNodes.length > 0 ? false : true}
          >
            {filterOptions.devices.length > 0 ? 'Editar' : 'Agregar'}
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            className={classes.devicesBtn}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default DevicesDialog;
