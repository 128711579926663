import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		// type: 'dark',
		primary: {
			main: '#2586bc',
			dark: '#3aa5d2',
			light: '#61afd2',
		},
		secondary: {
			main: '#af2c47',
		},
		grey: {
			main: '#544B50',
			dark: '#f6f5f9',
			hairline: '#949494',
		},
		green: {
			turquoise: '#00C3A5',
		},
		divider: '#2586bc',
		background: {
			default: '#ffffff',
			paper: '#f7f7f9',
		},
	},
	typography: {
		fontFamily: `'Poppins', sans-serif`,

		subtitle1: { fontSize: 14, letterSpacing: 0.5, fontWeight: 700 },
		h6: { fontSize: 18, fontWeight: 700 },
	},
});

export default theme;
