import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

function ConfirmationDialog({
  isOpen,
  handleConfirm,
  handleCancel,
  title,
  content,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button color="default" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="outlined"
          onClick={handleConfirm}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
