import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function CONST
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={33}
            labelBlock={type}
            userConfiguration={true}
        />
    );
}
export default CONST