import instance from './testing.instance';

export const getUnassignedMeters = () => instance.get(`/meters/electric/?unassigned=true`);

export const getOwnerMeters = () => instance.get(`/meters/electric/?owner=true`)

export const getHistoryV2 = query => instance.get(`/meters/electric/energy/history?${query}`);
export const getInstantV2 = query => instance.get(`/meters/electric/energy/instant-v2?${query}`);
export const getHistoryResponseV2 = (id, range, period) => instance.get(`/meters/electric/energy/history?filter=${id}&range=${range}&period=${period}&fields=kwh_i,kwh_e,kwh_g,kwh_c,kwh_ac&response_format=v2`)

export const getEvents = query => instance.get(`/meters/electric/events?${query}`);

export const getQualityCalc = query => instance.get(`/quality/calculate?${query}`);
export const getTables = query => instance.get(`/quality/${query}/listtables/`);

export const getRealtimeToken = id => instance.get(`/meters/electric/${id}/realtime_token`);
export const getStatus = () => instance.get(`/meters/electric/status`);

export const changeMeterName = (id, newName) => instance.put(`/meters/electric/${id}/nombre_amigable?nombre_amigable=${newName}`)
export const deleteMeter = (id) => instance.delete(`/meters/electric/${id}`)
export const trackStatusMeter = (id) => instance.get(`/meters/electric/${id}/track/eliminacion`)
export const cleanMeterData = (id) => instance.delete(`/meters/electric/${id}/mediciones`)

//nuevo endpoint historico
export const getHistory = (url) => instance.get(url);

//Invierte una fase de un dispositivo.
export const invertPhase = (device_id, phase_id) => instance.post(`/meters/electric/${device_id}/invertir_fase?fase_id=${phase_id}`)
//Obtener informacion de Alarma | umbrales de el dispositivo.
export const getControlSetting = (command_name, id) => instance.get(`/meters/electric/${id}/get_information?key=${command_name}`)
//Modificar informacion de Alarma | umbrales umbrales de alarmas del dispositivo.
export const putControlSetting = (id, body) => instance.put(`/meters/electric/${id}/set_information`, body)
//Verifica el status de los medidores "online" "offline".
export const getStatusNodes = () => instance.get(`/meters/electric/status/online`)

// INDICADORES 
//proyectados.
export const getIndicatorforecast = (id, fields, period) => instance.get(`/meters/electric/energy/forecast?filter=${id}&fields=${fields}&period=${period}`)
//Comparativos.
export const getIndicatorComparison = (idA, idB, fieldsA, fieldsB, period, rangeA, rangeB) => instance.get(`/meters/electric/energy/comparison?period=${period}&filter_a=${idA}&filter_b=${idB}&field_a=${fieldsA}&field_b=${fieldsB}&range_a=${rangeA}&range_b=${rangeB}&formula=delta`)

//Obtiene potencia para gráfico solar
export const getPowers = (idDevice, fields) => instance.get(`/meters/electric/energy/instant_last_data?filter=${idDevice}&fields=${fields}&response_format=v2`)