import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function MUX2
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={20}
            showLabelInput={true}
            LabelInputRight={'44px'}
        />
    );
}
export default MUX2