import { createContext, useState } from "react";
import { getNodesFromLocalStorage } from "../services/auth";

const NodesContext = createContext()

export const NodesProvider = ({ children }) => {
  const [nodes, setNodes] = useState(getNodesFromLocalStorage)

  return (
    <NodesContext.Provider value={{ nodes, setNodes }}>
      {children}
    </NodesContext.Provider>
  )
}

export default NodesContext;