import React, { useState, useEffect } from "react";
import axios from 'axios';
//Params router 
import { useParams } from 'react-router';
//Material
import {
    Box, makeStyles, CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
//Componente
import ShowcaseLayout from "./ShowcaseLayout";
import TopNav from '../common/TopNav'

const useStyles = makeStyles(theme => ({
    contanerMain: {
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    alert: {
        height: "70px",
        display: 'flex',
        alignItems: 'center'
    }
}));

const DashboardContainerPublic = () => {
    const { params_id } = useParams();
    const classes = useStyles()
    const { REACT_APP_API_URL } = process.env
    const baseURL = REACT_APP_API_URL
    const [stateData, setStateData] = useState({})
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState({});

    useEffect(() => {
        setLoading(true)
        const testFuncion = async () => {
            setError(false)
            let url = `${baseURL}/dashboard/custom/public-${params_id}/`
            axios.get(url)
                .then((response) => {
                    localStorage.setItem('PUBLIC', JSON.stringify({
                        sections: response.data.catalog,
                        info: {
                            plan: response?.data?.plan?.name,
                            tz: response?.data?.ìnfo?.tz
                        },
                        menu: { inst_days_request: response.data.plan.inst_days_request }
                    }));
                    let auxComponents = response.data.dashboard?.components.map((elem) => {
                        let aux = {
                            ...elem,
                            id: elem.device_id,
                            nameDevice: elem.device_name,
                            indexBox: elem.index_box,
                        }
                        return aux
                    })
                    let positionAux = response.data.dashboard?.positions?.map((elem) => {
                        return {
                            ...elem,
                            static: true
                        }
                    })
                    setStateData({
                        ...response.data.dashboard,
                        positions: positionAux,
                        components: auxComponents,
                    })
                    setRefresh({
                        ...response.data.dashboard,
                        positions: positionAux,
                        components: auxComponents,
                    })
                    setLoading(false)
                })
                .catch((error) => {
                    setError(true)
                    setLoading(false);
                });
        }
        testFuncion()
        //Cada 30min actualiza el dashboard.
        const intervalId = setInterval(testFuncion, 1200000);
        // Limpia el intervalo cuando el componente se desmonta para evitar fugas de memoria
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box className={classes.contanerMain}>
            <TopNav
                titulo='Dashboards'
                subtitulo={`| ${stateData?.name ? stateData?.name : ''} (Publico) `}
                marginLeft={true}
                marginTop={true}
            >
            </TopNav>
            {error &&
                <Alert className={classes.alert} severity='error'>
                    No se encuentra el dashboard personalizado.
                </Alert>
            }

            {loading ?
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                </Box>
                :
                <>
                    <ShowcaseLayout
                        setComponentState={() => { }}
                        setStateDataComponentsImageZoom={() => { }}
                        edit={false}
                        stateData={stateData}
                        refresh={refresh}
                    />
                </>
            }
        </Box>
    )
}
export default DashboardContainerPublic