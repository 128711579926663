import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chart: {
    height: '40vh',
  },
}));

export default function Chart({ chartName, qualityData }) {
  const classes = useStyles();
  useEffect(() => {
    const {
      niveles_tension,
      desequilibrio,
      conf: { sobretension_valor, subtension_valor, desequilibrio_ocurrencia },
    } = qualityData;

    const data = [];

    //niveles_tension= [[TENSION1,201,202,...],[OCURRENCIA1,0,0,...]]
    //data = [{tension:200, ocurrencia: OCURRENCIA,close: CLOSE }
    //CLOSE será 0 si TENSION<subtension_valor
    //CLOSE será OCURRENCIA si TENSION > subtension_valor y TENSION < sobretension_valor
    //CLOSE será 0 si TENSION > sobretension_valor

    for (let i = 0; i < niveles_tension[0].length; i++) {
      data.push({
        tension: niveles_tension[0][i],
        ocurrencia: niveles_tension[1][i],
        desequilibrio: desequilibrio[0][i],
        ocurrencia2: desequilibrio[1][i],
      });
    }

    am4core.useTheme(am4themes_animated);
    var chart = am4core.create(chartName, am4charts.XYChart);
    // SACAMOS LOGO DE AMCHARD
    if (chart.logo) {
      chart.logo.disabled = true;
    }
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.paddingRight = 20;
    chart.data = data;

    // Configuraciones generales para ambos gráficos, serán ampliadas con valores específicos luego.
    var valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    var valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.renderer.grid.template.disabled = true;
    var series = chart.series.push(new am4charts.LineSeries());
    var range = valueAxisX.createSeriesRange(series);

    // series.bullets.push(new am4charts.CircleBullet());
    function createGridX(value) {
      const range = valueAxisX.axisRanges.create();
      range.value = value;
    }
    function createGridX2(value) {
      const range = valueAxisX.axisRanges.create();
      range.value = value;
    }

    chart.cursor = new am4charts.XYCursor();
    //Oculto las líneas del cursor
    chart.cursor.lineX.disabled = false;
    chart.cursor.lineY.disabled = true;
    //Como estamos trabajando con X e Y como ValueAxis la única alternativa para usar tooltips es usando snapToSeries
    chart.cursor.snapToSeries = [series];
    // Oculto los valores del eje Y
    // valueAxisY.tooltip.disabled = true;
    // chart.scrollbarX = new am4core.Scrollbar();

    if (chartName === 'voltage') {
      const title = chart.titles.create();
      title.text = 'Tensión Media';
      valueAxisX.min = 200;
      valueAxisX.max = 250;
      // Queremos que respete este máximo aunque tenga más espacio disponible
      valueAxisX.strictMinMax = true;
      //Como quiero tener unidad V sólo en el eje X creo un nuevo NumberFormatter()
      valueAxisX.numberFormatter = new am4core.NumberFormatter();
      valueAxisX.numberFormatter.numberFormat = '#V';

      valueAxisY.numberFormatter = new am4core.NumberFormatter();
      valueAxisY.numberFormatter.numberFormat = '#  %';

      series.tooltipText = '({valueX}V ; {valueY}%)';

      valueAxisY.title.text = 'Ocurrencia';
      valueAxisX.title.text = 'Tensión';

      series.dataFields.valueX = 'tension';
      series.dataFields.valueY = 'ocurrencia';

      // series.sequencedInterpolation = true;
      series.fillOpacity = 0.5;
      // series.defaultState.transitionDuration = 1000;
      // series.tensionX = 0.8;

      range.value = subtension_valor;
      range.endValue = sobretension_valor;
      range.contents.fill = am4core.color('#67B7DC');
      range.contents.fillOpacity = 0;
      // chart.scrollbarX = new am4core.Scrollbar();
      for (let i = 200; i < 250; i++) {
        createGridX(i);
      }
    }
    if (chartName === 'imbalance') {
      const title = chart.titles.create();
      title.text = 'Desequilibrio de tensiones de fase';

      // Grafico 2
      valueAxisY.title.text = 'Ocurrencia';
      valueAxisX.title.text = 'Desequilibrio';

      series.tooltipText = '({valueX}% ; {valueY}%)';

      valueAxisX.numberFormatter = new am4core.NumberFormatter();
      valueAxisX.numberFormatter.numberFormat = '#%';

      valueAxisY.numberFormatter = new am4core.NumberFormatter();
      valueAxisY.numberFormatter.numberFormat = '#%';
      series.dataFields.valueX = 'desequilibrio';
      series.dataFields.valueY = 'ocurrencia2';

      range.value = desequilibrio_ocurrencia / 100;
      range.endValue = 1;

      range.contents.fill = am4core.color('#67B7DC');
      range.contents.fillOpacity = 0.5;

      for (let i = 0; i < 1; i += 0.005) {
        createGridX2(i);
      }
    }
    return () => {
      chart.dispose();
    };
  }, [chartName, qualityData]);
  return (
    <>
      <div id={chartName} className={classes.chart} />
    </>
  );
}
