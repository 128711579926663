import Container from "../Elements/Container"
import TopNav from "../common/TopNav"
import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import Root from "./Root";
import {
    Button, Box, Grid, CircularProgress, Tooltip, makeStyles
} from '@material-ui/core';
import {
    useNodesState,
    // useEdgesState,
    // useOnSelectionChange
    applyEdgeChanges,
    MarkerType,
    addEdge,
} from 'reactflow';
//Funciones auxiliares.
import { createTree } from "./helpers";
//Hook personalizado.
import DiagramContext from "./Store/DiagramContext";
//Componentes.
import AlertComponent from '../Elements/AlertComponent';
import DeletePanel from "./DeletePanel";
import LoadingComponentBlur from "../common/LoadingComponentBlur.js";
//Servicios.
import { postSaveDiagramAlert, getIndividualDiagramAlert, deleteDiagramAlert, putUpdateDiagramAlert } from "../../services/diagrams";
import { getPlc_automate_24v_byId, postPlc_automate_24v_byId, putPlc_automate_24v_byId, getPlc_automate_24v_state, getExportDiagramPlcJson } from "../../services/plc_automate_24";
import { useHistory } from 'react-router-dom';
//icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishIcon from '@mui/icons-material/Publish';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//Helper
import { validatorEdges } from './helpers.js';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles(theme => ({
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    btnDisabled: {
        '& .MuiButton-label': {
            color: '#b8c1c4a3',
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: 'grey',
        },
    },
    btnFileDownload: {
        width: '100%',
        marginTop: '4px',
        '& .MuiButton-label': {
            color: 'black',
        },
    },
    labelEstado: {
        color: '#fff',

    },
    estadoSyle: {
        fontSize: theme.spacing(1.8),
        cursor: 'pointer'
    },
    spinning: {
        animation: `$spin 2s infinite`,
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '50%': { transform: 'rotate(180deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
    selectNone: {
        color: 'transparent', // Hace el texto transparente
        border: 'none', // Elimina el borde
        background: 'transparent', // Elimina el fondo
        padding: 0, // Elimina el padding
        minHeight: 'auto', // Ajusta la altura mínima
        '& .MuiSelect-select': {
            padding: 0, // Elimina el padding interno
            minHeight: 'auto', // Ajusta la altura mínima
        },
        '& .MuiSelect-icon': {
            display: 'none', // Oculta el ícono del desplegable
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Elimina el borde en el estilo Outlined
        },
    },
}));

const DynamicDiagram = ({ componentType, params_id }) => {
    const history = useHistory();
    const classes = useStyles();
    const { isMobile } = useDeviceDetect(930);
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext);
    const [loading, setLoading] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    let nodesAux = []
    //VEO Q TIPO DE COMPONENTE ES PLC O ALERTS
    if (componentType === 'ALERTS_VARIABLES') {
        nodesAux = [{
            id: '0',
            position: { x: 0, y: -10 },
            type: 'ALERT',
        }]
    }
    const [id_diagram, setId_diagram] = useState('');
    const [nodes, setNodes, onNodesChange] = useNodesState(nodesAux);
    // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [edges, setEdges] = useState([]);
    const [initialedges, setInitialEdges] = useState([]);
    const [initialnodes, setInitialNodes] = useState(nodesAux);
    const [initilAlertState, setInitialAlertState] = useState();
    const [selectedNodes, setSelectedNodes] = useState([]);
    // const [selectedEdges, setSelectedEdges] = useState([]);
    const [diagram, setDiagram] = useState({});
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    //Estado de la alerta dinamica
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [stateNavIcon, setStateNavIcon] = useState({});
    // Estado encargado de abrir el panel de eliminacion de Dashboard.
    const [openDeletePanel, setOpenDeletePanel] = useState(false)
    // Estado encargado de guardar los distintos pasos de el diagrama.
    const [diagramStates, setDiagramStates] = useState([])
    //Indice del paginado de estados del diagrama.
    const [currentIndex, setCurrentIndex] = useState(0)
    const fileInputRef = useRef(null);
    const [openSelectFile, setOpenSelectFile] = useState(false);

    //Limita la cantidad de estados q guardo para volver hacia atras o adelante.
    useEffect(() => {
        let LIMIT = 17
        if (diagramStates?.length > LIMIT) {
            setDiagramStates(diagramStates.slice(diagramStates.length - LIMIT))
        }
    }, [diagramStates])

    //Efecto q maneja el estado del diagrama para cambiar el ICON.
    useEffect(() => {
        if (diagram?.state_value) {
            if (diagram?.state === 'PENDING_LOAD' || diagram?.state === 'PROCESS_LOAD') {
                changeIconSave()
            }
            setStateNavIcon({
                key: diagram?.state,
                value: diagram?.state_value
            })
        }
        // eslint-disable-next-line
    }, [diagram?.state_value])

    const handleOpenSelectFileDownload = () => {
        setOpenSelectFile(!openSelectFile)
    }

    const removeDuplicatesById = (array) => {
        const seen = new Map();
        return array.filter(item => {
            if (seen.has(item.id)) {
                // Si el id ya ha sido visto, no incluimos el elemento en el nuevo array
                return false;
            } else {
                // Si el id no ha sido visto, lo añadimos al mapa y al nuevo array
                seen.set(item.id, true);
                return true;
            }
        });
    }

    //Escucha cualquier cambio de los edges.
    const onEdgesChange = useCallback(
        (changes) => {
            const checkSelected = (oldEdges) => {
                let arrayNew = []
                for (let i = 0; i < [...changes].length; i++) {
                    let element = changes[i]
                    let elementFind = [...oldEdges].find((elem) => elem.id === element.id)
                    if (element.selected === true) {
                        elementFind.style = {
                            ...elementFind.style,
                            stroke: '#2586bc',
                        }
                    }
                    else {
                        elementFind.style = {
                            ...elementFind.style,
                            stroke: 'black',
                        }
                    }
                    arrayNew = removeDuplicatesById([...oldEdges, elementFind])
                }
                return arrayNew
            }
            setEdges((oldEdges) => applyEdgeChanges(changes, checkSelected(oldEdges)));
        },
        [setEdges],
    )

    //Escucha cuando comienza la conexión de un Edge. y valoda deshabilitar nodo.
    const onConnectStart = (event) => {
        let idAux = event.target.dataset.nodeid
        let filterNode = nodes.find(e => e.id === idAux)
        let typeNodeSelected = filterNode.type
        let arry = []
        if (typeNodeSelected === 'VARIABLE' || typeNodeSelected === 'CONST') { //si es uno de estos bloques.
            arry = ['AND', 'OR', 'NOT', 'ALERT'] //bloqueo estos bloques.
        }
        if (typeNodeSelected === 'NOT' || typeNodeSelected === 'OR' || typeNodeSelected === 'AND') {
            arry = ['LEQ', 'GEQ', 'EQ', 'LESS', 'GREATER', 'ADDER']
        }
        if (typeNodeSelected === 'LEQ' || typeNodeSelected === 'GEQ' || typeNodeSelected === 'EQ' || typeNodeSelected === 'LESS' || typeNodeSelected === 'GREATER' || typeNodeSelected === 'ADDER') {
            arry = ['LEQ', 'GEQ', 'EQ', 'LESS', 'GREATER', 'ADDER']
        }
        setStateDiagram({
            ...stateDiagram,
            disabledNodes: arry
        })
    }

    // escucha cuando conecta un bloque con otro.
    const onConnect = useCallback(
        (params, nodes) => {
            //Limpia el bloque q tiene error si lo conectas o se conecta el bloque con otro.
            let findErrorBlockTarget = stateDiagram?.errorBlockNodes?.includes(params?.target)
            let findErrorBlockSource = stateDiagram?.errorBlockNodes?.includes(params?.source)
            if (findErrorBlockTarget || findErrorBlockSource) {
                setStateDiagram({
                    ...stateDiagram,
                    errorBlockNodes: []
                })
            }
            //Valida las conexciones entre nodos.
            let res = validatorEdges(params, nodes, edges)
            // params.type = 'step'
            params.markerEnd = {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 20,
                color: '#1d1c33d1',
            }
            params.style = { strokeWidth: 0.8 }
            if (res === true) {
                setEdges((eds) => {
                    //Agrego estado para las flechas de back y next en la accion copiar.                  
                    setCurrentIndex(diagramStates.length)
                    setDiagramStates([
                        ...diagramStates,
                        { nodes, edges: addEdge(params, eds) },
                    ])
                    return addEdge(params, eds)
                })
            }
            else {
                setClose(true)
                setMsg({
                    msg: res.msg,
                    type: "error"
                })
            }
            // eslint-disable-next-line
        }, [setEdges, edges, nodes, stateDiagram],);

    //Evento cuando suelta el edge después de comenzar una conexión.
    const onConnectEnd = () => {
        setStateDiagram({
            ...stateDiagram,
            disabledNodes: []
        })
    }

    //Toma los datos de el diagrama ya existente debe estar al final de los useEffect.
    useEffect(() => {
        //Si existe un estado lo cargo
        setStateDiagram({
            ...stateDiagram,
            componentType
        })
        if (componentType === 'ALERTS_VARIABLES') {
            getDataDiagramAlert(params_id)
        }
        else {
            getDataDiagramPLC(params_id)
        }
        // eslint-disable-next-line
    }, [])

    //Escucha cuando el usuario oprime CTRL + V.
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'v') {
                //Cambia los colores y pone selected en false de los edges.
                let res = [...edges].map((elem) => {
                    if (elem?.selected === true) {
                        elem.selected = false
                        elem.style = {
                            ...elem.style,
                            stroke: 'black',
                        }
                        return elem
                    }
                    else {
                        return elem
                    }
                })
                setEdges(res)
                // 'El usuario presionó Ctrl + v'
                if (!stateDiagram?.openModal) {
                    onCopyBlock()
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        // Limpia el event listener cuando el componente se desmonte
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNodes, nodes, stateDiagram?.openModal]);

    //Funcion encargada de escuchar el drop de un bloque.
    const onDrop = useCallback(
        (event) => {
            event.preventDefault()
            const type = event.dataTransfer.getData('application/reactflow');
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            })
            const lastElement = nodes[nodes.length - 1];
            let newId = '1'
            if (lastElement) {
                newId = Number(lastElement.id) + 1
            }
            let newNode = {
                id: newId.toString(),
                position: position,
                type: type,
                // data: { value: newId.toString() },
            }
            let nodesAux = [
                ...nodes,
                newNode
            ]
            setNodes(nodesAux)
            // Agrego estado para las flechas de back y next en la accion copiar.                  
            setCurrentIndex(diagramStates.length)
            setDiagramStates([
                ...diagramStates,
                { nodes: nodesAux, edges: edges },
            ])
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance, nodes, setNodes,],
    );

    //Cierra el modal de el boton
    const containerRef = useRef(null); // Referencia al contenedor que queremos observar
    // Maneja el clic fuera del contenedor
    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setOpenSelectFile(false);
            }
        }
        // Agregar el event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //Función q retrocede o avanza el estado del diagrama.
    const diagramStateFunction = (state) => {
        let newIndex = currentIndex
        if (state === 'back') {
            if (currentIndex > 0) {
                newIndex = currentIndex - 1
                setCurrentIndex(newIndex)
            }
        }
        else if (state === 'next') {
            if (currentIndex < diagramStates.length - 1) {
                newIndex = currentIndex + 1
                setCurrentIndex(newIndex)
            }
        }
        if (diagramStates[newIndex]) {
            setNodes(diagramStates[newIndex].nodes);
            //Pongo en negro los edges de nuevo por q no estan seleccionados
            let edgesAux = diagramStates[newIndex].edges.map((elem) => {
                elem.style = {
                    ...elem.style,
                    stroke: 'black'
                }
                return elem
            })
            setEdges(edgesAux)
        }
    }

    //Funcion q toma los datos existentes de un diagrama alerta.
    const getDataDiagramAlert = async () => {
        try {
            if (params_id) {

                // let res = await getDiagramAlert()
                let res = await getIndividualDiagramAlert(params_id)
                let diagramAlert = res.data
                let alertObject = {
                    alert: diagramAlert
                }
                setNodes(diagramAlert.nodes)
                setEdges(diagramAlert.edges)
                setStateDiagram({
                    ...stateDiagram,
                    alertObject
                })
                setInitialEdges(diagramAlert.edges)
                setInitialNodes(diagramAlert.nodes)
                setInitialAlertState({
                    ...stateDiagram,
                    alertObject
                })
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(false)
        setTimeout(() => {
            setLoading(false)
        }, [1000])
    }

    //AUXILIAR! CAMBIAR HABLAR CON CRIS.
    function transformPropertiesToString(obj) {
        // Verificamos que el argumento pasado es un objeto, pero no null
        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {

            return
        }
        // Recorremos todas las propiedades del objeto
        for (let key in obj) {
            // Verificamos que la propiedad es propia del objeto y no heredada
            if (obj.hasOwnProperty(key)) {
                // Convertimos la propiedad a string
                obj[key] = String(obj[key]);
            }
        }
        return obj;
    }
    ///////////////////////////

    //Funcion q toma los datos existentes de un diagrama PLC.
    const getDataDiagramPLC = async (id) => {
        try {

            if (id) {
                let res = await getPlc_automate_24v_byId(id)
                if (res?.data?.diagram?.nodes) {
                    //Auxiliar transforma los datos de a en formato strng.(va a ser remplazado.)
                    let nodesAux = [...res?.data?.diagram?.nodes].map((element) => {
                        element.a = transformPropertiesToString(element.a)
                        return element
                    })
                    /////////////////////////////////////   
                    setDiagram(res?.data?.diagram)
                    setNodes(nodesAux)
                    setEdges(res?.data?.diagram?.edges)
                    setInitialNodes(res?.data?.diagram?.nodes)
                    setInitialEdges(res?.data?.diagram?.edges)
                    setId_diagram(res?.data?.diagram?.id)
                    //Agrego estado para las flechas de back y next.
                    setDiagramStates([
                        {
                            nodes: res?.data?.diagram?.nodes,
                            edges: res?.data?.diagram?.edges
                        }
                    ])
                }
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(false)
        setLoading(false)
    }

    // function modificarNumero(cadena) {
    //     // Definir la expresión regular para encontrar el patrón numérico
    //     const patron = /reactflow__edge-(\d+)-(\d+)i/;
    //     const resultado = cadena.match(patron);

    //     if (resultado) {
    //         const numero1Original = resultado[1];
    //         let numero2 = parseInt(resultado[2], 10);

    //         // Tomar el primer dígito del primer número y sumarle 2
    //         const primerDigitoNumero1 = parseInt(numero1Original.charAt(0), 10) + 2;
    //         const restoNumero1 = numero1Original.slice(1);

    //         // Reconstruir el primer número con el dígito modificado
    //         const nuevoNumero1 = `${primerDigitoNumero1}${restoNumero1}`;

    //         // Sumar 2 al segundo número
    //         numero2 += 2;

    //         // Reconstruir la cadena con el nuevo número
    //         const nuevaCadena = `reactflow__edge-${nuevoNumero1}-${numero2}i`;
    //         return nuevaCadena;
    //     }
    // }

    //Funcion encargada del drop de los bloques.
    const onCopyBlock = () => {
        let flag = 1
        let newArry = selectedNodes.map((elem) => {
            const lastElement = nodes[nodes.length - 1];
            let newId = Number(lastElement.id) + flag
            flag = flag + 1
            let copy = {
                position: {
                    x: 0,
                    y: 0
                }
            }
            copy.id = newId.toString()
            copy.position.x = elem.position.x + 16
            copy.position.y = elem.position.y + 16
            copy.selected = true
            // Si hago copia de estos bloques anulo el valueToRelative, por q los pines son unicos, reseteo el estado .a
            if (elem.type === 'INA' || elem.type === 'IND' || elem.type === 'OUTD' || elem.type === 'OUTA') {
                copy.a = {}
            }
            return {
                ...elem,
                ...copy,
            }
        })
        let newNodes = nodes.map((elem) => {
            elem.selected = false
            return elem
        })
        // let newEdges = selectedEdges.map((elem) => {
        //     flag = flag + 1
        //     let copy = JSON.parse(JSON.stringify(elem));
        //     copy.id = modificarNumero(copy.id)
        //     copy.source = (Number(copy.source) + 2).toString()
        //     copy.target = (Number(copy.target) + 2).toString()
        //     return {
        //         ...elem,
        //         ...copy,
        //     }
        // })
        // let edgesAux=      let copia = JSON.parse(JSON.stringify(EDGES));
        // console.log([
        //     ...edges,
        //     ...newEdges
        // ])
        // setEdges([
        //     ...edges,
        //     ...newEdges
        // ])
        let nodesAux = [
            ...newNodes,
            ...newArry
        ]
        setNodes(nodesAux)
        //Agrego estado para las flechas de back y next en la accion copiar.
        setCurrentIndex(diagramStates.length)
        setDiagramStates([
            ...diagramStates,
            { nodes: nodesAux, edges },
        ])
    }

    //Escucha todos los eventos q modifiquen nodes, edges.
    const onSelectionDrag = (event) => {
        // setSelectedEdges(event.edges)
        setSelectedNodes(event.nodes)
    };

    //Funcion Auxiliar. Remueve la propiedad id para enviar al back.
    const removeNodePropertyId = (nodo) => {
        // Verificar si el nodo es válido
        if (nodo && typeof nodo === 'object') {
            // Eliminar la propiedad id si existe
            if (nodo.hasOwnProperty('id')) {
                delete nodo.id;
            }
            // Recorrer los hijos del nodo si existen
            if (nodo.hasOwnProperty('childrens') && Array.isArray(nodo.childrens)) {
                nodo.childrens.forEach((hijo) => {
                    removeNodePropertyId(hijo); // Llamar recursivamente a la función para cada hijo
                });
            }
        }
    }

    //Guarda diagrama.
    const save = async () => {
        setLoadingSave(true)
        //Paso todos los nodos a selected false.
        setNodes(nodes.map((elem) => {
            elem.selected = false
            return elem
        }))
        try {
            let tree = createTree(nodes, edges, stateDiagram)
            removeNodePropertyId(tree)
            // Convierte el objeto a una cadena JSON
            var myObj = {
                alert: {
                    ...stateDiagram?.alertObject?.alert,
                    nodes: nodes,
                    edges: edges,
                },
                tree: tree,
            }

            //Pongo en negro los edges de nuevo por q no estan seleccionados
            setEdges(edges.map((elem) => {
                elem.style = {
                    ...elem.style,
                    stroke: 'black'
                }
                return elem
            }))
            if (componentType === 'ALERTS_VARIABLES') {
                //si existe un diagrama lo edito si no lo creo.
                if (params_id) {
                    //edito diagrama
                    await putUpdateDiagramAlert(params_id, myObj)
                }
                else {
                    //creo diagrama
                    let res = await postSaveDiagramAlert(myObj)
                    history.push(`/alert/${res.data.id}`)
                }
            }
            if (componentType === 'Automate-24V-4M') {
                const myObject = {
                    name: '',
                    edges,
                    nodes
                }
                let id_diagramAux = ''
                //Si existe un diagrama.
                if (id_diagram) {
                    //edito diagrama                
                    let res = await putPlc_automate_24v_byId(params_id, id_diagram, myObject)
                    setStateNavIcon({
                        key: res?.data?.state,
                        value: res?.data?.state_value
                    })
                }
                else {
                    //creo diagrama                 
                    let res = await postPlc_automate_24v_byId(params_id, myObject)
                    id_diagramAux = res?.data?.id
                    setStateNavIcon({
                        key: res?.data?.state,
                        value: res?.data?.state_value
                    })
                }
                //Agrego estado para las flechas de back y next en la accion copiar.
                setCurrentIndex(0)
                setDiagramStates([
                    { nodes, edges },
                ])
                changeIconSave(id_diagramAux)
            }
            setInitialNodes(nodes)
            setInitialEdges(edges)
            setInitialAlertState({ ...stateDiagram?.alertObject })
            setMsg({
                msg: "Se ha enviado su configuración.",
                type: "success"
            })
            //Limpio errores de bloques por q paso el save.
            setStateDiagram({
                ...stateDiagram,
                errorBlockNodes: []
            })
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
                //Identifico q bloque tiene error
                setStateDiagram({
                    ...stateDiagram,
                    errorBlockNodes: [error?.response?.data?.node_id]
                })
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setLoadingSave(false)
        setClose(true)
    }

    //Elimina diagrama
    const deleteFunction = async () => {
        try {
            setLoadingSave(true)
            setNodes([])
            setEdges([])
            setStateDiagram({
                alertObject: {
                    alert: {
                        name: '',
                        frequency: 20,
                        notify: false,
                    },
                    tree: {

                    }
                }
            })
            if (componentType === 'ALERTS_VARIABLES') {
                await deleteDiagramAlert(params_id)
                history.push(`/alerts`)
            }
            setMsg({
                msg: "Se elimino su configuración",
                type: "success"
            })
        }
        catch (error) {
            let msg = ''
            if (error.response) {
                msg = `${error.response.data.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(true)
    }

    //Vuelve al estado original de el diagrama.
    const cancel = () => {
        //Pongo en negro los edges de nuevo por q no estan seleccionados
        let edgesAux = initialedges.map((elem) => {
            elem.style = {
                ...elem.style,
                stroke: 'black'
            }
            return elem
        })
        setEdges(edgesAux)
        setNodes(initialnodes)
        //Agrego estado para las flechas de back y next en la accion copiar.
        setCurrentIndex(0)
        setDiagramStates([
            { nodes: initialnodes, edges: edgesAux },
        ])
        setStateDiagram(initilAlertState)
    }

    //Valida el boton eliminar solo en la seccion diagrama alertas.
    const validateButonDelete = () => {
        return params_id && componentType === 'ALERTS_VARIABLES'
    }

    //Se encarga de cambiar el ICON de estado de el diagrama.
    const changeIconSave = async (id_diagramAux) => {
        let flag = 1
        const auxFunction = async () => {
            let diagramIdAux = diagram?.id
            if (id_diagramAux) {
                diagramIdAux = id_diagramAux
            }
            let res = await getPlc_automate_24v_state(params_id, diagramIdAux)
            let valueAux = {
                key: res?.data?.state,
                value: res?.data?.state_value
            }
            setStateNavIcon(valueAux)
            if (valueAux?.key === 'OK' || valueAux?.key === 'ERROR_COMUNICATION' || valueAux?.key === 'ERROR_BLOCKS' || valueAux?.key === 'UNSPECIFIED') {
                flag = 8
            }
        }
        setInterval(() => {
            flag += 1
            if (flag < 9) {
                auxFunction()
            }
        }, 4000)
    }

    //Devuelve el icon segun el estado.
    const returnIconState = (element) => {
        if (element.key === 'UNSPECIFIED') {
            return <ErrorOutlineIcon style={{ color: '#3ed73e' }} />
        }
        if (element.key === 'OK') {
            return <CheckCircleOutlineIcon style={{ color: '#3ed73e' }} />
        }
        if (element.key === 'PENDING_LOAD') {
            return <HourglassFullIcon className={classes.spinning} style={{ color: '#FFAA33' }} />
        }
        if (element.key === 'PROCESS_LOAD') {
            return <HourglassFullIcon className={classes.spinning} style={{ color: '#FFAA33' }} />
        }
        if (element.key === 'ERROR_COMUNICATION') {
            return <FilterListOffIcon style={{ color: '#f75200' }} />
        }
        if (element.key === 'ERROR_BLOCKS') {
            return <ErrorOutlineIcon style={{ color: '#f75200' }} />
        }
    }

    //Handle encargado de abrir y cerrar el panel de eliminacion de dashboard.
    const handleOpenCloseDeletePanel = () => {
        setOpenDeletePanel(!openDeletePanel)
    }

    //Deshabilita el boton GUARDAR cuanto el estado sigue en proceso o en pendiente.
    const disabledButtonSave = () => {
        if (stateNavIcon?.key === 'PENDING_LOAD' || stateNavIcon?.key === 'PROCESS_LOAD') {
            return true
        } else {
            return false
        }
    }

    //Descarga typo de formato del diagrama.
    const handleDownloadFile = async (type) => {
        let res = await getExportDiagramPlcJson(params_id, id_diagram)
        let archivoJson = res?.data;
        try {
            let blob, fileName;
            if (type === 'json') {
                blob = new Blob([JSON.stringify(archivoJson, null, 2)], { type: 'application/json' });
                fileName = 'archivo.json';
            }
            else if (type === 'txt') {
                // Convertir el JSON a un string formateado para el archivo TXT
                const txtContent = JSON.stringify(archivoJson, null, 2);
                blob = new Blob([txtContent], { type: 'text/plain' });
                fileName = 'archivo.txt';
            }
            else {
                throw new Error('Tipo de archivo no soportado');
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nombre del archivo con extensión adecuada
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (error) {
        }
    }

    //Carga diagrama local 
    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                try {
                    const jsonContent = JSON.parse(content);
                    if (jsonContent?.length > 0) {
                        setNodes(jsonContent[0])
                        setEdges(jsonContent[1])
                    }

                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };
            reader.readAsText(file);
        }
    };

    const handleUploadFile = () => {
        fileInputRef.current.value = '';
        fileInputRef.current.click();
    }

    const returnLabelDynamic = () => {
        let res = {
            url: 'plc_list'
        }
        if (componentType === 'ALERTS_VARIABLES') {
            res.url = 'alerts'
        }
        return res.url
    }

    const returnIconButtonNav = () => {
        return (
            <Box>
                <Box ml={5} display='flex' alignItems='center'>
                    <Box mr={3} >
                        <Tooltip
                            title={`Volver a la lista.`}
                            arrow
                        >
                            <Button
                                style={{ marginLeft: "10px" }}
                                className={classes.btn}
                                type="button"
                                onClick={() => history.push(`/${returnLabelDynamic()}`)}
                                size="small"
                            >   <ReplyAllIcon />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        )
    }

    //Limpia los estados de el diagrama (Borra diagrama en el estado local)
    const cleanDiagram = () => {
        setNodes([])
        setEdges([])
    }

    return (
        <>

            {/* Panel de eliminacion dashboard. */}
            <DeletePanel
                openDeletePanel={openDeletePanel}
                handleOpenCloseDeletePanel={handleOpenCloseDeletePanel}
                deleteLocal={cancel}
            />

            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingSave}
            />
            <TopNav
                titulo={componentType === 'Automate-24V-4M' ? "Diagrama dinámico" : stateDiagram?.alertObject?.alert?.name ? stateDiagram?.alertObject?.alert?.name : ''}
                subtitulo={` ${''} `}
                changeTitleStyleFlex={true}
                marginLeft={true}
                iconButton={returnIconButtonNav()}
            >
                <Grid container justifyContent='flex-end' alignItems='center' display='flex'>
                    {componentType !== 'ALERTS_VARIABLES' && stateNavIcon?.value &&
                        <Box ml={5} display='flex' alignItems='center'>
                            <Box className={classes.labelEstado} >
                                Estado:
                            </Box>
                            <Box mr={3} >
                                <Tooltip
                                    title={`Estado ${stateNavIcon?.value}`}
                                    arrow
                                >
                                    <Box mt={0.6} ml={1} style={{ cursor: 'pointer' }}>
                                        {returnIconState(stateNavIcon)}
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                    }

                    <Box mb={1}>

                        {
                            !isMobile &&
                            <Tooltip
                                title={'Estado anterior del diagrama.'}
                                arrow
                                placement="right"
                            >
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    className={classes.btn}
                                    type="button"
                                    onClick={() => diagramStateFunction('back')}
                                    size="small"
                                >
                                    <UndoIcon />
                                </Button>
                            </Tooltip>
                        }
                        {
                            !isMobile &&
                            <Tooltip
                                title={'Siguiente estado del diagrama.'}
                                arrow
                                placement="right"
                            >
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    className={classes.btn}
                                    type="button"
                                    onClick={() => diagramStateFunction('next')}
                                    size="small"
                                >   <RedoIcon />
                                </Button>
                            </Tooltip>
                        }

                        {

                            <Tooltip
                                title={'Borrar diagrama.'}
                                arrow
                                placement="right"
                            >
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    className={classes.btn}
                                    type="button"
                                    onClick={() => cleanDiagram()}
                                    size="small"
                                >
                                    <   DeleteForeverIcon />
                                </Button>
                            </Tooltip>
                        }

                        <Tooltip
                            title={'Volver a estado original.'}
                            arrow
                            placement="right"
                        >
                            <span>
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    className={classes.btn}
                                    type="button"
                                    onClick={handleOpenCloseDeletePanel}
                                    size="small"
                                >
                                    {`Cancelar`}
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={disabledButtonSave() ? 'Espere que termine el proceso anterior' : 'Guardar diagrama.'}
                            arrow
                            placement="right"
                        >
                            <span>
                                <Button
                                    style={{ marginLeft: "10px" }}
                                    className={disabledButtonSave() ? classes.btnDisabled : classes.btn}
                                    type="button"
                                    onClick={save}
                                    disabled={disabledButtonSave()}
                                    size="small"
                                >
                                    {`Guardar`}
                                </Button>
                            </span>
                        </Tooltip>

                        {validateButonDelete() &&
                            <Tooltip
                                title={'Eliminar diagrama.'}
                                arrow
                                placement="right"
                            >
                                <span>
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        className={classes.btn}
                                        type="button"
                                        onClick={deleteFunction}
                                        size="small"
                                    >
                                        {`Eliminar`}
                                    </Button>
                                </span>
                            </Tooltip>
                        }

                        {componentType !== 'ALERTS_VARIABLES' && !isMobile &&
                            <>
                                <Tooltip
                                    title={'Importar diagrama'}
                                    arrow
                                    placement="right"
                                >
                                    <span>
                                        <input
                                            type="file"
                                            // accept=".json  .txt"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }} // Oculta el input de archivo
                                        />
                                        <Button
                                            style={{ marginLeft: '10px' }}
                                            className={classes.btn}
                                            type="button"
                                            onClick={handleUploadFile}
                                            size="small"
                                        >
                                            <FileDownloadIcon />
                                        </Button>
                                    </span>
                                </Tooltip>

                                <Tooltip
                                    title={'Exportar diagrama'}
                                    arrow
                                    placement="right"
                                >
                                    <span>
                                        <Button
                                            style={{ marginLeft: "10px" }}
                                            className={classes.btn}
                                            type="button"
                                            onClick={handleOpenSelectFileDownload}
                                            size="small"
                                        >
                                            <PublishIcon />

                                            {openSelectFile &&
                                                <Box
                                                    ref={containerRef} // Asigna la referencia al contenedor
                                                    style={{
                                                        zIndex: 2,
                                                        position: 'absolute',
                                                        borderRadius: '4px',
                                                        top: '10px',
                                                        width: '90px',
                                                        backgroundColor: '#eae0e0', // Color de fondo
                                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Sombra
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.btnFileDownload}
                                                        onClick={() => handleDownloadFile('json')}
                                                        type="button"
                                                        size="small"
                                                    >
                                                        Json
                                                    </Button>

                                                    <Button
                                                        className={classes.btnFileDownload}
                                                        onClick={() => handleDownloadFile('txt')}
                                                        type="button"
                                                        size="small"
                                                    >
                                                        Txt
                                                    </Button>
                                                </Box>
                                            }
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>
                        }

                    </Box>
                </Grid>
            </TopNav >

            {/* ALERTAS */}
            < AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container >

                {loading ?
                    <Box display="flex" justifyContent="center" alignItems={'center'} height={'200px'}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box style={{ width: '100%', height: `calc(100vh - 118px)` }} >
                        <Root
                            isMobile={isMobile}
                            componentType={componentType}
                            edges={edges}
                            nodes={nodes}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            onConnectEnd={onConnectEnd}
                            onDrop={onDrop}
                            onSelectionDrag={onSelectionDrag}
                            onConnectStart={onConnectStart}
                            setReactFlowInstance={setReactFlowInstance}
                        />
                    </Box>
                }
            </Container>
        </>
    )
}
export default DynamicDiagram



