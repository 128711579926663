//React
import React, { useState, useEffect, } from 'react';
//Material ui
import {
    Box, Button,
    makeStyles,
    InputAdornment,
    TextField,
} from '@material-ui/core';
//Dates
import { format } from 'date-fns';
// Componentes
import InputsBox from './InputsBox'
//Servicios
import {
    createRate, editRate, deleteRate, getRates,
    createTimeZoneEnergy, createTimeZonePower,
    editTimeZoneEnergy, editTimeZonePower,
    deleteTimeZoneEnergy, deleteTimeZonePower
} from '../../services/customRates';
import {
    postNewContract,
    postContractPower, putContractPower,
    deleteContract, deleteContractPower
} from '../../services/billing';
//Styles
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    form: {
        '& .MuiInputBase-root': {
            color: '#666666',
        },
    },
    containerinputs: {
        "@media (max-width: 700px)": {
            marginRight: "5px"
        }
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(2),
    },
    labelCharge: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(1),
    },
    btn: {
        margin: theme.spacing(3, 1),
    },
    wordCut: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    inputCost: {
        width: "150px",
    },
    inputActive: {
        backgroundColor: "#00800026",
        borderRadius: "5px",
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
}));

export default function FormRatesBilling({
    selectedNode, setClose,
    setMsg, createContract, newContract,
    powerContract, bestRate, setBestRate, handleCancel, setLoadingSave
}) {
    const classes = useStyles();
    const initialData = {
        name: "Unica",
        time_from: "00:00:00",
        time_to: "00:00:00",
        hired_demand_cost: 0,
        energy_cost: 0,
        peak_demand_cost: 0,
        hired_demand: 0
    }
    //Estados Inputs
    const [energy, setEnergy] = useState([initialData])
    const [power, setPower] = useState([initialData])
    //Estado Cargo Fijo
    const [fixedChargeState, setFixedChargeState] = useState(0)
    //Son las opciones para generar una tarifa nueva y sus respectivos id
    const [optionRates, setOptionRates] = useState({})
    //estado q gurda las franjas horaria a eliminar.
    const [deleteTimeZoneState, setDeleteTimeZoneState] = useState(
        {
            state: false,
            arry: []
        }
    )

    // Cargo las tarifas segun el formulario seleccionado
    useEffect(() => {
        //Trae todos los tipos de tarifa q existen para buscar TarifaCustom.
        getRatesFunction()
        // eslint-disable-next-line
    }, [])

    const mergeDataPower = () => {
        let arry = []
        bestRate?.powerrate.forEach((elem) => {
            let aux = powerContract.find(e => e.time_slot === elem.id)
            arry.push({
                ...elem,
                hired_demand: aux?.hired_demand
            })
        })
        setPower(arry)
    }

    useEffect(() => {
        if (bestRate.id) {
            setEnergy(bestRate?.energyrate)
            mergeDataPower()
            setFixedChargeState(bestRate?.fixed_charge)
        }
        // eslint-disable-next-line
    }, [])

    //Trae todos los tipos de tarifa q existen para buscar TarifaCustom.
    const getRatesFunction = async () => {
        try {
            let res = await getRates()
            let tarifacionOption = res.data.filter(e => e.name === 'TarifaCustom')
            setOptionRates(...tarifacionOption)

        }
        catch (error) {
            console.error(error);
        }
    }

    //handle input cargo fijo
    const handleInputFixedCharge = (e) => {
        setFixedChargeState(e.target.value)
    }

    //funcion q crea todas las franjas horarias de energia
    const timeZonesEnergy = async (rate) => {
        try {
            energy?.forEach(async (element) => {
                let energyBody = {
                    name: element?.name,
                    time_from: element?.time_from,
                    time_to: element?.time_to,
                    energy_cost: element?.energy_cost,
                    timezone: -3,
                    rate: rate?.data?.id
                }
                await createTimeZoneEnergy(energyBody)
            });
        }
        catch (error) {
            //alert
            setClose(true)
            setMsg({
                msg: "error en creacion de franja horaria de energia",
                type: "error"
            })
        }
    }

    const validatingChangePower = (power, powerrate) => {
        for (let i = 0; i < power.length; i++) {
            let bestRatPowerRate = powerrate[i]
            let powerAux = power[i]
            if (bestRatPowerRate?.name !== powerAux.name) return true
            if (bestRatPowerRate?.peak_demand_cost !== powerAux.peak_demand_cost) return true
            if (bestRatPowerRate?.hired_demand_cost !== powerAux.hired_demand_cost) return true
            if (bestRatPowerRate?.time_from !== powerAux.time_from) return true
            if (bestRatPowerRate?.time_to !== powerAux.time_to) return true
        }
        return false
    }

    const validatingChangePowerHired = (power, powerContract) => {
        for (let i = 0; i < power?.length; i++) {
            let powerAux = power[i]
            let oldAux = powerContract?.find((e) => e?.time_slot === powerAux?.id)
            if (oldAux?.hired_demand !== powerAux?.hired_demand) return true
        }
        return false
    }

    //funcion para calular las horas entre dos valores.
    function hoursBetween(time1, time2) {
        let minutes1 = parseInt(time1.split(":")[0]) * 60 + parseInt(time1.split(":")[1]);
        let minutes2 = parseInt(time2.split(":")[0]) * 60 + parseInt(time2.split(":")[1]);
        let minutesDiff = minutes2 - minutes1;
        if (minutesDiff < 0) {
            minutesDiff = 1440 + minutesDiff;
        }
        return minutesDiff / 60;
    }

    const validateDates = () => {
        let powerAux = 0
        let energyAux = 0
        let text = ""
        let check = {
            pow: false,
            ener: false
        }
        power.forEach((elem) => {
            let from = elem.time_from
            let to = elem.time_to
            let hours = hoursBetween(from, to)
            powerAux += hours
        })
        energy.forEach((elem) => {
            let from = elem.time_from
            let to = elem.time_to
            let hours = hoursBetween(from, to)
            energyAux += hours
        })
        // console.log("total energia" + energyAux)
        // console.log(energyAux >= 23.90 && energyAux <= 24)
        // console.log("total power" + powerAux)
        // console.log(powerAux >= 23.90 && powerAux <= 24)
        if (powerAux >= 23.90 && powerAux <= 24) {
            check.pow = true
        }
        else {
            text += " de potencia"
        }
        if (energyAux >= 23.90 && energyAux <= 24) {
            check.ener = true
        }
        else {
            text += " de energia"
        }
        if (check.ener && check.pow) {
            return true
        }
        else {
            setClose(true)
            setMsg({
                msg: `Debe completar las 24 hs del dia en las franjas horarias ${text}. ${"  "}  Ejemplo :  00:00 ; 23:59 `,
                type: "error"
            })
            return false
        }
    }

    const validateForm = () => {
        let errorForm = false
        let mensaje = ""
        if (fixedChargeState < 1) {
            errorForm = true
            mensaje += ` El cargo fijo debe ser mayor a "0", `
        }
        if (newContract.newContractName.length < 3) {
            errorForm = true
            mensaje += ` El Nombre del contrato debe ser mayor a 3 caracteres, `
        }
        if (errorForm) {
            setClose(true)
            setMsg({
                msg: mensaje,
                type: "error"
            })
            return false
        }
        else {
            return true
        }
    }

    //Evento Guardar
    const handleSubmit = async (e) => {
        setLoadingSave(true)
        e.preventDefault();
        //hago todas las validaciones!
        if (validateForm() && validateDates()) {
            let from = format(newContract.from_date, 'yyyy-MM-dd')
            let to = format(newContract.to_date, 'yyyy-MM-dd')
            //CREO CONTRATO
            if (createContract) {
                let deletCatch = ""
                try {
                    //Body de la tarifa
                    let body =
                    {
                        date_from: from,
                        date_to: to,
                        fixed_charge: fixedChargeState,
                        public: false,
                        default: false,
                        t_type: optionRates?.id,
                        jerarquia: selectedNode?.id
                    }
                    //CREO tarifa
                    let rate = await createRate(body)
                    //CREO las franjas horarias de ENERGIA  
                    await timeZonesEnergy(rate)
                    //NECESITO CREAR LA TARIFA PRIMERO PARA CREAR EL CONTRATO
                    // body de el contrato 
                    let newContractRes = {
                        name: newContract.newContractName,
                        date_from: from,
                        date_to: to,
                        location: selectedNode?.id,
                        rate: rate.data.id,
                    }
                    deletCatch = rate.data.id
                    //CREO CONTRATO.
                    let aux = await postNewContract(newContractRes)
                    // Si el contrato es nuevo se coloca la propiedad (time_slot):
                    // time_slot:es el id de las franjas de porencia q ya cree.
                    //Creo las franjas de potencia
                    power?.map(async (element) => {
                        let powerBody = {
                            name: element.name,
                            time_from: element.time_from,
                            time_to: element.time_to,
                            hired_demand_cost: element.hired_demand_cost,
                            peak_demand_cost: element.peak_demand_cost,
                            timezone: -3,
                            rate: rate?.data?.id
                        }
                        //creo carga de potencia
                        let res = await createTimeZonePower(powerBody)
                        //creo cargo de franja fijo de potencia
                        let objPow = {
                            contract: aux.data.id,
                            hired_demand: element?.hired_demand,
                            name: element.name,
                            time_slot: res.data.id
                        }
                        await postContractPower(objPow)
                    });
                    setClose(true)
                    setMsg({
                        msg: "Se creo correctamente su contrato y su tarifa personalizada",
                        type: "success"
                    })
                }
                catch (error) {
                    await deleteRate(deletCatch)
                    // console.log("error en creacion de contrato y tarifa personalizada")
                    setClose(true)
                    setMsg({
                        msg: error.response.data.msg,
                        type: "error"
                    })
                }
            }
            //EDITO CONTRATO
            else {
                try {
                    //CARGO FIJO
                    if (fixedChargeState !== bestRate.fixed_charge) {
                        //Body de la tarifa
                        let body =
                        {
                            fixed_charge: fixedChargeState,
                        }
                        await editRate(bestRate.id, body)
                    }
                    //FRANJA DE ENERGIA - si cambio algun valor de la  modifico ese dato correspondiente.
                    if (bestRate.energyrate !== energy) {
                        for (const element of energy || []) {
                            let energyBody = {
                                name: element?.name,
                                time_from: element?.time_from,
                                time_to: element?.time_to,
                                energy_cost: element?.energy_cost,
                                timezone: -3,
                                rate: bestRate?.id
                            }
                            if (element?.id) {
                                await editTimeZoneEnergy(element.id, energyBody)
                            }
                            else {
                                await createTimeZoneEnergy(energyBody)
                            }
                        }
                        setBestRate({
                            ...bestRate,
                            energyrate: energy
                        })
                    }
                    //FRANJA DE POTENCIA COSTO - Si cambio algun valor de  modifico ese dato correspondiente.
                    if (validatingChangePower(power, bestRate.powerrate)) {
                        for (const element of power || []) {
                            let powerBody = {
                                name: element?.name,
                                time_from: element?.time_from,
                                time_to: element?.time_to,
                                hired_demand_cost: element?.hired_demand_cost,
                                peak_demand_cost: element?.peak_demand_cost,
                                timezone: -3,
                                rate: bestRate?.id
                            }
                            if (element.id) {
                                await editTimeZonePower(element.id, powerBody)
                            }
                            else {
                                let res = await createTimeZonePower(powerBody)
                                let objPow = {
                                    contract: newContract.id,
                                    hired_demand: element.hired_demand,
                                    name: element.name,
                                    time_slot: res.data.id
                                }
                                await postContractPower(objPow)
                            }
                        }
                        setBestRate({
                            ...bestRate,
                            powerrate: power
                        })
                    }
                    //POTENCIA CONTRATADA = Si cambio algun valor de  modifico ese dato correspondiente.
                    if (validatingChangePowerHired(power, powerContract)) {
                        for (const elem of powerContract || []) {
                            let oldAux = power?.find((e) => e?.id === elem?.time_slot)
                            let objPow = {
                                contract: newContract.id,
                                hired_demand: oldAux?.hired_demand,
                                name: elem.name,
                                time_slot: elem.time_slot
                            }
                            await putContractPower(elem.id, objPow)
                        }
                        setBestRate({
                            ...bestRate,
                            powerrate: power
                        })
                    }
                    //BORRAR FRANJAS HORARIAS        
                    // si alguna franja de potencia fue eliminada las elimino si paso la validacion de fechas!
                    if (deleteTimeZoneState?.state) {
                        for (const elem of deleteTimeZoneState.arry || []) {
                            // deleteTimeZoneState.arry.forEach(async (elem) => {
                            //si es franja horaria de energia
                            if (elem?.energy_cost && elem?.id) {
                                // console.log("elimino energia")
                                await deleteTimeZoneEnergy(elem.id)
                            }
                            //si es franja horaria de potencia
                            if (elem?.hired_demand_cost && elem?.id) {
                                await deleteTimeZonePower(elem?.id)
                            }
                        }
                        setDeleteTimeZoneState({
                            state: false,
                            arry: []
                        })
                    }
                    setClose(true)
                    setMsg({
                        msg: "Se modifico contrato correctamente",
                        type: "success"
                    })
                }
                catch (error) {
                    setClose(true)
                    setMsg({
                        msg: dynamicErrorFunction(error),
                        type: "error"
                    })
                }
            }
        }
        setLoadingSave(false)
    }

    //Agrega otro inputBox Component Power
    const addInputBoxPower = () => {
        if (power?.length < 3) {
            setPower([
                ...power,
                initialData
            ])
        }
    }

    //Agrega otro inputBox Component energy
    const addInputBoxEnergy = () => {
        if (energy?.length < 3) {
            setEnergy([
                ...energy,
                initialData
            ])
        }
    }

    //Evento Cancelar
    const cancel = () => {
        handleCancel()
    }

    //Evento Eliminar Tarifa
    const deleteRateFunction = async () => {
        try {
            await deleteContract(newContract.id);
            await deleteRate(bestRate.id)
            setClose(true)
            setMsg({
                msg: "Se elimino su Contrato personalizado correctamente",
                type: "success"
            })
        }
        catch (error) {
            // console.log("error en deleteRateFunction")
            setClose(true)
            setMsg({
                msg: error.response.data.msg,
                type: "error"
            })
        }
        handleCancel()
    }

    //funcion q elimina el componente.
    const deleteElement = async (idx, potencia) => {
        try {
            let setData = setEnergy
            let data = energy
            let flag = 'energy'
            if (potencia) {
                setData = setPower
                data = power
                flag = 'power'
            }
            if (data.length > 1) {
                setData(
                    data.filter((e, i) => i !== idx)
                )
                let elementDelete = data.filter((e, i) => i === idx)
                setDeleteTimeZoneState({
                    ...deleteTimeZoneState,
                    state: flag,
                    arry: [
                        ...deleteTimeZoneState.arry,
                        ...elementDelete
                    ]
                })
            }
            else {
                setClose(true)
                setMsg({
                    msg: "No puede tener menos de una franja horaria",
                    type: "success"
                })
            }
        }
        catch (error) {
            setClose(true)
            setMsg({
                msg: "error en eliminacion de contrato y franja de potencia",
                type: "error"
            })

        }
    }

    //retorna html linea azul q divide bloques de franjas.
    const blueBar = () => {
        return (
            <div style={{
                backgroundColor: "#2586bc",
                height: "2px ",
                width: " 100 %",
                marginTop: "10px"
            }}></div>
        )
    }
    // // //ordena el array de franjas de potencia de menor a mayor por horario.
    // const orderArrayTime = (array) => {
    //     let order = array.sort((a, b) => {
    //         const timeA = new Date('1970-01-01 ' + a.time_from).getTime();
    //         const timeB = new Date('1970-01-01 ' + b.time_from).getTime();
    //         return timeA - timeB;
    //     });
    //     return order
    // }

    //function q cambia el background color a verde cuando el input esta activo
    const backgroundGreen = (disabled) => {
        if (disabled === false) {
            return classes.inputActive
        }
        else {
            return ""
        }
    }
    //Funcion q retorna el error no importa el name de la propiedad.
    function dynamicErrorFunction(error) {
        if (error && error.response && error.response.data) {
            // Recorremos todas las propiedades dentro de error.response.data
            for (const key in error.response.data) {
                if (error.response.data.hasOwnProperty(key)) {
                    // Aquí puedes acceder al valor sin importar el nombre de la propiedad
                    return `${error.response.data[key]}`
                }
            }
        }
        else {
            return 'Error'
        }
    }

    return (
        <>
            <form onSubmit={e => handleSubmit(e)} className={classes.form}>

                {/* CARGO FIJO INPUT */}
                <Box style={{ display: "flex", marginBottom: "20px" }}>
                    <Box mr={6} component='label' display='block' className={classes.labelCharge}>
                        Cargo Fijo
                    </Box>
                    <TextField
                        className={clsx(classes.inputCost, classes.input, backgroundGreen(false))}
                        autoFocus
                        type="number"
                        placeholder='$'
                        name='name'
                        value={fixedChargeState}
                        // disabled={selectedNode?.level_nombre === "sublocalización"}
                        onChange={(e) => { handleInputFixedCharge(e) }}
                        color='primary'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>$</InputAdornment>,
                        }}
                    />
                </Box>

                <Box className={classes.containerinputs}>

                    {/* Linea azul q separa bloques */}
                    {blueBar()}

                    {/*   Franjas Horarias de Energia  */}
                    <Box mb={2} component='label' display='block' className={classes.label}>
                        Franjas Horarias de Energia
                    </Box>

                    {/*   INPUTS ENERGIA  */}
                    {energy?.map((element, idx) => {
                        return (
                            <InputsBox
                                deleteElement={deleteElement}
                                key={idx}
                                setData={setEnergy}
                                deleteTimeZone={deleteTimeZoneEnergy}
                                data={energy}
                                element={element}
                                idx={idx}
                                selectedNode={selectedNode}
                            />
                        )
                    })}

                    <Button color='primary' variant='contained' onClick={addInputBoxEnergy} >
                        Añadir
                    </Button>

                    {/* Linea azul q separa bloques */}
                    {blueBar()}

                    {/*   Franjas Horarias de Potencia  */}
                    <Box mb={2} component='label' display='block' className={classes.label}>
                        Franjas Horarias de Potencia
                    </Box>

                    {/*   INPUTS POTENCIA */}
                    {power?.map((element, idx) => {
                        return (
                            <InputsBox
                                deleteElement={deleteElement}
                                key={idx}
                                deleteTimeZone={deleteTimeZonePower}
                                potencia={true}
                                setData={setPower}
                                data={power}
                                element={element}
                                idx={idx}
                                selectedNode={selectedNode}
                            />
                        )
                    })}

                    <Button color='primary' variant='contained' onClick={addInputBoxPower}  >
                        Añadir
                    </Button>

                    {/* Linea azul q separa bloques */}
                    {blueBar()}

                    {/*   BUTTONS  */}
                    <Box display='flex' justifyContent='center'>
                        <Button type='submit' color='primary' variant='contained' className={classes.btn} >
                            Guardar
                        </Button>
                        <Button variant='contained' onClick={cancel} className={classes.btn}>
                            Cancelar
                        </Button>
                        <Button variant='contained' onClick={deleteRateFunction} color='secondary' className={classes.btn}>
                            Eliminar
                        </Button>
                    </Box>

                </Box>
            </form>
        </>
    );
}




