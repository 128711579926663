import { IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
    alerts: {
        height: "60px",
        marginLeft: "55px",
        fontSize: "large",
        position: "absolute",
        top: "119px",
        width: 'calc(100% - 55px)',
        zIndex: "2",
        "@media (max-width: 920px)": {
            width: '100%',
            marginLeft: "0",
            height: "max-content",
         
        },
    },
}));

export default function AlertComponent({ severity, msg, setClose, close }) {
    const classes = useStyles();

    useEffect(() => {
        if (close === true) {
            setTimeout(() => {
                setClose(false)
            }, 7000)
        }
    }, [close, setClose])

    return (
        <>
            {
                close &&
                <Alert
                    className={classes.alerts}
                    severity={severity}
                    action={
                        <IconButton
                            aria-label='close'
                            size='small'
                            onClick={() => {
                                setClose(false);
                            }}
                        >
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    }
                >
                    {msg}
                </Alert>
            }
        </>
    );
}
