import React, { useContext, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { makeStyles } from '@material-ui/core';
import UserContext from '../../../context/UserContext';
//Servicios
import { getUserConfig } from '../../../services/client';

const useStyles = makeStyles(theme => ({
  chart: {
    height: '70vh',
    margin: "5px"
  },
}));

export default function Chart({ eventsData, filterOptions }) {
  const classes = useStyles();
  const { plan } = useContext(UserContext)
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    const { showAlarms, showElectricOutages, showConnectivityOutages } = filterOptions;
    let chart = am4core.create('chartEvents', am4plugins_timeline.SerpentineChart);
    // Quitar logo AmCharts
    if (chart.logo) {
      chart.logo.disabled = true
    }

    //Envuelvo todo en una funcion async para esperar antes q nada la respuesta de la consulta sobre el timeZone de el cliente.
    const getUserTimeZone = async () => {
      //Busco el timezone de el cliente.
      let res = await getUserConfig()
      // //Configuro el timezone (GMT) de el cliente.
      chart.dateFormatter.timezone = res?.data?.timezone
      chart.dateFormatter.dateFormat = 'yyyy-MM-ddTHH:mm:ssZ';
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ssZ';

      // Quiero que los nombres de los meses estén en español
      chart.language.locale = am4lang_es_ES;

      chart.curveContainer.padding(20, 20, 20, 20);

      if (isMobile) {
        chart.levelCount = 3;
        chart.orientation = 'vertical';
      }
      else {
        chart.levelCount = 8;
        chart.orientation = 'horizontal';
      }

      chart.fontSize = 11;

      let colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.6;

      const auxLinearArray = [];
      // mockedData.forEach(event => {
      eventsData.page.forEach(event => {
        auxLinearArray.push({ ...event.event });
        //Si el campo to es null le pongo el mismo valor que el from
        if (!event.event.to) {
          auxLinearArray[auxLinearArray.length - 1].to = auxLinearArray[auxLinearArray.length - 1].from;
        }

        if (event.event.description !== 'En curso' || event.event.description !== "") {
          // auxLinearArray[auxLinearArray.length - 1].description = `${event.event.description} ${getTimezoneFromKey(plan?.info?.tz)}`
        }

        auxLinearArray[auxLinearArray.length - 1].d1 = event.event.from
        // auxLinearArray[auxLinearArray.length - 1].d1 = format(new Date(event.event.from), 'ppp');

        if (event.event.duration === 'En curso') {
          auxLinearArray[auxLinearArray.length - 1].to = filterOptions.dateTo.toISOString();
          auxLinearArray[auxLinearArray.length - 1].color = am4core.color('#3C3744');
          auxLinearArray[auxLinearArray.length - 1].duration = '-';
          auxLinearArray[auxLinearArray.length - 1].description = 'En curso';
        }
        else {
          // console.log(event.event.title);
          if (event.event.title === 'Corte de energía')
            auxLinearArray[auxLinearArray.length - 1].color = colorSet.getIndex(5);
          else if (event.event.title === 'Corte de conectividad')
            auxLinearArray[auxLinearArray.length - 1].color = colorSet.getIndex(2);
          else if (event.event.title === 'Alarma') auxLinearArray[auxLinearArray.length - 1].color = colorSet.getIndex(8);
        }
      });

      // El datepicker devuelve la fecha pero además contiene la hora. Como quiero asegurarme que sean las 00 y las 23 59 respectivamente debo configurarlo a mano.
      const d1 = new Date(filterOptions.dateFrom);
      d1.setHours(0, 0, 0, 0);
      const d2 = new Date(filterOptions.dateTo);
      d2.setHours(23, 59, 0, 0);

      //Agrego elementos al inicio y al final del array como los mínimos y máximos del gráfico.
      if (showAlarms) {
        auxLinearArray.unshift({
          from: d1.toISOString(),
          to: d1.toISOString(),
          title: 'Alarma',
          color: colorSet.getIndex(8),
        });
        auxLinearArray.push({
          from: d2.toISOString(),
          to: d2.toISOString(),
          title: 'Alarma',
          color: colorSet.getIndex(8),
        });
      }

      if (showElectricOutages) {
        auxLinearArray.unshift({
          from: d1.toISOString(),
          to: d1.toISOString(),
          title: 'Corte de energía',
          color: colorSet.getIndex(5),
        });
        auxLinearArray.push({
          from: d2.toISOString(),
          to: d2.toISOString(),
          title: 'Corte de energía',
          color: colorSet.getIndex(5),
        });
      }

      if (showConnectivityOutages) {
        auxLinearArray.unshift({
          from: d1.toISOString(),
          to: d1.toISOString(),
          title: 'Corte de conectividad',
          color: colorSet.getIndex(2),
        });
        auxLinearArray.push({
          from: d2.toISOString(),
          to: d2.toISOString(),
          title: 'Corte de conectividad',
          color: colorSet.getIndex(2),
        });
      }

      chart.data = auxLinearArray;

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'title';
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.paddingRight = 25;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.innerRadius = -60;
      categoryAxis.renderer.radius = 60;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 70;

      //formatea el tooptip
      dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };
      // dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';

      dateAxis.min = d1.getTime();
      dateAxis.max = d2.getTime();

      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.startLocation = -0.5;
      dateAxis.renderer.line.strokeDasharray = '1,4';
      dateAxis.renderer.line.strokeOpacity = 0.7;
      dateAxis.tooltip.background.fillOpacity = 0.2;
      dateAxis.tooltip.background.cornerRadius = 5;
      dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
      dateAxis.tooltip.label.paddingTop = 7;

      if (!isMobile) {
        var categoryAxisLabelTemplate = categoryAxis.renderer.labels.template;
        categoryAxisLabelTemplate.horizontalCenter = 'left';

        var labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = 'middle';
        labelTemplate.fillOpacity = 0.7;
        labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor('background');
        labelTemplate.background.fillOpacity = 1;
        labelTemplate.padding(2, 2, 2, 2);

        categoryAxisLabelTemplate.adapter.add('rotation', function (rotation, target) {
          var position = dateAxis.valueToPosition(dateAxis.min);
          return dateAxis.renderer.positionToAngle(position) + 90;
        });
      }

      var series1 = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
      series1.columns.template.height = am4core.percent(20);
      series1.columns.template.tooltipText = `[bold]{title}[/] \n Inicio: {from_formated}\n Duración: {duration_str}\n {description} `;

      // Si la descripción es "En curso" no quiero mostrar la duración del evento pues no terminó.
      series1.columns.template.adapter.add("tooltipText", (text, target) => {
        if (target.dataItem._dataContext.description === "En curso") {
          return `[bold]{ title } [/] \n Inicio: {d1}\n {description}`
        }
        return text
      })

      series1.dataFields.openDateX = 'from';
      series1.dataFields.dateX = 'to';
      series1.dataFields.categoryY = 'title';
      series1.columns.template.propertyFields.fill = 'color'; // get color from data
      series1.columns.template.propertyFields.stroke = 'color';
      series1.columns.template.strokeOpacity = 0;

      var bullet = new am4charts.CircleBullet();
      series1.bullets.push(bullet);
      bullet.circle.radius = 3;
      bullet.circle.strokeOpacity = 0;
      bullet.propertyFields.fill = 'color';
      bullet.locationX = 0;

      var bullet2 = new am4charts.CircleBullet();
      series1.bullets.push(bullet2);
      bullet2.circle.radius = 3;
      bullet2.circle.strokeOpacity = 0;
      bullet2.propertyFields.fill = 'color';
      bullet2.locationX = 1;

      chart.scrollbarX = new am4core.Scrollbar();

      if (isMobile) {
        chart.scrollbarX.align = 'right';
        chart.scrollbarX.width = am4core.percent(75);
      }
      else {
        chart.scrollbarX.align = 'center';
        chart.scrollbarX.width = am4core.percent(80);
      }
      var cursor = new am4plugins_timeline.CurveCursor();
      chart.cursor = cursor;
      cursor.xAxis = dateAxis;
      cursor.yAxis = categoryAxis;
      cursor.lineY.disabled = true;
      cursor.lineX.strokeDasharray = '1,4';
      cursor.lineX.strokeOpacity = 1;

      dateAxis.renderer.tooltipLocation2 = 0;
      categoryAxis.cursorTooltipEnabled = false;
    }
    getUserTimeZone()

  }, [eventsData, filterOptions, isMobile, plan]);
  return (
    <>
      <div id='chartEvents' className={classes.chart} />
    </>
  );
}
