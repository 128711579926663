import { useEffect, useState } from "react";
import EventsCard from "./EventsCard";
import { getEvents } from "../../../services/meters";
import { Box } from "@material-ui/core";

  
function ContainerEventsCard ({title, subtitle, positions}){
    const [events,setEvents]= useState([]);

    useEffect(() => {
        getEventsList();       
        // eslint-disable-next-line
    }, [])

    const getEventsList = async() =>{
        try {
            let query = 'filter=1451&alarmas=true&conectividad=true&cortes=true&from=1/5/2024&to=14/5/2024&page=1&per_page=500'
            const res = await getEvents(query);
            setEvents(res?.data.page)
        } catch (error) {
            console.error(error);
        }   
    }

    return(
        <Box style={{ height: '100%',overflow:'hidden'}}>
            <EventsCard events={events}
            title={title}
            subtitle={subtitle}
            positions={positions}></EventsCard>
        </Box>
    )
}

export default ContainerEventsCard;