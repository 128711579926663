import { useState } from 'react';
import WeekChart from './WeekChart';

// mui
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// icons
import ClearIcon from '@material-ui/icons/Clear';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TuneIcon from '@material-ui/icons/Tune';

// date-fns
import DateFnsUtils from '@date-io/date-fns';
import { addDays, differenceInDays, lastDayOfWeek, subDays } from 'date-fns';
import { es } from 'date-fns/locale';

// components
import Container from '../Elements/Container';
import ErrorAlert from '../common/ErrorAlert';
import TopNav from '../common/TopNav';
import Filter from './Filter';
import YearChart from './YearChart';

// helpers
import { formatDateObjToSlash } from '../../helpers/common';
import { CALENDAR_INITIAL_CONFIG, FIELDS_MAP, getLabelText } from './helpers';

// endpoints
import { isAfter } from 'date-fns';
import { getHistoryV2, getInstantV2 } from '../../services/meters';
import {
  getFilterOptionsFromLocalStorage,
  saveFilterOptionsInLocalStorage,
} from '../../services/auth';

const useStyles = makeStyles(theme => ({
  quickDateContainer: {
    padding: '0 8px',
    color: theme.palette.common.white,
    fontSize: 16,
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.common.white,
      width: 125,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  date: {
    '& .MuiInputBase-root': {
      width: 120,
    },
    // Oculto botón calendario
    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
  quickBtn: {
    '&.Mui-disabled': {
      opacity: '0.5',
    },
  },
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      marginBottom: -theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
  },
  nodosBoxesLabels: {
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '200px',
    },
  },
}));

const Heatmap = () => {
  const classes = useStyles();
  const [filterPanel, setFilterPanel] = useState(true);
  const [loading, setLoading] = useState(false);
  const [heatmapData, setHeatmapData] = useState([]);

  const localStorageDevice = getFilterOptionsFromLocalStorage();

  const [filterOptions, setFilterOptions] = useState({
    dispositivo: localStorageDevice ? localStorageDevice : {},
    dateFrom: subDays(lastDayOfWeek(new Date()), 6),
    dateTo: lastDayOfWeek(new Date()),
    variable: 'energiaHora',
  });
  const [calendarConfig, setCalendarConfig] = useState(CALENDAR_INITIAL_CONFIG);

  const [errorMessage, setErrorMessage] = useState('');

  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };

  const handleClosePanel = () => {
    setFilterPanel(false);
  };

  const fetchInstantV2 = async url => {
    try {
      setLoading(true);
      setErrorMessage('');
      setHeatmapData([]);
      const res = await getInstantV2(url);
      setLoading(false);
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        setHeatmapData(res.data);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setErrorMessage(
          `${error.response.data.msg}.`
        );
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
    }
  };

  const fetchHistoryV2 = async url => {
    try {
      setLoading(true);
      setErrorMessage('');
      setHeatmapData([]);
      const res = await getHistoryV2(url);
      // console.log(res.data);
      setLoading(false);
      if (res.data.error) {
        setErrorMessage(res.data.error);
      } else {
        setHeatmapData(res.data);
      }
    } catch (error) {
      // console.error(error);
      setLoading(false);
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage(
            `No se encontraron datos para el período seleccionado.`
          );
        } else {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        }
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
    }
  };

  const handleSubmit = filter => {
    setFilterOptions(prevState => {
      return { ...prevState, ...filter };
    });

    saveFilterOptionsInLocalStorage(filter.dispositivo);

    //Si tengo un dispositivo seleccionado
    if (filter.dispositivo.id) {
      let url = '';
      url += `filter=${filter.dispositivo.id}`;

      url += '&fields=';

      url += FIELDS_MAP[filter.variable] || '';

      url += `&response_format=v2`;

      if (filter.variable.includes('Hora')) {
        url += `&from=${formatDateObjToSlash(filter.dateFrom)}`;
        url += `&to=${formatDateObjToSlash(filter.dateTo)}`;
        url += `&granularity=60`
        fetchInstantV2(url);
      }
      if (filter.variable.includes('Dia')) {
        url += `&year=${filter.dateFrom.getFullYear()}`;
        url += '&period=daily_yearly';
        fetchHistoryV2(url);
      }
    }

    //Le paso filter porque setFilterOptions es asincrónica y no estaría disponible filterOptions
    configCalendar(filter);

    //Cierro el panel de Filtros
    setFilterPanel(false);
    // setFilterOptions(filter);
  };

  const configCalendar = filter => {
    const config = {};
    if (filter.variable.includes('Hora')) {
      config.views = ['month', 'year'];
      config.disabled = false;

      config.format = 'dd/MM/yyyy';
    }
    if (filter.variable.includes('Dia')) {
      config.disabled = true;
      config.format = 'yyyy';
    }
    setCalendarConfig(config);
  };

  const decrementWeek = () => {
    const d1 = subDays(filterOptions.dateFrom, 7);
    const d2 = subDays(filterOptions.dateTo, 7);
    setFilterOptions({
      ...filterOptions,
      dateFrom: d1,
      dateTo: d2,
    });

    handleSubmit({
      ...filterOptions,
      dateFrom: d1,
      dateTo: d2,
    });
  };

  const incrementWeek = () => {
    const d1 = addDays(filterOptions.dateFrom, 7);
    const d2 = addDays(filterOptions.dateTo, 7);
    setFilterOptions({
      ...filterOptions,
      dateFrom: d1,
      dateTo: d2,
    });
    handleSubmit({
      ...filterOptions,
      dateFrom: d1,
      dateTo: d2,
    });
  };

  const decrementYear = () => {
    const result = new Date(filterOptions.dateFrom);
    result.setFullYear(result.getFullYear() - 1);
    setFilterOptions({ ...filterOptions, dateFrom: result, dateTo: result });
    handleSubmit({
      ...filterOptions,
      dateFrom: result,
      dateTo: result,
    });
  };
  const incrementYear = () => {
    const result = new Date(filterOptions.dateFrom);
    result.setFullYear(result.getFullYear() + 1);
    setFilterOptions({ ...filterOptions, dateFrom: result, dateTo: result });
    handleSubmit({
      ...filterOptions,
      dateFrom: result,
      dateTo: result,
    });
  };

  const isFuture = d2 => {
    const today = new Date();
    const candidate = addDays(d2, 7);

    // Si al presionar generaría una fecha futura con una distancia de 6 días o mas
    if (isAfter(candidate, today) && differenceInDays(candidate, today) > 5) {
      return true;
    }
    return false;
  };

  return (
    <>
      <TopNav titulo="Análisis" subtitulo="| Heatmap">
        <Grid container justifyContent="flex-end">
          {/* quick date container */}
          <Box
            className={classes.quickDateContainer}
            display="flex"
            justifyContent="center"
          >
            {/* botón semana anterior */}
            <Box display="flex" alignItems="center">
              <IconButton
                className={classes.quickBtn}
                aria-label="periodo anterior"
                onClick={
                  filterOptions.variable.includes('Hora')
                    ? decrementWeek
                    : decrementYear
                }
                disabled={loading}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </Box>
            {/* calendario fecha inicial */}
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.date}
                readOnly={true}
                autoOk
                views={calendarConfig.views}
                // format="dd/MM/yyyy"
                format={calendarConfig.format}
                disableToolbar
                variant="inline"
                margin="normal"
                // id='date-picker-inline'
                // label='Date picker inline'
                value={filterOptions.dateFrom}
                maxDate={new Date()}
                maxDateMessage=""
                minDateMessage=""
                KeyboardButtonProps={{
                  'aria-label': 'cambiar fecha',
                }}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}

              // PopoverProps={{ classes: classes }}
              />
            </MuiPickersUtilsProvider>
            {filterOptions.variable.includes('Hora') && (
              <>
                <Box display="flex" alignItems="center">
                  -
                </Box>
                {/* calendario fecha final */}
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    readOnly={true}
                    className={classes.date}
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={filterOptions.dateTo}
                    KeyboardButtonProps={{
                      'aria-label': 'cambiar fecha',
                    }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    minDate={new Date(filterOptions.dateFrom)}
                    maxDateMessage=""
                    minDateMessage=""
                  />
                </MuiPickersUtilsProvider>
              </>
            )}

            {/* boton semana siguiente */}
            <Box display="flex" alignItems="center">
              <IconButton
                className={classes.quickBtn}
                aria-label="periodo siguiente"
                onClick={
                  filterOptions.variable.includes('Hora')
                    ? incrementWeek
                    : incrementYear
                }
                disabled={isFuture(filterOptions.dateTo) || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Box>

          <Button
            className={classes.btn}
            type="button"
            onClick={togglePanel}
            size="small"
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>
        </Grid>
      </TopNav>
      <Container>
        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
        {!loading && errorMessage === '' && heatmapData.length === 0 && (
          <Alert severity="info">
            Bienvenido a Heatmap. En esta sección podrá consultar la información
            de sus dispositivos en forma de mapa de calor. Para comenzar haga
            click en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>
        )}

        {errorMessage !== '' && (
          <Box>
            <ErrorAlert message={errorMessage} severity="error" />
          </Box>
        )}
        {!loading && heatmapData.length > 0 && (
          <>
            <Box className={classes.nodosBoxesLabels}>
              <Chip
                className={classes.chip}
                label={
                  <Box component="span">
                    <strong>Dispositivo:</strong>{' '}
                    {filterOptions.dispositivo.nombre}
                  </Box>
                }
                color="primary"
                clickable
                onClick={() => setFilterPanel(true)}
              />

              <Chip
                className={classes.chip}
                label={
                  <Box component="span">
                    <strong>Variable:</strong>{' '}
                    {getLabelText(filterOptions.variable)}
                  </Box>
                }
                color="secondary"
                variant="outlined"
                clickable
                onClick={() => setFilterPanel(true)}
              />
            </Box>
            {filterOptions.variable.includes('Hora') && (
              <WeekChart data={heatmapData} filterOptions={filterOptions} />
            )}
            {filterOptions.variable.includes('Dia') && (
              <YearChart data={heatmapData} filterOptions={filterOptions} />
            )}
          </>
        )}
      </Container>

      <Dialog
        open={filterPanel}
        onClose={handleClosePanel}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
        disableScrollLock
      >
        <DialogTitle id="form-dialog-title">Filtrar</DialogTitle>
        <DialogContent>
          <Filter
            onSubmit={handleSubmit}
            filterOptions={filterOptions}
            setFilterPanel={setFilterPanel}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Heatmap;
