import { makeStyles, } from '@material-ui/core/styles';
import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@mui/material/Badge';
//Iconos Barra Superior
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import UserContext from '../../context/UserContext';
//Servicios
import { getNotificationsNumber } from '../../services/notifications'
//COmponentes
import CardNotification from './CardNotification';
import NotificationContent from './NotificationContent';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        right: '122px',
        top: '2px',
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 1100px)': {
            width: '100%',
            right: '0',
        },
    },
    icon: {
        '@media (max-width: 1100px)': {
            marginRight: '122px'
        },
    }
}));

const NotificationIconComponent = ({ clean }) => {
    const { plan } = useContext(UserContext);
    const [numberNotification, setNumberNotification] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openModalNotificationContent, setOpenModalNotificationContent] = useState(false);
    const [notificationSelect, setNotificationSelect] = useState({});
    const classes = useStyles()

    useEffect(() => {
        let intervalId = {}
        if (plan?.info) {
            getNotification()
            intervalId = setInterval(getNotification, 300000);
        }
        return () => {
            // Limpia el intervalo cuando el componente se desmonta para evitar fugas de memoria
            setNumberNotification(0)
            clearInterval(intervalId)
        };
    }, [clean, plan])

    //NOTIFICATIONS
    const getNotification = async () => {
        try {
            let res = await getNotificationsNumber()
            setNumberNotification(res?.data?.not_read)
        }
        catch (error) {
        }
    }

    const handleClose = () => {
        setOpenModal(!openModal)
    }

    const handleCloseNotificationContent = (notification) => {
        if (notification?.id) {
            setNotificationSelect(notification)
        }
        else {
            setNotificationSelect({})
        }
        setOpenModalNotificationContent(!openModalNotificationContent)
    }

    return (
        <Box className={classes.root}>

            {/* Modal de la notificacion contenido principal */}
            {notificationSelect?.id &&
                <NotificationContent
                    notificationSelect={notificationSelect}
                    openModalNotificationContent={openModalNotificationContent}
                    handleCloseNotificationContent={handleCloseNotificationContent}
                />
            }
            {/* NOTFICACIONES */}
            <Box className={classes.icon} display='flex' justifyContent='flex-end'>
                <Tooltip title="Notificaciones">
                    <IconButton
                        onClick={handleClose}
                        color="primary" >
                        <Badge badgeContent={numberNotification} showZero={numberNotification > 0} color="secondary">
                            <NotificationsNoneOutlinedIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            <CardNotification getNotification={getNotification} openModal={openModal} handleClose={handleClose} handleCloseNotificationContent={handleCloseNotificationContent} setNumberNotification={setNumberNotification} />
        </Box >
    )

}
export default NotificationIconComponent
