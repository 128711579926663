import {
    Box, Button, makeStyles
} from '@material-ui/core';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    buttonConfig: {
        margin: theme.spacing(1, 0, 1, 0),
    }
}));

const ButtonLowerCase = styled(Button)({
    textTransform: 'none',
    padding: "2px",
});

export default function ButtonComponent({ HandleSubmit, children,  backgroundColorButton }) {
    const classes = useStyles();
    return (
        <Box display='flex' justifyContent='center' flexDirection={"column"} width={"95%"}>
            {/* <Tooltip title={tootltipText}> */}
            <ButtonLowerCase
                className={classes.buttonConfig}
                variant='contained'
                color={backgroundColorButton}
                onClick={HandleSubmit}
            >
                {children}
            </ButtonLowerCase>
            {/* </Tooltip> */}
        </Box>
    )
}