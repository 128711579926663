import React, { useState, useEffect } from 'react';

import {
    Box, Button, makeStyles, Dialog, TextField, Switch, FormControlLabel,
    CircularProgress
} from '@material-ui/core';
//Icons material
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LaunchIcon from '@mui/icons-material/Launch';
//Helpers
import { blueBar } from '../../helpers/common'

const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(1),
    },
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    dialogSubtitle: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2),
        '@media (max-width: 700px)': {
            display: 'none',
        },
    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '400px'
    },
}));

export default function ModalShareDashboard({ open, setOpen, url, is_public, setPublicState, saveConfig }) {
    const classes = useStyles();
    const blueLine = blueBar()
    const [stateOption, setStateOption] = useState(true);
    const [stateSwitch, setStateSwitch] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStateSwitch(is_public)
        // eslint-disable-next-line 
    }, [is_public])

    useEffect(() => {
        if (stateSwitch !== is_public) {
            setStateOption(false)
        } else {
            setStateOption(true)
        }
        // eslint-disable-next-line 
    }, [stateSwitch])

    const handleSwitch = () => {
        setStateSwitch(prevState => !prevState);
    };

    const handleSubmit = async () => {
        setPublicState(prevState => ({
            ...prevState,
            is_public: stateSwitch
        }));
        setLoading(true)
        await saveConfig(stateSwitch)
        setLoading(false)
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby='modal_name'
            fullWidth
            disableScrollLock
        >
            {loading ?
                <div className={classes.circular}>
                    <CircularProgress />
                </div>
                :
                <Box className={classes.containerMain}>
                    <div className={classes.dialogSubtitle}>
                        Compartir dashboard.
                    </div>

                    {blueLine}
                    <Box display='flex'>
                        <Box m={1}>
                            <FormControlLabel
                                control={
                                    <Switch name='showPhase'
                                        // disabled={disabledInputFunction()}
                                        checked={stateSwitch}
                                        onChange={handleSwitch}
                                        color='primary' />
                                }
                            />
                        </Box>

                        <Box
                            mt={2.1}
                            width={'30%'}
                            style={{ fontWeight: 'bold', color: stateSwitch ? '#4CBF39' : '#af2c47' }}>
                            {stateSwitch ? 'Habilitado' : 'Deshabilitado'}
                        </Box>
                    </Box>

                    {blueLine}

                    <Box display={'flex'}>

                        <TextField
                            placeholder={url}
                            type='text'
                            fullWidth
                            label='Url publica.'
                            name='dashboard_name'
                            size='small'
                            variant='outlined'
                            color='primary'
                            value={is_public ? window.location.origin + url : "URL no generada."}
                            disabled={true}
                        />
                        <Button
                            disabled={!is_public}
                            style={{ marginLeft: '10px' }}
                            size='small'
                            variant='contained'
                            onClick={() => {
                                window.open(window.location.origin + url, '_blank');
                            }}
                        >
                            <LaunchIcon style={{ marginRight: '2px', fontSize: '17px' }} />
                        </Button>
                        <Button
                            disabled={!is_public}
                            style={{ marginLeft: '10px' }}
                            size='small'
                            variant='contained'
                            onClick={() => {
                                navigator.clipboard.writeText(window.location.origin + url)
                            }}
                        >
                            <FileCopyIcon style={{ marginRight: '2px', fontSize: '17px' }} />
                        </Button>
                    </Box>

                    {blueLine}

                    <Box display='flex' justifyContent='center' mt={2}>
                        <Button
                            disabled={stateOption}
                            onClick={handleSubmit}
                            variant='contained' color='primary' className={classes.btnSpacing}>
                            Guardar
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(false)
                            }}
                            variant='contained' className={classes.btnSpacing}>
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            }
        </Dialog>
    )
}