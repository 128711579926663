import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
// Material UI
import {
  Box, Button, Chip, List,
  CircularProgress, Dialog, DialogTitle,
  makeStyles
} from '@material-ui/core';
//Material Icons
import SpeedIcon from '@material-ui/icons/Speed';
//Componentes
import Node from '../../AlertsAndReports/Node';
//Helpers
import { getNodesTree } from '../../../services/hierarchy';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
//Servicios
import { getStatusNodes } from '../../../services/meters';

let scrollsSettings = {
  overflow: "scroll",
  height: "50vh",
  marginBottom: "10px",
  overflowX: "hidden",
}
let webkitScrollbar = {
  background: "white",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#beb1b18f",
  color: "red",
  width: "9px",
}
let webkitscrollbarTrack = {
  background: "#dfdfe957",
}

const useStyles = makeStyles(theme => ({
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px'
  },
  devicesBtn: {
    margin: theme.spacing(3, 1)
  },
  dialogSubtitle: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  dialogTitle: {
    paddingBottom: 0
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
  },
  containerNodes: {
    paddingRight: "2px",
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack

    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    "@media (max-width: 1100px)": {
      width: "100%",
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    "@media (max-width: 700px)": {
      width: "250px",
    }
  },
}));

function GaugeFilter({ isOpen, setIsOpen, device, setDevice }) {
  const { isMobile } = useDeviceDetect();
  const classes = useStyles();
  const [selectedNode, setSelectedNode] = useState({});
  const [nodes, setNodes] = useState([]);
  const [loadingNodes, setLoadingNodes] = useState(false);
  const [statusNodes, setStatusNodes] = useState([]);

  useEffect(() => {
    fetchNodesTreeData();
  }, []);

  useEffect(() => {
    if (device) {
      setSelectedNode(device);
    }
  }, [device]);

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      setStatusNodes(arry)
    }
    getStatusNodesAux()
  }, [])

  const fetchNodesTreeData = async () => {
    try {
      setLoadingNodes(true);
      const res = await getNodesTree();
      setNodes(res.data);
      setLoadingNodes(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedNode = node => {
    // console.log(node)
    // Si el nodo elegido es el mismo que el actualmente seleccionado lo deselecciono
    if (node === selectedNode) setSelectedNode({});
    else setSelectedNode(node);
  };

  const handleCloseDevices = () => {
    // Quiero que si elegi un nodo distinto al actual y luego cancelo que vuelva al confirmado.
    setSelectedNode(device)

    setIsOpen(false);
  };

  const handleDeleteNodeSelection = () => {
    setSelectedNode({});
  };

  // Submit en Dialog de dispositivos
  const handleDeviceAdded = () => {
    setDevice(selectedNode);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseDevices}
        aria-labelledby='form-dialog-title'
        fullWidth
        disableScrollLock
      >
        <DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
          Selección de Dispositivo
        </DialogTitle>
        {
          !isMobile &&
          <div className={classes.dialogSubtitle}>Seleccionar un elemento de la organización.</div>
        }

        <Box padding="8px">

          <Box display='flex' className={classes.selectedItemsContainer}>
            {selectedNode.nombre &&
              <Chip
                icon={<SpeedIcon />}
                label={selectedNode.nombre}
                clickable
                color='primary'
                className={classes.filterChip}
                onDelete={handleDeleteNodeSelection}
              />
            }
          </Box>

          <Box component='label' display='block' className={classes.label}>
            Organización
          </Box>

          <Box className={classes.containerNodes}>

            {
              loadingNodes ? (

                <div className={classes.circular}>

                  <CircularProgress />

                </div>
              )

                :

                (
                  <>
                    {nodes.length === 0 &&

                      <h2 style={{ textAlign: "center", fontSize: "19px", color: "#2586bc", paddingTop: "20px" }}>
                        Usted no posee ninguna localización configurada en su cuenta.<br></br>
                        Por favor, configure al menos una localización para poder continuar.
                        <NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
                          [link]
                        </NavLink>

                      </h2>
                    }

                    <List component='div' disablePadding dense className={classes.scrollableContainer}>

                      {nodes.map(node =>

                        <Node
                          node={node}
                          handleSelectedNode={handleSelectedNode}
                          selectedNode={selectedNode} key={node.id}
                          statusNodes={statusNodes}
                        />)
                      }

                    </List>
                  </>
                )}
          </Box>


          <Box display='flex' justifyContent='center'>
            <Button
              className={classes.devicesBtn}
              variant='contained'
              color='primary'
              onClick={handleDeviceAdded}
              disabled={selectedNode.nombre ? false : true}
            >
              Aceptar
            </Button>
            <Button variant='contained' onClick={handleCloseDevices} className={classes.devicesBtn}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default GaugeFilter;
