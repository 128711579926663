import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import SpeedIcon from '@material-ui/icons/Speed';
import clsx from 'clsx';
import React from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

const useStyles = makeStyles(theme => ({
	selected: {
		'&.MuiListItem-root.Mui-selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},

		'&.MuiListItem-root.Mui-selected p': {
			color: theme.palette.common.white,
		},
	},
	nodeHeader: {
		display: 'flex',
		'& span': {
			fontSize: "larger",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
		},
		'& p': {
			marginLeft: '5px',
			fontSize: "larger"
		},
		"@media (max-width: 700px)": {
			flexDirection: " column",
			'& span': {
				marginLeft: '5px',
				fontSize: "small",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
				maxWidth:'70%'
			},
			'& p': {
				marginLeft: '5px',
				fontSize: "small",
			},
		},

	},
	iconMedidor: {
		"@media (max-width: 700px)": {
			marginRight: theme.spacing(6)
		}
	},
	node: {
		"@media (max-width: 720px)": {
			height: "50px",
			marginBottom: "1px",
			padding: "10px",
		}
	}
}));

export default function Node({ node, selectedNode, handleSelectedNode }) {
	const classes = useStyles();

	return (
		<>
			{<ListItem
				button
				selected={selectedNode ? selectedNode.id === node.id : false}
				className={clsx(classes.selected, classes.node)}
				onClick={() => handleSelectedNode(node)}>
				<ListItemText primary={node.nombre} secondary={`(${node.llave})`} className={classes.nodeHeader} />

				<ListItemSecondaryAction >
					{/* ICONO SMART */}
					{node.nombre_producto === "SMART" && (
						<Tooltip title={`${node.nombre_producto}`}>
							<IconButton
								edge='end'
								aria-label='nombre producto'
							>
								<ApartmentIcon />
							</IconButton>
						</Tooltip>
					)}
					{/* ICONO HOME */}
					{node.nombre_producto === "HOME" && (
						<Tooltip title={`${node.nombre_producto}`}>
							<IconButton
								edge='end'
								aria-label='nombre producto'
							>
								<HomeIcon />
							</IconButton>
						</Tooltip>
					)}
					{/* ICONO ASIGNADO */}
					{node.asignado === "True" ? (
						<Tooltip title={`${node.jerarquia_nombre}`}>
							<IconButton
								edge='end'
								aria-label='nombre producto'
							>
								<AssignmentTurnedInIcon />
							</IconButton>
						</Tooltip>
					) :
					(
						<Tooltip title={`${node.jerarquia_nombre ? node.jerarquia_nombre : 'Medidor no asignado'}`}>
							<IconButton
								edge='end'
								aria-label='nombre producto'
							>
								<BookmarkBorderIcon />
							</IconButton>
						</Tooltip>
					)
					}
					{/* ICONO ESTADO */}					
					<IconButton	>
						<SpeedIcon style={node.online ? { color: '#66bb6a' } : { color: '#ef5350' }} />
					</IconButton>					

				</ListItemSecondaryAction>
			</ListItem>}
		</>
	);
}
