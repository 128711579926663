import ContainerBlockGenericTypeLogic from "../ContainersBlocks/ContainerBlockGenericTypeLogic";

function NOR({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericTypeLogic
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            userConfiguration={true}
        />
    );
}
export default NOR;