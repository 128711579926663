import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { getHistoryV2 } from '../../../services/meters';
import HorizontalTagsBar from './HorizontalTagsBar';
//Servicios
import { getSummaryLocation } from '../../../services/hierarchy';

const useStyles = makeStyles(theme => ({
    chart: {
        width: "100%",
        height: "100%",
    },
    title: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        margin: theme.spacing(1.5, 0, 0.5, 3),
        fontWeight: "600",
    }
}));

function ContainerTagsChart({ tags, id, titleDisplay, ky }) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showMain, setShowMain] = useState(false);
    const [data, setData] = useState([]);
    const classes = useStyles();
    useEffect(() => {

        const fetchTagsMonthly = async () => {
            try {
                setLoading(true);
                setData([])
                setShowErrorAlert(false);
                setErrorMessage('');
                let resTags = await getSummaryLocation(id)
                let tagIds = []
                //si tags existe es el componente q se utiliza en Resumen Localizacion.
                if (tags) {
                    tagIds = tags?.map(tag => tag.id);
                }
                //si tags no existe es el componente q se utiliza en dashboard personalizado.
                else {
                    tagIds = resTags?.data?.tags?.map(tag => tag.id);
                }
                const queryTags = tagIds?.join(`&filter=${id}+`);
                let url = `filter=${id}+${queryTags}`;
                url += `&fields=kwh`
                url += `&period=monthly`
                const res = await getHistoryV2(url);

                const filteredData = filterData(res.data);
                setData(filteredData);
                setShowMain(true)
                // En el caso de q todos los valores esten en cero podria mostrar un mensaje
                if (checkArrayData(filteredData)) {
                    setData(filteredData);
                    setShowMain(true)
                }
                else {
                    setShowMain(false)
                    setErrorMessage(`No posee etiquetas configuradas.`);
                    setShowErrorAlert(true);
                }
                setLoading(false);
            }
            catch (error) {
                setShowMain(false)
                setLoading(false);
                if (error.response) {
                    setErrorMessage(
                        `${error.response.data.msg}.`
                    );
                }
                else {
                    setErrorMessage(`Ocurrió un error inesperado, por favor verifique si tiene etiquetas configuradas en su localizacion`);
                }
                setShowErrorAlert(true);
            }
        };
        fetchTagsMonthly();
        return (() => {
            setData([])
        })
        // eslint-disable-next-line
    }, [id]);


    const monthCero = (value) => {
        if (value.slice(0, 1) === "0") {
            return Number(value.slice(1))
        }
        else {
            return value
        }
    }

    const filterData = data => {
        const currentMonth = new Date().getMonth() + 1;
        let dataAux = data?.map(tag => {
            let kwh = null;
            let color = null
            for (let i = 0; i < tag.data.length; i++) {
                let numberMontFilter = monthCero(tag.data[i].fecha_.split("-")[1])
                if (Number(numberMontFilter) === currentMonth) {
                    kwh = tag.data[i].kwh;
                    color = tag?.tags[0].color
                }
            }
            return { tag: tag.tags[0].nombre, kwh, color };
        });
        return dataAux?.filter((elem) => elem.kwh > 0)
    };

    //Funcion encargada de avisarme si las etiquetas existen pero todas tienen datos en cero
    const checkArrayData = (data) => {
        let res = 0
        data.forEach((e) => {
            if (e.kvarh === 0) {
                res++
            }
        })
        if (res === data.length) return false
        return true
    }

    return (
        <Box style={{ height: "100%" }}>
            {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}
            {loading ?
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                </Box>
                :
                <>
                    {showMain &&
                        <>
                            {titleDisplay &&
                                <Box className={classes.title}>
                                    Energía <span style={{ fontWeight: '400' }}>{"| consumida por etiqueta"}</span>
                                </Box>
                            }
                            < HorizontalTagsBar
                                data={data}
                                ky={ky}
                            />
                        </>
                    }
                </>
            }
        </Box>
    );
}
export default ContainerTagsChart;
