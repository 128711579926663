import React, { useEffect, useState } from 'react';
// Material UI
import {
    Box,
    Button,
    makeStyles,
    TextField,
    InputAdornment, IconButton,


} from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//servicios
import { changePassword } from '../../services/client';

const useStyles = makeStyles(theme => ({
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
}));

const PasswordChange = ({ handleOpenPasswordChange, setMsg, setClose }) => {
    const classes = useStyles();
    const [paswordState, setPasswordState] = useState({
        currentPassword: '',
        onePassword: '',
        secondPassword: ''
    })
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [error, setError] = useState({
        color: '',
        msg: ''
    })
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        if (paswordState.currentPassword.length > 0) {
            //si las pass son diferentes.
            if (paswordState.onePassword !== paswordState.secondPassword) {
                setButtonDisabled(true)
                setError({
                    color: 'red',
                    msg: 'Las contraseñas deben coincidir y debe tener mas de 4 digitos.'
                })
            }
            //si las pass son iguales.
            else {
                if (paswordState.onePassword.length > 4 && paswordState.secondPassword.length > 4) {
                    setButtonDisabled(false)
                    setError({
                        color: 'green',
                        msg: 'Validacion correcta.'
                    })
                }
            }
        }
        else {
            setButtonDisabled(true)
            setError({
                color: 'green',
                msg: ''
            })
        }
    }, [paswordState])

    const handleSubmit = async () => {
        try {
            let body = {
                current_password: paswordState.currentPassword,
                new_password: paswordState.onePassword
            }
            await changePassword(body)
            setClose(true)
            setMsg({
                msg: 'Se ha cambiado su contraseña con éxito.',
                type: 'success'
            })

        }
        catch (error) {
            let msg = "Ocurrio un error inesperado."
            if (error?.response?.data?.msg) {
                msg = error?.response?.data?.msg
            }
            setClose(true)
            setMsg({
                msg: msg,
                type: 'error'
            })
        }
        //Cierro modal.
        handleOpenPasswordChange()
    }

    const handleChange = (e) => {
        if (e.target.name === 'current_password') {
            setPasswordState({
                ...paswordState,
                currentPassword: e.target.value
            })
        }
        if (e.target.name === 'one_validation') {
            setPasswordState({
                ...paswordState,
                onePassword: e.target.value
            })
        }
        if (e.target.name === 'second_validation') {
            setPasswordState({
                ...paswordState,
                secondPassword: e.target.value
            })
        }
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <TextField
                type={showPassword ? 'text' : 'password'}
                placeholder={'Contraseña actual'}
                label={'Contraseña actual'}
                name={'current_password'}
                value={paswordState.currentPassword}
                onChange={handleChange}
                fullWidth
                color='primary'
                InputProps={{
                    endAdornment:
                        <InputAdornment position="start">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>,
                }}
            />

            <Box mt={1} >
                <TextField
                    placeholder={'Nueva contraseña'}
                    label={'Nueva contraseña'}
                    type={showPassword ? 'text' : 'password'}
                    name={'one_validation'}
                    value={paswordState.onePassword}
                    onChange={handleChange}
                    fullWidth
                    color='primary'
                    disabled={paswordState.currentPassword.length === 0}
               
                />
            </Box>

            <Box mt={1} >
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    placeholder={'Repita su nueva contraseña'}
                    label={'Repita su nueva contraseña'}
                    name={'second_validation'}
                    value={paswordState.secondPassword}
                    onChange={handleChange}
                    fullWidth
                    color='primary'
                    disabled={paswordState.currentPassword.length === 0}
                />
            </Box>

            {/* Error label */}
            <Box mt={1} style={{ color: error.color }} >
                {error.msg}
            </Box>


            <Box display='flex' justifyContent='center' marginTop={2}>
                <Button disabled={buttonDisabled} className={classes.btnSpacing} type='submit' color='primary' variant='contained' onClick={handleSubmit}>
                    Guardar
                </Button>
                <Button className={classes.btnSpacing} type='submit' variant='contained' onClick={handleOpenPasswordChange}>
                    Cancelar
                </Button>

            </Box>
        </>

    )
}
export default PasswordChange