import instance from './testing.instance';

export const getNodes = () => instance.get(`/hierarchy/nodes/`);
export const getNodesTreeContractValidate = () => instance.get(`/hierarchy/nodes/tree/?contract=True`);
export const getNodesTree = () => instance.get(`/hierarchy/nodes/tree/`);
export const getNodesById = id => instance.get(`/hierarchy/nodes/${id}/`);

//trae todas las sublozalizaciónes pertenecientes a una localización con la propiedad area_comun.
export const getNodesByIdCommonArea = IDLocation => instance.get(`/hierarchy/nodes/${IDLocation}/sublocations/`);

export const getExtraField = (id, field) => instance.get(`/hierarchy/nodes/${id}/${field}/`);
export const getExtraInfo = (id) => instance.get(`/hierarchy/nodes/${id}/info/`)
export const getSummary = id => instance.get(`/hierarchy/nodes/${id}/summary/`);
export const getTags = () => instance.get(`/hierarchy/tags/`);
export const getLocations = () => instance.get(`/hierarchy/nodes/locations/`);
export const getLocationsContractValidate = () => instance.get(`/hierarchy/nodes/locations/?contract=True`);
export const getSharedNodes = (id) => instance.get(`/hierarchy/nodes/${id}/shared/`)
export const getChildrenNodes = (id) => instance.get(`/hierarchy/nodes/${id}/childs/`)

export const postNode = body => instance.post(`/hierarchy/nodes/`, body);
export const linkTagToNodeId = (id, body) => instance.post(`/hierarchy/nodes/${id}/tags/`, body);
export const postNewTag = body => instance.post(`/hierarchy/tags/`, body);

export const putNodesById = (id, body) => instance.put(`/hierarchy/nodes/${id}/`, body);
export const putExtraField = (id, field, body) => instance.put(`/hierarchy/nodes/${id}/${field}/`, body);
export const putExtraInfo = (id, body) => instance.put(`/hierarchy/nodes/${id}/info/`, body)
export const putTagById = (id, body) => instance.put(`/hierarchy/tags/${id}/`, body);

export const deleteNode = id => instance.delete(`/hierarchy/nodes/${id}/`);
export const deleteTagById = id => instance.delete(`/hierarchy/tags/${id}/`);

// Notar que cuando tengo que pasarle datos a un delete es diferente que con un POST por ejemplo.
export const unlinkTagFromNodeId = (id, body) => instance.delete(`/hierarchy/nodes/${id}/tags/`, { data: body });

export const getQualityConfig = id => instance.get(`/hierarchy/nodes/${id}/conf/quality/`);
export const putQualityConfig = (id, body) => instance.put(`/hierarchy/nodes/${id}/conf/quality/`, body);

//patch sublocalizacion 
export const editSublocalization = (IDSubLocalizacion, body) => instance.patch(`/hierarchy/nodes/${IDSubLocalizacion}/`, body);
export const getCompanySummary = (id) => instance.get(`/hierarchy/nodes/${id}/companysummary/`)

//data dashboard Resumen Localizacion
export const getSummaryLocation = (IDLocalizacion) => instance.get(`/hierarchy/nodes/${IDLocalizacion}/locationsummary/`)

export const updateNodesTree = (body) => instance.put(`hierarchy/nodes/tree/`,body)