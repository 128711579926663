import React, { useState } from 'react';


import { Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import SpeedIcon from '@material-ui/icons/Speed';


const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '&.MuiListItem-root.Mui-selected p': {
      color: theme.palette.common.white,
    },
  },
  nodeHeader: {
    display: 'flex',
    alignItems: 'center',
    '&.MuiListItemText-multiline': {
      marginTop: 4,
      marginBottom: 4,
    },
    '& p': {
      marginLeft: '5px',
    },
  },
}));

export default function Node({ node, selectedNode, handleSelectedNode, statusNodes }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const handleArrows = () => {
    setOpen(!open);
  };

  const nodeStatus = statusNodes?.find(n => n.node_id === node.id)

  return (
    <>
      <ListItem
        button
        selected={selectedNode ? selectedNode.id === node.id : false}
        className={classes.selected}
        onClick={() => handleSelectedNode(node)}
      >
       
        <ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={classes.nodeHeader} />

        <ListItemSecondaryAction>
          {node.medidor ? (    
            <Tooltip title={`${node.nombre} tiene un medidor asociado ${nodeStatus?.online ? 'online' : 'offline'}`}>
              <IconButton
                edge='end'
                aria-label='medidores asociados'
              >
                <SpeedIcon style={nodeStatus?.online ? { color: '#66bb6a' } : { color: '#ef5350' }} />
              </IconButton>
            </Tooltip>            
          ) : null}
          {node.hijos &&
            node.hijos.length > 0 &&
            (open ? (
              <IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
                <ExpandMore />
              </IconButton>
            ))}
        </ListItemSecondaryAction>
      </ListItem>

      {node.hijos ? (
        <Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
          <List dense component='div' disablePadding style={node.id === selectedNode.id ? { background: 'rgba(0,0,0,0.1)' } : {}}>
            {node.hijos.map(c => (
              <Node statusNodes={statusNodes} node={c} key={c.id} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} />
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}
