import { React } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Fragment } from 'react';
import { toFormattedNumber, toFormattedNumberNoUnit } from '../../helpers/common';
const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(2, 5, 0, 10),
		color: theme.palette.primary.main,
		width: 'calc(100% - 80px - 40px)',
	},
	leftContainer: {
		paddingRight: theme.spacing(3),
	},
	rightContainer: {
		borderLeft: `1px solid ${theme.palette.primary.main}`,
		paddingLeft: theme.spacing(3),
		height: 'calc(100vh - 48px - 64px)',
	},
	form: {
		height: '100%',
		'& .MuiInputBase-root': {
			color: '#666666',
		},
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	btn: {
		margin: theme.spacing(3, 1),
	},
	btnSaveEditCancel: {
		margin: theme.spacing(3, 1),
		'& .MuiButton-label': {
			textTransform: 'none',
			fontWeight: 700,
		},
		'& .MuiButton-root': {
			color: '#666666',
		},
	},
	tableContainer: {
		height: "100%",
		border: "solid 1px #d9dcde",
	},
	headerRow: {
		background: theme.palette.primary.light,
		color: 'white',
		paddingTop: theme.spacing(1),
	},
	subHeaderRow: {
		background: theme.palette.primary.light,
		color: 'white',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	tableTitle: {
		fontWeight: "400",
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	spacer: {
		borderRight: '1px solid white',
	},
	spacerLeft: {
		borderLeft: '1px solid white',
	},
	tableSubTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	tableLeftTitle: {
		padding: theme.spacing(0.5),
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		background: theme.palette.grey[300],
		color: theme.palette.primary.light,
		paddingLeft: theme.spacing(3),
	},
	cell: {
		fontSize: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: "flex-end",
		color: theme.palette.primary.main,
	},
	borderBottom: {
		borderLeft: `1px solid ${theme.palette.grey[400]}`,
		borderBottom: `1px solid ${theme.palette.grey[400]}`,

	},
	borderBottomBold: {
		borderLeft: `1px solid ${theme.palette.grey[400]}`,
		borderBottom: `1px solid ${theme.palette.grey[400]}`,
		fontWeight: 600,
	},
	borderBottomFooter: {
		borderLeft: `1px solid ${theme.palette.grey[400]}`,
		borderBottom: `1px solid ${theme.palette.grey[400]}`,
		color: theme.palette.primary.dark,
	},
	transparentBoldAux: {
		borderBottom: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.dark,
		fontWeight: 600,
	},
	outstandingText: {
		color: theme.palette.primary.dark,
		fontWeight: 600,
	},
	transparent: {
		color: theme.palette.grey[400],
		borderLeft: `1px solid ${theme.palette.grey[400]}`,
		borderBottom: `1px solid ${theme.palette.grey[400]}`
	},
	transparentBold: {
		color: theme.palette.secondary.dark,
		fontWeight: 600,
		borderLeft: `1px solid ${theme.palette.grey[400]}`,
		borderBottom: `1px solid ${theme.palette.grey[400]}`
	},
	input: {
		padding: 0,
		'& .MuiInput-underline:before': {
			borderBottom: "1px dashed"
		},
		'& .MuiInputBase-input': {
			padding: 0,
			textAlign: 'right',
			fontSize: 15,
			// Saco las flechitas al input type number
			'&::-webkit-inner-spin-button': {
				webkitAppearance: "none",
				mozAppearance: "none",
				appearance: " none",
				margin: 0
			},
		},
		'& .MuiTypography-colorTextSecondary': {
			fontSize: 15,
			color: theme.palette.grey[400],
		},
	},
	inputFijo: {
		padding: 0,
		fontSize: 15,
		'& .MuiInput-underline:before': {
			borderBottom: "1px dashed"
		},
		'& .MuiInputBase-input': {
			fontWeight: 'bold',
			color: '#7a1e31',
			padding: 0,
			textAlign: 'right',
			fontSize: 15,
			// Saco las flechitas al input type number
			'&::-webkit-inner-spin-button': {
				webkitAppearance: "none",
				mozAppearance: "none",
				appearance: " none",
				margin: 0
			},
		},
	},
	inputBold: {
		padding: 0,
		fontSize: 15,
		'& .MuiInput-underline:before': {
			borderBottom: "1px dashed"
		},
		'& .MuiInputBase-input': {
			padding: 0,
			textAlign: 'right',
			fontSize: 15,
			// Saco las flechitas al input type number
			'&::-webkit-inner-spin-button': {
				webkitAppearance: "none",
				mozAppearance: "none",
				appearance: " none",
				margin: 0
			},
		},
		'& .MuiTypography-colorTextSecondary': {
			fontSize: 15,
			color: theme.palette.secondary,
			fontWeight: 600
		},
	},
	lineCenterCell: {
		height: "31px",
		position: "relative",
		left: "50%"
	},
	lineCenterCellBlack: {
		height: "31px",
		position: "relative",
		left: "50%"
	},
	wordCut: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	numberCell: {
		textAlign: "end",
		paddingLeft: "5px"
	},
	unidadCell: {
		textAlign: "start",
		marginLeft: "10px"
	},
	highlighted: {
		backgroundColor: "#00678026",
	},
}));

/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
Number.prototype.round = function (places) {
	return +(Math.round(this + 'e+' + places) + 'e-' + places);
};
export default function CostCompareTable({
	tableData,
	handleBillData,
	handlePowerEnergy,
	billData,
	handleDeletePanel,
	handleCancelBill,
	handleSaveBill,
	conciliation

}) {
	const classes = useStyles();
	// Función encargada de colocar mayúsculas en la primera letra de cada palabra
	const capitalizeFirstLetter = (str) => {
		if (str) {
			const arr = str?.toLowerCase().split(' ');
			for (let i = 0; i < arr.length; i++) {
				arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
			}
			return arr?.join(' ');
		}
		else {
			return ""
		}
	};

	// const handleTextFieldFocus = (event) => {
	// 	if (!conciliation) {
	// 		event?.target?.select(); // Seleccionar el contenido del TextField enfocado
	// 	}
	// };

	return (
		<>
			<form
				onSubmit={handleSaveBill}
				className={classes.form}
			// onFocus={handleTextFieldFocus
			>
				<Grid container className={classes.tableContainer}>
					<Grid container className={classes.headerRow}>
						<Grid item xs={2}></Grid>
						<Grid item xs={6} className={clsx(classes.tableTitle, classes.spacer, classes.spacerLeft)}>
							FACTURA RECONSTRUIDA
						</Grid>
						<Grid item xs={4} className={classes.tableTitle}>
							FACTURA REAL
						</Grid>
					</Grid>

					<Grid container className={classes.subHeaderRow}>
						<Grid item xs={2} className={classes.tableTitle}></Grid>
						<Grid item xs={2} className={clsx(classes.tableSubTitle, classes.spacer)}>
							Valor
						</Grid>

						<Grid item xs={2} className={clsx(classes.tableSubTitle, classes.spacer)}>
							Tarifa
						</Grid>
						<Grid item xs={2} className={clsx(classes.tableSubTitle, classes.spacer)}>
							Subtotal
						</Grid>

						<Grid item xs={2} className={clsx(classes.tableSubTitle, classes.spacer)}>
							Valor
						</Grid>
						<Grid item xs={2} className={classes.tableSubTitle}>
							Subtotal
						</Grid>

					</Grid>

					{/*FIJO */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText)}>
						{capitalizeFirstLetter(tableData[0]?.title)}
					</Grid>
					{/* factura reconstruida */}
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						<Grid style={{ justifyContent: "center" }} item xs={8} className={clsx(classes.numberCell)} >
							--
							{/* {tableData[0].data[0] ? parseFloat(tableData[0].data[0]) : ''} */}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >

						</Grid>
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{toFormattedNumber(tableData[0]['data'][1])}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* factura real */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						--
						{/* {tableData[0]['data'][2] ? '$/mes' + parseFloat(tableData[0]['data'][2]) : --} */}
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							<TextField
								// placeholder='fijo'
								disabled={conciliation}
								fullWidth
								name='fixed_charge'
								type="number"
								value={billData.factura_papel?.fixed_charge ? billData.factura_papel?.fixed_charge : ''}
								onChange={conciliation ? () => { } : e => handleBillData(e)}
								color='primary'
								className={classes.inputFijo}
							/>
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/*ENERGIA */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText)}>
						{capitalizeFirstLetter(tableData[1].title)}
					</Grid>

					{/* factura reconstruida */}
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{toFormattedNumber(tableData[1]['data'][0])}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							kWh
						</Grid>
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{toFormattedNumber(tableData[1]['data'][1])}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* factura real */}
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.energy_use.total ? toFormattedNumber(billData.factura_papel.energy_use.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							kWh
						</Grid>
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.energy_cost.total ? toFormattedNumber(billData.factura_papel.energy_cost.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* franjas */}
					{tableData[1].nested
						? tableData[1].nested[0].data[0].map((nest, idx) => (
							<Fragment key={idx}>
								<Grid item xs={2} className={classes.tableLeftTitle}>
									{capitalizeFirstLetter(nest.name)}
								</Grid>
								{/* factura reconstruida */}
								<Grid item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										{tableData[1].nested[0].data[0][idx].value ? toFormattedNumber(tableData[1].nested[0].data[0][idx].value) : ''}
									</Grid>
									<Grid item xs={4} className={clsx(classes.unidadCell)} >
										kWh
									</Grid>
								</Grid>
								<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
									{/* tarifa */}
									{`${tableData[1].nested[0].data[1][idx].rate_value} $/kwh`}
								</Grid>
								<Grid item xs={2} className={clsx(classes.cell, classes.borderBottom)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										{tableData[1].nested[0].data[1][idx].value ? toFormattedNumber(tableData[1].nested[0].data[1][idx].value) : ''}
									</Grid>
									<Grid item xs={4} className={clsx(classes.unidadCell)} >
										$
									</Grid>
								</Grid>
								{/* factura real */}
								<Grid item xs={2} className={clsx(classes.cell, classes.transparent)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										<TextField
											disabled={conciliation}
											fullWidth
											// type="number"
											// placeholder='Energia activa'
											name='energy_use'
											value={billData.factura_papel.energy_use.slots[idx].value ? toFormattedNumberNoUnit(billData.factura_papel.energy_use.slots[idx].value) : ''}
											onChange={conciliation ? () => { } : e => handlePowerEnergy(e, nest, idx)}
											color='primary'
											className={classes.inputBold}
										/>
									</Grid>
									<Grid item xs={4} style={{ color: "#757575", textAlign: "start", marginLeft: "10px" }} >
										kWh
									</Grid>
								</Grid>
								<Grid item xs={2} className={clsx(classes.cell, classes.transparent)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										<TextField
											disabled={conciliation}
											fullWidth
											// type="number"
											// placeholder='Costo energia activa'
											name='energy_cost'
											value={billData?.factura_papel?.energy_cost?.slots[idx]?.value ? toFormattedNumberNoUnit(billData?.factura_papel?.energy_cost?.slots[idx]?.value) : ''}
											onChange={conciliation ? () => { } : e => handlePowerEnergy(e, nest, idx)}
											color='primary'
											className={classes.input} />
									</Grid>
									<Grid item xs={4} style={{ color: "#757575", textAlign: "start", marginLeft: "10px" }} >
										$
									</Grid>
								</Grid>

							</Fragment>
						))
						:
						''}

					{/*POTENCIA CONTRATADA */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText)}>
						{capitalizeFirstLetter(tableData[2].title)}
					</Grid>
					{/* factura reconstruida */}
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{tableData[2].nested[0].data[0][0] ? toFormattedNumber(tableData[2].nested[0].data[0][0].value) : ''}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							kW
						</Grid>
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{tableData[2]['data'][1] ? toFormattedNumber(tableData[2]['data'][1]) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* factura real */}
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.hired_demand.total ? toFormattedNumber(billData.factura_papel.hired_demand.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							kW
						</Grid>
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.hired_demand_cost.total ? toFormattedNumber(billData.factura_papel.hired_demand_cost.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* franjas */}
					{tableData[2].nested
						? tableData[2].nested[0].data[0].map((nest, idx) => (
							<Fragment key={idx}>
								<Grid item xs={2} className={classes.tableLeftTitle}>
									{capitalizeFirstLetter(nest.name)}
								</Grid>
								{/* factura reconstruida*/}
								<Grid item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										{tableData[2].nested[0].data[0][idx] ? toFormattedNumber(tableData[2].nested[0].data[0][idx].value) : ''}
									</Grid>
									<Grid item xs={4} className={clsx(classes.unidadCell)} >
										kW
									</Grid>
								</Grid>
								<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
									{/* tarifa */}
									{`${tableData[2].nested[0].data[1][idx].rate_value} $/kwh`}
								</Grid>
								<Grid item xs={2} className={clsx(classes.cell, classes.borderBottom)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										{tableData[2].nested[0].data[1][idx] ? toFormattedNumber(tableData[2].nested[0].data[1][idx].value) : ''}
									</Grid>
									<Grid item xs={4} className={clsx(classes.unidadCell)} >
										$
									</Grid>
								</Grid>
								{/* factura real */}
								<Grid item xs={2} className={clsx(classes.cell, classes.transparent)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										<TextField
											disabled={conciliation}
											fullWidth
											// type="number"
											// placeholder='Potencia contratada'
											name='hired_demand'
											value={billData?.factura_papel?.hired_demand?.slots[idx]?.value ? toFormattedNumberNoUnit(billData.factura_papel.hired_demand.slots[idx].value) : ''}
											onChange={conciliation ? () => { } : e => handlePowerEnergy(e, nest, idx)}
											color='primary'
											className={classes.input}
										/>
									</Grid>
									<Grid item xs={4} style={{ color: "#757575", textAlign: "start", marginLeft: "10px" }} >
										kW
									</Grid>
								</Grid>
								<Grid item xs={2} className={clsx(classes.cell, classes.transparent)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										<TextField
											disabled={conciliation}
											fullWidth
											// type="number"
											// placeholder='Costo potencia contratada'
											name='hired_demand_cost'
											value={billData?.factura_papel?.hired_demand_cost?.slots[idx]?.value ? toFormattedNumberNoUnit(billData.factura_papel.hired_demand_cost.slots[idx].value) : ''}
											onChange={conciliation ? () => { } : e => handlePowerEnergy(e, nest, idx)}
											color='primary'
											className={classes.input}
										/>
									</Grid>
									<Grid item xs={4} style={{ color: "#757575", textAlign: "start", marginLeft: "10px" }} >
										$
									</Grid>
								</Grid>
							</Fragment>
						))
						: ''}

					{/*POTENCIA ADQUIRIDA */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText)}>
						{capitalizeFirstLetter(tableData[3].title)}
					</Grid>
					{/* factura reconstruida */}
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{tableData[3].nested[0].data[0][0] ? toFormattedNumber(tableData[3].nested[0].data[0][0].value) : ""}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							kW
						</Grid>
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{tableData[3]['data'][1] ? toFormattedNumber(tableData[3]['data'][1]) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* factura real */}
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.peak_demand.total ? toFormattedNumber(billData.factura_papel.peak_demand.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							kW
						</Grid>
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.peak_demand_cost.total ? toFormattedNumber(billData.factura_papel.peak_demand_cost.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* franjas */}
					{tableData[3].nested
						? tableData[3].nested[0].data[0].map((nest, idx) => (
							<Fragment key={idx}>
								<Grid item xs={2} className={classes.tableLeftTitle}>
									{capitalizeFirstLetter(nest.name)}
								</Grid>
								{/* factura reconstruida */}
								<Grid item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										{tableData[3].nested[0].data[0][idx] ? toFormattedNumber(tableData[3].nested[0].data[0][idx].value) : 0}
									</Grid>
									<Grid item xs={4} className={clsx(classes.unidadCell)} >
										kW
									</Grid>
								</Grid>
								<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
									{/* tarifa */}
									{`${tableData[3].nested[0].data[1][idx].rate_value} $/kwh`}
								</Grid>
								<Grid item xs={2} className={clsx(classes.cell, classes.borderBottom)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										{tableData[3].nested[0].data[1][idx] ? toFormattedNumber(tableData[3].nested[0].data[1][idx].value) : 0}
									</Grid>
									<Grid item xs={4} className={clsx(classes.unidadCell)} >
										$
									</Grid>
								</Grid>

								{/* factura real */}
								<Grid item xs={2} className={clsx(classes.cell, classes.transparent)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										<TextField
											disabled={conciliation}
											fullWidth
											// type="number"
											// placeholder='Potencia adquirida'
											name='peak_demand'
											value={billData?.factura_papel?.peak_demand?.slots[idx]?.value ? toFormattedNumberNoUnit(billData.factura_papel.peak_demand.slots[idx].value) : ''}
											onChange={conciliation ? () => { } : e => handlePowerEnergy(e, nest, idx)}
											color='primary'
											className={classes.input}
										/>
									</Grid>
									<Grid item xs={4} style={{ color: "#757575", textAlign: "start", marginLeft: "10px" }} >
										kW
									</Grid>
								</Grid>
								<Grid item xs={2} className={clsx(classes.cell, classes.transparent)}>
									<Grid item xs={8} className={clsx(classes.numberCell)} >
										<TextField
											disabled={conciliation}
											fullWidth
											// type="number"
											// placeholder='Costo potencia adquirida'
											name='peak_demand_cost'
											value={billData?.factura_papel?.peak_demand_cost?.slots[idx]?.value ? toFormattedNumberNoUnit(billData.factura_papel.peak_demand_cost.slots[idx].value) : ''}
											onChange={conciliation ? () => { } : e => handlePowerEnergy(e, nest, idx)}
											color='primary'
											className={classes.input}
										/>
									</Grid>
									<Grid item xs={4} style={{ color: "#757575", textAlign: "start", marginLeft: "10px" }} >
										$
									</Grid>
								</Grid>
							</Fragment>
						))
						:
						''}

					{/* Sub Total */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText, classes.highlighted)}>
						Sub total
					</Grid>
					{/* factura reconstruida */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderLeft, classes.highlighted)}>
						--
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight, classes.highlighted)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.highlighted)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura.sub_total ? toFormattedNumber(billData.factura.sub_total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* factura real */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.transparent, classes.highlighted)}>
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold, classes.highlighted)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.sub_total ? toFormattedNumber(billData.factura_papel.sub_total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* Impuestos */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText)}>
						Impuestos
					</Grid>
					{/* factura reconstruida */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
						--
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura.taxes ? toFormattedNumber(billData.factura.taxes) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* factura real */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.transparent)}>
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.taxes ? toFormattedNumber(billData.factura_papel.taxes) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* Penalizaciones */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText)}>
						Penalizaciones
					</Grid>
					{/* factura reconstruida */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
						--
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottom, classes.borderRight)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura.penalty ? toFormattedNumber(billData.factura.penalty) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* factura real */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.transparent)}>
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.penalty ? toFormattedNumber(billData.factura_papel.penalty) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>

					{/* TOTAL */}
					<Grid item xs={2} className={clsx(classes.tableLeftTitle, classes.outstandingText, classes.highlighted)}>
						Total
					</Grid>
					{/* factura reconstruida */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomFooter, classes.borderRight, classes.highlighted)}>
						--
					</Grid>
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.borderRight, classes.highlighted)}>
						{/* tarifa */}
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.borderBottomBold, classes.highlighted)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura.total ? toFormattedNumber(billData.factura.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
					{/* factura real */}
					<Grid style={{ justifyContent: "center" }} item xs={2} className={clsx(classes.cell, classes.transparent, classes.highlighted)}>
						--
					</Grid>
					<Grid item xs={2} className={clsx(classes.cell, classes.transparentBold, classes.highlighted)}>
						<Grid item xs={8} className={clsx(classes.numberCell)} >
							{billData.factura_papel.total ? toFormattedNumber(billData.factura_papel.total) : 0}
						</Grid>
						<Grid item xs={4} className={clsx(classes.unidadCell)} >
							$
						</Grid>
					</Grid>
				</Grid>

				{!conciliation &&
					<Box display='flex' justifyContent='center'>
						<Button type='submit' color='primary' variant='contained' className={classes.btn}>
							Guardar
						</Button>

						<Button variant='contained' onClick={handleCancelBill} className={classes.btnSaveEditCancel}>
							CANCELAR
						</Button>

						<Button color='secondary' variant='contained' onClick={handleDeletePanel} className={classes.btnSaveEditCancel}>
							ELIMINAR
						</Button>
					</Box>
				}
			</form>
		</>
	);
}


