import { useEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Box, makeStyles } from '@material-ui/core';

import { format } from 'date-fns';
import {
  COLORS,
  FIELDS_MAP,
  calculateMinMax,
  getChartUnit,
  getProportionalColor,
} from './helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    // margin: theme.spacing(2),
    '@media (max-width: 700px)': {
      margin: theme.spacing(0),
    },
  },
}));

const WeekChart = ({ data, filterOptions }) => {
  const classes = useStyles();
  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    const chart = am4core.create('heatmap-week-chart', am4charts.XYChart);

    chart.logo.disabled = true;
    chart.fontSize = 11;

    // Establecer notación ingeniería con k como mínima unidad.
    chart.numberFormatter.numberFormat = `#.## !a${getChartUnit(
      filterOptions.variable
    )}`;

    const auxData = data[0]?.data[FIELDS_MAP[filterOptions.variable]];

    const latestTimestamp = new Date(auxData[auxData.length - 1].timestamp);

    // Como quiero empezar siempre en domingo y terminar en sábado.
    // Creo un array para almacenar las horas faltantes hasta el sábado a las 23.
    const missingTimestamps = [];


    while (auxData.length + missingTimestamps.length < 24 * 7) {
      latestTimestamp.setHours(latestTimestamp.getHours() + 1);
      missingTimestamps.push(new Date(latestTimestamp));
    }

    // Inserto los timestamps faltantes en el array
    missingTimestamps.forEach(timestamp => {
      const missingObj = {
        valor: 0,
        timestamp,
        type: 'FUTURE',
      };
      auxData.push(missingObj);
    });

    auxData.forEach(item => {
      const date = new Date(item.timestamp);
      const day = format(date, 'dd/MM/yyyy');
      const hour = format(date, 'HH');
      // Pasar de kW a W
      item.valor = item.valor * 1000;
      // Agregar propiedades
      item.hour = hour;
      item.day = day;
    });

    const justValues = auxData.map(obj => obj.valor);

    const { minimum, maximum } = calculateMinMax(justValues);
    // console.log('Minimum:', minimum);
    // console.log('Maximum:', maximum);

    chart.maskBullets = false;
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.renderer.inversed = true;
    yAxis.dataFields.category = 'day';
    xAxis.dataFields.category = 'hour';
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 20;
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.minGridDistance = 30;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = 'hour';
    series.dataFields.categoryY = 'day';
    series.dataFields.value = 'valor';
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 2000;

    series.min = -maximum;
    series.max = maximum;
    series.strictMinMax = true;

    const columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 0.8;
    columnTemplate.stroke = am4core.color('#ffffff');
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);

    columnTemplate.tooltipText = `{valor} - {day} - {hour}hs`;

    // Set up a gradient color scale
    series.heatRules.push({
      property: 'fill',
      target: series.columns.template,
      min: am4core.color(COLORS.MIN_POSITIVE_VALUE),
      max: am4core.color(COLORS.MAX_POSITIVE_VALUE),
      minValue: minimum,
      maxValue: maximum,
      // logarithmic: true,
    });

    // Contenedor para almacenar los heatlegends
    const heatLegendsContainer = chart.chartContainer.createChild(
      am4core.Container
    );
    // Si no tengo generación quiero un layout vertical sino horizontal con legend2 legend1
    heatLegendsContainer.layout = minimum >= 0 ? 'vertical' : 'horizontal';
    heatLegendsContainer.width = am4core.percent(100);
    heatLegendsContainer.height = 50;
    heatLegendsContainer.x = am4core.percent(0);
    heatLegendsContainer.y = am4core.percent(0);

    // heat legend para los valores negativos
    const heatLegend2 = heatLegendsContainer.createChild(am4charts.HeatLegend);
    heatLegend2.width = am4core.percent(50);
    heatLegend2.series = series;
    heatLegend2.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend2.valueAxis.renderer.minGridDistance = 60;
    // Si no tengo generación quiero ocultarla y que la otra ocupe el 100%.
    heatLegend2.disabled = minimum >= 0 ? true : false;

    heatLegend2.minColor = am4core.color(COLORS.MIN_NEGATIVE_VALUE);
    heatLegend2.maxColor = am4core.color(COLORS.MAX_NEGATIVE_VALUE);
    heatLegend2.minValue = minimum;
    heatLegend2.maxValue = 0;

    // heat legend para los valores positivos
    const heatLegend = heatLegendsContainer.createChild(am4charts.HeatLegend);
    // Si no tengo generación quiero que ocupe todo el ancho, sino la mitad.
    heatLegend.width = minimum >= 0 ? am4core.percent(100) : am4core.percent(50);
    heatLegend.series = series;
    heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend.valueAxis.renderer.minGridDistance = 60;

    heatLegend.minColor = am4core.color(COLORS.MIN_POSITIVE_VALUE);
    heatLegend.maxColor = am4core.color(COLORS.MAX_POSITIVE_VALUE);
    heatLegend.minValue = 0;
    heatLegend.maxValue = maximum;

    // heat legend behavior
    series.columns.template.events.on('over', event => {
      handleHover(event.target);
    });
    series.columns.template.events.on('hit', event => {
      handleHover(event.target);
    });

    function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value);
        heatLegend2.valueAxis.showTooltipAt(column.dataItem.value);
      } else {
        heatLegend.valueAxis.hideTooltip();
        heatLegend2.valueAxis.hideTooltip();
      }
    }

    // En caso de generación de energía (valor <0) pintar del verde que le corresponda
    series.columns.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem && target.dataItem?.values?.value?.value < 0) {
        // Si es menor que el mínimo retorno el color del mínimo
        if (target.dataItem?.values?.value?.value < minimum) {
          return am4core.color(COLORS.MIN_NEGATIVE_VALUE);
        }

        const color = getProportionalColor(
          target.dataItem?.values?.value?.value,
          minimum,
          0,
          COLORS.MIN_NEGATIVE_VALUE,
          COLORS.MAX_NEGATIVE_VALUE
        );
        return am4core.color(color);
      }
      return fill;
    });

    // Si el valor es 0 (ya sea porque no hubo medición o por ser type FUTURE)
    series.columns.template.adapter.add('tooltipText', function (text, target) {
      const data = target.tooltipDataItem.dataContext;
      if (data.valor === 0) {
        return `Sin datos - ${data.day} - ${data.hour}hs`;
      }
      return text;
    });

    // Cambio el color de aquellas horas con valor 0
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Si el valor es 0
      if (target.dataItem.dataContext.valor === 0) {
        return 'rgba(0, 0, 0, 0.01)';
      }
      return fill;
    });

    chart.data = auxData;

    return () => {
      // console.log('Dispose');
      chart.dispose();
    };
  }, [data, filterOptions]);

  return (
    <Box className={classes.root}>
      <div id="heatmap-week-chart" style={{ height: '450px' }} />
    </Box>
  );
};

export default WeekChart;
