import { makeStyles, } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
    Box, Button, Dialog, IconButton, CircularProgress
} from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { checkReadNotificationRead } from '../../services/notifications';
import CancelIcon from '@mui/icons-material/Cancel';
//Servicios
import { getNotificationsContent, checkReadNotificationAll } from '../../services/notifications'

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden'
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};
const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        '@media (max-width: 900px)': {
            minWidth: 0,
        },
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiList-root.MuiList-dense': {
            // marginRight: '10px',
        },
    },
    notification: {
        borderBottom: `solid 1px ${'#47a6db'}`,
        color: 'black',
        padding: theme.spacing(2, 1, 2, 1),
    },
    title: {
        padding: theme.spacing(1, 0, 1, 0),
        color: '#5d5959ed',
        fontSize: 15,
        marginRight: 5,
        '@media (max-width: 900px)': {
            fontSize: 12,
        }
    },
    body: {
        fontSize: 14,
        color: '#5d5959ed',
        marginLeft: 13,
        cursor: 'pointer',
        padding: '0 2px 0 0',
        '@media (max-width: 900px)': {
            fontSize: 12,
        }
    },
    circleList: {
        width: 5,
        height: 5,
        borderRadius: 300,
        marginRight: 10
    },
    date: {
        fontSize: 13,
        color: '#5c5959',
        margin: theme.spacing(4, 0, 0, 1),
    },
    containerBtn: {
        margin: theme.spacing(1.5, 1, 0, 0),
    },
    btn: {
        '& .MuiButton-label': {
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
            height: '26px'
        },
    },
    dialog: {
        '& .MuiDialog-container': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            margin: '40px 140px 0 0',
            '@media (max-width: 1100px)': {
                margin: '0px 0px 0 0',
            }

        },
        '& .MuiDialog-paper': {
            margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
            '@media (max-width: 900px)': {
                maxHeight: '100vh'
            }
        },
        '& .MuiPaper-root': {
            width: '100%',
        },

    },
    iconCLoseButton: {
        marginLeft: theme.spacing(2),
        height: "40px"
    }
}));

const CardNotification = ({ openModal, handleClose, handleCloseNotificationContent, setNumberNotification }) => {
    const classes = useStyles()
    const [notificationArray, setNotificationArray] = useState([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (openModal === true) {
            const getNotificationComponent = async () => {
                let res = await getNotificationsContent(1)
                setNotificationArray(res?.data?.results)
                setCount(calcCountPage(res?.data?.count));
                setLoading(false)
            }
            getNotificationComponent()
            // eslint-disable-next-line 
        }
        return (() => {
            setLoading(true)
            setPage(1)
        })
    }, [openModal])

    //Calcula el estado count q es el encagado de designar la cantidad de paginas de 10 item. 
    const calcCountPage = (count) => {
        const itemsPerPage = 10;
        const numberOfPages = Math.ceil(count / itemsPerPage);
        return numberOfPages;
    }

    const handleButton = async (id, read, elem) => {
        handleCloseNotificationContent(elem)
        if (!read) {
            await checkReadNotificationRead(id)
            let editArry = notificationArray.map((elem) => {
                if (elem.id === id) {
                    elem.leido = true
                    return elem
                } else {
                    return elem
                }
            })
            setNotificationArray(editArry)
        }
    }

    const handleChangePaginator = async (event, value) => {
        try {
            setLoading(true)
            setPage(value)
            let res = await getNotificationsContent(value)
            setNotificationArray(res?.data?.results)
            setLoading(false)
        } 
        catch (error) {
            setLoading(false)
        }
    }

    //Le da formato a la fecha.
    const formatDate = (date) => {
        const originalDate = date;
        const formattedDate = new Date(originalDate);
        const options = { day: 'numeric', month: 'short' };
        return formattedDate.toLocaleDateString('es-ES', options);
    }


    const checkReadNotificationAllAux = () => {
        checkReadNotificationAll()
        let editArry = notificationArray.map((elem) => {
            elem.leido = true
            return elem
        })
        setNotificationArray(editArry)
        setNumberNotification(0)
    }

    return (
        <Dialog
            className={classes.dialog}
            open={openModal}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'
        >
            <Box style={{ borderBottom: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }}
                display={'flex'}
                justifyContent={'space-between'}
                pt={1}
                pb={1}
            >
                <IconButton
                    className={classes.iconCLoseButton}
                    onClick={handleClose}
                >
                    <CancelIcon />
                </IconButton>

                <Button
                    style={{ color: '#47a6db', marginRight: '10px', textTransform: 'none', fontSize: '16px' }}
                    onClick={checkReadNotificationAllAux}
                >
                    Marcar todo como leído
                </Button>
            </Box>




            <Box className={classes.container}
                style={{ borderTop: `solid 1px ${'#47a6db'}`, boxShadow: ' -2px 2px 10px rgba(128, 128, 128, 0.5)' }}
                sx={{ bgcolor: 'white' }}
            >
                {loading ?
                    <Box height={'100%'} width={'100%'} display="flex" justifyContent="center" alignItems={'center'}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        {
                            notificationArray?.length === 0 &&
                            <Box p={1} display='flex' alignItems='center'>
                                <Box style={{ backgroundColor: '#2586bc' }} className={classes.circleList}>
                                </Box>
                                <Box style={{ fontWeight: 'bold', color: '#5d5959ed', }}>
                                    Usted no posee notificaciones.
                                </Box>
                            </Box>
                        }

                        {notificationArray.map((elem) => {
                            return (
                                <Box style={elem.leido ? {} : { backgroundColor: '#f0f8ffc9' }} key={elem.id} className={classes.notification} >
                                    <Box display='flex' alignItems='center'>
                                        <Box style={elem.leido ? { backgroundColor: '#a79a9a' } : { backgroundColor: '#2586bc' }} className={classes.circleList}>
                                        </Box>
                                        <Box style={elem.leido ? { color: '#a79a9a' } : { fontWeight: 'bold', }} className={classes.title}>
                                            {elem?.titulo}
                                        </Box>
                                    </Box>
                                    <Box onClick={() => handleButton(elem.id, elem.leido)} style={elem.leido ? { color: '#a79a9a', } : { fontWeight: 'bold', }} className={classes.body}>
                                        {elem?.contenido}
                                    </Box>
                                    <Box display='flex' justifyContent='space-between'>
                                        <Box style={elem.leido ? { color: '#a79a9a' } : {}} className={classes.date}>
                                            {formatDate(elem?.timestamp)}
                                        </Box>
                                        <Box className={classes.containerBtn}>
                                            <Button
                                                style={{ color: '#47a6db', marginLeft: '10px', textTransform: 'none', fontSize: '16px' }}
                                                onClick={() => { handleButton(elem.id, elem.leido, elem) }}
                                            >
                                                Ver más
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })}
                    </>
                }
            </Box>

            {
                notificationArray?.length !== 0 &&
                <Box style={{ borderTop: `solid 1px ${'#47a6db'}`, backgroundColor: 'white' }} display={'flex'} justifyContent={'center'} pt={1} pb={1}>
                    <Stack >
                        <Pagination page={page} onChange={handleChangePaginator} count={count} />
                    </Stack>
                </Box>
            }

        </Dialog >
    )
}
export default CardNotification