import { useEffect, useState } from 'react';
//Material UI
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
//Componentes
import TopNav from '../../common/TopNav';
import Container from '../../Elements/Container';
import BubbleMap from '../ComponentsDashboards/BubbleMap';
import DashPie from '../ComponentsDashboards/DashPie';
import DashTable from '../ComponentsDashboards/DashTable';
import NodeData from '../ComponentsDashboards/NodeData';
import IndictorCard from '../ComponentsDashboards/IndicatorCard';
// Material Icons
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { Alert } from '@mui/material';
import { getCompanySummary } from '../../../services/hierarchy';
import EnergyMonth from '../ComponentsDashboards/EnergyMonth';
import EnergyTags from '../ComponentsDashboards/EnergyTags';
import GaugeFilter from './GaugeFilter';
import { getFormattedNumber, getUnit } from '../../../helpers/common';
import PowerDemand from '../ComponentsDashboards/PowerDemand';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
// Custom Hooks
import useDeviceDetect from '../../../hooks/useDeviceDetect';

let boxes = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #cbc1c157',
  boxShadow: '3px 3px 3px #cbc1c157',
}
const useStyles = makeStyles(theme => ({
  alertIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  alert: {
    marginLeft: '65px',
    '@media (max-width: 920px)': {
      marginLeft: 0,
    },
  },
  btn: {
    '& .MuiButton-label': {
      color: theme.palette.common.white,
      textTransform: 'none',
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  btnMobile: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
  buttonNav: {
    '@media (max-width: 920px)': {
      margin: theme.spacing(0, 0, 1, 1),
      paddingTop: theme.spacing(2),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  container: {
    position: 'relative',
    width: '100%',
    display: 'grid',
    alignContent: 'center',
    margin: theme.spacing(1),
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'repeat(4, 1fr)',
    // gridTemplateRows: "repeat(5, 1fr)",
    gridTemplateRows: '1fr minmax(400px, 1fr) minmax(400px, 1fr)',
    gridAutoRows: 'auto',
    gridTemplateAreas: `
      'map map map map'
      'table table pie pie'
      'bar bar pie2 pie2'
      'energyMonth projectedEnergy vbar vbar' 
      'powerDemandChart powerDemandChart powerDemandChart powerDemandChart'
      'table2 table2 table2 table2'
      `,
    '@media (max-width: 720px)': {
      gridTemplateRows: 'minmax(400px, 1fr)',
      gridTemplateColumns: '100%',
      gridAutoRows: 'min-content',
      gridTemplateAreas: `
      'map'
      'company'
      'table'
      'pie2'
      'pie'
      'energyMonth'
      'projectedEnergy'      
      'vbar'
      'bar'
      'powerDemandChart'
      'table2'
      `,
    },
  },
  mapContainer: {
    gridArea: 'map',

  },
  companyContainer: {
    gridArea: 'company',
    position: 'absolute',
    top: theme.spacing(9),
    left: theme.spacing(1),
    bottom: theme.spacing(1),
    '@media (max-width: 720px)': {
      position: 'static',
      height: '220px'
    },
  },
  tableContainer: {
    gridArea: 'table',
    alignSelf: 'stretch',
  },
  pieContainer: {
    gridArea: 'pie',
    alignSelf: 'stretch',
  },
  projectedEnergyContainer: {
    gridArea: 'projectedEnergy',
    alignSelf: 'stretch',
    padding: theme.spacing(1),
    ...boxes
  },
  energyMonthContainer: {
    gridArea: 'energyMonth',
    padding: theme.spacing(1),
    ...boxes
  },
  verticalBarContainer: {
    gridArea: 'vbar',
    ...boxes
  },
  pie2Container: {
    gridArea: 'pie2',
  },
  barContainer: {
    gridArea: 'bar',
  },
  powerDemandChartContainer: {
    gridArea: 'powerDemandChart',
    ...boxes
  },
  table2Container: {
    gridArea: 'table2',
  },
}));

const SummaryGeneral = () => {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect();
  const [filterPanel, setFilterPanel] = useState(true);
  const [companyData, setCompanyData] = useState({});
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    const fetchCompanySummary = async () => {
      try {
        setLoading(true);
        setShowErrorAlert(false);
        setErrorMessage('');
        const res = await getCompanySummary(company?.id);

        const updatedLocations = res.data.locations.map((location) => {
          const [lat, lng] = location.coordinates.split(",");
          return { ...location, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        const updatedCompanyData = { ...res.data, locations: updatedLocations };
        setCompanyData(updatedCompanyData);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        } else {
          setErrorMessage(`Ocurrió un error inesperado`);
        }
        setShowErrorAlert(true);
      }
    };
    if (!company.id) {
      return;
    }
    fetchCompanySummary();
  }, [company]);

  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };

  return (
    <>
      <TopNav titulo="Dashboards" subtitulo={`| Resumen general `}>
        <Box className={classes.buttonNav} display="flex" justifyContent="flex-end">
          <Button
            className={
              isMobile ? clsx(classes.btn, classes.btnMobile) : classes.btn
            }
            type="button"
            onClick={togglePanel}
            size="small"
            startIcon={<LabelImportantIcon />}
          >
            {isMobile
              ? '' : company.nombre || `Seleccionar Empresa`}
          </Button>
        </Box>
      </TopNav>
      {!company.nombre && !showErrorAlert && (
        <Box>
          <Alert className={classes.alert} severity="info">
            Bienvenido al dashboard Resumen General. En esta sección podrá
            consultar la información de sus empresas. Para empezar haga click en
            el botón
            <span className={classes.alertIcon}>
              <LabelImportantIcon />
            </span>
            , elija el dispositivo y haga click en Aceptar.
          </Alert>
        </Box>
      )}

      <Container>
        {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}

        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
        {companyData.company && (

          <Box className={classes.container}>


            {/* Mapa */}
            <Box className={classes.mapContainer}>
              <BubbleMap data={companyData.locations} />
            </Box>

            {/* Información de la empresa */}
            <Box className={classes.companyContainer}>
              <NodeData data={companyData} />
            </Box>
            <Box className={classes.tableContainer}>
              <DashTable
                title="Consumo de energía"
                subtitle={`este año ${format(new Date(), 'yyyy', { locale: es })}`}
                data={companyData.locations}
              />
            </Box>
            <Box className={classes.pieContainer}>
              <DashPie
                name="pie1"
                title="Consumo de energía"
                subtitle={`${format(new Date(), 'MMMM yyyy', { locale: es })}`}
                data={companyData?.locations}
                variable="energy_month"
              />
            </Box>

            <Box className={classes.energyMonthContainer}>
              <IndictorCard
                title="Energía consumida |"
                subTitle={`${format(new Date(), 'MMMM yyyy', { locale: es })}`}
                value={getFormattedNumber(companyData.energy_total_month)}
                unity={`${getUnit(companyData.energy_total_month)}Wh`}
                titleFooter={"Energía"}
                marginTitle={2}
              />
            </Box>

            {/* value={toFormattedNumber(
                  getFormattedNumber(companyData.energy_total_last_month) * companyData.energy_total_last_month
                )} */}
            <Box className={classes.projectedEnergyContainer}>
              <IndictorCard
                title="Consumo proyectado  |"
                subTitle={`${format(new Date(), 'MMMM yyyy', { locale: es })}`}
                value={getFormattedNumber(companyData.energy_month_projection)}
                unity={`${getUnit(companyData.energy_total_month)}Wh`}
                arrow={true}
                compareValue={getFormattedNumber(companyData.energy_total_last_month)}
                marginTitle={2}
              />
            </Box>

            <Box className={classes.verticalBarContainer}>
              <EnergyTags id={companyData.company_id} tags={companyData.tags} />
            </Box>
            <Box className={classes.pie2Container}>
              <DashPie
                name="pie2"
                title="Consumo de energía"
                subtitle={`este año ${format(new Date(), 'yyyy', { locale: es })}`}
                data={companyData?.locations}
                variable="energy_total_year"
              />
            </Box>
            <Box className={classes.barContainer}>
              <EnergyMonth id={companyData.company_id} />
            </Box>
            <Box className={classes.powerDemandChartContainer}>
              <PowerDemand
                title="Demanda de potencia máxima"
                subtitle={`${format(new Date(), 'MMMM yyyy', { locale: es })}`}
                data={companyData.locations}
              />
            </Box>
            <Box className={classes.table2Container}>
              <DashTable
                title="Demanda de potencia máxima por localización"
                subtitle={`${format(new Date(), 'MMMM yyyy', { locale: es })}`}
                data={companyData.locations}
              />
            </Box>
          </Box>
        )}
      </Container>

      <GaugeFilter
        isOpen={filterPanel}
        setIsOpen={setFilterPanel}
        setDevice={setCompany}
        device={company}
      />
    </>
  );
};

export default SummaryGeneral;
