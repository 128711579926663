import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  countdownContainer: ({ size }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: size,
    height: size,
  }),
  progressRing: {
    transform: 'rotate(-90deg)',
  },
  progressRingCircle: {
    transition: 'stroke-dashoffset 1s linear',
  },
  timerText: ({ size }) => ({
    position: 'absolute',
    fontSize: size / 4,
    color: '#000',
  }),
}));

const CountdownTimer = ({ initialTime, size, onComplete }) => {
  const classes = useStyles({ size });
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const radius = size / 2 - 10;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = (1 - timeLeft / initialTime) * circumference;

  useEffect(() => {
    if (timeLeft === 0) {
      if (onComplete) {
        onComplete();
      }
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onComplete]);

  return (
    <Box className={classes.countdownContainer}>
      <svg className={classes.progressRing} width={size} height={size}>
        <circle
          className={classes.progressRingCircle}
          stroke="rgb(54, 143, 193)"
          strokeWidth="4"
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{ strokeDasharray: circumference, strokeDashoffset }}
        />
      </svg>
      <Box className={classes.timerText}>{timeLeft}s</Box>
    </Box>
  );
};

export default CountdownTimer;
