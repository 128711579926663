import { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { Alert } from '@mui/material';

// Componentes
import VerticalBar from './VerticalBar';

// Services
import { getHistoryV2 } from '../../../services/meters';

// Date-fns
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

function EnergyTags({ id, tags }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [data, setData] = useState([]);

  const filterData = data => {
    const yearStr = new Date().getFullYear().toString();
    return data?.map(tag => {
      let kwh = null;
      for (let i = 0; i < tag.data.length; i++) {
        if (tag.data[i].year === yearStr) {
          kwh = tag.data[i].kwh;
        }
      }
      return { tag: tag.tags[0].nombre, color: tag.tags[0].color, kwh };
    });
  };

  useEffect(() => {
    const fetchTags = async query => {
      try {
        setLoading(true);
        setData([])
        setShowErrorAlert(false);
        setErrorMessage('');
        const res = await getHistoryV2(query);
        const filteredData = filterData(res.data);

        setData(filteredData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        } else {
          setErrorMessage(`Ocurrió un error inesperado`);
        }
        setShowErrorAlert(true);
      }
    };
    // Retonar si no tenemos tags asociados a esa empresa
    if (!tags.length) {
      return;
    }
    const tagIds = tags.map(tag => tag.id);
    //  /meters/electric/energy/yearly?filter=1271+11&filter=1271+13&filter=1271+12&showPhase=false
    //  11&filter=1271+13&filter=1271+12&showPhase=false
    const queryTags = tagIds.join(`&filter=${id}+`);
    //  filter=1271+11&filter=1271+13&filter=1271+12&showPhase=false
    let url = `filter=${id}+${queryTags}`
    url += `&fields=kwh`
    url += `&period=yearly`;
    fetchTags(url);
  }, [id, tags]);

  return (
    <>
      {tags.length > 0 ?
        <VerticalBar
          name="vbar"
          title="Consumo de energía"
          subtitle={`este año ${format(new Date(), 'yyyy', { locale: es })}`}
          data={data}
        >
          {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}
          {loading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}

        </VerticalBar>
        :
        <Alert severity="error">{"La empresa no cuenta con etiquetas"}</Alert>

      }
    </>
  );
}

export default EnergyTags;
