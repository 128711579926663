export const GRANULARITY_OPTIONS = [
  { label: '1 Minuto', value: '1m' },
  { label: '5 Minutos', value: '5m' },
  { label: '15 Minutos', value: '15m' },
  { label: '1 Hora', value: '1h' },
];

export const getCatalogo = (VAR) => {
  let nameVarLocal = 'UI_ROLES'
  let VARS_CATALOGO = JSON.parse(localStorage.getItem(nameVarLocal))?.sections[VAR]
  return VARS_CATALOGO
};


//Formatea el numero seghun el base_unit de la variable.
export const formattingSeries = (seriesData, variable, VAR) => {
  if (seriesData === undefined) {
    return []
  }
  const VARS_CATALOGO = getCatalogo(VAR)
  let varFound = VARS_CATALOGO?.find(e => e.key === variable)
  let base_unit = varFound?.base_unit
  let validUnit = base_unit?.split('')[0] === 'k'

  let serieDataAux = [...seriesData]
  if (validUnit) {
    serieDataAux = seriesData?.map((element => {
      element.valor = element.valor * 1000
      return element
    }))
  }
  return serieDataAux
}