import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import clsx from 'clsx';
// Material UI
import {
	Box, Button, ButtonGroup, Chip,
	Dialog, DialogTitle, FormControlLabel, FormGroup,
	IconButton, List, Snackbar, Step, StepLabel, Stepper, Switch, Tooltip, makeStyles
} from '@material-ui/core';
//Material Icons
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
//Helpers
//Componentes 
import NodesContext from '../../../context/NodesContext';
import DatePickerCommon from '../../DatePicker/DatePickerCommon';
import MultiNode from '../../Instant/MultiNode';
import { Alert } from '@mui/material';
import { DATE_OPTIONS } from './helpers';
//Servicios
import { getStatusNodes } from '../../../services/meters';

let scrollsSettings = {
	overflow: "scroll",
	height: "50vh",
	overflowX: "hidden",
}
let webkitScrollbar = {
	background: "white",
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: "#beb1b18f",
	color: "red",
	width: "9px",
}
let webkitscrollbarTrack = {
	background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
	root: {
		// padding: theme.spacing(2, 4),
		'& .MuiGrid-root': {
			padding: theme.spacing(1),
		},
		marginBottom: "10px"
	},
	devicesLabel: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
	},
	devicesBtn: {
		margin: theme.spacing(3, 1),
	},
	leftSpacing: {
		marginLeft: theme.spacing(1),
	},
	hint: {
		backgroundColor: 'rgba(0, 0, 0, 0.38)',
		color: theme.palette.common.white,
		height: theme.spacing(3),
		width: theme.spacing(3),
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(1),
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	tick: {
		backgroundColor: theme.palette.success.light,
	},
	active: {
		backgroundColor: theme.palette.primary.main,
	},
	steppersFilter: {
		'@media (max-width: 920px)': {
			display: 'none',
		},
		'&.MuiStep-horizontal': {
			paddingLeft: '0',
			paddingRight: '0',
		},
	},
	addBtn: {
		margin: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: theme.spacing(3),
		width: theme.spacing(3),
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	filterChip: {
		margin: theme.spacing(0.5),
		"@media (max-width: 700px)": {
			width: "250px",
		}
	},
	dialogTitle: {
		"@media (max-width: 700px)": {
			textAlign: "center",
		}
	},
	dialogSubtitle: {
		marginLeft: theme.spacing(3),
		color: theme.palette.primary.main,
		'@media (max-width: 700px)': {
			display: 'none',
		},
	},
	selectedItemsContainer: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		marginTop: theme.spacing(1)
	},
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '15px',
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.main,
		marginLeft: "12px",
	},
	filterLabel: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
	},
	switchLabel: {
		fontSize: '0.75rem',
	},
	rowSpace: {
		marginBottom: theme.spacing(2),
	},
	btnSpacing: {
		margin: theme.spacing(2, 1),
	},
	containerNodes: {
		paddingRight: "8px",
		...scrollsSettings,
		'&::-webkit-scrollbar': {
			//son los estilos de las barras scroll en una variable.
			...webkitScrollbar
		},
		'&::-webkit-scrollbar-track': {
			//son los estilos de las barras scroll en una variable.
			...webkitscrollbarTrack
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.primary.main,
		},
		"@media (max-width: 1100px)": {
			width: "100%",
		},
	},
	wrapperDatepicker: {
		marginTop: "10px",
		position: "relative",
		width: "100%",
		left: "50%",
		transform: "translate(-50%)",
		textAlign: "center"
	},
	labelDate: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.main,
	},
	help: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
		padding: theme.spacing(0.5)
	}
}));

function Filter({ onSubmit, filterOptions, setFilterPanel }) {
	const classes = useStyles();
	const { nodes } = useContext(NodesContext);
	const [selectedNodes, setSelectedNodes] = useState([]);
	const [primaryList, setPrimaryList] = useState(filterOptions.dispositivos);
	const [showDevices, setShowDevices] = useState(false);
	const [filter, setFilter] = useState(filterOptions);
	const [notification, setNotification] = useState({
		open: false,
		message: '',
		severity: 'warning',
	});
	const { dateFrom, dateTo } = filter;
	const switchOptions = [
		{ id: 1, label: 'Alarmas', optionName: 'showAlarms' },
		{ id: 2, label: 'Cortes de conectividad', optionName: 'showConnectivityOutages' },
		// { id: 3, label: 'Notificaciones del sistema', optionName: 'showNotifications' },
		{ id: 4, label: 'Cortes de servicio eléctrico', optionName: 'showElectricOutages' },
	];
	const [activeStep, setActiveStep] = useState(0);
	const steps = [
		'Elija el/los dispositivos que desea graficar',
		'Elija los tipos de eventos que desea graficar',
		'Seleccione la fecha deseada',
		'Haga click en CONSULTAR',
	];
	const [statusNodes, setStatusNodes] = useState([]);

	//Verifica status de el medidor.
	useEffect(() => {
		const getStatusNodesAux = async () => {
			let res = await getStatusNodes()
			let arry = res.data
			setStatusNodes(arry)
		}
		getStatusNodesAux()
	}, [])
	useEffect(() => {
		setFilter(filterOptions);

		// Si ya tengo cargado un valor en el array de dispositivos corresponde al paso 3
		if (filterOptions.dispositivos?.length > 0) {
			setActiveStep(3)
		}

	}, [filterOptions]);

	// Funciones
	const handleFilterChange = e => {
		// Si el cambio fue en alguno de los checkboxes

		if (e.target.type === 'checkbox') {
			setFilter({ ...filter, [e.target.name]: JSON.parse(e.target.checked) });
		}
	};

	const handleDateChange = (option) => {
		const startDate = new Date();
		const endDate = new Date();

		// if (option === 'HOY') no quiero modificar el valor por defecto de startDate

		if (option === 'AYER') {
			startDate.setDate(startDate.getDate() - 1);
			endDate.setDate(endDate.getDate() - 1);
		}
		if (option === 'ESTA_SEMANA') {
			startDate.setDate(startDate.getDate() - 7);
		}
		if (option === 'ESTE_MES') {
			startDate.setMonth(startDate.getMonth() - 1);
		}

		setFilter({ ...filter, dateFrom: startDate, dateTo: endDate, quickDateOption: option });
	}

	const handleCancelFilterDialog = () => {
		setFilterPanel(false);
	};

	// Cuando hacemos click en un elemento del listado de nodos
	const handleSelectedNode = node => {
		setSelectedNodes(prevSelectedNodes => {
			const nodeIndex = prevSelectedNodes.findIndex(
				selectedNode => selectedNode.id === node.id
			);
			// Si el nodo está en la lista lo elimino
			if (nodeIndex >= 0) {
				return prevSelectedNodes.filter(
					selectedNode => selectedNode.id !== node.id
				);
			} else {
				// Si el nodo no está en la lista.

				const newSelectedNodes = [...prevSelectedNodes, node];
				return newSelectedNodes;
			}
		});
	};

	// Cuando presiono Cancelar o click outside en el listado de nodos.
	const handleCloseDevices = () => {
		setShowDevices(false);
	};

	const handleDeleteNodeSelection = id => {
		setSelectedNodes(prevState => {
			const newNodes = prevState.filter(node => node.id !== id);
			return newNodes;
		});
	};


	// Submit en Dialog de dispositivos
	const handleDeviceAdded = () => {
		setShowDevices(false);
		setPrimaryList(selectedNodes)
		setFilter({ ...filter, dispositivos: selectedNodes });

		if (selectedNodes.length > 0) {
			setActiveStep(3)
		}
	};
	const nodeList = () => {
		return nodes?.map(node => (
			<MultiNode
				node={node}
				handleSelectedNode={handleSelectedNode}
				selectedNodes={selectedNodes}
				key={node.id}
				statusNodes={statusNodes}
			/>
		));
	};

	// Click en óvalo azul dentro del panel de filtrar
	const handleClickedSelectionItem = () => {
		setSelectedNodes(primaryList)
		// Mostrar lista de dispositivos
		setShowDevices(true);
	};
	// Click en la cruz del óvalo azul del panel de filtrar
	const handleDeleteSelectionItem = (id) => {
		const filteredList = primaryList.filter(item => {
			return item.id !== id;
		});

		setPrimaryList(filteredList)
		setSelectedNodes(filteredList)

		setFilter({ ...filter, dispositivos: filteredList });

		if (filteredList.length === 0) {
			setActiveStep(0)
		}

	};

	return (
		<>
			<Stepper
				className={classes.steppersFilter}
				activeStep={activeStep}
				alternativeLabel
			>
				{steps.map(label => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<form className={classes.root} onSubmit={e => onSubmit(e, filter)}>
				<Box
					display="flex"
					alignItems="center"
					flexWrap="wrap"
					minHeight={40}
				>
					<Tooltip
						title="Elija el/los dispositivos que desea añadir al gráfico haciendo click en el botón +"
						arrow
						placement="right"
					>
						<Box
							className={clsx(
								classes.hint,
								classes.leftSpacing,
								{
									[classes.active]: activeStep === 0,
								},
								{
									[classes.tick]: activeStep > 0,
								}
							)}
						>
							1
						</Box>
					</Tooltip>
					<div className={classes.devicesLabel}>Agregar dispositivos</div>
					<IconButton className={classes.addBtn} aria-label='delete' onClick={() => setShowDevices(true)}>
						<AddIcon fontSize='small' />
					</IconButton>
					{
						primaryList.map(node => (
							<Chip
								key={node.id}
								icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
								label={node.nombre}
								clickable
								color='primary'
								onDelete={() => handleDeleteSelectionItem(node.id)}
								className={classes.filterChip}
								onClick={handleClickedSelectionItem}
							/>
						))
					}
				</Box>

				<Box display="flex">
					<Tooltip
						title="Elija los tipos de eventos que desea graficar"
						arrow
						placement="right"
					>
						<Box
							className={clsx(classes.hint, classes.leftSpacing, {
								[classes.tick]: activeStep > 1,
							})}
						>
							2
						</Box>
					</Tooltip>

					<Box className={classes.devicesLabel}>Activar o desactivar opciones:</Box>

				</Box>


				<Box display="flex" justifyContent="center">

					<FormGroup classes={{ root: classes.rowSpace }}>
						{switchOptions.map(({ id, label, optionName }) => (
							<FormControlLabel
								key={id}
								classes={{ label: classes.switchLabel }}
								control={<Switch checked={filter[optionName]} onChange={handleFilterChange} name={optionName} color='primary' />}
								label={label}
							/>
						))}
					</FormGroup>
				</Box>


				<Box
					display="flex"
					flexWrap="wrap"
					minHeight={40}
				>
					<Tooltip
						title="Seleccione la fecha deseada"
						arrow
						placement="right"
					>
						<Box
							className={clsx(classes.hint, classes.leftSpacing, {
								[classes.tick]: activeStep > 2,
							})}
						>
							3
						</Box>
					</Tooltip>

					<Box className={classes.filterLabel} mb={1}>
						Seleccionar fecha:
					</Box>
				</Box>
				<Box display="flex" flexDirection="column" alignContent="center">

					<Box display='flex' justifyContent='center'>
						<ButtonGroup variant='outlined' color='primary' size='small' aria-label='botones rápidos de fecha'>
							{DATE_OPTIONS.map(option => (
								<Button
									key={option.key}
									onClick={() => handleDateChange(option.key)}
									variant={filter.quickDateOption === option.key ? 'contained' : ''}
								>
									{option.label}
								</Button>
							))}
						</ButtonGroup>
					</Box>

					{/* DatePicker */}
					<Box className={classes.wrapperDatepicker}   >
						<label className={classes.labelDate} >
							Desde / Hasta
						</label>
						<DatePickerCommon
							startDate={dateFrom}
							endDate={dateTo}
							setDate={setFilter}
						/>
					</Box>
				</Box>
				{filter.dispositivos.length === 0 && (
					<Box className={classes.help}>
						Debe seleccionar al menos un dispositivo.
					</Box>
				)}

				<Box display='flex' justifyContent='center'>
					<Button variant='contained' color='primary' type='submit' className={classes.btnSpacing} disabled={primaryList.length === 0}>
						Consultar
					</Button>

					<Button variant='contained' onClick={handleCancelFilterDialog} className={classes.btnSpacing}>
						Cancelar
					</Button>
				</Box>
			</form>

			<Dialog
				open={showDevices}
				onClose={handleCloseDevices}
				aria-labelledby='form-dialog-title'
				maxWidth="lg"
				fullWidth

			>
				<Box >
					<Snackbar
						open={notification.isOpen}
						autoHideDuration={2000}
						onClose={() => setNotification({ ...notification, isOpen: false })}
					>
						<Alert
							severity={notification.severity}
							onClose={() => setNotification(false)}
						>
							{notification.msg}
						</Alert>
					</Snackbar>
					<Box padding="8px">
						<DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
							Selección de Dispositivo
						</DialogTitle>
						<div className={classes.dialogSubtitle}>
							Seleccionar uno o más elementos de la organización
						</div>
						<Box display='flex' className={classes.selectedItemsContainer}>
							{/* Quiero que si "elimino" el ovalo en la seleccion de dispositivos, lo oculte */}
							{selectedNodes.map(node => (
								<Chip
									className={classes.chip}
									key={node.id}
									icon={<AccountTreeIcon />}
									label={node.nombre}
									clickable
									color='primary'
									onDelete={() =>
										handleDeleteNodeSelection(node.id)}
								/>
							))}
						</Box>

						<Box component='label' display='block' className={classes.label}>
							Organización
						</Box>
						<Box className={classes.containerNodes} >
							<>
								{nodes.length === 0 &&
									<h2 style={{ textAlign: "center", fontSize: "19px", color: "#2586bc", paddingTop: "20px" }}>
										Usted no posee ninguna localización configurada en su cuenta.<br></br>
										Por favor, configure al menos una localización para poder continuar.
										<NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
											[link]
										</NavLink>

									</h2>
								}
								<List component='div' disablePadding dense >
									{nodeList()}
								</List>
							</>

						</Box>
						<Box display='flex' justifyContent='center'>
							<Button
								className={classes.devicesBtn}
								variant='contained'
								color='primary'
								onClick={handleDeviceAdded}
								disabled={selectedNodes.length === 0}
							>
								Aceptar
							</Button>
							<Button variant='contained' onClick={handleCloseDevices} className={classes.devicesBtn}>
								Cancelar
							</Button>
						</Box>
					</Box >
				</Box>
			</Dialog>
		</>
	);
}

export default Filter;
