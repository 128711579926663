import React, { useState } from 'react';

import DatePicker, {
  registerLocale,
  CalendarContainer,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, IconButton, Box, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { format, lastDayOfWeek, subDays } from 'date-fns';
import es from 'date-fns/locale/es';
import CancelIcon from '@mui/icons-material/Cancel';
// Custom Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';

const useStyles = makeStyles(theme => ({
  dateContainer: {
    margin: "0 2px 0 2px",
    borderStyle: "none",
    '& .react-datepicker__input-container': {
      '& input': {
        width: "100%",
        cursor: "pointer",
        margin: "auto",
        height: "35px",
        fontSize: "15px",
        font: "inherit",
        textAlign: "center",
        color: "#5e5b5b",
        borderColor: "#e2e2e2",
        borderWidth: "1px",
        borderStyle: "none"
      },
    },
    '& .react-datepicker-popper': {
      width: '100%',
    },
    '& .react-datepicker': {
      borderStyle: "none"
    },
    '& .react-datepicker__day-name': {
      color: '#827a7a',
    },
    '& .react-datepicker__day--in-selecting-range': {
      backgroundColor: '#216ba5ad',
    },
    '& .react-datepicker__day': {
      borderRadius: '50% !important',
    },
    '& .react-datepicker__day--today': {
      color: '#88c0ed',
    },
    '& .react-datepicker__header': {
      backgroundColor: '#f4f4f5',
    },
    '& .react-datepicker__month-container': {
      padding: '5px',
      height: '315px',
    },
    '& .react-datepicker__navigation--previous ': {
      top: '10px',
      right: '25px',
    },
    '& .react-datepicker__navigation--next ': {
      top: '10px'
    },
    '& .react-datepicker__day--disabled': {
      color: '#ec222254',
    },
  },
  btn: {
    margin: theme.spacing(1, 1),
    fontSize: '13px',
    letterSpacing: '2px',
    minHeight: '37px',
  },
  iconDate: {
    marginLeft: '10px',
  },
  buttonReady: {
    zIndex: "2",
    backgroundColor: "#f4f4f5",
    marginBottom: "8px"
  },
  buttonClose: {
    width: '40px',
  }
}));

const DatePickerHeatmap = ({
  endDate,
  startDate,
  displayDisabled,
  setDate,
  type,
}) => {
  const { isMobile } = useDeviceDetect();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  //Funcion para pasar a español
  registerLocale('es', es);

  //funcion q escucha cambios de fecha.
  const handleDateChange = e => {
    if (type === 'year') {
      setDate(prevState => ({
        ...prevState,
        dateFrom: new Date(e),
        dateTo: new Date(e),
      }));
    }
    if (type === 'week') {
      setDate(prevState => ({
        ...prevState,
        dateFrom: subDays(lastDayOfWeek(new Date(e)), 6),
        dateTo: lastDayOfWeek(new Date(e)),
      }));
    }
  };

  //Container para poder agregarle elementos al datepicker
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        {/* Boton cerrar */}
        <Box
          style={{
            width: "100%",
            display: 'flex',
            justifyContent: 'flex-end',
          }}>

          <IconButton
            className={classes.buttonClose}
            color='primary'
            onClick={() => setIsOpen(false)}
          >
            <CancelIcon />
          </IconButton>
        </Box>
        {/* Container dias */}
        <Box style={{ position: 'relative' }}>
          {children}
        </Box>
        {/* Boton aceptar */}
        <Box
          style={{
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            // backgroundColor: '#f4f4f5',
          }}>
          <Button
            variant='contained'
            className={classes.buttonReady}
            size="small"
            aria-label="upload picture"
            // component="label"
            onClick={() => setIsOpen(false)} >
            Aceptar
          </Button>
        </Box>
      </CalendarContainer>
    );
  };
  const handleClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        // onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <Box className={classes.dateContainer}>

          <DatePicker
            showYearPicker={type === 'year'}
            //formato de fecha
            dateFormat="dd/MM/yyyy"
            //Clickeas el input.
            // onInputClick={() => setIsOpen(true)}
            //Clickeas afuera.
            onClickOutside={() => setIsOpen(false)}
            open={isOpen}
            selected={startDate}
            locale="es"
            calendarStartDay={0}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            //transforma el datepicker en dos caras formato libro abierto una por mes.
            monthsShown={1}
            selectsRange
            disabled={displayDisabled}
            maxDate={new Date()}
            // minDate={minDate}
            // desabilita los dias en formato:
            // excludeDateIntervals={[{ start: subDays(new Date("12/01/2022"), 0), end: addDays(new Date("01/01/2023"), 0) }]}
            // excludeDateIntervals={disabledFunction()}
            //elimina la repeticion de el dia final q seleccionaste en todos los meses, si no usas esta opcion selecciona todos los meses todos los dias iguales al dia end
            disabledKeyboardNavigation
            inline
            //container calendario para poder agregar el boton close
            calendarContainer={MyContainer}

          //Agrega un reseteador del datepicker y lo cierra
          // isClearable={true}

          //Agrega selector para ir al mes q quieras
          // showMonthDropdown

          //deshabilita dias en otra configuracion, dias inBoxidual mente
          // selectsDisabledDaysInRange
          />

        </Box>
      </Dialog>

      {/* FECHA NUMERICA */}
      <Box style={{ fontSize: '16px' }}>
        {type === 'week' &&
          `${format(startDate, 'dd/MM/yyyy')} - ${format(
            endDate,
            'dd/MM/yyyy'
          )}`}
        {type === 'year' && `${format(startDate, 'yyyy')}`}
      </Box>
      <Box>
        <Button
          type="submit"
          disabled={displayDisabled}
          onClick={handleClick}
          color="primary"
          variant="contained"
          className={classes.btn}
        >
          {type === 'year' ? 'Seleccione año' : 'Seleccione semana'}
          {!isMobile && <InsertInvitationIcon className={classes.iconDate} />}
        </Button>
      </Box>
    </>
  );
};
export default DatePickerHeatmap;
