
import { makeStyles, Box } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';

//Helpers
import { getFormattedNumber, getUnit } from '../../../helpers/common';
const useStyles = makeStyles(theme => ({
    chart: {
        width: '100%',
        // height: '110%',
    },
    title: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        margin: theme.spacing(1.5, 0, 4, 3),
        fontWeight: '600',
    }
}));
const SunburstChart = ({ stateData, location, subTitle }) => {
    const chartRef = useRef(null);
    const classes = useStyles();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const returnExtra = (element) => {
            let res = []
            for (let propiedad in element) {
                let valor = element[propiedad];
                const settings = propiedad === 'Energía no medida' ? { opacity: 0.5, fill: am5.color('#e8e5f3') } : {}
                const labelColor = propiedad === 'Energía no medida' ? { fill: am5.color(0x888888) } : { }
                let child = {
                    name: propiedad,
                    valueAux: valor,
                    value: valor,
                    valueFormat: `${getFormattedNumber(valor)} ${getUnit(valor)}Wh`,
                    nodeSettings: settings,
                    labelColor: labelColor
                }
                res.push(child)
            }
            return res
        }

        let childrensRoot = []
        stateData.energy_sector?.forEach(element => {
            const auxArry = returnExtra(element.extra)
            let obj = {}
            if (auxArry.length > 0) {
                obj = {
                    name: element.name,
                    children: returnExtra(element.extra),
                    valueAux: element.energy,
                    valueFormat: `${getFormattedNumber(element.energy)} ${getUnit(element.energy)}Wh`,
                }
            }
            else {
                obj = {
                    name: element.name,
                    valueAux: element.energy,
                    value: element.energy,
                    valueFormat: `${getFormattedNumber(element.energy)} ${getUnit(element.energy)}Wh`,
                }
            }
            childrensRoot.push(obj)
        })

        // La propiedad valueAux se agrega para hacer la sumatoria total de los valores para los tooltip.
        //ya q no puedo modificar los valores originales por q el sunburst funciona sumando sus hijos automaticamente.
        const data = {
            name: location.nombre,
            children: childrensRoot,
            valueFormat: `${getFormattedNumber(stateData.energy_month)} ${getUnit(stateData.energy_month)}Wh`,
        }
        setChartData(data)
        // eslint-disable-next-line
    }, [stateData])

    useEffect(() => {
        const root = am5.Root.new(chartRef.current)
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        const chart = root.container.children.push(am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout
        }));
        // SACAMOS LOGO DE AMCHARD
        chart.root._logo.dispose();

        const series = chart.children.push(am5hierarchy.Sunburst.new(root, {
            singleBranchOnly: true,
            downDepth: 2,
            initialDepth: 2,
            valueField: 'value',
            categoryField: 'name',
            childDataField: 'children',
        }));

        // series.get('colors').set('colors',
        //     [
        //         am5.color('#47a6db')
        //     ]
        // )

        //Agrega texto en el tooltext del hover de la serie. en este caso le agrega la unidad 'Kwh'
        series.nodes.template.set('tooltipText', '{category}: {valueFormat} ');

        //Cambio color background de la porcion.
        series.slices.template.setAll({
            templateField: 'nodeSettings'
        });

        //Cambio color de el label de la porcion.
        series.labels.template.setAll({
            templateField: 'labelColor'

        });


        series.data.setAll([chartData]);
        // series.set('selectedDataItem', series.dataItems[0]);
        series.appear(1000, 100);

        //legends
        // let legend = root.container.children.push(am5.Legend.new(root, {}));
        // legend.data.setAll(series.dataItems);

        return () => {
            root.dispose();
        }
    }, [chartData]);

    return (
        <Box style={{ height: '80%' }}>
            <Box className={classes.title}>
                Consumo de energía mensual por sector <span style={{ fontWeight: '400' }}>{`| ${subTitle}`}</span>
            </Box>
            <div ref={chartRef} style={{ height: '93%' }} className={classes.chart}></div>
        </Box>
    );
}
export default SunburstChart;



//example data
// var data = {
//     color: am5.color('#2196f3'),
//     name: 'name',
//     children: [
//         {
//             name: 'sub Localizacion 1',
//             value: 100

//         },
//         {
//             name: 'sub Localizacion 2',
//             value: 150

//         }
//     ]
// }

