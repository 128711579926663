import React from 'react';

import { Link } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import TopNav from '../common/TopNav';

const useStyles = makeStyles(theme => ({
	leftSpacer: {
		marginLeft: theme.spacing(8),
	},
	loginLink: {
		padding: theme.spacing(1, 2),
		background: 'white',
		textDecoration: 'none',
		borderRadius: '3px',
		marginLeft: theme.spacing(1),
		'&:visited': {
			color: theme.palette.primary.main,
		},
		'&:hover': {
			background: theme.palette.primary.main,
			color: 'white',
		},
	},
}));

export default function Auditor() {
	const classes = useStyles();

	return (
		<>
			<TopNav titulo='Auditor' />
			<Box className={classes.leftSpacer} display='flex' justifyContent='center' flexDirection='column'>
				<Alert severity='warning'>
					Sección en construcción.
					<Link to='/' className={classes.loginLink}>
						Volver al Inicio
					</Link>
				</Alert>
			</Box>
		</>
	);
}
