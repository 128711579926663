
import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
//Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RefreshIcon from '@material-ui/icons/Refresh';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff'; import { green, red } from '@material-ui/core/colors';
import { truncateWithEllipsis } from '../../../helpers/common';
import { getRealtimeToken } from '../../../services/meters';
import { DefaultDevice } from './Summary';
import { Alert } from '@material-ui/lab';
import GaugeChart from './GaugeChart';
import PowerTableChart from './PowerTableChart';
//Helpers
import { utcConverter, millisecondsConverter } from '../../../helpers/controllers';
//Servicios
import { getStatusNodes } from '../../../services/meters';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '8px 0px 4px 00px',
    boxShadow: '3px 3px 3px #cbc1c157',
    border: 'solid 1px #d9dcde',
    minHeight: '40vh',
    width: '100%',

  },
  title: {
    width: '100%',
    fontSize: theme.spacing(2.2),
    // margin: theme.spacing(0, 0, 1, 3),
    fontWeight: '600',
    // paddingTop: theme.spacing(1.5),
    padding: theme.spacing(3),
    color: theme.palette.grey.hairline,
  },
  dateFooter: {
    textAlign: 'center',
    marginTop: "20px",
    "@media (max-width: 900px)": {
      marginTop: "0px",
    }
  },
  online: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(2),
    color: green[400]
  },
  offline: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(2),
    color: red[400]
  },
  gaugeContainers: {
    flexDirection: 'row',
    "@media (max-width: 700px)": {
      flexDirection: 'column'
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  buttonUpdateData:{
    backgroundColor:'#2586bc',
    borderRadius:'25%',
    padding:2,
    boxShadow: '1px 1px 1px #cbc1c157',
    border: "1px solid #cbc1c157",
    color:'#efefef',
    margin:5,
    '&:hover': {
      color:'#D4D4D4'
    }
  }  
}));

function RealTimeSocket() {

  const classes = useStyles();
  const defaultDevice = useContext(DefaultDevice);
  const [lastData, setLastData] = useState({})
  const [socketData, setSocketData] = useState({})
  const [loading, setLoading] = useState(false);
  const [isRealtimeOn, setIsRealtimeOn] = useState(false)
  const [statusNode, setStatusNode] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [ws, setWs] = useState(null);

  //Socket conexión.
  useEffect(() => {
    // Close the previous WebSocket connection if it exists
    if (ws) {
      ws.close();
    }
    fetchRealtimeToken(defaultDevice.medidor);
    return () => {
      // Clean up WebSocket on component unmount
      if (ws) {
        ws.close();
      }
    };
    // eslint-disable-next-line
  }, [defaultDevice])

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      let nodeId = defaultDevice?.id
      let nodeAux = arry.find(e => e.node_id === nodeId)
      setStatusNode(nodeAux?.online)
    }
    getStatusNodesAux()
  }, [defaultDevice])

  const fetchRealtimeToken = async (id) => {
    try {
      setLoading(true)
      setShowErrorAlert(false)
      setErrorMessage('')
      const res = await getRealtimeToken(id);
      // console.log('token ok')
      setLoading(false)
      setLastData(res.data?.last_data)
      establishWebSocket(res.data?.token);
    }
    catch (error) {
      console.error(error)
      setLoading(false)
      if (error.response) {
        setLastData({})
        setSocketData({})
        setErrorMessage(`${error.response.data.msg}`);
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
      setShowErrorAlert(true);
    }
  };

  const establishWebSocket = (token) => {
    try {
      const newWs = new WebSocket(process.env.REACT_APP_REAL_TIME_URL);
      newWs.onmessage = (event) => {
        // console.log('ws onmessage', event.data)
        if (event.data !== 'connected') {
          setIsRealtimeOn(true)
          setSocketData(JSON.parse(event.data))
        }
      };
      newWs.onopen = () => {
        // console.log('ws opened');
        newWs.send(JSON.stringify({ token }));
      };
      newWs.onclose = () => {
        // console.log('ws onclose');
        setIsRealtimeOn(false)
      }
      // console.log(JSON.stringify({ token }))
      setWs(newWs);
    }
    catch (error) {
      setErrorMessage(`Error de conexión al web-socket`);
      console.error(error)
    }
  };



  const getData = (variable, index) => {
    // Mientras el real time esté apagado y tenga lastData.
    // Mientras el real time esté encendido y todavía no tenga socketData.
    if ((!isRealtimeOn && Object.keys(lastData).length > 0) || (isRealtimeOn && Object.keys(socketData).length === 0)) {
      if (variable === 'all') {
        return lastData;
      }
      if (variable === 't') {
        //el timezone viene en formato UTC (2023-12-06T16:00:02Z)  lo transformo a  (2023-12-11 21:57 GMT +01:00 ).
        return utcConverter(lastData.t)
      }
      return lastData?.f?.[index]?.[variable]
    }
    // Mientras el real time esté encendido y haya recibido socketData.
    if (isRealtimeOn && Object.keys(socketData).length > 0) {
      if (variable === 'all') {
        return socketData;
      }
      if (variable === 't') {
        //el timezone viene en  milisegundos (1702328084)  lo transformo a  (2023-12-11 21:57 GMT +01:00 ).
        return millisecondsConverter(socketData.t)
      }
      return socketData?.f?.[index]?.[variable] || 0
    }
  }

  //Header Boxes RealTime.
  const headerBoxesComponent = () => {
    return (
      <Box className={classes.title} display='flex' justifyContent='space-between'>
        <Box display='flex'>
          <Tooltip
            className={classes.cursorPointer}
            title={`Status dispositivo ${statusNode ? 'online' : 'offline'}`}
            arrow
            placement="bottom"
          >
            <FiberManualRecordIcon className={statusNode ? classes.online : classes.offline} />
          </Tooltip>

          <Box>{"Consumo"} <span style={{ fontWeight: '300' }}>{"| Tiempo real"}</span></Box>
        </Box>
        <Tooltip
          className={classes.cursorPointer}
          title={`${isRealtimeOn ? 'Transmitiendo' : 'No transmitiendo'}`}
          arrow
          placement="bottom"
        >
          <Box>
            {
              isRealtimeOn
                ?
                <ImportExportIcon style={{ fontSize: '25px', color:'#66BB6A' }} />
                :
                <MobiledataOffIcon style={{ fontSize: '25px' }} />
            }
          </Box>
        </Tooltip>
      </Box>
    )
  }
  return (
    <Box mt={1}>
      {loading &&
        <Grid container>
          <Grid className={classes.box} item md={6}>
            <Box display='flex' justifyContent='center' alignItems="center">
              <CircularProgress />
            </Box>
          </Grid>
          <Grid className={classes.box} item md={6}>
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      }
      {/* Si no estoy cargando el token ni recibi ningun mensaje por el socket */}
      {
        !loading && (
          <>
            <Grid container>
              <Grid item className={classes.box} md={6}>

                {/* Header de las cajas */}
                {
                  headerBoxesComponent()
                }

                {showErrorAlert && (
                  <Alert severity='error'>
                    {errorMessage}
                  </Alert>
                )}

                {
                  (Object.keys(lastData).length > 0 || Object.keys(socketData).length > 0) &&
                  <>

                    <Box display="flex" justifyContent="center" className={classes.gaugeContainers}>
                      {
                        defaultDevice.producto === 'HOME' &&
                        <GaugeChart id={`gaugesocket3`} color={'#594F96'} tension={getData('v', 0) || 0} potencia={getData('p', 0) + getData('p', 1)} descripcion={`${truncateWithEllipsis(defaultDevice.nombre, 10)}`} />
                      }
                      {
                        defaultDevice.producto === 'SMART' &&
                        (
                          <>
                            <GaugeChart id={`gaugesocket3`} color={'#00C3A5'} tension={getData('v', 0)} potencia={getData('p', 0)} descripcion={`${truncateWithEllipsis(defaultDevice.nombre, 10)}(R)`} />
                            <GaugeChart id={`gaugesocket4`} color='#ff3f67' tension={getData('v', 1)} potencia={getData('p', 1)} descripcion={`${truncateWithEllipsis(defaultDevice.nombre, 10)}(S)`} />
                            <GaugeChart id={`gaugesocket5`} color='#ffb326' tension={getData('v', 2)} potencia={getData('p', 2)} descripcion={`${truncateWithEllipsis(defaultDevice.nombre, 10)}(T)`} />
                          </>

                        )
                      }
                    </Box>

                    <Box display="flex" justifyContent="center" className={classes.dateFooter}>
                      <Box display="flex" alignItems="center">   Última lectura: {getData('t')}</Box>
                      {
                        !isRealtimeOn && 
                        <Tooltip title="Actualizar">
                          <IconButton className={classes.buttonUpdateData} aria-label="refrescar" onClick={() => fetchRealtimeToken(defaultDevice.medidor)} >
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>

                  </>
                }

              </Grid>
              <Grid item className={classes.box} md={6}>

                {/* Header de las cajas */}
                {
                  headerBoxesComponent()
                }

                {showErrorAlert && (
                  <Alert severity='error'>
                    {errorMessage}
                  </Alert>
                )}
                {
                  (Object.keys(lastData).length > 0 || Object.keys(socketData).length > 0) &&
                  <>
                    <PowerTableChart data={getData('all')} />

                    <Box display="flex" justifyContent="center" className={classes.dateFooter}>
                      <Box display="flex" alignItems="center">   Última lectura: {getData('t')}</Box>
                      {
                        !isRealtimeOn &&
                        <Tooltip title="Actualizar">
                           <IconButton className={classes.buttonUpdateData} aria-label="refrescar" onClick={() => fetchRealtimeToken(defaultDevice.medidor)} >
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>

                  </>
                }

              </Grid>
            </Grid>
          </>
        )
      }
    </Box>
  )
}
export default RealTimeSocket