import React from 'react'

import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.5),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiIconButton-root ': {
      padding: theme.spacing(0.25)
    }
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}


));

function Question({ id, title, isExpanded, toggle, videoId, enabled, children }) {
  const classes = useStyles();

  if (!enabled) {
    return <></>
  }

  return (
    <Box className={classes.container} >
      <Box className={classes.row} onClick={() => toggle(id)}>
        <IconButton
          aria-expanded={isExpanded}
          aria-label={title}
        >
          {isExpanded?<ExpandLessIcon/>:<ExpandMoreIcon />}
          
        </IconButton>
        <Box className={classes.title}>{title}</Box>
      </Box>

      {isExpanded &&
        <Grid container spacing={2}>

          {children}
        </Grid>
      }
    </Box>
  )
}

export default Question