import React, { useEffect, useState } from "react";
import {
    Box, makeStyles, Dialog, IconButton, CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { OPTIONS_DEVICES } from './helpers'
//Componentes
import { Alert } from '@material-ui/lab';
import PhaseControl from "./PhaseControl";
import DeleteControl from "./DeleteControl";
import ReleControl from "./ReleControl";
import AlarmsControl from "./AlarmsControl";
//Services
import { getControlSetting } from '../../services/meters';

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        padding: theme.spacing(1, 1, 1, 1),
        gap: theme.spacing(1),
        width: "100%",
        // -webkit-backdrop-filter: blur(10px);
        backdropFilter: "blur(10px)",
        "@media (max-width: 920px)": {
            flexWrap: "wrap",
            justifyContent: "center",
            padding: theme.spacing(0),
        }
    },
    box: {
        width: "50%",
        "@media (max-width: 920px)": {
            width: "80%",
        }
    },
    alerts: {
        height: "60px",
        fontSize: "large",
        position: "absolute",
        width: '100%',
        zIndex: "2",
        margin: theme.spacing(1, 0, 0, 0),
    },
}));

export default function ControlModal({ openModal, setOpenModal, data }) {
    const classes = useStyles();
    const [stateData, setSatateData] = useState({})
    const [showError, setShowError] = useState(true)
    //Loading control.
    const [loading, setLoading] = useState(false)
    //Loading de la caja interna espera todas las consultas de los controles.
    const [loadingInbox, setLoadingInbox] = useState(true)
    //Estados alert.
    const [alertState, setAlertState] = useState({
        close: false,
        type: "info",
        msg: "mensaje"
    })
    const dataAux = {
        plan: data?.product,
        id: data?.medidor_id
    }

    useEffect(() => {
        const GetInfo = async () => {
            //Si el modal esta abierto hace las consultas.
            if (openModal) {
                getAllControls()
            }
        }
        GetInfo()
        return (() => {
            setLoadingInbox(true)
        })
        // eslint-disable-next-line 
    }, [openModal])

    const getAllControls = async () => {
        let msg = "Ocurrio un error inesperado"
        let type = "error"
        try {
            setOpenModal(true)
            setShowError(true)
            setLoadingInbox(true)
            let key = 'UMBRALES_GET&key=TIMER_GET&key=RELAY_STATUS'
            let res = await getControlSetting(key, dataAux?.id)
            let UMBRALES_GET = res?.data.find(objeto => objeto.key === 'UMBRALES_GET');
            let TIMER_GET = res?.data.find(objeto => objeto.key === 'TIMER_GET');
            let RELAY_STATUS = res?.data.find(objeto => objeto.key === 'RELAY_STATUS');
            setSatateData(
                {
                    UMBRALES_GET,
                    TIMER_GET,
                    RELAY_STATUS
                }
            )
        }
        catch (error) {
            if (error?.response?.data) {
                msg = error?.response?.data.MSJ
            }
            setOpenModal(false)
            setShowError(false)
            setAlertState({
                close: true,
                msg: msg,
                type: type
            })
        }
        setLoadingInbox(false)
    }

    return (
        <>
            {/* Container loading */}
            <Dialog
                open={alertState.close || loading}
                PaperProps={{
                    style: {
                        backgroundColor: '#ffffff82',
                        boxShadow: 'none',
                        height: dataAux.plan === OPTIONS_DEVICES.HOME ? "538px" : "715px"
                    },
                }}
                // onClose={() => setOpenModal(false)}
                aria-labelledby="modal_inerl"
                disableScrollLock
                fullWidth
                maxWidth='sm'
            >
                <Box display="flex" justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"}>
                    {
                        loading &&
                        <CircularProgress />
                    }
                    {/* ALERTAS */}
                    {alertState.close &&
                        <Alert
                            className={classes.alerts}
                            severity={alertState.type}
                            action={
                                <IconButton
                                    aria-label='close'
                                    size='small'
                                    onClick={() => {
                                        setAlertState({
                                            ...alertState,
                                            close: false
                                        })
                                    }}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            }
                        >
                            {alertState.msg}
                        </Alert>
                    }
                </Box>
            </Dialog>

            {/* Container Main */}
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal_phase_control"
                disableScrollLock
                fullWidth
                maxWidth='sm'
            >
                {showError &&
                    <Box display="flex" className={classes.root}>
                        {
                            loadingInbox ?
                                <Box display="flex" justifyContent="center" width="100%" m={4}>
                                    <CircularProgress />
                                </Box>
                                :
                                <>
                                    {/* Caja izquierda */}
                                    <Box className={classes.box}>
                                        <AlarmsControl
                                            stateData={stateData}
                                            options={dataAux}
                                            setLoading={setLoading}
                                            setAlertState={setAlertState}
                                        />
                                    </Box>
                                    {/* Caja derecha */}
                                    <Box className={classes.box}>
                                        {/* el rele solo aparece en la opcion de powermeter home */}
                                        {dataAux.plan === OPTIONS_DEVICES.SMART &&
                                            <ReleControl
                                                stateData={stateData}
                                                options={dataAux}
                                                setLoading={setLoading}
                                                setAlertState={setAlertState}
                                            />
                                        }
                                        <PhaseControl
                                            stateData={stateData}
                                            options={dataAux}
                                            dataPhases={data?.phases}
                                            setLoading={setLoading}
                                            setAlertState={setAlertState}
                                        />
                                        <DeleteControl
                                            options={dataAux}
                                            setLoading={setLoading}
                                            setAlertState={setAlertState}
                                        />
                                    </Box>
                                </>
                        }
                    </Box>}
            </Dialog>
        </>
    )
}