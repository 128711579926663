import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import TopNav from '../common/TopNav';
import Container from '../Elements/Container';

import { getPlanInfo } from '../../services/client';
import {
    getAPIToken, saveTokenInLocalStorage,
    saveUserInfoInLocalStorage,
    saveNodesInLocalStorage,
    saveNodesListInLocalStorage,
    saveTagsInLocalStorage,
} from '../../services/auth';
import { getNodes, getNodesTree, getTags } from '../../services/hierarchy';

import UserContext from '../../context/UserContext';
import NodesContext from '../../context/NodesContext';
import NodesListContext from '../../context/NodesListContext';
import TagsContext from '../../context/TagsContext';
import { useParams } from 'react-router';
import {
    deleteDefaultDeviceFromLocalStorage,
} from '../../services/auth';
const useStyles = makeStyles(theme => ({
    logo: {
        width: '300px',
        margin: theme.spacing(0, 5),
    },
    title: {
        textAlign: 'center',
        margin: theme.spacing(1),
        color: theme.palette.primary.main,
        fontSize: 23,
    },
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        maxWidth: '28rem',
        margin: '0 auto'
    },
    leftSpacer: {
        marginLeft: theme.spacing(8),
    },
    input: {
        '& .MuiInputBase-input': {
            fontSize: 16,
            padding: theme.spacing(3, 0),
        },
    },
    firstBtn: {
        margin: theme.spacing(3, 0, 1, 0),
    },
    btn: {
        margin: theme.spacing(1, 0),
    },
}));

function DemoUser({ setUser, setRefresh }) {
    const classes = useStyles();
    const history = useHistory();
    const { params_id } = useParams();
    const { setPlan } = useContext(UserContext)
    const { setNodes } = useContext(NodesContext);
    const { setNodesList } = useContext(NodesListContext);
    const { setTags } = useContext(TagsContext);

    const handleSubmit = async e => {
        try {
            let user = {}
            let flag = false
            if (params_id === 'general') {
                flag = true
                user = {
                    username: 'demo@powermate.com',
                    password: 'cuentaDemo'
                }
            }
            if (params_id === 'home') {
                flag = true
                user = {
                    username: 'demo.home@powermate.com',
                    password: 'cuentaDemo'
                }
            }

            if (params_id === 'smart') {
                flag = true
                user = {
                    username: 'demo.smart@powermate.com',
                    password: 'cuentaDemo'
                }
            }
            if (params_id === 'subfactura') {
                flag = true
                user = {
                    username: 'demo.subfactura@powermate.com',
                    password: 'cuentaDemo'
                }
            }
            if (params_id === 'retail') {
                flag = true
                user = {
                    username: 'demo.retail@powermate.com',
                    password: 'cuentaDemo'
                }
            }

            deleteDefaultDeviceFromLocalStorage()
            let res = {}
            if (flag) {
                res = await getAPIToken(user);
            }
            else {
                history.push(`/login`)
                return
            }
            // Guardo el token en el localStorage
            saveTokenInLocalStorage(res.data.token);
            setUser(true);

            // Ejecución de promesas en paralelo
            const [planInfoResponse, nodesTreeResponse, nodesResponse, tagsResponse] = await Promise.all([
                getPlanInfo(),
                getNodesTree(),
                getNodes(),
                getTags(),
            ]);

            saveUserInfoInLocalStorage(planInfoResponse.data);
            setPlan(planInfoResponse.data);

            //Estado encargado de recargar la app.
            setRefresh((prevRefresh) => !prevRefresh);

            saveNodesInLocalStorage(nodesTreeResponse.data);
            setNodes(nodesTreeResponse.data);

            saveNodesListInLocalStorage(nodesResponse.data);
            setNodesList(nodesResponse.data);

            saveTagsInLocalStorage(tagsResponse.data);
            setTags(tagsResponse.data);

            history.push(`/`);
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleSubmit()
        // eslint-disable-next-line 
    }, [])

    return (
        <>
            <TopNav titulo='Demo' />
            <Container>
                <Alert className={classes.alert} severity='info'>
                    Bienvenido a Powermate usted esta utilizando una cuenta demo, en instantes será redireccionado.
                </Alert>
            </Container>
        </>
    );
}

export default DemoUser;
