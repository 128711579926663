import instance from './testing.instance';

//Consulta todos los id y dashboard del cliente.
export const getDashboards = () => instance.get(`/dashboard/custom/`);
//Consulta un dashboard especifco.
export const getDashboard = (id) => instance.get(`/dashboard/custom/${id}/`);
//Crea y edita un dashboard
export const postDashboard = (body) => instance.post(`/dashboard/custom/`, body);
//Edita y edita un dashboard
export const putDashboard = (id, body) => instance.put(`/dashboard/custom/${id}/`, body);
//Elimina un dashboard
export const deleteDashboard = (id) => instance.delete(`/dashboard/custom/${id}`);