import React from "react";

import clsx from "clsx";

import {
  Box,
  CircularProgress,
  Divider,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BatteryChargingFullOutlinedIcon from "@mui/icons-material/BatteryChargingFullOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import LinkIcon from "@mui/icons-material/Link";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
//Helpers
import { toFormattedNumber, getFormattedNumber, getUnit } from "../../helpers/common";

const useStyles = makeStyles((theme) => ({
  infoPanel: {
    position: "absolute",
    top: theme.spacing(0),
    // left: theme.spacing(0.5),
    bottom: theme.spacing(0),
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    width: "300px",

    '@media (max-width: 720px)': {
      position: "static",
      width:'100%'
    },
  },
  circular: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    height: "50%",
  },
  header: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  locationStr: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  zoneStr: {
    color: "white",
    fontSize: 14,
    margin: 0,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  companyStr: {
    color: "white",
    fontSize: 14,
    margin: 0,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  divider: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      backgroundColor: "white",
    },
  },
  divider2: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.primary.light,
    },
  },
  infoContainer: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    fontSize: 11,
    fontWeight: 200,
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },

  sectionHeader: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.light,
  },
  container: {
    margin: theme.spacing(0.5),
    '@media (max-width: 720px)': {
      padding: '0 16px'
    },
  },
  valueContainer: {
    color: theme.palette.grey[500],
    display: "flex",
    flexDirection: "column",
    gap: 0,
    
  },
  value: {
    fontSize: theme.spacing(3.2),
    fontWeight: 900,
  },
  delta_value: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
  },
  unit: {
    fontSize: theme.spacing(3),
    marginTop: -theme.spacing(1.5),
  },
  descriptionStr: {
    marginTop: -theme.spacing(0.8),
    fontSize: theme.spacing(1.5),
  },
  delta_descriptionStr: {
    marginTop: -theme.spacing(0.5),
    // marginLeft: theme.spacing(1.4),
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(1.5),
  },
  descriptionStr2: {
    fontSize: theme.spacing(1.5),
  },
  highlightValue: {
    outline: `1px solid ${theme.palette.secondary.main}80`,
  },
  timeSet: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
  state_header: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  state_icon: {
    textTransform: "uppercase",
    fontSize: theme.spacing(1.5),
  },
  divider3: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      backgroundColor: "white",
    },
  },
}));

const SummaryPanel = ({ data, loading }) => {
  const classes = useStyles();

  return (
    <>
      {loading && (
        <Box className={classes.infoPanel}>
          <Box className={classes.circular}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {data && (
        <Box className={classes.infoPanel}>
          <Box className={classes.header}>
            <Box className={clsx(classes.locationStr, classes.ellipsis)}>
              {data.name}
            </Box>
            <Box className={classes.zoneStr}>{data.zone}</Box>
            <Box className={classes.companyStr}>{data.company}</Box>
            <Divider className={classes.divider} />
            <Box className={classes.infoContainer}>
              <Box className={classes.infoItem}>
                <RoomOutlinedIcon style={{ fontSize: "18px" }} />
                <Box className={classes.ellipsis}>
                  {data.address || `Dirección no cargada`}
                </Box>
              </Box>
              <Box className={classes.infoItem}>
                <MessageOutlinedIcon style={{ fontSize: "18px" }} />
                <Box className={classes.ellipsis}>
                  {data.telephone || `Teléfono no cargado`}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.sectionHeader} ml={1} mt={1}>
            <ElectricBoltOutlinedIcon />
            <Box ml={0.5}>Energía</Box>
          </Box>
          <Box display="flex" className={classes.container}>
            <Box className={classes.valueContainer} mr={0.5}>
              <Box className={classes.value}>
                {getFormattedNumber(data.energy)}
              </Box>
              <Box className={classes.unit}>{getUnit(data.energy)}Wh</Box>
              <Box className={classes.descriptionStr}>MES ACTUAL</Box>
            </Box>
            {/* <Divider orientation='vertical' flexItem light /> */}
            <Box className={classes.valueContainer} ml="auto">
              <Box
                className={classes.delta_value}
                display="flex"
                alignItems="center"
              >
                {data.delta_energy > 0 ? (
                  <span style={{ color: "red" }}>
                    {toFormattedNumber(data.delta_energy)}%
                  </span>
                ) : (
                  <span style={{ color: "green" }}>
                    {toFormattedNumber(data.delta_energy)}%
                  </span>
                )}
                {data.delta_energy > 0 ? (
                  <ArrowDropUpIcon style={{ fontSize: 40, color: "red" }} />
                ) : data.delta_energy < 0 ? (
                  <ArrowDropDownIcon style={{ fontSize: 40, color: "green" }} />
                ) : (
                  ""
                )}
              </Box>
              {data.delta_energy > 0 ? (
                <Tooltip title="El consumo de energia de este mes es mayor al del mes anterior">
                  <Box className={classes.delta_descriptionStr}>
                    MES ANTERIOR
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip title="El consumo de energia de este mes es menor al del mes anterior">
                  <Box className={classes.delta_descriptionStr}>
                    MES ANTERIOR
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Divider className={classes.divider2} />

          <Box className={classes.sectionHeader} ml={1} mt={1}>
            <BatteryChargingFullOutlinedIcon />
            <Box ml={0.5}>Demanda de Potencia</Box>
          </Box>

          <Box>
            <Box display="flex" className={classes.container}>
              <Box className={classes.valueContainer} mr={0.5}>
                <Box className={classes.value}>
                  {getFormattedNumber(data.powerdemand)}
                </Box>
                <Box className={classes.unit}>{getUnit(data.powerdemand)}W</Box>
                <Box className={classes.descriptionStr}>DEMANDADA</Box>
              </Box>
              {/* <Divider orientation='vertical' flexItem light /> */}
              <Box className={classes.valueContainer} ml="auto">
                <Box
                  className={classes.delta_value}
                  display="flex"
                  alignItems="center"
                >
                  {data.delta_powerdemand > 0 ? (
                    <span style={{ color: "red" }}>
                      {toFormattedNumber(data.delta_powerdemand)}%
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>
                      {toFormattedNumber(data.delta_powerdemand)}%
                    </span>
                  )}
                  {data.delta_powerdemand > 0 ? (
                    <ArrowDropUpIcon style={{ fontSize: 40, color: "red" }} />
                  ) : data.delta_powerdemand < 0 ? (
                    <ArrowDropDownIcon
                      style={{ fontSize: 40, color: "green" }}
                    />
                  ) : (
                    ""
                  )}
                </Box>

                {data.delta_powerdemand > 0 ? (
                  <Tooltip title="La potencia registrada de este mes es mayor a la del mes anterior">
                    <Box className={classes.delta_descriptionStr}>
                      MES ANTERIOR
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip title="La potencia registrada de este mes es menor a la del mes anterior">
                    <Box className={classes.delta_descriptionStr}>
                      MES ANTERIOR
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.state_header} p={1} mt={1}>
            <Box ml={1} mt={1}>
              Estado
            </Box>
            <Box display="flex" justifyContent="center">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                m={0.5}
              >
                <LinkIcon style={{ fontSize: 28 }} />
                <Box className={classes.state_icon}>Conectividad</Box>
              </Box>
              {/* <Divider orientation='vertical' flexItem className={classes.divider3} /> */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                m={0.5}
              >
                <CampaignIcon style={{ fontSize: 28 }} />
                <Box className={classes.state_icon}>Notificación</Box>
              </Box>
              {/* <Divider orientation='vertical' flexItem className={classes.divider3} /> */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                m={0.5}
              >
                <PointOfSaleIcon style={{ fontSize: 28 }} />
                <Box className={classes.state_icon}>Facturación</Box>
              </Box>
            </Box>
            <Box ml={1} mt={1}>
              <Box>
                Medidor{" "}
                {data.online === "Virtual"
                  ? "Virtual"
                  : data.online
                    ? "online"
                    : "offline"}
              </Box>
              {data.contract_alert && <Box>Alertas de contrato</Box>}
              {data.bill_alert && <Box>Alertas de Facturación</Box>}
              {data.conciliation_alert ? (
                <Box>Alertas de conciliación</Box>
              ) : (
                <Box>Sin alertas de conciliación</Box>
              )}
              {data.alarm ? <Box>Alarmas</Box> : <Box>Sin alarmas</Box>}
              {data.extra_costs === 0 ? (
                <Box>Sin costos extra</Box>
              ) : (
                <Box>Costos Extra: ${data.extra_costs}</Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SummaryPanel;
