import { useEffect, useState } from "react";

const useDeviceDetect = (value) => {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    const onResize = () => {
      let pixel = 720
      if (value) {
        pixel = value
      }
      if (document.body.clientWidth <= pixel) {
        // console.log("Mobile")
        setMobile(true)
      }
      else {
        // console.log("Desktop");
        setMobile(false)
      }
    }
    onResize()
    window.addEventListener("resize", onResize, { passive: true })
    //Clean up
    return () => {
      window.removeEventListener('resize', onResize);
    }
  })
  return { isMobile };
}

export default useDeviceDetect;
