import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

const colors = {
	christmasBlue: '#2586bc',
	shimmeringBrook: '#61afd2',
	enamelledJewel: '#075a66',
	bluebeard: '#aab6c6',
	caramelInfused: '#d07555',
	riverOfGold: '#e4b25e',
	tropicalSmoothie: '#c7566a',
	pondSedge: '#608782',
	oldSchool: '#353b3d',
	prunella: '#88488e',
	diveIn: '#3f4b81',
	oakPlank: '#5e4f3a',
};

const brandColors = {
	primary1: colors.christmasBlue,
	primary2: colors.shimmeringBrook,
	primary3: colors.enamelledJewel,
	primary4: colors.bluebeard,
	primary5: colors.caramelInfused,
	primary6: colors.riverOfGold,
	primary7: colors.tropicalSmoothie,
	primary8: colors.pondSedge,
	secondary1: colors.oldSchool,
	secondary2: colors.prunella,
	secondary3: colors.diveIn,
	secondary4: colors.oakPlank,
};

export const getStatusColor = status => {
	if (status === 'diff_high') return red[400];
	if (status === 'diff_low') return yellow[400];
	if (status === 'diff_ok') return green[400];
	if (status === 'conciliated') return colors.christmasBlue;
	else return colors.oldSchool;
};

export default brandColors;
