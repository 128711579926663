

import clsx from 'clsx';
import { createContext, useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// icons
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {
  getDefaultDeviceFromLocalStorage,
  saveDefaultDeviceInLocalStorage,
} from '../../../services/auth';
//components
import Container from '../../Elements/Container';
import TopNav from '../../common/TopNav';
import GaugeFilter from './GaugeFilter';
import Pie from './Pie';
// Custom Hooks
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import ContainerCards from '../ComponentsDashboards/ContainerCards';
import ContainerChartHistorical from '../ComponentsDashboards/ContainerChartHistorical';
import ContainerChartInstant from '../ComponentsDashboards/ContainerChartInstant';
import RealTimeSocket from './RealTimeSocket';

const useStyles = makeStyles(theme => ({
  padding: {
    padding: '3px 0px 4px 00px',
  },
  boxes: {
    border: '1px solid #cbc1c157',
    boxShadow: '3px 3px 3px #cbc1c157',
    width: '100%',
  },
  alertIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  alert: {
    marginLeft: '65px',
    '@media (max-width: 920px)': {
      marginLeft: 0,
    },
  },
  btn: {
    '& .MuiButton-label': {
      color: theme.palette.common.white,
      textTransform: 'none',
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  btnMobile: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  devicesText: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(0.5),
  },
  buttonNav: {
    '@media (max-width: 920px)': {
      margin: theme.spacing(0, 0, 1, 1),
      paddingTop: theme.spacing(2),
    },
  }
}));

export const DefaultDevice = createContext();

const Summary = () => {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect();
  const [device, setDevice] = useState(
    getDefaultDeviceFromLocalStorage() || {}
  );
  const [filterPanel, setFilterPanel] = useState(!device.nombre ? true : false);

  useEffect(() => {
    if (device.id) {
      saveDefaultDeviceInLocalStorage(device);
    }
  }, [device]);

  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };

  return (
    <>
      <TopNav titulo="Dashboards" subtitulo="| Resumen dispositivo">
        <Grid className={classes.buttonNav} container justifyContent="flex-end">
          <Button
            className={
              isMobile ? clsx(classes.btn, classes.btnMobile) : classes.btn
            }
            type="button"
            onClick={togglePanel}
            size="small"
            startIcon={<LabelImportantIcon />}
          >
            {!isMobile
              ? Object.keys(device).length > 0 ? `${device.nombre} - ${device.producto}` : `Seleccionar Dispositivo Default`
              : ''}
          </Button>
        </Grid>
      </TopNav>

      {/* Alert bienvenido */}
      {!device.nombre && (
        <Box>
          <Alert className={classes.alert} severity="info">
            Bienvenido al dashboard Resumen dispositivo. En esta sección podrá consultar la
            información de sus dispositivos para cambiar el dispositivo
            default haga click en el botón
            <span className={classes.alertIcon}>
              <LabelImportantIcon style={{ fontSize: '18px' }} />
            </span>
            , elija el dispositivo y haga click en Aceptar.
          </Alert>
        </Box>
      )}

      <GaugeFilter
        isOpen={filterPanel}
        setIsOpen={setFilterPanel}
        setDevice={setDevice}
        device={device}
        descriptcion={false}
      />

      <Container>
        {device.nombre && (
          <DefaultDevice.Provider value={device}>
            <RealTimeSocket />

            <Grid container>

              <Grid item md={6} className={clsx(classes.boxes, classes.padding)}>
                <ContainerChartInstant
                  height={'90%'}
                  config_key={"DASHBOARDS_INSTANT-POTENCIA_ACTIVA_TOTAL-VALIDATE_INSTANT-ESTE_MES-15_MINUTES"}
                  title={'Demanda de potencia'}
                  id={device?.id}
                  variable={'Potencia activa total'}
                />
              </Grid>

              <Grid item md={3} className={clsx(classes.boxes, classes.padding)}>
                <Pie />
              </Grid>

              <Grid item md={3} className={clsx(classes.boxes, classes.padding)}>
                <ContainerCards
                  config_key="DASHBOARDS_INDICATOR-ENERGIA_ACTIVA_PROYECTADA_TOTAL-FORECAST-ESTE_MES-MONTHLY"
                  id={device.id}
                  variable=""
                  title="Energía proyectada"
                  size={false}
                  marginTitle={2}
                />
              </Grid>

              <Grid item md={6} className={clsx(classes.boxes, classes.padding)}>
                <ContainerChartHistorical
                  height={'93%'}
                  config_key={"DASHBOARDS_HISTORY-ENERGIA_ACTIVA_TOTAL-VALIDATE_HISTORY-ESTE_MES-DAILY"}
                  ky={'1'}
                  title="Consumo"
                  subtitle="Histórico mensual"
                  id={device?.id}
                  color={device?.producto === 'SMART' ? '#00C3A5' : '#594F96'}
                />
              </Grid>

              <Grid item md={6} className={clsx(classes.boxes, classes.padding)}>
                <ContainerChartHistorical
                  height={'93%'}
                  config_key={"DASHBOARDS_HISTORY-ENERGIA_ACTIVA_TOTAL-VALIDATE_HISTORY-ESTE_ANO-MONTHLY"}
                  ky={'2'}
                  title="Consumo"
                  id={device?.id}
                  color={device?.producto === 'SMART' ? '#00C3A5' : '#594F96'}
                  descriptcion={false}
                />
              </Grid>

            </Grid>
          </DefaultDevice.Provider>
        )}

      </Container>
    </>
  );
};

export default Summary;