import { makeStyles } from '@material-ui/core';
import { Collapse, IconButton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { useState, useEffect } from 'react';
import { getSingleLozalization } from '../../services/billing';
import { getNodes } from '../../services/hierarchy';
import PieChart from './PieChart';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
import clsx from 'clsx';
import Container from '../Elements/Container';
import Nav from './Nav';
import ModalFilter from './ModalFilter';
import { KeyboardArrowDown, KeyboardArrowUp, } from '@material-ui/icons';
import TuneIcon from '@material-ui/icons/Tune';

const useStyles = makeStyles(theme => ({
    containerMain: {
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px',
        justifyContent: 'center',
    },
    containerTable: {
        border: '1px solid #cbc1c157',
        boxShadow: '3px 3px 3px #cbc1c157',
        width: '97%',
        backgroundColor: '#f7f7f7b8',
    },
    containerPie: {
        border: '1px solid #cbc1c157',
        boxShadow: '3px 3px 3px #cbc1c157',
        backgroundColor: '#f7f7f7b8',
        width: '97%',
    },
    borderSolidBlue: {
        borderRight: '1px solid #2586bc',
        borderBottom: '1px solid #2586bc'
    },
    borderRightNone: {
        borderRight: 'none',
    },
    borderBottomtNone: {
        borderBottom: 'none',
    },
    headerTable: {
        BorderStyle: 'none',
        backgroundColor: theme.palette.primary.light,
    },
    table: {
        cursor: 'default'
    },
    backgroundHeader: {
        position: 'relative',
        top: '3px',
        backgroundColor: theme.palette.primary.light,
        width: '100%',
    },
    blueHeaderMain: {
        position: 'relative',
        top: '5px',
        marginTop: '12px',
        height: '60px',
        zIndex: '2'
    },
    blueFooterMain: {
        position: 'relative',
        height: '2px',
        top: '1px',
        zIndex: '2'
    },
    colorHeaderText: {
        position: 'relative',
        color: 'white',
        '@media (max-width: 700px)': {
            fontSize: '13px',
        }
    },
    tituloHeaderBox: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        fontSize: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2.2),
        paddingLeft: '18px',
        color: theme.palette.grey.main,
        cursor: 'default'
    },
    boldText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '@media (max-width: 700px)': {
            fontSize: '10px',
        }
    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
    },
    columnProperties: {
        backgroundColor: '#e0e0e0',
    },
    normalText: {
        color: theme.palette.primary.main,
        '@media (max-width: 700px)': {
            fontSize: '10px',
        }
    },
    white: {
        backgroundColor: 'white',
    },
    barWhite: {
        position: 'absolute',
        width: '20px',
        height: '18px',
        left: '0',
        top: '7px',
        borderLeft: '1px solid white',
    },
    fontSizeXl: {
        color: theme.palette.primary.main,
        '@media (max-width: 700px)': {
            fontSize: '12px',
        }
    },
    tuneIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.5em',
            paddingTop: theme.spacing(0.5),
        },
    },

}))

function createData(name, faseR, faseS, faseT, total, functionButon) {
    return { name, faseR, faseS, faseT, total, functionButon };
}

const SimpleCalculator = () => {
    const classes = useStyles();
    const [rows, setRows] = useState()
    const [pieChartData, setpieChartData] = useState()
    const [showContent, setShowContent] = useState(false)
    const [showModal, setShowModal] = useState(true)
    const [activeStep, setActiveStep] = useState(0);
    const [locations, setLocations] = useState('')
    const [valueVariable, setValueVariable] = useState(0)
    const [valueFijo, setValueFijo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showDatesTable, setShowDatesTable] = useState(true)
    const [locationFilterSelect, setLocationFilterSelect] = useState('')
    const [locationFilterSelectName, setLocationFilterSelectName] = useState('')
    const [alertError, setAlertError] = useState(false)
    const [alertErrorContent, setAlertErrorContent] = useState('')
    const [open, setOpen] = useState(false);
    //Seteo default de filtro opciones
    const [data, setData] = useState({
        dateFrom: new Date(Date.now() - 48 * 60 * 60 * 1000),
        dateTo: new Date(),
        costoFijo: '0',
        costoVariable: '0'
    })

    //Funcion para identificar vista mobile.
    const onResize = () => {
        if (window.screen.width <= 700) {
            setShowDatesTable(false)
            // console.log('mobile')
        }
        else {
            setShowDatesTable(true)
            // console.log('desktop');
        }
    }

    //Opciones Steps
    const steps = [
        'Elija un medidor de su lista',
        'Introduzca los cargos de su tarifa eléctrica',
        'Seleccione el periodo de facturacion',
        'Haga click en consultar',
    ];

    //Identifico vista mobile o desktop para q la tabla muestre solo total.
    useEffect(() => {
        window.addEventListener('resize', onResize, { passive: true })

    })

    //Aca obtendo todo los nodos del cliente para filtrar solo la localizacion a mostrar.
    useEffect(() => {
        onResize()
        gettingLocations()
    }, [])

    //Obtengo todos los nodos del cliente para buscar la primera localizacion q tenga el cliente
    const gettingLocations = async () => {
        let arrayData = await getNodes()
        let medidorVirtualFalse = arrayData.data.filter(e => e.medidor_virtual === false)
        setLocations(medidorVirtualFalse)
    }

    //Funcion dinamica para abrir el accordeon de cada uno de los valores en mobile screen
    const openBodyMobileTable = (i, e) => {
        i !== open ? setOpen(i) : setOpen(10)
    }

    //Obteniengo los datos de la localizacion por idLocalizacion.
    const sendDatos = async (idLocalizacion, costoF, costoV, initialDate, finalDate) => {
        try {
            const getLozalizacion = await getSingleLozalization(idLocalizacion, costoF, costoV, initialDate, finalDate);
            let data = getLozalizacion.data
            //Si data llego  cargo todos la i la tabla. para dibujar tabla
            if (data) {
                setAlertError(false)
                setLoading(true) //Si la data llego desaparece el loading
                setRows([
                    createData('Costo', `$ ${parseFloat(data.data.costR).toLocaleString()}`, `$ ${parseFloat(data.data.costS).toLocaleString()}`, `$ ${parseFloat(data.data.costT).toLocaleString()}`, `$ ${parseFloat(data.data.cost).toLocaleString()}`),
                    createData('Energia activa', `${parseFloat(data.data.kwhR).toLocaleString()}  kwh`, `${parseFloat(data.data.kwhS).toLocaleString()} kwh`, `${parseFloat(data.data.kwhT).toLocaleString()} kwh`, `${parseFloat(data.data.kwh).toLocaleString()} kwh`),
                    createData('Energia reactiva', `${parseFloat(data.data.kvarhR).toLocaleString()}  kVARh`, `${parseFloat(data.data.kvarhS).toLocaleString()} kVARh`, `${parseFloat(data.data.kvarhT).toLocaleString()} kVARh`, `${parseFloat(data.data.kvarh).toLocaleString()} kVARh`),
                    createData('Cos(fi)', parseFloat(data.data.cosfiR).toLocaleString(), parseFloat(data.data.cosfiS).toLocaleString(), parseFloat(data.data.cosfiT).toLocaleString(), parseFloat(data.data.cosfi).toLocaleString()),
                ]);
                // Cargo todos la info  para dibujar el grafico Pie
                setpieChartData([
                    {
                        parameter: `${data.data.kwhR.toLocaleString()} kwh (R)`,
                        cost: data.data.kwhR,
                    },
                    {
                        parameter: `${data.data.kwhS.toLocaleString()} kwh (S)`,
                        cost: data.data.kwhS,
                    },
                    {
                        parameter: `${data.data.kwhT.toLocaleString()} kwh (T)`,
                        cost: data.data.kwhT,
                    },
                ])
            }
        }
        catch (error) {
            setShowContent(false)
            setLoading(true)
            //Muestro la alerta de errores si existe el error muestro su contenido.
            setAlertError(true)
            error.response.data.msg
                ?
                setAlertErrorContent(error.response.data.msg)
                :
                setAlertErrorContent('Selecione un medidor')
        }
    }

    //Funcion q toma Costo Fijo del form opciones.
    const handleInputChangeCostoFijo = (event) => {
        event.target.value >= 0 && setValueFijo(event.target.value)
        setData({
            ...data,
            costoFijo: event.target.value
        })
    }

    //Funcion q toma Costo Variable del form opciones.
    const handleInputChangeCostoVariable = (event) => {
        event.target.value >= 0 && setValueVariable(event.target.value)
        setData({
            ...data,
            costoVariable: event.target.value
        })
    }

    //Funcion q toma la localizacion 
    const handleLocation = (e) => {
        let value = e.target.value
        let location = locations.find(e => e.id === value)
        setLocationFilterSelectName(location?.nombre)
        setLocationFilterSelect(e.target.value)
    }

    //Funcion q envia los datos para cargar tabla y grafico.
    const handleSubmit = () => {
        gettingLocations()
        setShowContent(true)
        setLoading(false)//si envio data nueva aparece de nuevo el loading hasta q la nueva data llegue
        let initialDate = format(data.dateFrom, 'dd/MM/yyyy')
        let endDate = format(data.dateTo, 'dd/MM/yyyy')
        sendDatos(locationFilterSelect, data.costoFijo, data.costoVariable, initialDate, endDate)
        setShowModal(false)
    }

    //Funcion para sacarle la clase del bordeBottom Blue solo a la fila de abajo de la tabla.
    const bottom = (rows, i) => {
        if (i + 1 === rows.length) {
            return classes.borderBottomtNone
        } else
            return ''
    }

    return (
        <Box>
            {/* NAV */}
            <Nav data={data} setShowModal={setShowModal} showModal={showModal} />

            {/* MODAL FILTER OPCIONES */}
            < ModalFilter
                handleSubmit={handleSubmit}
                handleInputChangeCostoVariable={handleInputChangeCostoVariable}
                handleInputChangeCostoFijo={handleInputChangeCostoFijo}
                handleLocation={handleLocation}
                steps={steps}
                data={data}
                setShowModal={setShowModal}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                valueVariable={valueVariable}
                valueFijo={valueFijo}
                showModal={showModal}
                locations={locations}
                locationFilterSelect={locationFilterSelect}
                setData={setData}
            />

            {/* COMPONERNTE PAGINA PRINCIPAL FEATURE HOME*/}
            <Container>
                {/* ALERTA INICIAL */}
                {
                    !showModal && !loading &&
                    <Alert severity='info'>
                        Bienvenido a Calculador Simple. En esta sección podrá consultar la información de facturación. Para comenzar haga click
                        en el botón
                        <span className={classes.tuneIcon}>
                            <TuneIcon />
                        </span>
                        , ingrese los parámetros y haga click en CONSULTAR.
                    </Alert>
                }
                {/*ALERTA DE ERROR */}
                {
                    alertError &&
                    <Alert severity='error'>{alertErrorContent}</Alert>
                }
                {loading ?
                    <Box className={classes.containerMain}>

                        {/* TABLA DE DATOS */}
                        {rows &&
                            <Box className={classes.containerTable}>

                                {/* header box */}
                                <Box className={classes.blueHeaderMain}>
                                    <Box className={classes.tituloHeaderBox}>
                                        {'Facturacion'} <span style={{ fontWeight: '300' }}>{'| Consumo Periodo'}</span>
                                    </Box>
                                </Box>

                                <Box className={classes.backgroundHeader}></Box>

                                <TableContainer className={classes.table} component={Paper}>
                                    <Table size='small' aria-label='simple table'>
                                        {/* mobile */}
                                        {
                                            !showDatesTable &&
                                            <TableHead >
                                                <TableRow className={classes.headerTable}>
                                                    <TableCell></TableCell>
                                                    <TableCell className={clsx(classes.colorHeaderText,)} align='center'>Total<div className={classes.barWhite}></div></TableCell>
                                                </TableRow>
                                            </TableHead>
                                        }
                                        {/* mobile */}
                                        {
                                            !showDatesTable &&
                                            <TableBody>
                                                {rows.map((row, i) => (
                                                    <TableRow
                                                        className={classes.white}
                                                        key={i}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className={clsx(classes.columnProperties,)} component='th' scope='row'>

                                                            <IconButton
                                                                className={clsx(classes.fontSizeXl, classes.boldText)}
                                                                aria-label='expand row'
                                                                size='small'
                                                                onClick={(e) => openBodyMobileTable(i, row.name)}
                                                            >
                                                                {open === i ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                                {row.name}
                                                            </IconButton>

                                                            <Collapse in={open === i} timeout='auto' unmountOnExit>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow >
                                                                            <TableCell className={clsx(classes.boldText)}>{'Fase  (R)'}</TableCell>
                                                                            <TableCell className={clsx(classes.normalText)} >
                                                                                {row.faseR}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                        <TableRow>
                                                                            <TableCell className={clsx(classes.boldText)}>{'Fase  (S)'}</TableCell>
                                                                            <TableCell className={clsx(classes.normalText)}>
                                                                                {row.faseS}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                        <TableRow>
                                                                            <TableCell className={clsx(classes.boldText)}>{'Fase  (T)'}</TableCell>
                                                                            <TableCell className={clsx(classes.normalText)}>
                                                                                {row.faseT}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </Collapse>

                                                        </TableCell>
                                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.borderRightNone, bottom(rows, i))} align='center'>{row.total}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        }
                                        {/* desktop */}
                                        {
                                            showDatesTable &&
                                            <TableHead >
                                                <TableRow className={classes.headerTable}>
                                                    <TableCell className={clsx(classes.colorHeaderText,)}>{locationFilterSelectName}</TableCell>
                                                    <TableCell className={clsx(classes.colorHeaderText,)} align='center'>Fase R<div className={classes.barWhite}></div></TableCell>
                                                    <TableCell className={clsx(classes.colorHeaderText,)} align='center'>Fase S<div className={classes.barWhite}></div></TableCell>
                                                    <TableCell className={clsx(classes.colorHeaderText,)} align='center'>Fase T<div className={classes.barWhite}></div></TableCell>
                                                    <TableCell className={clsx(classes.colorHeaderText,)} align='center'>Total<div className={classes.barWhite}></div></TableCell>
                                                </TableRow>
                                            </TableHead>
                                        }
                                        {/* desktop */}
                                        {
                                            showDatesTable &&
                                            <TableBody>
                                                {rows.map((row, i) => (
                                                    <TableRow
                                                        className={classes.white}
                                                        key={i}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className={clsx(classes.columnProperties, classes.normalText)} component='th' scope='row'>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, bottom(rows, i))} align='center'>{row.faseR}</TableCell>
                                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, bottom(rows, i))} align='center'>{row.faseS}</TableCell>
                                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, bottom(rows, i))} align='center'>{row.faseT}</TableCell>
                                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText, classes.borderRightNone, bottom(rows, i))} align='center'>{row.total}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        }

                                    </Table>
                                </TableContainer>

                                <Box className={classes.blueFooterMain}></Box>

                            </Box>}

                        {/* GRAFICO DE TORTA */}
                        {
                            pieChartData &&
                            <Box className={classes.containerPie}>
                                {/* header box */}
                                <Box className={classes.blueHeaderMain}>
                                    <Box className={classes.tituloHeaderBox}>
                                        {'Grafico'} <span style={{ fontWeight: '300' }}>{'| Parametros'}</span>
                                    </Box>
                                </Box>
                                <PieChart chartData={pieChartData} />
                            </Box>
                        }
                    </Box >
                    :
                    <Box className={classes.circular}>
                        {/* {CIRCULAR LOADING}   */}
                        {showContent && <CircularProgress />}
                    </Box>
                }
            </Container >
        </Box >
    )
}
export default SimpleCalculator