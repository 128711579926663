export const getLat = coordinates => {
  if (coordinates) {
    return +coordinates.split(',')[0]
  }
};
export const getLng = coordinates => {
  if (coordinates) {
    return +coordinates.split(',')[1]
  }
};

export const defaultCenter = { lat: -34.546342, lng: -58.451925 }