import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { grey } from '@material-ui/core/colors';
import { getFormattedNumber, getUnit } from '../../../helpers/common';
// import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { utcConverter } from '../../../helpers/controllers';

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.primary.light,
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
    color: grey[500],
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.hover,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    border: '1px solid #cbc1c157',
    boxShadow: '3px 3px 3px #cbc1c157',
  },
  title: {
    color: theme.palette.grey.hairline,
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(1.5, 0, 0.5, 2),
    fontWeight: 600,
    padding: theme.spacing(1, 0, 1, 0),
  },
  subtitle: {
    fontWeight: 400,
  },
  table: {
    flexGrow: 1
  }
}));

function DashTable({ title, subtitle, data, type }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  // const [rowsPerPage, setRowsPerPage] = useState(8);
  const rowsPerPage = 6
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const rows = data.map((row, index) => ({ ranking: index + 1, ...row }))

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const stableSort = (array, comparator) => {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  let headCells = []
  if (title === 'Consumo de energía') {
    headCells = [
      { id: 'ranking', numeric: true, label: 'N°' },
      { id: 'name', numeric: true, label: 'Localización' },
      { id: 'energy_total_year', numeric: true, label: 'Energía' },
      { id: 'power_max_year', numeric: false, label: 'Pot. Máxima' },
      { id: 'link', numeric: false, label: 'Link' },
    ];

  }
  if (title === 'Demanda de potencia máxima por localización') {
    headCells = [
      { id: 'ranking', numeric: true, label: 'N°' },
      { id: 'name', numeric: true, label: 'Localización' },
      { id: 'power_max_month_timestamp', numeric: false, label: 'Fecha y Hora' },
      { id: 'power_max_month', numeric: true, label: 'Pot. Máxima' },
      { id: 'power_contract', numeric: false, label: 'Pot. Contratada' },
    ];
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {title}
        <span className={classes.subtitle}> | {subtitle}</span>
      </Box>
      <TableContainer className={classes.table}>
        <Table aria-label={title} size="small">
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {
              title === 'Consumo de energía' && (
                stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.ranking}
                    </StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {getFormattedNumber(row.energy_total_year)} {getUnit(row.energy_total_year)}Wh
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {getFormattedNumber(row.power_max_year)} {getUnit(row.power_max_year)}W
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        component={NavLink}
                        to={{
                          pathname: '/resumen_localizacion',
                          state: { id: row.id, nombre: row.name }
                        }}

                      >
                        Ver +
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )
            }
            {
              title === 'Demanda de potencia máxima por localización' && (
                stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.ranking}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {utcConverter(row.power_max_month_timestamp)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getFormattedNumber(row.power_max_month)} {getUnit(row.power_max_month)}W
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.power_contract}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        nextIconButtonText="Página siguiente"
        backIconButtonText='Página anterior'
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}

      />
    </Box>
  );
}

export default DashTable;
