import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale, CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import es from 'date-fns/locale/es'
import { formatDateStrDashToSlashReverse } from "../../helpers/common";
import { Button, Dialog, Box, IconButton } from '@material-ui/core';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles(theme => ({
    dateContainer: {
        margin: "0 2px 0 2px",
        borderStyle: "none",
        '& .react-datepicker__input-container': {
            '& input': {
                width: "100%",
                cursor: "pointer",
                margin: "auto",
                height: "35px",
                fontSize: "15px",
                font: "inherit",
                textAlign: "center",
                color: "#5e5b5b",
                borderColor: "#e2e2e2",
                borderWidth: "1px",
                borderStyle: "none"
            },
        },
        '& .react-datepicker-popper': {
            width: "100%"
        },
        '& .react-datepicker': {
            borderStyle: "none"
        },
        '& .react-datepicker__day-name': {
            color: "#827a7a"
        },
        '& .react-datepicker__day--in-selecting-range': {
            backgroundColor: "#216ba5ad"
        },
        '& .react-datepicker__day': {
            borderRadius: "50% !important",
        },
        '& .react-datepicker__day--today': {
            color: "#88c0ed",
        },
        '& .react-datepicker__header': {
            backgroundColor: "#f4f4f5",
        },
        '& .react-datepicker__month-container': {
            padding: "5px",
            height: "315px"
        },
        '& .react-datepicker__navigation--previous ': {
            top: "10px",
            right: "25px"
        },
        '& .react-datepicker__navigation--next ': {
            top: "10px",

        },
        '& .react-datepicker__day--disabled': {
            color: "#ec222254"
        },
    },
    btn: {
        margin: theme.spacing(1, 1),
        // fontWeight: "bold",
        fontSize: "13px",
        letterSpacing: "2px",
        minHeight: "37px"
    },
    iconDate: {
        marginLeft: "10px",

    },
    buttonReady: {
        zIndex: "2",
        backgroundColor: "#f4f4f5",
        marginBottom: "8px"
    },
    buttonClose: {
        width: '40px',
    }
}));

const DatePickerBill = ({
    setActiveStep, datePickerDisabled,
    setEndDate, endDate, startDate, setStartDate,
    displayDisabled, setBillData, billData
}) => {

    const { isMobile } = useDeviceDetect();
    const classes = useStyles();
    const [maxDate, setMaxDate] = useState(new Date())
    const [minDate, setMinDate] = useState()
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let date = new Date(datePickerDisabled?.inicio + ':03:00')
        setMinDate(date)
    }, [datePickerDisabled])

    //Funcion para pasar a español
    registerLocale("es", es)

    // Funcion para retornar el arrays de date Desabilitadaas    
    const disabledFunction = () => {
        let arryRes = []
        datePickerDisabled?.no_disponibles.forEach(element => {
            let from = formatDateStrDashToSlashReverse(element.desde)
            let to = formatDateStrDashToSlashReverse(element.hasta)
            let obj = { start: subDays(new Date(from), 0), end: addDays(new Date(to), 0) }
            arryRes.push(obj)

        });
        return arryRes
    }

    //funcion para ordenar de mayor a menor un array de fechas.
    const orderArry = (arryListDate) => {
        let newArry = []
        arryListDate.forEach(element => {
            newArry.push(formatDateStrDashToSlashReverse(element.desde))
            newArry.push(formatDateStrDashToSlashReverse(element.hasta))
        });
        let arryMinToMax = newArry.sort((a, b) => parseInt(a.slice(0, 2)) - parseInt(b.slice(0, 2)))
        //Aca ordeno las fechas disabled de menor a mayor pero por Año
        let arryOrderYearMinToMax = arryMinToMax.sort((a, b) => parseInt(a.slice(a.length - 4, a.length)) - parseInt(b.slice(b.length - 4, b.length)))
        return arryOrderYearMinToMax
    }

    //Funcion para designar el hasta q dia muestra el datepicker el designa la fecha maxima.
    const setMax = (start, end) => {
        setMaxDate(new Date())
        let disabledPeriod = datePickerDisabled?.no_disponibles
        let startCopy = new Date(JSON.parse(JSON.stringify(start)));
        for (let i = 0; i < orderArry(disabledPeriod).length; i++) {
            let element = orderArry(disabledPeriod)[i]
            if (startCopy < new Date(element)) {
                setMaxDate(new Date(element))
                break
            }
        }
        if (end) {
            setMaxDate(new Date())
        }
    }

    //funccion q escucha cambios de fecha.
    const onChanges = (e) => {
        const [start, end] = e;
        setMax(start, end)
        setStartDate(start)
        setEndDate(end);
        if (end !== null) {
            setActiveStep(3)

            if (setBillData && billData) {
                setBillData(billData => ({
                    ...billData,
                    date_from: format(start, 'yyyy-MM-dd'),
                    date_to: format(end, 'yyyy-MM-dd')
                }));
            }
        }
    };

    //Container para poder agregarle elementos al datepicker
    const MyContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                {/* Boton cerrar */}
                <Box
                    style={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>

                    <IconButton
                        className={classes.buttonClose}
                        color='primary'
                        onClick={() => setIsOpen(false)}
                    >
                        <CancelIcon />
                    </IconButton>
                </Box>


                <Box style={{ position: "relative" }}>
                    {children}
                </Box>

                {/* Boton aceptar */}
                <Box
                    style={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: 'center',
                        // backgroundColor: '#f4f4f5',
                    }}>
                    <Button
                        variant='contained'
                        className={classes.buttonReady}
                        size="small"
                        aria-label="upload picture"
                        // component="label"
                        onClick={() => setIsOpen(false)} >
                        Aceptar
                    </Button>
                </Box>
            </CalendarContainer>
        );
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                // onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
            >
                <Box className={classes.dateContainer}>

                    <DatePicker
                        //formato de fecha
                        dateFormat="dd/MM/yyyy"
                        //Clickeas el input.
                        // onInputClick={() => setIsOpen(true)}
                        //Clickeas afuera.   
                        onClickOutside={() => setIsOpen(false)}
                        open={isOpen}
                        selected={startDate}
                        locale="es"
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChanges}
                        //transforma el datepicker en dos caras formato libro abierto una por mes.
                        monthsShown={isMobile ? 1 : 2}
                        selectsRange
                        disabled={displayDisabled}
                        maxDate={new Date(maxDate)}
                        minDate={minDate}
                        // desabilita los dias en formato:
                        // excludeDateIntervals={[{ start: subDays(new Date("12/01/2022"), 0), end: addDays(new Date("01/01/2023"), 0) }]}
                        excludeDateIntervals={disabledFunction()}
                        //elimina la repeticion de el dia final q seleccionaste en todos los meses, si no usas esta opcion selecciona todos los meses todos los dias iguales al dia end
                        disabledKeyboardNavigation
                        inline
                        //container calendario para poder agregar el boton close
                        calendarContainer={MyContainer}

                    //Agrega un reseteador del datepicker y lo cierra
                    // isClearable={true}

                    //Agrega selector para ir al mes q quieras
                    // showMonthDropdown

                    //deshabilita dias en otra configuracion, dias inBoxidual mente
                    // selectsDisabledDaysInRange
                    />

                </Box>
            </Dialog>

            {/* FECHA NUMERICA */}
            <Box style={{ fontSize: "16px" }} >
                {startDate && `${format(startDate, "dd-MM-yyyy")}   /  ${endDate && format(endDate, "dd-MM-yyyy")}`}
            </Box>

            <Box>
                <Button type='submit' disabled={displayDisabled} onClick={handleClick} color='primary' variant='contained' className={classes.btn}>
                    {'Seleccione periodo'}
                    {!isMobile && <InsertInvitationIcon className={classes.iconDate} />}
                </Button>
            </Box>
        </>
    );
}

export default DatePickerBill
