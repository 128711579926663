import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
// Servicios
import { getHistoryV2 } from '../../../services/meters';
const useStyles = makeStyles(theme => ({
    chart: {
        width: "100%",
        height: "85%",
        marginTop: "40px",
    },
    title: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        margin: theme.spacing(1.5, 0, 0.5, 3),
        fontWeight: "600",
    }
}));

export default function PieChart({ location, subTitle }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [loadingMain, setLoadingMain] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        const testHistory = async () => {
            try {
                let id = location.id
                let url = `filter=${id}`
                url += `&fields=kwh`
                url += `&showPhase=true`
                url += `&period=monthly`
                url += `&range=ESTE_ANO`
                let res = await getHistoryV2(url);

                //Obtengo los últimos tres elementos del array
                const today = new Date();
                const currentMonth = today.getMonth();
                const currentData = res?.data?.[0]?.data?.[currentMonth];

                let faseR = currentData.find((e) => e.fase__envio_id === 'R')
                let faseS = currentData.find((e) => e.fase__envio_id === 'S')
                let faseT = currentData.find((e) => e.fase__envio_id === 'T')

                let data = [
                    {
                        color: am4core.color("#2196f3"),
                        cost: faseR.kwh,
                        parameter: "fase R"
                    },
                    {
                        color: am4core.color("#5460ca"),
                        cost: faseS.kwh,
                        parameter: "fase S"
                    },
                    {
                        color: am4core.color("#54b664c4"),
                        cost: faseT.kwh,
                        parameter: "fase T"
                    },
                ];
                setChartData([...data])
                //Valido si alguna de las 3 fases tiene data
                if (checkDataValue(faseR.valor, faseS.valor, faseT.valor)) {
                    setLoadingMain(true)
                    setErrorMessage(`No posee información para visualizar el gráfico.`)
                    setShowErrorAlert(true);
                    setLoadingMain(false)
                    return
                }
                setLoadingMain(false)
            }
            catch (error) {
                if (error.response) {
                    setErrorMessage(
                        `${error.response.data.msg}.`
                    );
                } else {
                    setErrorMessage(`Ocurrió un error inesperado`);
                }
                setShowErrorAlert(true)
                setLoadingMain(false)
            }
        }
        testHistory()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Create chart instance
        let chart = am4core.create("costPieChart", am4charts.PieChart);
        // SACAMOS LOGO DE AMCHARD
        if (chart.logo) {
            chart.logo.disabled = true
        }
        // Add data
        chart.data = chartData;
        chart.innerRadius = am4core.percent(0)
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "cost";
        pieSeries.dataFields.category = "parameter";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.truncate = true;
        pieSeries.labels.template.fontSize = 13;
        //desaparece los valores de las flechas
        // pieSeries.labels.template.disabled = true
        // pieSeries.slices.template.propertyFields.fill = "color";
        // pieSeries.slices.template.propertyFields.fillOpacity = 0.7;
        // pieSeries.slices.template.propertyFields.stroke = am4core.color("#ffffff");
        // pieSeries.slices.template.events.on("over", (event) => {
        //     event.target.dataItem.slice.fill = am4core.color("#000000");
        //     event.target.dataItem.slice.fillOpacity = 1;
        // });
        // pieSeries.slices.template.events.on("out", (event) => {
        //     event.target.dataItem.slice.fill = event.target.dataItem.dataContext.color;
        //     event.target.dataItem.slice.fillOpacity = 0.7;
        // });
        // Create a linear gradient for each slice
        pieSeries.slices.template.adapter.add("fill", (fill, target) => {
            const gradient = new am4core.LinearGradient();
            gradient.addColor(target.dataItem.dataContext.color, 0.4);
            gradient.addColor(am4core.color(target.dataItem.dataContext.color, 10));
            return gradient;
        });
        pieSeries.alignLabels = true;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        // pieSeries.hiddenState.properties.startAngle = 0;
        // pieSeries.hiddenState.properties.endAngle = 360;
        //SELECTORES DE COLOR DE CADA VALUE DEL GRAPH
        chart.legend = new am4charts.Legend()
        chart.legend.itemContainers.template.paddingBottom = 10;
        //OPCION PARA MODIFICAR LOS TEXTOS DE LOS LEGEND
        pieSeries.legendSettings.valueText = "{cost} kWh";
        pieSeries.legendSettings.labelText = "([/bold]{parameter}[/bold])";
        return () => {
            chart.dispose();
        };
        // eslint-disable-next-line
    }, [loadingMain]);

    const checkDataValue = (r, s, t) => {
        return r === 0 && s === 0 && t === 0
    }

    return (
        <>
            {showErrorAlert ?
                <Alert severity="error">{errorMessage}</Alert>
                :
                <>
                    {loadingMain ?
                        <Box display="flex" justifyContent="center" mt={4}>
                            <CircularProgress />
                        </Box>
                        :
                        <Box style={{ height: "80%" }}>
                            <Box className={classes.title}>
                                Consumo de energia mensual por fase <span style={{ fontWeight: '400' }}>{`| ${subTitle}`}</span>
                            </Box>
                            <Box id='costPieChart' className={classes.chart} />
                        </Box>
                    }
                </>
            }
        </>

    );

}





