//Material ui
import { makeStyles, Box } from '@material-ui/core'
import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const useStyles = makeStyles(theme => ({
  chart: {
    width: "100%",
    height: "100%",
    "@media (max-width: 1040px)": {
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.2),
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "5px",
    marginTop: "10px"
  }
}));

export default function HorizontalTagsBar({ data, ky }) {
  const classes = useStyles();

  useEffect(() => {
    //Ejemplo de objeto q entra
    // let powerRates = ['Iluminacion', 'Fuerza motriz', 'Aire acondicionado', 'Sector segundo']
    // let chartData = [
    //   {
    //     type: 'Iluminacion',
    //     'Iluminacion': 30
    //   },
    //   {
    //     type: 'Fuerza motriz',
    //     'Fuerza motriz': 20
    //   },
    //   {
    //     type: 'Aire acondicionado',
    //     'Aire acondicionado': 10
    //   },
    //   {
    //     type: 'Sector segundo',
    //     'Sector segundo': 40
    //   }
    // ]

    let powerRates = []
    let chartData = []

    data?.forEach((element) => {
      let name = element.tag
      let obj = {
        type: element.tag,
        [name]: element.kwh
      }
      powerRates.push(element)
      chartData.push(obj)

    })

    am4core.useTheme(am4themes_animated);
    // Create chart instance
    let chart = am4core.create(`powerBarChart${ky}`, am4charts.XYChart);
    // SACAMOS LOGO DE AMCHARD
    if (chart.logo) {
      chart.logo.disabled = true
    }
    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "type";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.opacity = 0;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.adapter.add('text', function (text) {
      return text + ' kWh';
    });

    // Create series
    function createSeries(field) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      // series.name = field?.tag;
      series.dataFields.valueX = field?.tag;
      series.dataFields.categoryY = "type";
      series.sequencedInterpolation = true;
      // Make it stacked
      series.stacked = true;
      // Configure columns
      //series.columns.template.width = am4core.percent(60);
      series.columns.template.height = am4core.percent(100);
      series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX} kWh";
      series.columns.template.fill = field?.color;
      series.columns.template.strokeOpacity = 0; // Remove the border

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueX} kWh";
      labelBullet.locationY = 0.5;
      labelBullet.locationX = 1;
      labelBullet.label.hideOversized = true;
      labelBullet.label.dx = 40;
      labelBullet.paddingLeft = 15;
      //eslint-disable-next-line
      return series;
    }
    //Recorro todas las franjas
    powerRates.forEach(rate => {
      createSeries(rate);
    })
    // Legend
    // Crea una instancia de la leyenda
    // chart.legend = new am4charts.Legend();
    // Ajusta la orientación de la leyenda
    // chart.legend.position = "right";
    // chart.legend.valign = "middle";
    // chart.legend.align = "right";
    // Ajusta el margen derecho de la leyenda
    // chart.legend.paddingRight = 20;
    // Opcional: si deseas ajustar el ancho de la leyenda
    // chart.legend.width = 200;

    // Add data
    chart.data = chartData;
    return () => {
      chart.dispose();
    };
    // eslint-disable-next-line
  }, [data]);

  return (
    <Box style={{ height: "80%" }}>
      <Box id={`powerBarChart${ky}`} className={classes.chart} />
    </Box>
  );
}