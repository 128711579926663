import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function MOD
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={33}
            adderFlag={22}
            showLabelInput={true}
            LabelInputRight={'43px'}
            labelBlock={'%'}
            labelValue1={'Divisor'}
            keyVar1={'dr'}
            userConfiguration={true}
        />
    );
}
export default MOD