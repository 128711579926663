import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  bubble: {
    borderRadius: '50%',
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${grey[300]}`
  },
  bubbleIcon: {
    color: grey[300],
  },
  bubbleLabel: {
    textAlign: 'center',
  },
}));

function Bubble({ name, zoom, setZoom, lat, lng, setCenter, surface }) {
  const classes = useStyles();

  const handleClick = () => {
    setZoom(15)
    setCenter({
      lat, lng
    });
  }

  const getDimensions = (surfaceArea, zoom) => {
    let width, height;
    if (surfaceArea <= 100) {
      width = height = zoom > 9 ? `${3 * zoom}px` : '20px';
    } else if (surfaceArea <= 1000) {
      width = height = zoom > 9 ? `${5 * zoom}px` : '15px';
    } else {
      width = height = zoom > 9 ? `${8 * zoom}px` : '10px';
    }
    return { width, height, transition: 'width 0.25s, height 0.25s' };
  };

  return (
    <Box>
      <Box
        className={classes.bubble}
        style={getDimensions(surface, zoom)}
      >
        <Tooltip title={name} onClick={handleClick}>
          <IconButton >
            <Box></Box>
            {/* {zoom > 9 &&
              <SpeedIcon className={classes.bubbleIcon} style={{ fontSize: zoom < 9 ? `${0.1 * surface * zoom}px` : "20px" }} />} */}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default Bubble;
