import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { Box, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input": {
      fontSize: 13,
      padding: theme.spacing(2, 0),
    },
  },
  suggestionsContainer: {
    border: `0.5px dotted ${theme.palette.primary.light}`,
  },

  suggestionsList: {
    padding: 0,
    width: '100%',
    maxHeight: '60px',
    overflow: 'auto',
    margin: 0,
    cursor: 'pointer',
    "& > li": {
      margin: 0,
      background: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      overflow: 'hidden', 
      whiteSpace: 'nowrap',
      textOverflow:'ellipsis'
    },
    "& > li:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
      
    }
  },
  suggestionItem: {
    listStyleType: 'none',
  }
}));

const PlacesAutocomplete = ({ extraField, setExtraField }) => {
  const classes = useStyles();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    defaultValue: extraField.address,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);

    // Ante un cambio en el input cargo la coordenada 0,0 para obtener el error hasta que se valide la dirección.
    setExtraField({ ...extraField, coordinates: "0,0" });
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setExtraField({
          ...extraField,
          address: description,
          coordinates: `${lat},${lng}`,
        });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)} className={classes.suggestionItem}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <TextField
        error={extraField.coordinates === "0,0"}
        fullWidth
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Dirección"
        className={classes.input}
        helperText={
          extraField.coordinates === "0,0" && "El campo dirección es requerido"
        }
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <Box mt={1} p={1}  className={classes.suggestionsContainer}>
          <ul className={classes.suggestionsList}>{renderSuggestions()}</ul>
        </Box>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
