import { makeStyles } from '@material-ui/core';
import { Grid, Button, } from '@material-ui/core';
import TopNav from '../common/TopNav';
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import DateContent from './DateContent';

const useStyles = makeStyles(theme => ({

    btnNav: {
        margin: theme.spacing(2, 1),
        '& .MuiButton-label': {
            textTransform: 'none',
            // fontSize: 11,
            fontWeight: 700,
            color: theme.palette.primary.main,
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.common.white,
        },
        containerMain: {
            position: "fixed"
        }
    },
}))

const Nav = ({ setShowModal, showModal, data }) => {
    const classes = useStyles();
    return (

        <TopNav className={classes.containerMain} titulo={"Calculador"} subtitulo={"| Simple"} >
            <Grid container justifyContent='flex-end' alignItems='center'>

                <DateContent data={data} />
                <Button
                    className={classes.btnNav}
                    type='button'
                    onClick={() => setShowModal(true)}
                    size='small'
                    startIcon={showModal ? <ClearIcon /> : <TuneIcon />}
                >
                    | Filtrar
                </Button>
            </Grid>
        </TopNav>


    )
}
export default Nav


