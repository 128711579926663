import React, { useState, } from 'react';
import { Badge, Box, Button, Grid, makeStyles, Paper } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { truncateWithEllipsis } from '../../../helpers/common';

const useStyles = makeStyles(theme => ({
  card: {
    margin: 0,
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    width: '100%',
    '@media (max-width: 700px)': {
      padding: theme.spacing(0),
    },
  },
  titleContainer: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 1),
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 15,
    borderRadius: theme.spacing(0.5),
  },
  title: {
    fontWeight: 600,
    marginLeft: theme.spacing(2),
  },
  eventNumber: {
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(0.5),
  },
  eventHeader: {
    color: 'white',
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    fontSize: 14,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  eventBody: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  eventTime: {
    paddingLeft: theme.spacing(1),
  },
  eventTitle: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  eventDescription: {
    fontSize: 12,
  },
  singleCardContainer: {
    "@media (max-width: 700px)": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    }
  },
  singleCard: {
    minHeight: '100%'
  }
}));

function EventsCard({ title, backPage, pageSizeBack }) {
  const classes = useStyles();
  const TABLEpAGE = 12;
  const [visibleItems, setVisibleItems] = useState(TABLEpAGE);
  const [lastPage, setLastPage] = useState(false);

  const handleShowMore = () => {
    //Si no puedo mostrar una nueva página entera muestro lo que pueda
    if (backPage.length <= visibleItems + TABLEpAGE) {
      setVisibleItems(visibleItems => backPage.length);
      setLastPage(true);
    }
    //Muestro una página entera
    else {
      setVisibleItems(visibleItems => visibleItems + TABLEpAGE);
    }
  };

  return (
    <Box justifyContent='center' className={classes.card}>
      <Box className={classes.titleContainer}>
        <Badge overlap="rectangular" badgeContent={`${backPage.length}`} color='secondary' max={pageSizeBack}>
          <NotificationsActiveIcon />
        </Badge>
        <span className={classes.title}>{title}</span>
      </Box>
      <Grid container spacing={2}>
        {backPage.slice(0, visibleItems).map((ev,index) => {
          const { event, date_formated } = ev;
          const {  from_formated, to_formated, title, description, duration_str, meter_name, meter, event_detail } = event;

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.singleCardContainer}>
              <Paper className={classes.singleCard}>
                <div className={classes.eventHeader}>
                  <div className={classes.eventTitle}>{`${title} ${event_detail ? 'de ' + event_detail : ""}`}</div>
                  <div className={classes.eventDate}>{truncateWithEllipsis(meter_name || meter, 30)}</div>
                  <div className={classes.eventDate}> {date_formated}</div>
                </div>
                <div className={classes.eventBody}>
                  <div className={classes.eventTime}>Inicio {from_formated}</div>
                  {!description && <div className={classes.eventTime}>{to_formated}</div>}
                  {description && <div className={classes.eventTime}>{description}</div>}
                  {duration_str ? <div className={classes.eventTime}> Duración: {duration_str}</div> : null}
                </div>

              </Paper>
            </Grid>
          );
        })}
      </Grid>

      <Box align='center' mt={2}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleShowMore}
          disabled={lastPage || backPage.length < TABLEpAGE}
        >
          Mostrar mas...
        </Button>
      </Box>
    </Box>
  );
}
export default EventsCard;
