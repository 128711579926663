import { makeStyles } from '@material-ui/core';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    containerRoot: {
        marginLeft: "65px",
        "@media (max-width: 920px)": {
            marginLeft: 0,
        },
        "@media (max-width: 700px)": {
            marginLeft: "0px",
            paddingLeft: "0px",
        }
    }
}))

const Container = ({ children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.containerRoot}>
            {children}
        </Box>
    )
}
export default Container 