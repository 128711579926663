import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TimerIcon from '@material-ui/icons/Timer';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

export const TYPE_OPTIONS = [
  { label: 'Tabla', value: 'TABLE' },
  // { label: 'Dashboard', value: 'DASHBOARD' },
];

export const FORMAT_OPTIONS = [
  { label: 'CSV', value: 'CSV' },
  { label: 'XLSX', value: 'XLSX' },
  // { label: 'PDF', value: 'pdf' },
];

export const VARIABLE_OPTIONS = [
  { label: 'Tensión', value: 'TENSION' },
  { label: 'Corriente', value: 'CORRIENTE' },
  { label: 'Potencia activa total', value: 'POTENCIA_ACTIVA_TOTAL' },
  { label: 'Potencia activa fase', value: 'POTENCIA_ACTIVA_FASE' },
  { label: 'Potencia reactiva total', value: 'POTENCIA_REACTIVA_TOTAL' },
  { label: 'Potencia reactiva fase', value: 'POTENCIA_REACTIVA_FASE' },
  {
    label: 'Potencia activa y reactiva total',
    value: 'POTENCIA_ACTIVA_REACTIVA_TOTAL',
  },
  { label: 'Energía por hora', value: 'ENERGIA_X_HORA' },
  { label: 'Potencia adquirida total', value: 'POTENCIA_ADQUIRIDA' },
  // { label: 'Energía por dia', value: 'ENERGIA_X_DIA' },
  // { label: 'Energía por mes', value: 'ENERGIA_X_MES' },
  // { label: 'Energía acumulada', value: 'ENERGIA_ACUMULADA' },
];


export const FREQUENCY_OPTIONS = [
  { label: 'Diario', value: 'DAILY' },
  { label: 'Semanal', value: 'WEEKLY' },
  { label: 'Mensual', value: 'MONTHLY' },
  { label: 'Anual', value: 'YEARLY' },
  { label: 'Personalizado', value: 'CUSTOM' },
];

export const REPORT_STATUS_MESSAGES = {
  OK: {
    message: 'El último reporte se pudo generar correctamente.',
    icon: <CheckIcon />,
    color: '#3EA055',
  },
  IN_PROCESS: {
    message:
      'En este momento el informe está siendo generado, por favor aguarde unos instantes.',
    icon: <HourglassFullIcon />,
    color: '#FFAA33',
  },
  REPORT_ERROR: {
    message: 'Error al generar el reporte',
    // TODO Pendiente de modificar cuando en last_report_data tengamos esa info aun en caso de falla
    // message:
    // 'Error al intentar generar el informe. Último intento de generación: hh:mm:ss - dd/mm/aaaa',
    icon: <ErrorIcon />,
    color: '#E55451',
  },
  PENDING_PROCESS: {
    message: 'Reporte pendiente de procesamiento.',
    icon: <MoreHorizIcon />,
    color: '#FFAA33',
  },
};

export const SEND_STATUS_MESSAGES = {
  SEND_OK: {
    message: 'Reporte automático enviado correctamente.',
    icon: <CheckIcon />,
    color: '#3EA055',
  },
  SEND_PENDING: {
    message: 'Reporte automático pendiente de envío',
    icon: <TimelapseIcon />,
    color: '#FFAA33',
  },
  SEND_ERROR: {
    message: 'Error al intentar enviar el informe.',
    icon: <ErrorIcon />,
    color: '#E55451',
  },
  PENDING_PROCESS: {
    message: 'Reporte pendiente de procesamiento.',
    icon: <TimerIcon />,
    color: '#FFAA33',
  },
};

export const DEFAULT_FILTER_OPTIONS = {
  id: null,
  report_name: 'Reporte ',
  type: 'TABLE',
  format: 'CSV',
  devices: [],
  variable: [],
  frequency_date: '',
  chosen_range: '',
  report_automatic: false,
  reportAutomatic: null,
  emails: [],
  frequency: '',
  frequency_custom: 1,
  dateFrom: null,
  tabValue: 0
};

export const WELCOME_MESSAGE =
  'Bienvenido a Informes. En esta sección podrá generar nuevos reportes y modificar los existentes.';
export const NO_REPORTS_MESSAGE =
  "No posee ningún reporte puede crear uno a continuación haciendo click en el botón '+ Nuevo reporte'.";
