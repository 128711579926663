import React, { useState } from 'react';

//Material ui
import {
	Collapse, IconButton, Box,
	List, ListItem, ListItemSecondaryAction,
	ListItemText, makeStyles, Tooltip
} from '@material-ui/core';

//Icons
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SpeedIcon from '@material-ui/icons/Speed';
import VisibilityIcon from '@material-ui/icons/Visibility';

//Style
import clsx from 'clsx';

let locationMobileStyles = {
	height: "50px",
	padding: "10px",
	marginBottom: "1px"
}
const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
		"@media (max-width: 700px)": {
			position: "relative",
			left: "-32px",
			width: 'calc(100% + 32px)',
		},
	},
	selected: {
		'&.MuiListItem-root.Mui-selected': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.common.white,
		},

		'&.MuiListItem-root.Mui-selected p': {
			color: theme.palette.common.white,
		},
	},
	nodeHeader: {
		display: 'flex',
		'& span': {
			fontSize: "larger",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
		},
		'& p': {
			marginLeft: '5px',
			fontSize: "larger"
		},
		"@media (max-width: 700px)": {
			flexDirection: " column",
			'& span': {
				marginLeft: '5px',
				fontSize: "small",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
			},
			'& p': {
				marginLeft: '5px',
				fontSize: "small",
			},
		},

	},
	iconButton: {
		"@media (max-width: 700px)": {
			position: "relative",
			left: "-36px",
		}
	},
	empresa: {
		"@media (max-width: 700px)": {
			// backgroundColor: theme.palette.primary.main,
			...locationMobileStyles
		},
	},
	zona: {
		"@media (max-width: 700px)": {
			// background: "#c1c1c1",
			marginLeft: theme.spacing(1),
			...locationMobileStyles
		}
	},
	localizacion: {
		"@media (max-width: 700px)": {
			// background: "#e0e0e0",
			marginLeft: theme.spacing(2),
			...locationMobileStyles
		}
	},
	sublocaclizacion: {
		"@media (max-width: 700px)": {
			// background: "#f1efef",
			marginLeft: theme.spacing(3),
			...locationMobileStyles
		}
	},
	wordCut: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	iconMedidor: {
		"@media (max-width: 700px)": {
			marginRight: theme.spacing(6)
		}
	}
	// meterWithoutChild: {
	// 	marginRight: 24,
	// 	"@media (max-width: 700px)": {
	// 		marginRight: 0
	// 	},
	// 	"@media (max-width: 415px)": {
	// 		display: "none"
	// 	}
	// },
}));

export default function Node({ node, selectedNode, handleSelectedNode }) {
	const classes = useStyles();
	const [open, setOpen] = useState(true);

	const handleArrows = () => {
		setOpen(!open);
	};

	const classNodeSelect = (node) => {
		if (node.level_nombre === "empresa") {
			return classes.empresa
		}
		else if (node.level_nombre === "zona") {
			return classes.zona
		}
		else if (node.level_nombre === "localización") {
			return classes.localizacion
		}
		else if (node.level_nombre === "sublocalización") {
			return classes.sublocaclizacion
		}
		else {
			return ""
		}
	}

	return (
		<>
			<ListItem
				button
				selected={selectedNode ? selectedNode.id === node.id : false}
				className={clsx(classes.selected, classNodeSelect(node))}
				onClick={() => handleSelectedNode(node)}
				disabled={!node.valid}
			>
				{!node.owner && (
					<Tooltip title={`El nodo ${node.nombre} es compartido`}
					>
						<IconButton edge='end' aria-label='nodo compartido' size='small' color="primary">
							<VisibilityIcon />
						</IconButton>
					</Tooltip>
				)}

				<ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={clsx(classes.nodeHeader,)}
				/>

				{/* ICON CONTRACT */}
				<Box width={30} >

					{/* {node.level_nombre === 'localización' &&
						<Tooltip
							title={node?.valid_contract !== true
								?
								`El nodo no tiene contrato valido al dia de hoy`
								:
								`El nodo si tiene contrato valido al dia de hoy`
							}>
							<IconButton	>
								<RequestPageOutlinedIcon />
							</IconButton>
						</Tooltip>
					} */}

				</Box>

				{/* ICON MEDIDOR FISICO */}
				<Box width={30}
					className={clsx(classes.iconMedidor)}>
					{node.medidor &&
						<Tooltip title={`${node.nombre} tiene un medidor asociado`}>
							<IconButton	>
								<SpeedIcon />
							</IconButton>
						</Tooltip>
					}
				</Box>


				{/* ICON OPEN CLOSE */}
				<ListItemSecondaryAction >
					<Box width={30}>
						{node.hijos &&
							node.hijos.length > 0 &&
							(open ? (
								<IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
									<ExpandLess />
								</IconButton>

							) : (

								<IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
									<ExpandMore />
								</IconButton>
							))}
					</Box>

				</ListItemSecondaryAction>

				{/* <ListItemSecondaryAction >
					{node.medidor ? (
						<Tooltip title={`${node.nombre} tiene un medidor asociado`}>
							<IconButton
								classes={!node.hijos.length ? { root: classes.meterWithoutChild } : { root: classes.meterWithoutChild }}
								className={classes.iconButton}
								edge='end' aria-label='medidores asociados'>
								<SpeedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					{node.hijos &&
						node.hijos.length > 0 &&
						(open ? (
							<IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
								<ExpandLess />
							</IconButton>
						) : (
							<IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
								<ExpandMore />
							</IconButton>
						))}
				</ListItemSecondaryAction> */}

			</ListItem>

			{node.hijos ? (
				<Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
					<List dense component='div' disablePadding>
						{node.hijos.map(c => (
							<Node node={c} key={c.id} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} />
						))}
					</List>
				</Collapse>
			) : null}
		</>
	);
}
