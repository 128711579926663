import { Box, Button, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { truncateWithEllipsis } from '../../helpers/common';

const useStyles = makeStyles(theme => ({
	scheduleTitle: {
		fontSize: '13px',
		color: theme.palette.grey.main,
		backgroundColor: theme.palette.grey.dark,
		padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
		borderRadius: theme.spacing(1),
		textAlign: 'center',
	},
	rowContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// border: `1px solid ${theme.palette.grey.dark}`,
		borderRadius: theme.spacing(0.5),
		height: theme.spacing(3),
	},
	dayContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: theme.spacing(4),
	},
	dayName: {
		fontSize: 13,
		color: theme.palette.primary.main,
		textAlign: 'center',
	},
}));

export default function UsersList({ shared }) {
	const classes = useStyles();
	let history = useHistory();

	// console.log(shared)
	return (
		<>
			{
				shared.length > 0 &&
				<>
					<Grid container spacing={1} className={classes.rowContainer}>
						<Grid item xs={5}>
							<div className={classes.scheduleTitle}>Mail</div>
						</Grid>
						<Grid item xs={5}>
							<div className={classes.scheduleTitle}>Estado</div>
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>

					{shared.map((user, i) => {
						return (
							<Grid container spacing={1} className={classes.dayContainer} key={i}>
								<Grid item xs={5}>

									<div className={classes.dayName}>{truncateWithEllipsis(user.email, 20)}</div>
								</Grid>
								<Grid item xs={5}>
									<div className={classes.dayName}>{user.status}</div>
								</Grid>
								<Grid item xs={1}>
									<Tooltip
										title={`Editar Sub-Cuenta`}
									>
										<IconButton aria-label='edit' color='primary' onClick={() => {
											history.push(`/configuracion-subcuentas`);
										}}>
											<EditIcon />
										</IconButton>
									</Tooltip>

								</Grid>

							</Grid>
						)
					})}
				</>
			}
			{
				shared.length === 0 && <Box>
					<p>El nodo seleccionado no posee invitaciones</p>
					<Button aria-label='edit' color='primary' variant="outlined" onClick={() => {
						history.push(`/configuracion-subcuentas`);
					}}>
						Ir a Configuración Sub-Cuentas
					</Button></Box>
			}
		</>

	);
}
