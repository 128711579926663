import { useState, useEffect } from 'react';
//Material ui
import {
    IconButton, Box, makeStyles,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, FormControl,
    Select, MenuItem, Tooltip
} from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
} from "@material-ui/icons";
import AddBoxIcon from '@mui/icons-material/AddBox';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
//default arrow
import ImportExportIcon from '@mui/icons-material/ImportExport';
//down arrow / up arrow
import StraightIcon from '@mui/icons-material/Straight';
//Style 
import clsx from 'clsx';
//Helpers
import { getFormattedNumber, getUnit, toFormattedNumber } from '../../helpers/common';
import { downloadCsvSubBillResumenGeneral, downloadXlsxSubBillResumenGeneral } from '../../services/billing';

const useStyles = makeStyles(theme => ({
    borderSolidBlue: {
        borderRight: "1px solid #2586bc",
        borderBottom: "1px solid #2586bc"
    },
    headerTable: {
        BorderStyle: "none",
        backgroundColor: theme.palette.primary.light,
    },
    table: {
        cursor: "default"
    },
    colorHeaderText: {
        position: "relative",
        color: "white",
        "@media (max-width: 700px)": {
            fontSize: "13px",
        }
    },
    boldText: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        "@media (max-width: 700px)": {
            fontSize: "10px",
        }
    },
    columnProperties: {
        backgroundColor: "#e0e0e0",
    },
    normalText: {
        color: theme.palette.primary.main,
        "@media (max-width: 700px)": {
            fontSize: "10px",
        }
    },
    white: {
        backgroundColor: "white",
    },
    barWhite: {
        position: "absolute",
        width: "20px",
        height: "18px",
        left: "0",
        top: "7px",
        borderLeft: "1px solid white",
    },
    fontSizeXl: {
        color: theme.palette.primary.main,
        fontSize: "14px",
    },
    buttonAddHeader: {
        '& .MuiIconButton-root': {
            padding: "0",
            marginLeft: "4px"
        },
    },
    selectHeader: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "transparent"
        },
    },
    iconFlex: {
        '& .MuiButtonBase-root ': {
            display: " flex",
            justifyContent: "space-between"
        },
    },
    buttonPrint: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: "10px",
        "@media print": {
            display: 'none'
        }
    },
}))

const TableData = ({
    selectPeriod, setBillPanel, billPanel
}) => {
    const classes = useStyles();
    //Open collapse 
    const [openFinalUser, setOpenFinalUser] = useState(true)
    const [openCommonAreas, setOpenCommonAreas] = useState(true)
    //Array de usuarios finales.
    const [arryFinalUsers, setArryFinalUsers] = useState([])
    //backUp 
    const [backUpArryFinalUser, setBackUpArryFinalUser] = useState([])
    //Array de usuarios areas comunes.
    const [arryCommonArea, setArryCommonArea] = useState([])
    //estado de el ordenador MAYOR/MENOR
    const [stateOrder, setStateOrder] = useState('')
    //switch bandera para identificar cuando elimine una columna
    const [switchCero, setSwitchCero] = useState(false)
    //Lista de opcuiones de descarga
    const [showOptionsDownload, setShowOptionsDownload] = useState(false);
    //estado de los header
    const [headers, setHeaders] = useState({
        one: "",
        two: "",
        tree: "",
    })
    const [headerRow, setHeaderRow] = useState([
        {
            title: "Unidad funcional"
        },
        {
            title: "Tarifa"
        },
        {
            title: "Header"
        },
        {
            title: "Header"
        }
    ])

    const VAR_SELECT_TABLE = {
        TOTAL_COST: "Costo total",
        TOTAL_ENERGY: "Energia consumida total",
        ENERGY_COST: "Costo de energia",
        HIRED_DEMAND_COST: "Costo potencia. contratada",
        PEAK_DEMAND_COST: "Costo potencia. adquirida",
        HIRED_DEMAND: "Potencia contratada maxima",
        PEAK_DEMAND: "Potencia adquirida maxima",
    }


    const TOOLTIP_TEXT = {
        MINOR_BUTTON: "Menor a mayor.",
        MAJOR_BUTTON: "Mayor a menor.",
        STANDAR: "Ordene sus filas de mayor a menor.",
        REFRESH_BUTTON: "Resetear lista a su estado original.",
        ADD_COLUMN: "Agregue una colmna.",
        SUBTRACT_COLUMN: "Elimine una colmna."
    }

    const optionSelectHeader = Object.entries(VAR_SELECT_TABLE).map(([clave, valor]) => valor)

    useEffect(() => {
        //Cargo en el estado los Usuarios Finales.
        let arryFinalUsersAux = selectPeriod?.subdivision?.filter((e) => !e.area_comun) ?? []
        setBackUpArryFinalUser([...arryFinalUsersAux])
        setArryFinalUsers(arryFinalUsersAux)
        let arryCommonAreaAux = selectPeriod?.subdivision?.filter((e) => e.area_comun) ?? []
        //Cargo en el estado las Areas Comunes.
        setArryCommonArea(arryCommonAreaAux)
        // eslint-disable-next-line
    }, [])

    //funcion q añade un header
    const addHeader = () => {
        setSwitchCero(true)
        if (headerRow.length < 5) {
            setHeaderRow([
                ...headerRow,
                {
                    title: "Header"
                }
            ])
            setHeaders({
                ...headers,
                tree: ""
            })
        }
    }

    //funcion q resta el ultimo header
    const subtractHeader = () => {
        setHeaderRow(headerRow.slice(0, headerRow.length - 1))
    }

    //Funcion para abrir collapse de usuarios finales
    const openFinalUserCollapse = () => {
        setOpenFinalUser(!openFinalUser)
    }

    //Funcion para abrir collapse de Areas comunes
    const openCommonAreasCollapse = () => {
        setOpenCommonAreas(!openCommonAreas)
    }

    //Funcion para modificar los valores del select de los header!
    const valueSelect = (i) => {
        if (i === 2) {
            return headers.one
        }
        if (i === 3) {
            return headers.two
        }
        if (i === 4) {
            return headers.tree
        }
    }

    const wachBill = (elem) => {
        setBillPanel({
            bill: elem,
            open: true
        })
    }

    const getMaxValue = (array) => {
        if (array.length === 0) {
            return null; // Retorna null si el array está vacío
        }
        return array.reduce(function (anterior, actual) {
            return (actual.value > anterior.value) ? actual : anterior;
        }, array[0]);
    }

    const headerValue = (elem, i, position) => {
        if (headerRow.length === 5 && position === "tree" && switchCero) {
            return "";
        }
        else {
            switch (headers[position]) {
                case VAR_SELECT_TABLE.TOTAL_COST:
                    return "$ " + toFormattedNumber(elem?.factura.total);
                case VAR_SELECT_TABLE.ENERGY_COST:
                    return "$ " + toFormattedNumber(elem?.factura?.energy_cost?.total);
                case VAR_SELECT_TABLE.HIRED_DEMAND_COST:
                    return "$ " + toFormattedNumber(elem?.factura?.hired_demand_cost?.total);
                case VAR_SELECT_TABLE.PEAK_DEMAND_COST:
                    return "$ " + toFormattedNumber(elem?.factura?.peak_demand_cost?.total);
                case VAR_SELECT_TABLE.TOTAL_ENERGY:
                    let energy = elem?.factura?.energy_use?.total
                    return getFormattedNumber(energy) + getUnit(energy) + "wh";
                case VAR_SELECT_TABLE.PEAK_DEMAND:
                    let value = getMaxValue(elem?.factura?.peak_demand?.slots);
                    return getFormattedNumber(value.value) + getUnit(value.value) + "w";
                case VAR_SELECT_TABLE.HIRED_DEMAND:
                    let valueaux = getMaxValue(elem?.factura?.hired_demand?.slots);
                    return getFormattedNumber(valueaux.value) + getUnit(valueaux.value) + "w";
                default:
                    if (position === "one") {
                        setHeaders({
                            ...headers,
                            one: VAR_SELECT_TABLE.TOTAL_COST,
                        })
                        return "$ " + toFormattedNumber(elem?.factura?.energy_cost?.total);
                    }
                    else if (position === "two") {
                        setHeaders({
                            ...headers,
                            two: VAR_SELECT_TABLE.TOTAL_ENERGY,
                        })
                        return "$ " + toFormattedNumber(elem?.factura.total);
                    }
                    else {
                        return "";
                    }
            }
        }
    };

    const handleSelectHeader = (e, i) => {
        setSwitchCero(false)
        if (i === 2) {
            setHeaders({
                ...headers,
                one: e.target.value
            })
        }
        if (i === 3) {
            setHeaders({
                ...headers,
                two: e.target.value
            })
        }
        if (i === 4) {
            setHeaders({
                ...headers,
                tree: e.target.value
            })
        }
    }

    //Funcion q ordena el array segun lo seleccionado por el usuario de maor a menor.
    const returnKey = (value, signo) => {
        let aux = []
        if (value === VAR_SELECT_TABLE.TOTAL_COST) {
            if (signo === '+') {
                aux = arryFinalUsers.sort((a, b) => b.factura.total - a.factura.total)
            } else {
                aux = arryFinalUsers.sort((a, b) => a.factura.total - b.factura.total)
            }
        }
        if (value === VAR_SELECT_TABLE.ENERGY_COST) {
            if (signo === '+') {
                aux = arryFinalUsers.sort((a, b) => b.factura?.energy_cost?.total - a.factura?.energy_cost?.total)
            } else {
                aux = arryFinalUsers.sort((a, b) => a.factura?.energy_cost?.total - b.factura?.energy_cost?.total)
            }
        }
        if (value === VAR_SELECT_TABLE.HIRED_DEMAND_COST) {
            if (signo === '+') {
                aux = arryFinalUsers.sort((a, b) => b.factura?.hired_demand_cost?.total - a.factura?.hired_demand_cost?.total)
            } else {
                aux = arryFinalUsers.sort((a, b) => a.factura?.hired_demand_cost?.total - b.factura?.hired_demand_cost?.total)
            }
        }
        if (value === VAR_SELECT_TABLE.PEAK_DEMAND_COST) {
            if (signo === '+') {
                aux = arryFinalUsers.sort((a, b) => b.factura?.peak_demand_cost?.total - a.factura?.peak_demand_cost?.total)
            } else {
                aux = arryFinalUsers.sort((a, b) => a.factura?.peak_demand_cost?.total - b.factura?.peak_demand_cost?.total)
            }
        }
        if (value === VAR_SELECT_TABLE.TOTAL_ENERGY) {
            if (signo === '+') {

                aux = arryFinalUsers.sort((a, b) => b.factura?.energy_use?.total - a.factura?.energy_use?.total)
            } else {
                aux = arryFinalUsers.sort((a, b) => a.factura?.energy_use?.total - b.factura?.energy_use?.total)
            }
        }
        if (value === VAR_SELECT_TABLE.HIRED_DEMAND) {
            if (signo === '+') {
                aux = arryFinalUsers.sort((a, b) => getMaxValue(b.factura?.hired_demand?.slots)?.value - getMaxValue(a.factura?.hired_demand?.slots)?.value)
            } else {
                aux = arryFinalUsers.sort((a, b) => getMaxValue(a.factura?.hired_demand?.slots)?.value - getMaxValue(b.factura?.hired_demand?.slots)?.value)
            }
        }
        if (value === VAR_SELECT_TABLE.PEAK_DEMAND) {
            if (signo === '+') {
                aux = arryFinalUsers.sort((a, b) => getMaxValue(b.factura?.peak_demand?.slots)?.value - getMaxValue(a.factura?.peak_demand?.slots)?.value)
            } else {
                aux = arryFinalUsers.sort((a, b) => getMaxValue(a.factura?.peak_demand?.slots)?.value - getMaxValue(b.factura?.peak_demand?.slots)?.value)
            }
        }
        setArryFinalUsers([...aux])
    }

    //Funcionh para identificar la colimna a filtrar.
    const orderList = (i) => {
        let signo = ""
        if (stateOrder === '') {
            setStateOrder('+')
            signo = '+'
        }
        else if (stateOrder === '+') {
            setStateOrder('-')
            signo = '-'
        }
        else if (stateOrder === '-') {
            setStateOrder('')
            signo = ''
            setHeaders({
                ...headers,
                signo: '1'
            })
            setArryFinalUsers([...backUpArryFinalUser])
            return
        }

        setHeaders({
            ...headers,
            signo: i
        })
        if (i === 2) {
            returnKey(headers.one, signo)
        }
        if (i === 3) {
            returnKey(headers.two, signo)
        }
        if (i === 4) {
            if (headers.tree.length > 1) {
                returnKey(headers.tree, signo)
            }
        }
    }

    const xlsxDownload = async () => {
        try {
            let res = await downloadXlsxSubBillResumenGeneral(selectPeriod?.id)
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'archivo.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) {
        }
        finally {
            setShowOptionsDownload(false)
        }
    }

    const csvDownload = async () => {
        try {
            let res = await downloadCsvSubBillResumenGeneral(selectPeriod?.id)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download', 'archivo.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (error) {
        }
        finally {
            setShowOptionsDownload(false)
        }
    }

    const openOptions = () => {
        setShowOptionsDownload(!showOptionsDownload)
    }

    return (
        <Box>
            <Box className={classes.buttonPrint} >
                <IconButton
                    onClick={openOptions}
                >
                    <CloudDownloadIcon />
                </IconButton>
                {showOptionsDownload &&
                    <Box sx={{ width: '100px', position: 'absolute', bgcolor: '#ede7e7', marginRight: '60px', marginTop: '8px', zIndex: '1', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.5)' }}>
                        <List>
                            {/* <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText
                                    onClick={takePrint}
                                    primary="pdf" />
                            </ListItemButton>
                        </ListItem> */}
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="#simple-list">
                                    <ListItemText
                                        onClick={xlsxDownload}
                                        primary="xlsx" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="#simple-list">
                                    <ListItemText
                                        onClick={csvDownload}
                                        primary="csv" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                }
            </Box>

            {/* CONTAINER PRINCIPAL TABLA */}
            <TableContainer className={classes.table} component={Paper}>
                <Table size="small" aria-label="simple table">



                    {/* Header Table */}
                    <TableHead >
                        <TableRow className={classes.headerTable}>
                            {
                                headerRow.map((elem, i) => {
                                    if (elem.title === "Header") {
                                        return (
                                            <TableCell
                                                key={i}
                                                className={clsx(classes.colorHeaderText,)} align="center"
                                            >
                                                <div className={classes.barWhite}> </div>

                                                <Box style={{ display: "flex" }} >
                                                    <FormControl fullWidth >
                                                        <Select
                                                            className={clsx(classes.colorHeaderText, classes.selectHeader)}
                                                            // disabled={activeStep === 0 ? true : false}
                                                            value={valueSelect(i)}
                                                            labelId='period'
                                                            name='period'
                                                            defaultValue={"Seleccione un valor"}
                                                            displayEmpty
                                                            onChange={(e) => handleSelectHeader(e, i)}
                                                        >
                                                            <MenuItem value="" disabled style={{ color: "gray" }}>
                                                                Selecciona una opción
                                                            </MenuItem>

                                                            {optionSelectHeader.map((elem, i) => (
                                                                <MenuItem key={i} value={elem}>
                                                                    {elem}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {/* Nuevo icono para ordenar de mayor a menor. */}
                                                    <Box className={classes.buttonAddHeader}>
                                                        <IconButton
                                                            onClick={() => orderList(i)}                                                        >

                                                            {
                                                                headers?.signo === i
                                                                    ?
                                                                    (
                                                                        //flecha up.
                                                                        stateOrder === '+'
                                                                            ?
                                                                            <Tooltip
                                                                                title={TOOLTIP_TEXT.MAJOR_BUTTON}
                                                                                arrow
                                                                                placement="bottom"
                                                                            >
                                                                                <StraightIcon />
                                                                            </Tooltip>

                                                                            :
                                                                            //flecha down
                                                                            <Tooltip
                                                                                title={TOOLTIP_TEXT.MINOR_BUTTON}
                                                                                arrow
                                                                                placement="bottom"
                                                                            >
                                                                                <StraightIcon
                                                                                    style={{ transform: 'rotate(180deg)' }}
                                                                                />
                                                                            </Tooltip>
                                                                    )
                                                                    :
                                                                    <Tooltip
                                                                        title={TOOLTIP_TEXT.STANDAR}
                                                                        arrow
                                                                        placement="bottom"
                                                                    >
                                                                        <ImportExportIcon />
                                                                    </Tooltip>
                                                            }
                                                        </IconButton>
                                                    </Box>

                                                    {
                                                        i === headerRow.length - 1 &&

                                                        <Box className={classes.buttonAddHeader}>
                                                            {
                                                                headerRow.length < 5 &&
                                                                <Tooltip
                                                                    title={TOOLTIP_TEXT.ADD_COLUMN}
                                                                    arrow
                                                                    placement="bottom"
                                                                >
                                                                    <IconButton onClick={addHeader} >
                                                                        <AddBoxIcon />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            }
                                                            {
                                                                headerRow.length > 4 &&
                                                                <Tooltip
                                                                    title={TOOLTIP_TEXT.SUBTRACT_COLUMN}
                                                                    arrow
                                                                    placement="bottom"
                                                                >
                                                                    <IconButton onClick={subtractHeader}>
                                                                        < IndeterminateCheckBoxIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                        </Box>
                                                    }

                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                    // if (elem.title === "Unidad funcional") {
                                    //     return (
                                    //         <TableCell className={clsx(classes.colorHeaderText,)} align="center">
                                    //             <Box display='flex' alignItems={'center'} justifyContent={'space-between'}>
                                    //                 <Box>
                                    //                     {elem.title}
                                    //                 </Box>
                                    //                 <Box>
                                    //                     <IconButton onClick={refreshTable}>
                                    //                         <Tooltip
                                    //                             title={TOOLTIP_TEXT.REFRESH_BUTTON}
                                    //                             arrow
                                    //                             placement="bottom"
                                    //                         >
                                    //                             <RestartAltIcon />
                                    //                         </Tooltip>
                                    //                     </IconButton>
                                    //                 </Box>
                                    //             </Box>

                                    //         </TableCell>
                                    //     )
                                    // }
                                    return (
                                        <TableCell className={clsx(classes.colorHeaderText,)} align="center">{elem.title}
                                            {elem.title === "Tarifa" && <div className={classes.barWhite}> </div>}
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    {/* --- aca termina el TableHead --- */}

                    <TableBody>
                        {/* Usuarios Finales Row */}
                        <TableRow
                            className={classes.white}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell className={clsx(classes.columnProperties,)} component="th" scope="row">
                                <IconButton
                                    className={clsx(classes.fontSizeXl, classes.boldText)}
                                    aria-label="expand row"
                                    size="small"
                                    onClick={openFinalUserCollapse}
                                >
                                    {openFinalUser ? <KeyboardArrowUp /> : <KeyboardArrowDown />}

                                    {"Usuarios Finales"}

                                </IconButton>
                            </TableCell>

                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            {
                                headerRow.length === 5 &&
                                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            }
                        </TableRow>
                        {
                            openFinalUser &&

                            arryFinalUsers?.map((elem, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        className={classes.white}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell
                                            className={clsx(classes.columnProperties, classes.normalText)}
                                            component="th"
                                            scope="row"
                                        >
                                            {elem?.sublocation_name}

                                            {/* Boton para ver factura */}
                                            <Tooltip title={`Ver factura`}>
                                                <IconButton
                                                    style={{ marginLeft: "10px", float: "right" }}
                                                    size="small"
                                                    onClick={() => { wachBill(elem) }}
                                                >
                                                    {< RequestQuoteIcon />}
                                                </IconButton>
                                            </Tooltip>

                                        </TableCell>

                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{elem?.rate}</TableCell>
                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{headerValue(elem, i, "one")}</TableCell>
                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{headerValue(elem, i, "two")}</TableCell>
                                        {
                                            headerRow.length === 5 &&
                                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{headerValue(elem, i, "tree")}</TableCell>
                                        }
                                    </TableRow>
                                )
                            })
                        }

                        {/* Areas Comunes */}
                        <TableRow
                            className={classes.white}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell className={clsx(classes.columnProperties,)} component="th" scope="row">
                                <IconButton
                                    className={clsx(classes.fontSizeXl, classes.boldText)}
                                    aria-label="expand row"
                                    size="small"
                                    onClick={openCommonAreasCollapse}
                                >
                                    {openCommonAreas ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    {"Areas comunes"}
                                </IconButton>
                            </TableCell>

                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            {
                                headerRow.length === 5 &&
                                <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{"--"}</TableCell>
                            }
                        </TableRow>

                        {
                            openCommonAreas &&

                            arryCommonArea.map((elem, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        className={clsx(classes.white,)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className={clsx(classes.columnProperties, classes.normalText, classes.iconflex)} component="th" scope="row">
                                            {elem?.sublocation_name}
                                            {/* Boton para ver factura */}
                                            <Tooltip title={`Ver factura`}>
                                                <IconButton
                                                    style={{ marginLeft: "10px", float: "right" }}
                                                    size="small"
                                                    onClick={() => { wachBill(elem) }}
                                                >
                                                    {< RequestQuoteIcon />}
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>

                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{elem?.rate}</TableCell>
                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{headerValue(elem, i, "one")}</TableCell>
                                        <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{headerValue(elem, i, "two")}</TableCell>

                                        {
                                            headerRow.length === 5 &&
                                            <TableCell className={clsx(classes.borderSolidBlue, classes.normalText)} align="center">{headerValue(elem, i, "tree")}</TableCell>
                                        }

                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Box >
    )
}
export default TableData







