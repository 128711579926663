import React, { useEffect, useState } from "react";
//Material ui
import { Box, CircularProgress, makeStyles, IconButton } from '@material-ui/core';
//Icons
import BlockIcon from '@mui/icons-material/Block';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CloseIcon from '@mui/icons-material/Close';
//Zoom 
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '94%',
        overflow: 'hidden',
        cursor: 'pointer',
        '& .transform-component-module_wrapper__SPB86 ': {
            width: '100%',
            height: '100%',
        },
        '& .transform-component-module_content__FBWxo ': {
            // width: '100%',
            // height: '100%',
        },
    },
    image: {
        // flex: 1,
        // width: '100%',
        // objectFit: 'cover',
        // objectPosition: 'center',
    },
}));

const ContainerImgDashboardPersonalizado = ({
    img, edit, editFunctionImg, positionsAux,
    image_zoom, stateData, index, setStateDataComponentsImageZoom
}) => {

    const classes = useStyles();
    const [disabledState, setDisabledState] = useState(true);
    const [loading, setLoading] = useState(true);

    //Se encarga de deshabilitar la edición de la imagen si se deshabilita la edición del dashboard.    
    useEffect(() => {
        if (edit === false) {
            setDisabledState(true)
        }
    }, [edit])

    //Loading.
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    //Estado de el zoom.
    const handleChangeZoom = (stats) => {
        let state = stats?.state
        // const copyState = JSON.parse(JSON.stringify(stateData.components));
        let editedStateComponents = stateData.components.map((elem, indx) => {
            if (indx === index) {
                elem.image_zoom = state
                return elem
            } else {
                return elem
            }
        })
        //Este estado guarda la configuracion nueva de el zoom de cada componente 
        setStateDataComponentsImageZoom(editedStateComponents)
    }

    //Habilita la edicion de la imagen.
    const changeDisabledComponent = () => {
        setDisabledState(!disabledState)
        editFunctionImg(positionsAux, index, disabledState)
    }

    return (
        <Box
            style={!disabledState ? { border: `2px dashed ${'#7e7979'}` } : {}}
            className={classes.container}
        >
            {loading ?
                <Box display="flex" justifyContent="center" alignItems={'center'}>
                    <CircularProgress />
                </Box>
                :
                <TransformWrapper
                    disabled={disabledState}
                    minScale={0.4}
                    maxScale={7}
                    onTransformed={handleChangeZoom} //Me da en cualquier accion las posiciones                     
                    initialScale={image_zoom.scale}
                    initialPositionX={image_zoom.positionX}
                    initialPositionY={image_zoom.positionY}
                    centerZoomedOut={{ disabled: true }}
                // centerOnInit={{ disabled: true }}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                            {edit &&
                                <Box mt={1} display='flex' position={'absolute'} zIndex={3} >
                                    <Box style={{ gap: '2px' }} display='flex'>
                                        <Box ml={1} style={{ backgroundColor: 'white', borderRadius: '50%' }}>
                                            {disabledState ?
                                                <IconButton onClick={changeDisabledComponent} >
                                                    <ZoomOutMapIcon style={{ fontSize: '23px' }} />
                                                </IconButton>
                                                :
                                                <IconButton onClick={changeDisabledComponent} >
                                                    <BlockIcon style={{ fontSize: '23px' }} />
                                                </IconButton>
                                            }
                                        </Box>
                                        {!disabledState &&
                                            <>
                                                <Box onClick={zoomIn} style={{ backgroundColor: 'white', borderRadius: '50%' }}>
                                                    <IconButton onClick={() => zoomIn()} >
                                                        <ZoomInIcon style={{ fontSize: '23px' }} />
                                                    </IconButton>
                                                </Box>
                                                <Box style={{ backgroundColor: 'white', borderRadius: '50%' }}>
                                                    <IconButton onClick={() => zoomOut()}  >
                                                        <ZoomOutIcon style={{ fontSize: '23px' }} />
                                                    </IconButton>
                                                </Box>
                                                <Box style={{ backgroundColor: 'white', borderRadius: '50%' }}>
                                                    <IconButton onClick={() => resetTransform()} >
                                                        <CloseIcon style={{ fontSize: '23px' }} />
                                                    </IconButton>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                </Box>
                            }
                            <TransformComponent>
                                <img
                                    alt=''
                                    style={!disabledState ? { pointerEvents: 'none' } : {}}
                                    className={classes.image}
                                    src={img}
                                />
                            </TransformComponent>
                        </>
                    )}
                </TransformWrapper>
            }
        </Box>
    )
}
export default ContainerImgDashboardPersonalizado

// defaultScale={scale}
// defaultPositionX={positionX}
// defaultPositionY={positionY}
// disabled={disabledState}
// minScale={0.4}
// maxScale={7}
// onPanning={handleChangeZoom}
// onPanningStart={handleChangeZoom}
// onTransformed={handleChangeZoom} //Me da en cualquier accion las posiciones
// onZoom={handleChangeZoom}  //posiciones cuando el user hace zoom
// onInit={handleChangeZoom} //me da las posiciones cuando entra componente.
// panning={{ disabled: true, velocityDisabled: true }} //deshabilita el movimiento en la edicion.
// centerZoomedOut={{ disabled: true }}
// initialScale={image_zoom.scale}
// initialPositionX={image_zoom.positionX}
// initialPositionY={image_zoom.positionY}



