import { NavLink } from "react-router-dom";
import { makeStyles, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core';
import { Box, Dialog, Chip, } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
// Material UI
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
// Iconos
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
//Helpers
import DatePickerBill from '../DatePicker/DatePickerBill';
import BillFilterNode from './BillFilterNode';
import { blueBar } from '../../helpers/common'
//Endpoints
import { getNodesTreeContractValidate, getLocationsContractValidate } from '../../services/hierarchy';
//Servicios
import { getStatusNodes } from '../../services/meters';

let scrollsSettings = {
	overflow: "scroll",
	height: "90vh",
	overflowX: "hidden",
}
let webkitScrollbar = {
	background: "white",
	borderStyle: "solid",
	borderWidth: "1px",
	borderColor: "#beb1b18f",
	color: "red",
	width: "10px"
}
let webkitscrollbarTrack = {
	background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2, 4),
		'& .MuiGrid-root': {
			padding: theme.spacing(1),
		},
	},
	devicesLabel: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem',
		marginLeft: theme.spacing(2),
	},
	addBtn: {
		// margin: theme.spacing(1, 0),
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: theme.spacing(2),
		width: theme.spacing(2),
		// marginLeft: theme.spacing(1),
		marginLeft: theme.spacing(2),
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	circular: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(1),
	},
	cancelBtn: {
		height: theme.spacing(2),
		width: theme.spacing(2),
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.main,
	},
	btnSpacing: {
		margin: theme.spacing(0, 1),
	},
	containerSelectMainFactura: {
		paddingLeft: "7px"
	},
	hint: {
		backgroundColor: 'rgba(0, 0, 0, 0.38)',
		color: theme.palette.common.white,
		height: theme.spacing(3),
		width: theme.spacing(3),
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: 'rgb(54, 143, 193);',
		},
	},
	tick: {
		backgroundColor: theme.palette.success.light,
	},
	containerLocalizacionYNuevaFactura: {
		display: "flex",
		width: "100%"
	},
	containerPeriodSelect: {
		display: "flex",
		width: "600px",
		marginLeft: theme.spacing(3),
		"@media (max-width: 720px)": {
			marginTop: theme.spacing(2),
		}
	},
	selectNuevaFactura: {
		width: "100%"
	},
	selectFacturaExistente: {
		display: "flex",
		width: "600px",
	},
	containerSelect: {
		//son los estilos de las barras scroll en una variable.
		...scrollsSettings,
		'&::-webkit-scrollbar': {
			//son los estilos de las barras scroll en una variable.
			...webkitScrollbar
		},
		'&::-webkit-scrollbar-track': {
			//son los estilos de las barras scroll en una variable.
			...webkitscrollbarTrack
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.primary.main,
		},
		"@media (max-width: 700px)": {
			padding: theme.spacing(0, 0, 0, 1),
		}
	},
	filterChip: {
		fontSize: 15,
		fontWeight: "500",
		"@media (max-width: 700px)": {
			width: "250px",
		}
	},
	labelDate: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.main,
	},
	labelPeriod: {
		fontSize: 16,
		color: "red",

	},
	titleMarginTop: {
		marginTop: "5px",
		width: "230px"
	},
	circularDevicesBox: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: "40%",
	},
}));

function Filter({
	onSubmitFilter,
	handleCancelFilterDialog,
	setDisplayDisabled,
	bills,
	billData,
	location,
	setLocations,
	handleChangeLocalization,
	handleChangePeriod,
	newBill,
	setNewBill,
	handleBillData,
	setBillData,
	datePickerDisabled,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	displayDisabled,
	openModalTree,
	setOpenModalTree,
	loadingPeriod
}) {
	const classes = useStyles();
	const [nodes, setNodes] = useState([]);
	//loading para la caja de dispositivos.
	const [loadingDevices, setLoadingDevices] = useState(false);
	const blueLine = blueBar()
	const [activeStep, setActiveStep] = useState(0);
	const [statusNodes, setStatusNodes] = useState([]);
	let variation = !newBill ? "Consultar" : "Nuevo"
	const steps = [
		'Elija la localización de la cual desea consultar la factura',
		'Elija o cree Factura',
		'Seleccione las fechas a cargar o consultar',
		'Haga click en ' + variation
	];



	//Verifica status de el medidor.
	useEffect(() => {
		const getStatusNodesAux = async () => {
			let res = await getStatusNodes()
			let arry = res.data
			setStatusNodes(arry)
		}
		getStatusNodesAux()
	}, [])

	//OBTENGO TREE
	useEffect(() => {
		const fetchNodesTreeData = async () => {
			try {
				const res = await getNodesTreeContractValidate();
				setNodes(res.data);
				setLoadingDevices(true)
			}
			catch (error) {
				console.error(error);
			}
		};
		fetchNodesTreeData();
	}, [setLocations]);

	// //OBTENGO LOCALIZACIONES
	useEffect(() => {
		const fetchNodesTreeData = async () => {
			try {
				const res = await getLocationsContractValidate();
				//Adentro del forEach llamo a getLocations
				setLocations(res.data)
			}
			catch (error) {
				// console.error(error);
			}
		};
		fetchNodesTreeData();
		// setLoadingNodes(true);
	}, [setLocations]);
	//Funcion para cerrar el modal de los tree
	const handleCloseDevicesDialog = () => {
		setOpenModalTree(false);
	};
	// Función encargada de colocar mayúsculas en la primera letra de cada palabra
	const capitalizeFirstLetter = str => {
		const arr = str.toLowerCase().split(' ');
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		return arr.join(' ');
	};

	let buttonCheck = billData.location > 100 && billData.name.length > 2

	const nodeList = () => {
		return nodes.map(node => <BillFilterNode node={node} handleSelectedNode={handleChangeLocalization} key={node.id} statusNodes={statusNodes} />);
	};
	return (
		<>
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map(label => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<form className={classes.root} onSubmit={e => onSubmitFilter(e)}>
				<Box className={classes.tableContainer}>
					<Box className={classes.containerSelectMainFactura}>

						{blueLine}

						{/* STEP 1 */}
						<Box mt={2} display="flex" alignItems="center" >
							<Tooltip title='Elija la localización de la cual desea consultar la factura' arrow placement='right'>
								<Box className={clsx(classes.hint, { [classes.tick]: activeStep !== 0 })}>
									{activeStep > 0 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
								</Box>
							</Tooltip>
							<Box ml={2} className={classes.devicesLabel}>
								Selecciona una Localización
							</Box>
							<IconButton
								aria-label="agregar dispositivos"
								className={classes.addBtn}
								onClick={() => {
									setOpenModalTree(true)
								}}  >
								<AddIcon fontSize="small" />
							</IconButton>

							{location?.name &&
								<Box ml={2}>
									<Chip
										label={location?.name ? location?.name : "Elija una localización"}
										clickable
										color='primary'
										className={classes.filterChip}
										onClick={() => {
											setOpenModalTree(true);
										}}
									/>
								</Box>
							}

						</Box>
					</Box>

					<Grid className={clsx(classes.containerLocalizacionYNuevaFactura)}>
						<Dialog
							open={openModalTree}
							onClose={handleCloseDevicesDialog}
							aria-labelledby='form-dialog-title'
							// maxWidth='lg'
							fullWidth
							disableScrollLock
						>
							<Grid className={classes.containerSelect}>
								{
									!loadingDevices ?
										<Box className={classes.circularDevicesBox}>
											<CircularProgress />
										</Box>
										:
										<>
											{nodes.length === 0 &&
												<h1 style={{ textAlign: "center", fontSize: "19px", color: "#2586bc", paddingTop: "20px" }}>
													Usted no posee ninguna localización configurada en su cuenta.<br></br>
													Por favor, configure al menos una localización para poder continuar.
													<NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
														[link]
													</NavLink>
												</h1>
											}
											<List >
												<Box  >{nodeList()}</Box>
											</List>
										</>
								}
							</Grid>
						</Dialog>
						<Box className={classes.selectNuevaFactura}>

							{blueLine}

							{/* STEP 2 */}
							<Box mt={2} style={{ fontWeight: "400" }} display="flex" alignItems={"center"} >
								<Tooltip title='Elija un periodo de facturacion previamente guardado o cree uno nuevo' arrow placement='right'>
									<Box className={clsx(classes.hint, { [classes.tick]: activeStep !== 0 })}>
										{activeStep > 1 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
									</Box>
								</Tooltip>
								<Box ml={2} className={classes.devicesLabel}>
									Selecciona una factura existente o cree una
								</Box>
							</Box>

							<Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
								<Box mr={6} ml={3}>
									{billData.location !== 7 && !newBill &&
										<Box mt={3} className={classes.label}>
											Nueva Factura
											<IconButton
												aria-label='nueva Factura'
												className={classes.addBtn}
												onClick={() => {
													setActiveStep(2)
													setNewBill(true)
													// setDisplayDisabled(false)
													setEndDate(new Date())
													setStartDate(new Date())
												}}
											>
												<AddIcon fontSize='small' />
											</IconButton>
										</Box>
									}
									{newBill &&
										<Box mt={3} style={{ display: 'flex', alignItems: 'center' }}>
											<TextField
												autoFocus
												placeholder='Nombre'
												name='name'
												value={billData.name ? billData.name : ''}
												onChange={e => {
													handleBillData(e);
												}}
												color='primary'
											/>
											<IconButton
												aria-label='cancelar'
												className={classes.cancelBtn}
												onClick={() => {
													setActiveStep(1);
													setNewBill(false)
													// setDisplayDisabled(true)
												}}
											>
												<CancelIcon fontSize='small' />
											</IconButton>
										</Box>}
								</Box>
								<Box className={classes.selectFacturaExistente}>
									{loadingPeriod ?
										<>
											<Box className={classes.circular}>
												<CircularProgress size={20} />
											</Box>
										</>
										:
										<>
											{bills.length > 0 && !newBill &&
												<Box mt={3} className={classes.containerPeriodSelect}>
													<Box className={clsx(classes.label, classes.titleMarginTop)}>
														Factura Existente
													</Box>
													<FormControl fullWidth>
														<Select
															// disabled={activeStep === 0 ? true : false}
															labelId='period'
															name='period'
															defaultValue=''
															onChange={e => {
																setActiveStep(2);
																handleChangePeriod(e, false)
																setActiveStep(3)
															}}
														>
															{
																bills
																	? bills.map(bill => (
																		<MenuItem key={bill.id} value={bill.id}>
																			{capitalizeFirstLetter(bill.name)}
																		</MenuItem>
																	))
																	:
																	''
															}
														</Select>
													</FormControl>
												</Box>
											}

											{bills.length === 0 && Object.keys(location).length > 0 &&
												<Box mt={3} className={classes.labelPeriod}>
													No posee facturas!
												</Box>
											}
										</>
									}
								</Box>
							</Box>
						</Box>
					</Grid>

					{blueLine}

					{/* STEP 3 */}
					<Box ml={1} display="flex" alignItems={"center"}>
						<Tooltip title='Seleccione las fechas a consultar' arrow placement='right'>
							<Box className={clsx(classes.hint, { [classes.tick]: activeStep > 2 })}>
								{activeStep > 2 ? <DoneIcon style={{ padding: '2px' }} /> : 3}
							</Box>
						</Tooltip>
						<Box ml={2} className={classes.devicesLabel}>
							Seleccione las fechas a consultar
						</Box>
					</Box>

					{/* DATE PICKER */}
					<Box width={"100%"} textAlign={"center"} className={classes.wrapperDatepicker} >
						<label className={classes.labelDate} >
							Desde / Hasta
						</label>
						<DatePickerBill
							setActiveStep={setActiveStep}
							datePickerDisabled={datePickerDisabled}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							displayDisabled={displayDisabled}
							setBillData={setBillData}
							billData={billData}
						/>
					</Box>
				</Box >

				<Box display='flex' justifyContent='center'>

					<Button
						disabled={!buttonCheck}
						variant='contained' color='primary' type='submit' className={classes.btnSpacing}>
						{newBill ? 'Nuevo' : 'Consultar'}
					</Button>

					<Button variant='contained' onClick={handleCancelFilterDialog} className={classes.btnSpacing}>
						Cancelar
					</Button>

				</Box>
			</form >
		</>
	);
}

export default Filter;
