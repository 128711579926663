import { useEffect } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { Box, makeStyles } from '@material-ui/core';
import { getFormattedNumber, getUnit } from '../../../helpers/common';

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1),
    border: '1px solid #cbc1c157',
    boxShadow: '3px 3px 3px #cbc1c157',
  },
  title: {
    color: theme.palette.grey.hairline,
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(1.5, 0, 0.5, 2),
    fontWeight: 600,
    padding: theme.spacing(1, 0, 1, 0),
  },
  subtitle: {
    fontWeight: 400,
  },
  chart: {
    width: '90%',
    height: '100%',
  },
}));

export default function DashBar({ children, name, title, subtitle, data }) {
  const classes = useStyles();
  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    const chart = am4core.create(name, am4charts.XYChart);

    if (chart.logo) {
      chart.logo.disabled = true;
    }

    const formattedData = data[0]?.data.map(row => ({
      ...row,
      formattedKwh: `${getFormattedNumber(row.kwh)} ${getUnit(row.kwh)}Wh`,
    }));

    chart.data = formattedData;

    chart.colors.list = [am4core.color('#61afd2')];

    // chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'

    const dateAxis = chart.yAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.dataFields.category = 'fecha_';
    dateAxis.dateFormats.setKey('month', 'MMMM');

    dateAxis.renderer.minGridDistance = 5;
    dateAxis.renderer.labels.template.fill = am4core.color('#9e9e9e');
    dateAxis.renderer.labels.template.fontSize = 12;
    // Oculto grid
    dateAxis.renderer.grid.template.disabled = true;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = null;

    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0.1;
    valueAxis.renderer.labels.template.fill = am4core.color('#9e9e9e');
    valueAxis.renderer.labels.template.fontSize = 12;
    // Oculto grid
    valueAxis.renderer.grid.template.disabled = true;

    // Configuro unidad y multiplicador del eje X
    valueAxis.renderer.labels.template.adapter.add('text', (text, target) => {
      const value = Number(text) || 0;
      return `${getFormattedNumber(value / 1000)}${getUnit(value)}Wh`;
    });

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.dateY = 'fecha_';
    series1.dataFields.valueX = 'kwh';
    series1.columns.template.tooltipText =
      '{dateY.formatDate("MMMM")}: {formattedKwh}';
    series1.columns.template.strokeOpacity = 0;
    series1.stacked = true;
    series1.columns.template.fillOpacity = 0.7;

    // Add label
    let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{formattedKwh}";
    labelBullet.locationY = 0.5;
    labelBullet.locationX = 1;

    labelBullet.label.hideOversized = false; // Show the complete label even if it exceeds the available space
    labelBullet.label.dx = 50;
    labelBullet.label.truncate = false; // Prevent truncation of label text

    // labelBullet.label.fill = am4core.color("#9e9e9e");
    labelBullet.label.fontSize = 12;


    // Label adapter function to hide label when the value is zero
    labelBullet.label.adapter.add("visible", (visible, target) => {
      const value = target.dataItem?.valueX || 0;
      return value !== 0; // Return true to show the label if the value is not zero
    });

    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingRight = 30;
    chart.numberFormatter.numberFormat = '#.##';
    chart.language.locale = am4lang_es_ES;
    return () => {
      chart.dispose();
    };
  }, [data, name]);

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {title}
        <span className={classes.subtitle}> | {subtitle}</span>
      </Box>
      {children}
      {data.length > 0 && <Box id={name} className={classes.chart} />}
    </Box>
  );
}
