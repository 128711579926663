import { useEffect, useState } from 'react';
import {
  changeMeterName,
  cleanMeterData,
  deleteMeter,
  getOwnerMeters,
  trackStatusMeter,
} from '../../services/meters';
import TopNav from '../common/TopNav';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Node from './Node';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { ArrowBackIos } from '@material-ui/icons';
import Container from '../Elements/Container';
import AlertComponent from '../Elements/AlertComponent';
import { Alert } from '@mui/material';

let scrollsSettings = {
  overflow: 'scroll',
  height: '90vh',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: 'flex',
  },
  leftContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    //son los estilos de las barras scroll en una variable.
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 1280px)': {
      width: '100%',
    },
  },
  rightContainer: {
    // borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    //son los estilos de las barras scroll en una variable
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 23,
    fontWeight: 700,
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: theme.spacing(2),
  },
  input: {
    '& .MuiInputBase-input': {
      fontSize: 13,
      padding: theme.spacing(2, 0),
    },
  },
  form: {
    marginLeft: theme.spacing(2),
    '& .MuiInputBase-root': {
      color: '#666666',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
  },
  inputConfirmationDialog: {
    '& .MuiInputBase-input': {
      fontSize: 13,
      padding: theme.spacing(2, 0),
    },
    margin: '0px 25px 10px 25px',
  },
  buttonsConfirmationDialog: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  unselectedMeterText: {
    color: '#000000',
    fontSize: 20,
    textAlign: 'center',
    padding: 15,
    fontWeight: 500,
  },
  containerUnselectedMeterText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  deletionStatusMeter: {
    textAlign: 'center',
    margin: 4,
  },
  containerSearch: {
    margin: 15
  },
  containerNoData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  circle: {
    minWidth: '75px', // ajusta según tus necesidades
    height: '75px', // ajusta según tus necesidades
    borderRadius: '50%',
    background: 'linear-gradient(to bottom, #00bfff, #0077ff)'
  }

}));

export default function ControlPanelMeters() {
  const classes = useStyles();
  const [ownerMeters, setOwnerMeters] = useState({});
  const [filteredOwnerMeters, setFilteredOwnerMeters] = useState({});
  const [loading, setLoading] = useState(false);
  const { isMobile } = useDeviceDetect(1278);

  const [showLeftBlock, setShowLeftBlock] = useState(true);
  const [showRightBlock, setShowRightBlock] = useState(true);
  const [selectedNode, setSelectedNode] = useState(null);

  //Estados alert
  const [close, setClose] = useState(false);
  const [msg, setMsg] = useState({
    msg: '',
    type: 'success',
  });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingCleanData, setLoadingCleanData] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [valueInputConfirmationDelete, setValueInputConfirmationDelete] =
    useState('');
  const [isTrackerDialogOpen, setIsTrackerDialogOpen] = useState(false);
  const [deletionStatusMeter, setDeletionStatusMeter] = useState('');
  const [titleDialogTracker, setTitleDialogTracker] = useState('');
  const [actionSelected, setActionSelected] = useState('');
  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    getOwnerMetersData();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setShowRightBlock(false);
    } else {
      setShowLeftBlock(true);
      setShowRightBlock(true);
    }
  }, [isMobile]);

  const getOwnerMetersData = async () => {
    try {
      setLoading(true);
      setInputSearch("");
      setActionSelected("");
      setDeletionStatusMeter("");
      const res = await getOwnerMeters();
      res.data.map(meter => (meter.nombreGuardado = meter.nombre));
      setOwnerMeters(res.data);
      setFilteredOwnerMeters(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleSelectedNode = node => {
    setSelectedNode(node);
    if (isMobile) {
      setShowRightBlock(true);
      setShowLeftBlock(false);
    }
  };

  const handleBackPageClick = () => {
    setShowLeftBlock(true);
    setShowRightBlock(false);
    setSelectedNode(null);
  };

  const changeName = e => {
    setSelectedNode({
      ...selectedNode,
      nombre: e.target.value,
    });
  };

  const handleDelete = async () => {
    try {
      setTitleDialogTracker('Eliminando medidor');
      setIsConfirmationDialogOpen(false);
      setLoadingDelete(true);
      let res = await deleteMeter(selectedNode.id);
      saveTrackerId(res?.data?.tracker);
      setLoadingDelete(false);
    } catch (error) {
      if (error.response) {
        setClose(true);
        setMsg({
          msg: `${error.response.data.msg}.`,
          type: 'error',
        });
      } else {
        setClose(true);
        setMsg({
          msg: `${`Ocurrió un error inesperado`}.`,
          type: 'error',
        });
      }
      setLoadingDelete(false);
    }
  };

  const handleCleanData = async () => {
    try {
      setTitleDialogTracker('Limpiando datos del medidor');
      setIsConfirmationDialogOpen(false);
      setLoadingCleanData(true);
      let res = await cleanMeterData(selectedNode.id);
      saveTrackerId(res?.data?.tracked);
      setLoadingCleanData(false);
    } catch (error) {
      if (error.response) {
        setClose(true);
        setMsg({
          msg: `${error.response.data.msg}.`,
          type: 'error',
        });
      } else {
        setClose(true);
        setMsg({
          msg: `${`Ocurrió un error inesperado`}.`,
          type: 'error',
        });
      }
      setLoadingCleanData(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoadingSave(true);
      await changeMeterName(selectedNode.id, selectedNode.nombre);
      setClose(true);
      setMsg({
        msg: 'Cambio de nombre realizado correctamente',
        type: 'success',
      });
      setSelectedNode({
        ...selectedNode,
        nombreGuardado: selectedNode.nombre,
      });
      setLoadingSave(false);
      getOwnerMetersData();
    } catch (error) {
      if (error.response) {
        setClose(true);
        setMsg({
          msg: `${error.response.data.msg}.`,
          type: 'error',
        });
      } else {
        setClose(true);
        setMsg({
          msg: `${`Ocurrió un error inesperado`}.`,
          type: 'error',
        });
      }
      setLoadingSave(false);
    }
  };

  const handleConfirmationDialog = actionSelected => {
    setActionSelected(actionSelected);
    setValueInputConfirmationDelete('');
    setIsConfirmationDialogOpen(!isConfirmationDialogOpen);
  };

  const handleTrackerDialog = status => {
    if (status) {
      setDeletionStatusMeter(status);
    }
  };

  const saveTrackerId = async trackerId => {
    setIsTrackerDialogOpen(true);
    return await new Promise(resolve => {
      const interval = setInterval(async () => {
        let track = await trackStatusMeter(trackerId);
        let status = track?.data?.status;
        if (deletionStatusMeter !== status) {
          handleTrackerDialog(status);
        }
        if (status === 'finalizado') {
          resolve('foo');
          clearInterval(interval);
        }
      }, 10000);
    });
  };

  const handleTrackerDialogClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsTrackerDialogOpen(false);
      getOwnerMetersData();
    }
  };

  const handleInputSearch = (e) => {
    setInputSearch(e.target.value)
    let valueInputLowercase = e.target.value.toLowerCase().trim();
    let listFiltered = ownerMeters.filter(meter => {
      let nameMeterLowercase = meter.nombre.toLowerCase();
      let keyMeterLowercase = meter.llave.toLowerCase();
      if ((nameMeterLowercase.includes(valueInputLowercase) || keyMeterLowercase.includes(valueInputLowercase))) {
        return meter;
      }
      return null
    }
    )
    setFilteredOwnerMeters(listFiltered);
  };

  const validateIfIsMobile = () => {
    return !(isMobile && selectedNode);
  }

  return (
    <>
      <TopNav titulo={'Medidores'} />

      {/* ALERTAS */}
      <AlertComponent
        severity={msg.type}
        msg={msg.msg}
        setClose={setClose}
        close={close}
      />

      <Dialog
        open={isConfirmationDialogOpen}
        onClose={handleConfirmationDialog}
        aria-labelledby="form-dialog-title"
      >
        {actionSelected === 'delete_data' && (
          <Box>
            <DialogTitle id="form-dialog-title">{`¿Está seguro que desea eliminar el medidor "${selectedNode?.nombre}"?`}</DialogTitle>
            <DialogContent>
              En caso de confirmar, se perderá toda la información de este y no
              podrá recuperarla. Escriba a continuación el nombre del medidor
              para continuar.
            </DialogContent>
          </Box>
        )}
        {actionSelected === 'clean_data' && (
          <Box>
            <DialogTitle id="form-dialog-title">{`¿Está seguro que desea limpiar los datos del medidor "${selectedNode?.nombre}"?`}</DialogTitle>
            <DialogContent>
              En caso de confirmar, se perderá toda la información de este y no
              podrá recuperarla. Escriba a continuación el nombre del medidor
              para continuar.
            </DialogContent>
          </Box>
        )}
        <TextField
          placeholder="Nombre del medidor"
          name="nombre"
          color="primary"
          className={classes.inputConfirmationDialog}
          value={valueInputConfirmationDelete}
          onChange={e => setValueInputConfirmationDelete(e.target?.value)}
          onPaste={e => e.preventDefault()}
        />
        <DialogActions className={classes.buttonsConfirmationDialog}>
          <Button color="default" onClick={handleConfirmationDialog}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="outlined"
            onClick={() => {
              if (actionSelected === 'delete_data') {
                handleDelete();
              } else if (actionSelected === 'clean_data') {
                handleCleanData();
              }
            }}
            disabled={valueInputConfirmationDelete !== selectedNode?.nombre}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTrackerDialogOpen}
        onClose={handleTrackerDialogClose}
        aria-labelledby="dialog-status"
      >
        <DialogTitle>{titleDialogTracker}</DialogTitle>
        {deletionStatusMeter === 'finalizado' && (
          <DialogContent className={classes.deletionStatusMeter}>
            {actionSelected === 'delete_data' &&
              'El medidor se eliminó con exito.'}
            {actionSelected === 'clean_data' &&
              'Los datos del medidor se limpiaron correctamente.'}
          </DialogContent>
        )}
        {deletionStatusMeter === 'fallo' && (
          <DialogContent className={classes.deletionStatusMeter}>
            Ocurrió un error al eliminar el medidor.
          </DialogContent>
        )}
        {deletionStatusMeter !== 'fallo' &&
          deletionStatusMeter !== 'finalizado' && (
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <CircularProgress />
            </Box>
          )}
        <DialogActions className={classes.buttonsConfirmationDialog}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={deletionStatusMeter !== 'finalizado'}
            onClick={handleTrackerDialogClose}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}

        {(!loading && validateIfIsMobile()) && (
          <Box>
            <Alert severity="info">Solo podés gestionar tus propios medidores, los compartidos solo pueden ser gestionados por la cuenta administradora</Alert>
            <Box className={classes.containerSearch}>
              <Box component="label" display="block" className={classes.label}>
                Buscador
              </Box>
              <TextField
                fullWidth
                placeholder="Ingrese nombre de medidor o MAC"
                name="buscador"
                value={inputSearch}
                onChange={handleInputSearch}
                color="primary"
                className={classes.input}
              />
            </Box>
          </Box>
        )}

        {!loading && (
          <Grid className={classes.root} container>
            {showLeftBlock &&
              (filteredOwnerMeters.length > 0 ?
                (<Grid item xs={12} lg={7} className={classes.leftContainer}>
                  <List>
                    {
                      filteredOwnerMeters.map(node => (
                        <Node
                          node={node}
                          handleSelectedNode={handleSelectedNode}
                          selectedNode={selectedNode}
                          key={node.id}
                        />
                      ))
                    }
                  </List>
                </Grid>
                ) :
                (
                  <Grid item xs={12} lg={7} className={classes.leftContainer}>
                    <Box className={classes.containerNoData}>
                      No hay medidores asociados a tu cuenta.
                    </Box>
                  </Grid>)
              )
            }

            {showRightBlock &&
              (selectedNode ? (
                <Grid item xs={12} lg={5} className={classes.rightContainer}>
                  {isMobile && (
                    <IconButton
                      onClick={handleBackPageClick}
                      style={
                        isMobile && { padding: '0px', marginBottom: '10px' }
                      }
                    >
                      <ArrowBackIos />
                    </IconButton>
                  )}

                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.titleContainer}
                  >
                    <Typography variant="h2" className={classes.title}>
                      <Box ml={1}>{selectedNode.nombreGuardado}</Box>
                    </Typography>
                  </Box>

                  <div className={classes.form}>
                    <Box
                      component="label"
                      display="block"
                      className={classes.label}
                    >
                      Nombre *
                    </Box>
                    <Box display="flex" alignItems="center">
                      <TextField
                        fullWidth
                        placeholder="Nombre"
                        name="nombre"
                        value={selectedNode.nombre || ''}
                        onChange={e => changeName(e)}
                        color="primary"
                        className={classes.input}
                      />
                    </Box>

                    <Box display="flex" justifyContent="center">
                      {loadingSave ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          className={classes.btn}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          onClick={handleSave}
                          disabled={loadingCleanData || loadingDelete}
                          color="primary"
                          variant="contained"
                          className={classes.btn}
                        >
                          Guardar
                        </Button>
                      )}
                      {loadingCleanData ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          className={classes.btn}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          onClick={() => {
                            handleConfirmationDialog('clean_data');
                          }}
                          disabled={true}
                          // disabled={loadingSave || loadingDelete}
                          variant="contained"
                          className={classes.btn}
                        >
                          Limpiar datos
                        </Button>
                      )}
                      {loadingDelete ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          className={classes.btn}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          disabled={true}
                          // disabled={loadingSave || loadingCleanData}
                          onClick={() => {
                            handleConfirmationDialog('delete_data');
                          }}
                          className={classes.btn}
                        >
                          Eliminar
                        </Button>
                      )}
                    </Box>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} lg={5} className={classes.rightContainer}>
                  <Box className={classes.containerUnselectedMeterText}>
                    <Typography
                      variant="h2"
                      className={classes.unselectedMeterText}
                    >
                      <Box ml={1}>
                        Seleccione un medidor para ver información
                      </Box>
                    </Typography>
                  </Box>
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </>
  );
}
