import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Box, Chip, Dialog, DialogTitle,
    List, ListItem, ListItemText, Snackbar,
    makeStyles, Grid, Button
} from '@material-ui/core';
import MultiNode from '../Instant/MultiNode';
import { Alert } from '@material-ui/lab';
import NodesContext from '../../context/NodesContext';
import TagsContext from '../../context/TagsContext';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LabelIcon from '@material-ui/icons/Label';
//servicios
import { getStatusNodes } from '../../services/meters';

let scrollsSettings = {
    overflow: 'scroll',
    height: '50vh',
    marginBottom: '10px',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(2, 4),
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
    },
    label: {
        marginLeft: theme.spacing(3),
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.primary.main,
        '@media (max-width: 700px)': {
            marginLeft: theme.spacing(1),
            marginBottom: '5px',
        },
    },
    selected: {
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    dialogTitle: {
        '@media (max-width: 700px)': {
            textAlign: 'center',
        },
    },
    dialogSubtitle: {
        marginLeft: theme.spacing(3),
        color: theme.palette.primary.main,
        '@media (max-width: 700px)': {
            display: 'none',
        },
    },
    devicesBtn: {
        margin: theme.spacing(3, 1),
    },
    scrollableContainer: {
        height: 'max-content',
    },
    selectedItemsContainer: {
        height: '35px',
    },
    containerNodes: {
        paddingRight: '2px',
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '@media (max-width: 1100px)': {
            width: '100%',
        },
    },
}));

const MultiSelectorNodeTag = ({ isOpen, setIsOpen, setActiveStep, setStateMultiSelector, stateComponent, flagOpenMultiSelector }) => {
    const classes = useStyles();
    const { nodes } = useContext(NodesContext);
    const { tags } = useContext(TagsContext);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'warning',
    });
    const [statusNodes, setStatusNodes] = useState([]);

    //Verifica status de el medidor.
    useEffect(() => {
        const getStatusNodesAux = async () => {
            let res = await getStatusNodes()
            let arry = res.data
            setStatusNodes(arry)
        }
        getStatusNodesAux()
    }, [])

    useEffect(() => {
        if (stateComponent?.nodes?.length >= 1 && flagOpenMultiSelector !== 'main') {
            setSelectedNodes([stateComponent?.nodes[flagOpenMultiSelector]])
            setSelectedLabel(stateComponent?.nodes[flagOpenMultiSelector]?.tag)
        }
        else {
            setSelectedNodes([])
            setSelectedLabel({})
        }
        // eslint-disable-next-line
    }, [isOpen])

    const nodeList = () => {
        return nodes?.map(node => (
            <MultiNode
                node={node}
                handleSelectedNode={handleSelectedNode}
                selectedNodes={selectedNodes}
                key={node.id}
                statusNodes={statusNodes}
            />
        ));
    };

    const labelsList = () => {
        return tags?.map(tag => (
            <ListItem
                key={tag.id}
                button
                onClick={() => handleSelectedLabel(tag.nombre, tag.id)}
                selected={selectedLabel ? selectedLabel.id === tag.id : false}
                className={classes.selected}
            >
                <ListItemText primary={tag.nombre} />
            </ListItem>
        ));
    };

    //validA q no exista una combinación igual q ya haya sido seleccionada
    const findEqualNode = (nodesSelected, node) => {
        let res = nodesSelected.find(e => e.id === node.id)
        return !!res?.id
    }


    const handleSelectedNode = node => {
        setActiveStep(3);

        //Valido q no exista una combinación igual q ya haya sido seleccionada
        if (findEqualNode(stateComponent?.nodes, node) && !selectedLabel?.id) {
            setNotification({
                isOpen: true,
                msg: 'Ha elegido un nodo que ya está seleccionado.',
                severity: 'warning',
            });
            return
        }

        setSelectedNodes(prevSelectedNodes => {
            const nodeIndex = prevSelectedNodes.findIndex(
                selectedNode => selectedNode.id === node.id
            );

            // Si el nodo está en la lista lo elimino
            if (nodeIndex >= 0) {
                return prevSelectedNodes.filter(
                    selectedNode => selectedNode.id !== node.id
                );
            }
            else {
                // Si tengo una etiqueta seleccionada
                if (selectedNodes.length > 0 && selectedLabel?.id) {
                    setNotification({
                        isOpen: true,
                        msg: 'Sólo es posible una selección múltiple si no tiene etiquetas seleccionadas.',
                        severity: 'warning',
                    });
                    return prevSelectedNodes;
                }
                const newSelectedNodes = [...prevSelectedNodes, node];
                return newSelectedNodes;
            }
        });
    };

    const handleSelectedLabel = (label, id) => {
        if (selectedNodes.length > 1) {
            return setNotification({
                isOpen: true,
                msg: 'Sólo es posible elegir etiquetas con un único dispositivo seleccionado.',
                severity: 'warning',
            });
        }
        // Si la etiqueta elegida es la misma que la actualmente seleccionado la deselecciono
        if (label === selectedLabel?.nombre && id === selectedLabel?.id)
            setSelectedLabel({});
        else setSelectedLabel({ nombre: label, id: id });
    };

    const handleCloseDevicesDialog = () => {
        // setSelectedNodes([]);       
        setIsOpen(!isOpen);
    };

    const handleDeleteNodeSelection = id => {
        setSelectedNodes(prevState => {
            const newNodes = prevState.filter(node => node.id !== id);
            return newNodes;
        });
    };

    const handleDeleteLabelSelection = () => {
        setSelectedLabel({});
        setStateMultiSelector({
            selectedNodes: selectedNodes,
            selectedLabel: {},
        })
    };

    const handleSave = () => {
        let auxSelectNode = selectedNodes
        //Entro en agregar un chip.
        if (flagOpenMultiSelector === 'main') {
            //Si tengo una etiqueta seleccionada.
            if (selectedLabel?.id) {
                let nodo = Object.assign({}, selectedNodes[0])
                nodo.tag = selectedLabel
                auxSelectNode = [nodo]
                //Si tengo una etiqueta seleccionada y hay mas de un chip.
                if (stateComponent?.nodes.length >= 1) {
                    auxSelectNode = [...stateComponent?.nodes, nodo]
                }
            }
            //Si no tengo etiqueta seleccionada.
            else {
                auxSelectNode = selectedNodes
                //Si no tengo etiqueta seleccionada y hay mas de un chip.
                if (stateComponent?.nodes.length >= 1) {
                    auxSelectNode = [...stateComponent?.nodes, ...selectedNodes]
                }
            }
            setStateMultiSelector({
                selectedNodes: auxSelectNode,
            })
        }
        //Edito un chip.
        else {
            if (selectedNodes.length > 1) {
                return setNotification({
                    isOpen: true,
                    msg: 'Sólo es posible modificar la etiqueta en modo edición.',
                    severity: 'warning',
                });
            }
            let arrayAux = stateComponent?.nodes
            let nodo = Object.assign({}, selectedNodes[0])
            nodo.tag = selectedLabel ? selectedLabel : undefined
            //modifico el array original
            arrayAux[flagOpenMultiSelector] = nodo

            setStateMultiSelector({
                selectedNodes: arrayAux,
            })

        }
        setIsOpen(!isOpen);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseDevicesDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            fullWidth
            disableScrollLock
        >
            <Snackbar
                open={notification.isOpen}
                autoHideDuration={2000}
                onClose={() => setNotification({ ...notification, isOpen: false })}
            >
                <Alert
                    severity={notification.severity}
                    onClose={() => setNotification(false)}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

            <Box padding="8px">
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    Selección de Dispositivos para gráfico
                </DialogTitle>
                <Box className={classes.dialogSubtitle}>
                    Seleccionar uno o más elementos de la organización o un elemento y
                    una etiqueta.
                </Box>

                <Box display="flex" className={classes.selectedItemsContainer}>
                    {selectedNodes.map(node => (
                        <Chip
                            key={node?.id}
                            className={classes.wordCut}
                            icon={<AccountTreeIcon />}
                            label={node?.nombre}
                            clickable
                            color="primary"
                            onDelete={() => handleDeleteNodeSelection(node?.id)}
                        />
                    ))}

                    {selectedLabel?.nombre ? (
                        <Chip
                            icon={<LabelIcon />}
                            label={selectedLabel?.nombre}
                            clickable
                            color="secondary"
                            onDelete={handleDeleteLabelSelection}
                        />
                    ) : null}
                </Box>

                <Grid container display="block">
                    <Grid item xs={12} md={6}>
                        <Box component="label" display="block" className={classes.label}>
                            Organización
                        </Box>

                        <Box className={classes.containerNodes}>
                            {nodes?.length === 0 && (
                                <h2
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '19px',
                                        color: '#2586bc',
                                        paddingTop: '20px',
                                    }}
                                >
                                    Usted no posee ninguna localización configurada en su
                                    cuenta.<br></br>
                                    Por favor, configure al menos una localización para poder
                                    continuar.
                                    <NavLink
                                        className={classes.navLink}
                                        to={`/configuracion-organizacion`}
                                    >
                                        [link]
                                    </NavLink>
                                </h2>
                            )}
                            <List component="div" disablePadding dense>
                                {nodeList()}
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box component="label" display="block" className={classes.label}>
                            Etiquetas
                        </Box>
                        <List component="div" disablePadding dense>
                            <List
                                component="div"
                                disablePadding
                                dense
                                className={classes.scrollableContainer}
                            >
                                {labelsList()}
                            </List>
                        </List>
                    </Grid>
                </Grid>

                <Box display="flex" justifyContent="center">
                    <Button
                        className={classes.devicesBtn}
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={selectedNodes?.length === 0}
                    >
                        {stateComponent?.nodes?.length >= 1 && flagOpenMultiSelector !== 'main' ? 'Editar' : 'Agregar'}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCloseDevicesDialog}
                        className={classes.devicesBtn}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Box>

        </Dialog>
    )
}

export default MultiSelectorNodeTag
