import React from 'react';
import { Link } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import TopNav from './common/TopNav';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
	leftSpacer: {
		marginLeft: theme.spacing(8),
		'@media (max-width: 720px)': {
			marginLeft: 0
		}
	},
	loginLink: {
		padding: theme.spacing(1, 2),
		background: 'white',
		textDecoration: 'none',
		borderRadius: '3px',
		marginLeft: theme.spacing(1),
		'&:visited': {
			color: theme.palette.primary.main,
		},
		'&:hover': {
			background: theme.palette.primary.main,
			color: 'white',
		},
		'@media (max-width: 720px)': {
			display:'block',
			padding: theme.spacing(0.5),
			width: 'fit-content',
			marginRight: 'auto',
			marginTop: theme.spacing(1)
		}
	},
}));

const Unauthorized = () => {
	const classes = useStyles();
	return (
		<>
			<TopNav titulo='403 - No Autorizado' />
			<Box className={classes.leftSpacer} display='flex' justifyContent='center' flexDirection='column'>
				<Alert severity='info'>
					Usted no tiene suficientes permisos para acceder a esta sección
					<Link to='/' className={classes.loginLink}>
						Volver al Inicio
					</Link>
				</Alert>
			</Box>
		</>
	);
};

export default Unauthorized;
