import { useContext, useEffect, useState } from 'react';
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { Alert } from '@mui/material';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
// Componentes
import PieChart from './PieChart';
// Servicios
import { getHistoryV2 } from '../../../services/meters';
//Context Provider
import { DefaultDevice } from './Summary';

const useStyles = makeStyles(theme => ({
  btn: {
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      textTransform: 'none',
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  cancelBtn: {
    '& .MuiButton-label': {
      color: theme.palette.secondary.main,
      textTransform: 'none',
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    height: '100%'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  defaultDevice: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  devicesText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  title: {
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(0, 0, 1, 3),
    fontWeight: '600',
    paddingTop: theme.spacing(1.5),
    color: theme.palette.grey.hairline,
  },
}));

export default function Pie({ hideFilter = false }) {
  const defaultDevice = useContext(DefaultDevice);
  const classes = useStyles();
  const [pieData, setPieData] = useState({});
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const { id } = defaultDevice;
    if (id) {
      setLoading(true);
      let url = `filter=${id}`
      url += `&fields=kwh`
      url += `&showPhase=true`
      url += `&period=monthly`
      url += `&range=ESTE_ANO`
      fetchHistory(url);
    }
    // eslint-disable-next-line
  }, [defaultDevice]);

  const fetchHistory = async url => {
    try {
      const res = await getHistoryV2(url);
      setError(false)
      setLoading(false)
      //Obtengo los últimos tres elementos del array
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentData = res?.data?.[0]?.data?.[currentMonth];

      if (checkDataValue(currentData)) {
        setEmpty(true);
      }
      else {
        setEmpty(false);
      }
      setPieData(currentData);
    }
    catch (error) {
      setLoading(false)
      setError(true)
    }
  };

  //Verifico si todas las propiedades de el array 'kwn'sean igual a cero o tengan un valor mayor.
  const checkDataValue = (dataValues) => {
    return dataValues.every(obj => obj.kwh === 0);
  };

  // 2023-02-01 > febrero 2023
  function formatDate(dateString) {
    const date = parseISO(dateString);
    const formattedMonth = format(date, 'MMMM yyyy', { locale: es });
    return formattedMonth;
  }

  return (
    <>
      {loading && (
        <Box className={classes.circular}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        < Box className={classes.root} >

          {(pieData.length === 0 || empty === true || error) &&
            <Box className={classes.alert}>
              <Alert severity="error">{'No se dispone de datos suficientes para mostrar este gráfico.'}</Alert>
            </Box>
          }
          {!empty && pieData.length > 0 && !error && (
            <Box mt={1}>
              <Box className={classes.title}>
                {'Energía Mensual'}{' '}
                <span style={{ fontWeight: '300' }}>{'| Por fase'}</span>
              </Box>
              <PieChart data={pieData} empty={empty} />
              <Box style={{ textAlign: 'center' }}>
                {formatDate(pieData[0]?.fecha_)}
              </Box>
            </Box>
          )}
        </Box >
      )}
    </>
  );
}
