// react
import React, { useContext, useState } from 'react';

// @material-ui
import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// @material-ui/icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// react-router-dom
import { Redirect } from 'react-router-dom';
// components
import TopNav from '../common/TopNav';
// services
import {
  getAPIToken,
  saveTokenInLocalStorage,
  saveUserInfoInLocalStorage,
  saveNodesInLocalStorage,
  saveNodesListInLocalStorage,
  saveTagsInLocalStorage,
} from '../../services/auth';
import { getPlanInfo } from '../../services/client';
import { getNodes, getNodesTree, getTags } from '../../services/hierarchy';
// context
import UserContext from '../../context/UserContext';
import NodesContext from '../../context/NodesContext';
import NodesListContext from '../../context/NodesListContext';
import TagsContext from '../../context/TagsContext';
import { registerUser } from '../../service_worker/helpers';

// constants
const { REACT_APP_API_URL } = process.env;
const baseURL = REACT_APP_API_URL;
const RESETuRL = baseURL + '/accounts/password/reset/';
const SIGNUPuRL = baseURL + '/accounts/signup/';

const useStyles = makeStyles(theme => ({
  logo: {
    width: '300px',
    margin: theme.spacing(0, 5),
  },
  title: {
    textAlign: 'center',
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 23,
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    maxWidth: '28rem',
    margin: '0 auto',
  },
  leftSpacer: {
    marginLeft: theme.spacing(8),
  },
  input: {
    '& .MuiInputBase-input': {
      fontSize: 16,
      padding: theme.spacing(3, 0),
    },
  },
  firstBtn: {
    margin: theme.spacing(3, 0, 1, 0),
  },
  btn: {
    margin: theme.spacing(1, 0),
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
}));

function Login({ user, setUser, setIsLogingOut, setRefresh }) {
  const classes = useStyles();
  const { plan, setPlan } = useContext(UserContext);
  const { setNodes } = useContext(NodesContext);
  const { setNodesList } = useContext(NodesListContext);
  const { setTags } = useContext(TagsContext);
  const [userData, setUserData] = useState({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const handleChange = e => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault();
    //Creo el service worker a penas abre la app asi ya esta registrada.
    registerUser()
    // console.log('userData', userData);
    setIsLogingOut(false);
    try {
      const res = await getAPIToken(userData);
      // Guardo el token en el localStorage
      saveTokenInLocalStorage(res.data.token);
      setUser(true);
      setErrorMessage('');

      const res2 = await getPlanInfo();
      saveUserInfoInLocalStorage(res2.data);
      setPlan(res2.data);
      //Actualizo option bar para q carguen los dashboard personalizados.
      setRefresh(prevRefresh => !prevRefresh);
      setLoading(false)
      
      const res3 = await getNodesTree();
      saveNodesInLocalStorage(res3.data);
      setNodes(res3.data);

      const res4 = await getNodes();
      saveNodesListInLocalStorage(res4.data);
      setNodesList(res4.data);

      const res5 = await getTags();
      saveTagsInLocalStorage(res5.data);
      setTags(res5.data);

    }
    catch (error) {
      // console.log(error)
      // Muestro el error devuelto
      setErrorMessage(
        `${error.response?.data.non_field_errors} (${error.response?.status})`
      );
      // console.log(error.response);
    }
    setLoading(false)

  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      <TopNav titulo="Login" />




      {loading ?
        <div className={classes.circular}>
          <CircularProgress />
        </div>
        :
        <>
          {!plan?.info?.username ? (
            <>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '24px' }}
              >
                <img className={classes.logo} src="/images/logo.svg" alt="logo" />
                <p className={classes.title}>Iniciar Sesión</p>
                <form className={classes.loginForm} onSubmit={handleSubmit}>
                  <Input
                    placeholder="Correo Electrónico"
                    name="username"
                    value={userData.username}
                    onChange={handleChange}
                    color="primary"
                    className={classes.input}
                    type="email"
                  />

                  <Input
                    placeholder="Contraseña"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={userData.password}
                    onChange={handleChange}
                    color="primary"
                    className={classes.input}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Button
                    className={classes.firstBtn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    // Quiero que el botón esté deshabilitado si username o password están vacíos
                    disabled={
                      !userData.username || !userData.password ? true : false
                    }
                  >
                    Ingresar
                  </Button>
                  <Button
                    className={classes.btn}
                    color="primary"
                    variant="outlined"
                    href={SIGNUPuRL}
                  >
                    Crear cuenta
                  </Button>
                  <Button
                    className={classes.btn}
                    color="primary"
                    variant="outlined"
                    href={RESETuRL}
                  >
                    Olvidé mi Contraseña
                  </Button>
                  {/* Ponemos errorMessage.toString() para evitar "Objects are not valid as a React child" */}
                  {errorMessage ? (
                    <Alert className={classes.alert} severity="error">
                      {errorMessage.toString()}
                    </Alert>
                  ) : null}
                </form>
              </Grid>
            </>
          )
            :
            (
              <Redirect to="/" />
            )}
        </>

      }

    </>
  );
}

export default Login;
