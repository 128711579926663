export const FILTER_INITIAL_VALUES = {
  selectedDate: new Date(),
  dispositivos: [],
  variable: '',
  granularity: '',
  showPhase: false,
  variableFields: '',
  variableName:''
};

export const CALENDAR_INITIAL_CONFIG = {
  views: ['month', 'year'],
  disabled: false,
  format: 'MM/yyyy',
};
